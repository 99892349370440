import React from 'react';

import Icon from '../Icon';

const IconFolder = (props) => {
  const {
    size,
    inverse,
    onClick,
    role,
    dataTooltipId,
    dataTooltipContent,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-folder"
      role={role}
      dataTooltipId={dataTooltipId}
      dataTooltipContent={dataTooltipContent}
    >
      <svg viewBox="0 0 22 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.8801 2.95312L11.1325 1.47229H20.1667C21.1796 1.47229 22 2.23161 22 3.15961V3.60286C21.5129 3.20029 20.8714 2.95312 20.1668 2.95312H11.8801Z" />
        <path d="M1.83333 0H8.21333C8.92376 0 9.57459 0.383932 9.87252 0.978675L11.0504 3.31141C11.1971 3.60667 11.5271 3.79647 11.88 3.79647H20.1667C21.1796 3.79647 22 4.55579 22 5.4838V14.3127C22 15.245 21.1796 16 20.1667 16H1.83333C0.824994 16 -4.76837e-07 15.245 -4.76837e-07 14.3127V1.68732C-4.76837e-07 0.759315 0.824994 0 1.83333 0Z" />
      </svg>
    </Icon>
  );
};

export default IconFolder;
