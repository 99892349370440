import React, { useState, useEffect } from 'react';

import { IconChevronDown, IconBlockedRound } from '../Icons';
import useClickOutside from '../../hooks/useClickOutside';

import './SelectControl.scss';

const SelectControl = (props) => {
  const {
    label,
    name,
    value,
    onChange,
    placeholder,
    errorMessage,
    children,
    required,
    disabled,
    maxAmount = 6,
  } = props;

  const [activeSelect, setActiveSelect] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  const disabledClassName = disabled ? 'disabled' : '';
  const activeClassName = activeSelect ? 'active' : '';
  const errorClassName = errorMessage ? 'error' : '';
  const placeholderClassName = value ? '' : 'placeholder';
  const requiredClassName = required ? 'c-select-control_required' : '';
  const bottomText = errorMessage || '';
  const selectClassName = `c-select-control ${requiredClassName} ${errorClassName} ${activeClassName} ${disabledClassName}`;
  const selectTestClassName = `c-select-control__select-text ${placeholderClassName}`;

  const refSelect = useClickOutside(() => {
    setActiveSelect(false);
  });

  const handleDropDownList = () => {
    setActiveSelect(!activeSelect);
  };

  const handleClickOption = (event, optionValue) => (
    onChange({
      ...event,
      currentTarget: {
        ...event.currentTarget,
        name,
        value: optionValue,
      },
    })
  );

  useEffect(() => {
    if (value !== selectedValue) {
      setSelectedValue(value);
    }
  }, [value]);

  return (
    <label
      ref={refSelect}
      className={selectClassName}
    >
      <p className="c-select-control__label-text">{label}</p>
      <input
        className="c-select-control__select"
        value={selectedValue}
        placeholder={placeholder}
        name={name}
        readOnly
      />
      <p className={selectTestClassName}>
        {children.find((child) => child.key === selectedValue)?.props.text || <span className="c-select-control__select-placeholder-text">{placeholder}</span>}
      </p>
      <ul
        style={{ height: maxAmount * 40 }}
        className="c-select-control__list"
        onClick={handleDropDownList}
      >
        {children.map((child) => (
          <div
            className="c-select-control__wrap-option"
            key={child.props.value}
            onClick={(e) => handleClickOption(e, child.props.value)}
          >
            {child}
          </div>
        ))}
      </ul>
      <div
        onClick={handleDropDownList}
        className="c-select-control__icon-wrap"
      >
        {disabled ? (
          <IconBlockedRound size="sm" />
        )
          : (<IconChevronDown size="sm" />)}
      </div>
      <span className="c-select-control__bottom-text">
        {bottomText}
      </span>
    </label>
  );
};

export default SelectControl;
