import React from 'react';

// Component
import { IconCalendar } from '../Icons';

// Style
import './TaskDate.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const TaskDate = (props) => {
  const {
    data,
    updateActiveTaskMutation,
    isMobile,
  } = props;

  const {
    startDate,
    endDate,
  } = data;

  const I18N = useI18N();
  const taskDateClassName = !isMobile ? 'c-task-date' : 'c-task-date mobile';

  const updateStartDate = (event) => {
    const updatedStartDate = {
      startDate: event.currentTarget.value,
      id: data.id,
    };
    updateActiveTaskMutation(updatedStartDate);
  };

  const updateEndDate = (event) => {
    const updatedEndDate = {
      endDate: event.currentTarget.value,
      id: data.id,
    };
    updateActiveTaskMutation(updatedEndDate);
  };

  return (
    <div className={taskDateClassName}>
      <div className="c-task-date__item">
        <div className="c-task__title">
          {I18N.DATA_START}
        </div>
        <div className="c-task-date__item__input">
          <input
            type="date"
            name="startDate"
            value={startDate || ''}
            onChange={updateStartDate}
            className={startDate ? 'c-task-date__item__input__has-value' : ''}
          />
          {!startDate && <span className="c-task-date__item__input__placeholder">{I18N.ENTER_DATE}</span>}
        </div>
        <IconCalendar size="sm" />
      </div>
      <div className="c-task-date__item">
        <div className="c-task__title">
          {I18N.DATA_END}
        </div>
        <div className="c-task-date__item__input">
          <input
            type="date"
            name="endDate"
            value={endDate || ''}
            onChange={updateEndDate}
            className={endDate ? 'c-task-date__item__input__has-value' : ''}
          />
          {!endDate && <span className="c-task-date__item__input__placeholder">{I18N.ENTER_DATE}</span>}
        </div>
        <IconCalendar size="sm" />
      </div>
    </div>
  );
};

export default TaskDate;
