import React from 'react';
import Icon from '../Icon';

const IconBurger = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-burger"
    >
      <svg
        viewBox="0 0 28 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-outside-1_4477_5704" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="18">
          <rect fill="white" width="28" height="18" />
          <path d="M2 2H26ZM2 9H26ZM2 16H26Z" />
        </mask>
        <path d="M2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4V0ZM26 4C27.1046 4 28 3.10457 28 2C28 0.895431 27.1046 0 26 0V4ZM2 7C0.895431 7 0 7.89543 0 9C0 10.1046 0.895431 11 2 11V7ZM26 11C27.1046 11 28 10.1046 28 9C28 7.89543 27.1046 7 26 7V11ZM2 14C0.895431 14 0 14.8954 0 16C0 17.1046 0.895431 18 2 18V14ZM26 18C27.1046 18 28 17.1046 28 16C28 14.8954 27.1046 14 26 14V18ZM2 4H26V0H2V4ZM2 11H26V7H2V11ZM2 18H26V14H2V18Z" mask="url(#path-1-outside-1_4477_5704)" />
      </svg>
    </Icon>
  );
};

export default IconBurger;
