import React from 'react';
import Icon from '../Icon';

const IconSearch = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (

    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-search"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.66575 1.6665C7.59774 1.66647 8.51118 1.92691 9.30301 2.41845C10.0948 2.90999 10.7335 3.61306 11.147 4.44831C11.5604 5.28356 11.7322 6.21774 11.643 7.14544C11.5537 8.07314 11.2069 8.95743 10.6418 9.6985L13.8038 12.8618C13.9233 12.9818 13.9927 13.1428 13.9979 13.3121C14.0031 13.4814 13.9436 13.6463 13.8316 13.7734C13.7196 13.9004 13.5634 13.98 13.3948 13.9961C13.2262 14.0122 13.0577 13.9634 12.9238 13.8598L12.8611 13.8045L9.69775 10.6425C9.06642 11.1239 8.32953 11.448 7.54805 11.5881C6.76657 11.7281 5.96297 11.6801 5.20375 11.4479C4.44453 11.2157 3.75152 10.8061 3.18205 10.2529C2.61259 9.69966 2.18305 9.0188 1.92897 8.26663C1.67489 7.51445 1.60359 6.71258 1.72095 5.92738C1.83831 5.14217 2.14097 4.3962 2.60388 3.75119C3.06679 3.10618 3.67665 2.58068 4.38298 2.21816C5.08932 1.85565 5.87182 1.66655 6.66575 1.6665ZM6.66575 2.99984C5.69329 2.99984 4.76066 3.38615 4.07303 4.07378C3.38539 4.76141 2.99909 5.69404 2.99909 6.6665C2.99909 7.63896 3.38539 8.5716 4.07303 9.25923C4.76066 9.94686 5.69329 10.3332 6.66575 10.3332C7.63821 10.3332 8.57084 9.94686 9.25848 9.25923C9.94611 8.5716 10.3324 7.63896 10.3324 6.6665C10.3324 5.69404 9.94611 4.76141 9.25848 4.07378C8.57084 3.38615 7.63821 2.99984 6.66575 2.99984Z" fill="#A4A4A4" />
      </svg>

    </Icon>
  );
};

export default IconSearch;
