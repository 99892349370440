import React from 'react';

// Components
import UserAvatar from '../UserAvatar';

// Styles
import './UserField.scss';

const UserField = (props) => {
  const {
    user,
    size,
    onClick,
    showEmail,
    showWithoutAuthor,
  } = props;

  const sizeClassName = size ? `c-user-field_${size}` : 'c-user-field_md';
  const clickableClassName = onClick ? 'clickable' : '';
  const userFieldClassName = `c-user-field ${sizeClassName} ${clickableClassName}`;

  const handleClick = (event) => {
    if (typeof onClick === 'function') {
      return onClick(event);
    }
    return null;
  };

  return (
    <div className={userFieldClassName}>
      <UserAvatar
        userFirstName={user?.firstName}
        userLastName={user?.lastName}
        userImageUrl={user?.userImageUrl}
        onClick={onClick}
        size={size}
      />
      {user && (
        <div onClick={handleClick} className="c-user-field__user-info">
          <span className="c-user-field__user-name">{user.userName}</span>
          {showEmail && (
          <>
            <br />
            <span className="c-user-field__user-email">{user.email}</span>
          </>
          )}
        </div>
      )}
      {(!user && showWithoutAuthor) && (<span className="c-user-field__user-name">Without an author</span>)}
    </div>
  );
};

export default UserField;
