import React from 'react';
import Icon from '../Icon/Icon';

const IconArrowLeft = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;
  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-arrow-left">
      <svg width="8" height="14" viewBox="0 0 8 16" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M7.78501 1.34901C7.85369 1.27268 7.90737 1.18288 7.94301 1.08476C7.97865 0.986633 7.99553 0.882096 7.99271 0.777116C7.98988 0.672136 7.96739 0.568769 7.92653 0.472916C7.88567 0.377064 7.82724 0.290603 7.75457 0.218471C7.68189 0.146339 7.59641 0.0899478 7.50299 0.0525173C7.40957 0.0150869 7.31004 -0.00264992 7.21009 0.000319814C7.11015 0.00328955 7.01174 0.0269075 6.92048 0.0698253C6.82922 0.112743 6.74691 0.17412 6.67823 0.250452L0.208067 7.44623C0.0744498 7.59468 0 7.79122 0 7.99551C0 8.1998 0.0744498 8.39634 0.208067 8.54478L6.67823 15.7414C6.74645 15.8194 6.82875 15.8824 6.92034 15.9267C7.01194 15.971 7.111 15.9957 7.21178 15.9995C7.31255 16.0033 7.41304 15.9859 7.50739 15.9486C7.60175 15.9112 7.6881 15.8545 7.76141 15.7818C7.83473 15.7091 7.89357 15.6218 7.93449 15.525C7.97542 15.4282 7.99762 15.3238 7.99982 15.2179C8.00202 15.112 7.98416 15.0067 7.94728 14.9081C7.91041 14.8095 7.85525 14.7196 7.78501 14.6436L1.8081 7.99551L7.78501 1.34901Z" fill="#515151" />
      </svg>
    </Icon>
  );
};

export default IconArrowLeft;
