import React from 'react';
import Icon from '../Icon/Icon';

const IconAdditionalActions = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-additional_actions">
      <svg width="26" height="6" viewBox="0 0 26 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="3" cy="3" r="3" fill="#515151" />
        <circle cx="13" cy="3" r="3" fill="#515151" />
        <circle cx="23" cy="3" r="3" fill="#515151" />
      </svg>
    </Icon>
  );
};

export default IconAdditionalActions;
