import React from 'react';
import { useSearchParams } from 'react-router-dom';

// Component
import AuthForm from '../../components/AuthForm';

// Style
import './AuthView.scss';

// Constants
import { AUTH_FORM_TYPE } from '../../config/config';

const AuthView = () => {
  const [searchParams] = useSearchParams();

  const queryType = searchParams.get('type');

  const authType = queryType ? AUTH_FORM_TYPE[queryType] : AUTH_FORM_TYPE.LOGIN;

  return (
    <div className="v-auth">
      <AuthForm
        authFormType={authType}
      />
    </div>
  );
};

export default AuthView;
