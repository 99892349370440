import React from 'react';

import './CheckboxControl.scss';

const CheckboxControl = (props) => {
  const {
    children,
    description,
    disabled,
    errorMessage,
    isValid,
    label,
    name,
    onBlur,
    onChange,
    onClick,
    required,
    value,
  } = props;

  const requiredClassName = required ? 'c-checkbox-control_required' : '';
  const checkboxClassName = `c-checkbox-control ${requiredClassName}`;
  const bottomText = errorMessage || '';
  const handleBlur = (event) => (typeof onBlur === 'function' ? onBlur(event) : null);

  const handleClickCheckbox = (event) => {
    onChange({
      ...event,
      currentTarget: {
        ...event.currentTarget,
        name,
        value: event.currentTarget.checked,
      },
    });
  };

  return (
    <div className={checkboxClassName}>
      {label && <label htmlFor={name} className="c-checkbox-control__title">{label}</label>}
      <input
        id={name}
        name={name}
        type="checkbox"
        value={value}
        onBlur={handleBlur}
        checked={value}
        onChange={handleClickCheckbox}
        className={`c-checkbox-control__input${disabled ? ' disable' : ''}`}
        onClick={onClick}
      />
      <label htmlFor={name} className="c-checkbox-control__description">{description || children}</label>
      { !isValid && (<span className="c-checkbox-control__bottom-text">{bottomText}</span>)}
    </div>
  );
};

export default CheckboxControl;
