import React, { useState, useEffect, useRef } from 'react';
import { COLOR_PALETTE_DEFAULT } from '../../config/config';

import './ColorPalette.scss';

const ColorPalette = (props) => {
  const {
    onClick,
    palette,
    selectedColor,
  } = props;

  const currentPalette = palette || COLOR_PALETTE_DEFAULT;

  const [selected, setSelected] = useState(selectedColor);
  const [tempSelected, setTempSelected] = useState(selectedColor);

  const colorRefs = useRef([]);

  const selectedButtonClassName = (item) => (item === selected
    ? 'c-color-palette__color c-color-palette__color_selected'
    : 'c-color-palette__color');

  const handleClick = (item) => {
    setSelected(item);
    setTempSelected(item);
    onClick(item);
  };

  const handleKeyDown = (event, index) => {
    let newIndex;
    switch (event.key) {
      case 'ArrowLeft':
        newIndex = index > 0 ? index - 1 : currentPalette.length - 1;
        break;
      case 'ArrowRight':
        newIndex = index < currentPalette.length - 1 ? index + 1 : 0;
        break;
      case 'ArrowUp':
        newIndex = index - 3 >= 0 ? index - 3 : currentPalette.length - 3 + index;
        break;
      case 'ArrowDown':
        newIndex = index + 3 < currentPalette.length
          ? index + 3
          : (index + 3) % currentPalette.length;
        break;
      case 'Enter':
        handleClick(tempSelected);
        return;
      default:
        return;
    }
    setTempSelected(currentPalette[newIndex]);
    colorRefs.current[newIndex].focus();
  };

  const colors = currentPalette.map((item, index) => (
    <button
      key={item}
      type="button"
      ref={(el) => {
        colorRefs.current[index] = el;
      }}
      className={selectedButtonClassName(item)}
      onClick={() => handleClick(item)}
      onKeyDown={(event) => handleKeyDown(event, index)}
      style={{ backgroundColor: item }}
    />
  ));

  useEffect(() => {
    if (selectedColor) {
      setSelected(selectedColor);
      setTempSelected(selectedColor);
      const selectedIndex = currentPalette.indexOf(selectedColor);
      if (selectedIndex !== -1) {
        colorRefs.current[selectedIndex].focus();
      }
    }
  }, [selectedColor, currentPalette]);

  return <ul className="c-color-palette">{colors}</ul>;
};

export default ColorPalette;
