import React from 'react';
import Icon from '../Icon';

const IconColorPattern = (props) => {
  const { size, inverse, onClick } = props;
  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-color-pattern">
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.38415 0.157349C3.27782 0.763613 0.774637 3.26055 0.162122 6.3575C-0.994155 12.2014 4.27785 16.5577 8.24981 15.9421C9.53735 15.7421 10.1686 14.2358 9.57797 13.0764C8.85608 11.6576 9.88735 10.0013 11.4811 10.0013H13.9718C15.0906 10.0013 15.9969 9.07631 16 7.96066C15.9844 3.03554 11.503 -0.83955 6.38415 0.157349ZM2.99969 10.0013C2.44655 10.0013 1.99967 9.55445 1.99967 9.00131C1.99967 8.44817 2.44655 8.00129 2.99969 8.00129C3.55283 8.00129 3.99971 8.44817 3.99971 9.00131C3.99971 9.55445 3.55283 10.0013 2.99969 10.0013ZM3.99971 6.00124C3.44658 6.00124 2.99969 5.55435 2.99969 5.00121C2.99969 4.44808 3.44658 4.00119 3.99971 4.00119C4.55285 4.00119 4.99974 4.44808 4.99974 5.00121C4.99974 5.55435 4.55285 6.00124 3.99971 6.00124ZM7.99981 4.00119C7.44667 4.00119 6.99979 3.55431 6.99979 3.00117C6.99979 2.44803 7.44667 2.00114 7.99981 2.00114C8.55295 2.00114 8.99983 2.44803 8.99983 3.00117C8.99983 3.55431 8.55295 4.00119 7.99981 4.00119ZM11.9999 6.00124C11.4468 6.00124 10.9999 5.55435 10.9999 5.00121C10.9999 4.44808 11.4468 4.00119 11.9999 4.00119C12.553 4.00119 12.9999 4.44808 12.9999 5.00121C12.9999 5.55435 12.553 6.00124 11.9999 6.00124Z" />
      </svg>
    </Icon>
  );
};

export default IconColorPattern;
