export const messages = {
  en: {
    translation: {
      ABOUT: 'About application',
      ACCEPT: 'Accept',
      ADD: 'Add',
      ADD_COLUMN: 'Add column',
      ADD_FAVORITE: 'Add to favorite',
      ADD_FILTER: 'Add filter',
      ADD_LINK: 'Add link',
      ADD_STATUS: 'Add status',
      ADD_TAG: 'Add tag',
      ADD_TASK: 'Add task',
      ADD_TIME: 'Add time',
      ADD_USER: 'Add user',
      AGREEMENT: 'I agree with the ',
      APPLICATION_NAME: 'Application Name',
      APPLY: 'Apply',
      ARCHIVE: 'Archive',
      ASSIGNED: 'Assigned',
      ASSIGNED_USER: 'Assigned user',
      ARCHIVE_BOARD: 'Archive board',
      ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
      BACK_TO: 'Back to',
      BOARD: 'Board',
      BOARDS: 'Boards',
      BOARD_ACTIONS: 'Board actions',
      BOARD_CREATED: 'Board created',
      BOARD_DELETED: 'Board deleted!',
      BOARD_INFO: 'Board info',
      BOARD_PREFIX: 'Board prefix',
      BOARD_TEAM: 'Board team',
      BOARD_TITLE: 'Board title',
      BOARD_UPDATED: 'Board updated!',
      BACKLOG: 'Backlog',
      CANCEL: 'Cancel',
      CHANGE_BOARD: 'Change board',
      CHANGE_PASSWORD: 'Change password',
      CHANGE_STATUS: 'Change status',
      CHANGING_TASK_INDEX: 'Changing the task index',
      CHECKLIST: 'Checklist',
      CHECK_EMAIL: 'Check your email!',
      CLOSE_CHECKLIST: 'Close checklist',
      COLOR_MARK: 'Color mark',
      COLOR_THEME: 'Color theme',
      CONFIRM_PASSWORD: 'Confirm password',
      CONTACT_FORM: 'Contact form',
      CONTACT_US: 'Contact us',
      CONTAIN_INJECTIONS: 'Input data contain injections',
      CONTINUE_WITH: 'Continue with ',
      COPYRIGHT_TEXT: 'Copyright text',
      CREATED_BY: 'Created by',
      CREATE_NEW_BOARD: 'Create board',
      CREATE_NEW_COLUMN: 'Create new column',
      CREATE_NEW_TASK: 'Create new task',
      CREATE_TASK: 'Create task',
      CREATE_YOUR_ACCOUNT_BELLOW: 'Create your account bellow',
      CREATED: 'created',
      CUSTOM: 'custom',
      CURRENT_PASSWORD: 'Current password',
      DARK: 'Dark',
      DATA_END: 'End date',
      DATA_NOT_SAVED: 'Data not saved',
      DATA_START: 'Start date',
      DATE: 'Date',
      DELETE: 'Delete',
      DELETE_ACCOUNT: 'Delete account',
      DELETE_BOARD: 'Delete board',
      DELETE_LOGGED_TIME: 'Delete logged time',
      DELETE_RECORD: 'Delete record',
      DELETE_STATUS: 'Delete status',
      DELETE_TASK: 'Delete task',
      DELETE_TIME: 'Delete time',
      DELETE_USER: 'Delete user',
      DESCRIPTION: 'Description',
      DESCRIPTION_ERROR_403: 'You do not have permission to view this page. Please check your credentials and try again.',
      DETAILS: 'Details',
      DEVELOPED_BY_LANADEV: 'Developed by LanaDev',
      DONT_HAVE_AN_ACCOUNT: 'Don’t have an account?',
      EDIT: 'Edit',
      EDIT_TIME: 'Edit time',
      EMAIL: 'Email',
      EMPTY_FIELDS: 'Empty fields',
      END: 'End',
      ENTER_BOARD_NAME: 'Enter board name',
      ENTER_DATE: 'Enter date',
      ENTER_LINK: 'Enter link',
      ENTER_NEW_ITEM: 'Enter new item',
      ENTER_NEW_TAG: 'Enter new tag',
      ENTER_TIME: 'Enter time',
      ENTER_USER_NAME_OR_EMAIL: 'Enter username or email',
      ENTER_YOUR_CREDENTIALS: 'Enter your credentials to continue',
      ENTER_YOUR_EMAIL: 'Enter your email',
      ENTER_YOUR_MESSAGE: 'Enter your message',
      ENTER_YOUR_NAME: 'Enter your name',
      ENTER_YOUR_SUBJECT: 'Enter your subject',
      EQUAL_PASSWORDS: 'Passwords should be equal',
      ESTIMATED_TIME: 'Estimated time',
      ESTIMATION: 'Estimation',
      EXAMPLE: 'Example',
      FAQ: 'Frequently Asked Questions',
      FAVORITE: 'Favorite',
      FIELDS_NOT_EQUAL: 'Fields should be equal',
      FILTERING: 'Filtering',
      FILTERS: 'Filters:',
      FIND_USER: 'Find user',
      FIRST_NAME: 'First name',
      FOLLOW_US: 'Follow us',
      FORGOT_PASSWORD: 'Forgot password',
      FORGOT_YOUR_PASSWORD: 'Forgot your password?',
      GENERAL_INFO: 'General info',
      HIDE_ALL: 'hide all',
      GET_IN_TOUCH: 'Get in touch',
      GO_TO_BOARD: 'Go to board',
      HI_WELCOME_BACK: 'Hi, Welcome Back',
      GO_BACK: 'Go back',
      GO_HOME: 'Go home',
      GO_TO_THE_PREVIOUS_PAGE: 'Go to the previous page',
      HIGH: 'High',
      HOME: 'Home',
      INCORRECT_DATA_FORMAT: 'Incorrect data format!',
      INFO: 'Info',
      ITEMS: 'Item(-s)',
      LANGUAGES: 'Languages',
      LAST_NAME: 'Last name',
      LEAVE: 'Leave',
      LEAVE_BOARD: 'Leave board',
      LESS_ACTIONS: 'Less actions',
      LIGHT: 'Light',
      LINK: 'Link',
      LOAD: 'LOAD',
      LOGGED_TIME: 'Logged time',
      LOGOUT: 'Logout',
      LOW: 'Low',
      MEDIUM: 'Medium',
      MESSAGE: 'Message',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX_CREATED_TASK: 'The expected task index {{expectedBoardIndex}} the created task is changed to {{newBoardIndex}} since task index {{expectedBoardIndex}} is already used on the board.',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX: 'The task index has been changed from {{oldBoardIndex}} to {{newBoardIndex}} because the index {{oldBoardIndex}} is already occupied on this board.',
      MESSAGE_ABOUT_MOBILE_VERSION: 'Mobile version coming soon',
      MESSAGE_ABOUT_UNSAVED_DATA:
        'The changes is not saved and will be lost on close window. Please confirm actoin',
      MESSAGE_ARELLA_IS_THE_VISUAL_TOOL:
        'The visual project management tool for team collaboration',
      MESSAGE_JUST_SIGN_UP_CREATE_A_BOARD_AND_YOU_ARE_OFF:
        'Just sign up, create a board, and you’re off!',
      MESSAGE_PROMO_CONTACT_FORM_A:
        'By providing your contact information, you agree that our team may contact you via email about its product, services and events',
      MESSAGE_PROMO_CONTACT_FORM_B:
        'If you have any feature requests, questions about application, if you need software support? Or just want to say “Hi”, please contact us!',
      MESSAGE_TO_BACK_TO_SEARCH_RESULT: 'Back to search result',
      MESSAGE_TO_CHOOSE_USER:
        '{{userName}} will be added to this board. {{userName}} would have access to create, update and delete all types of data on this board. Would you like to continue?',
      MESSAGE_TO_COOKIE_BANNER:
        'We use cookies to improve your experience on our website.',
      MESSAGE_TO_CREATE_NEW_BOARD: 'It’s time to create new board!',
      MESSAGE_TO_ARCHIVE_BOARD:
        'You’re going to archive this board. Please confirm your action.',
      MESSAGE_TO_DELETE_ACCOUNT:
        '{{userName}} , are you sure you want to delete your account? This action cannot be undone and all your data will be permanently deleted. Please think twice before proceeding.',
      MESSAGE_TO_DELETE_BOARD:
        'You’re going to remove this board. All data will be lost and can’t be restored! Please confirm deletion.',
      MESSAGE_TO_DELETE_USER: 'Remove access for {{userName}} to this board.',
      MESSAGE_TO_ERROR_404: 'Oops! Page not found.',
      MESSAGE_TO_ERROR_403: 'Access Denied',
      MESSAGE_TO_ERROR: 'Oops! Something happened. Please try again!',
      MESSAGE_TO_ERROR_CHANGE_BOARD_TASK:
        'This task is already located in this board',
      MESSAGE_TO_ERROR_CHANGE_PASSWORD: 'Access data is incorrect',
      MESSAGE_TO_ERROR_CHANGE_STATUS_TASK:
        'This task is already assigned to this status',
      MESSAGE_TO_ERROR_PAGE: 'Oops! Something went wrong.',
      MESSAGE_TO_LEAVE_BOARD: 'Confirm your actions',
      MESSAGE_TO_REGISTER: 'Please register to access all features',
      MESSAGE_TO_RESTORE_PASSWORD:
        'We send you temporary password on your email. You can change it later in your Profile settings.',
      MESSAGE_TO_UNARCHIVE_BOARD:
        'You’re going to unarchive this board. Please confirm your action.',
      MESSAGE_WHAT_IS_ARELLA: 'What is Arella?',
      METHODOLOGY_OF_AGILE: 'Methodology of Agile',
      MORE_ACTIONS: 'More actions',
      NAME: 'Name',
      NEW_BOARD: 'New board',
      NEW_PASSWORD: 'New password',
      NOT_ALLOWED_VALUE: 'Not Allowed Value',
      NOT_FOUND: 'Not found',
      NO_ASSIGNED_USER: 'No assigned user',
      NO_BOARDS_FOUND: 'No boards found',
      NO_ITEMS: 'No items',
      NO_LOGGED_TIME: 'No logged time',
      NO_STATUSES_FOUND: 'No statuses found',
      NO_TAGS_ADDED: 'No tags added',
      NO_TASKS: 'No tasks',
      NO_VALID_DATA: 'Input data is not valid',
      NO_VALID_EMAIL: 'Email is not valid',
      NO_VALID_PASSWORD_OR_EMAIL: 'Email or password is not valid',
      OK: 'Ok',
      OOPS: 'Oops!',
      OPEN_CHECKLIST: 'Open checklist',
      OR_UC: 'OR',
      SIGN_IN_WITH_EMAIL: 'sign in with email',
      REGISTER_WITH_EMAIL: 'register with email',
      PAGE: 'Page',
      PARTICIPANT: 'participant',
      PARTICIPANTS: 'participants',
      PASSWORD: 'Password',
      PASSWORD_SUGGESTION: 'Password must be at least 6 characters long',
      PLEASE_CONFIRM_DELETE_ACTION: 'Please confirm delete action',
      PLEASE_CREATE_NEW_PASSWORD_TO_CONTINUE: 'Please create a new password to continue',
      PREFIX_SUGGESTION: 'Prefix must be at least 1 characters long',
      PRIORITY: 'Priority',
      PRIVACY_POLICY: 'Privacy policy',
      PRIVATE: 'Private',
      PROMO_FEATURE_METHODOLOGY_OF_AGILE:
        'We believe in the power of Agile methodology to drive project success. Agile is more than just a development framework. It is a mindset that promotes adaptability, collaboration, and continuous improvement. Arella is designed to seamlessly integrate Agile principles into your workflow',
      PROMO_FEATURE_SIMPLE_FLEXIBLE_AND_POWERFUL:
        'At Arella, we have crafted a user experience that seamlessly blends simplicity, flexibility, and power. Navigate effortlessly through our intuitive interface, where simplicity is not just a feature but a guiding principle',
      PROMO_FEATURE_TRANSPARENCY_OF_WORK:
        'Arella is committed to fostering open communication within teams. With our transparency features, everyone involved in a project can stay informed about the progress of tasks. Real-time updates ensure that team members are on the same page, facilitating better collaboration and coordination',
      PROMO_FEATURE_VISUALIZATION_OF_PROCESSES:
        'Transform the abstract into the tangible with our visualization features. Dive into a visual representation of your work processes, providing clarity and insight at every stage. Watch as tasks progress through dynamic visual boards, making it easy to identify bottlenecks and optimize workflows',
      REGISTER: 'Register',
      REGISTER_HERE: 'Register here!',
      REMEMBER_ME: 'Remember me',
      REMOVE: 'Remove',
      REQUIRED: 'This field is required',
      RESET: 'Reset',
      RESTORE: 'Restore',
      RIGHTS: 'All rights reserved',
      SAVE: 'Save',
      SAVED: 'Saved!',
      SAVE_TASK: 'Save task',
      SEARCH_BOARD: 'Search board',
      SEARCH_TASK: 'Search task',
      SEARCH_USER: 'Search user',
      SEE_WHAT_YOUR_TEAM_COULD_DO_WITH: 'See what your team could do with',
      SECURITY: 'Security',
      SERVER_ERROR: 'Server error',
      SEND: 'Send',
      SETTINGS: 'Settings',
      SHARED: 'Shared',
      SHARED_WITH_FRIENDS: 'Shared with friends',
      SIGN_IN: 'Sign in',
      SIGN_IN_HERE: 'Sign in here!',
      SIMPLE_FLEXIBLE_AND_POWERFUL: 'Simplicity, flexibility and power',
      SLOGAN_ARELLA_A:
        'The perfect solution to take your project management experience to the next level',
      SLOGAN_ARELLA_B:
        'Increase your productivity, optimize collaboration, and stay ahead of your goals with our features designed specifically for our users',
      SLOGAN_ARELLA_C:
        'The Kanban board is a tool for workflow visualization that provides full transparency of work',
      SLOGAN_ARELLA_D:
        'All work items are visualized as Kanban cards, allowing each team member to move cards and see every task’s status',
      SMALL_PASSWORD: 'Password must be more than 6 characters',
      SORT_BY: 'Sort by:',
      START: 'Start',
      STATUS: 'Status',
      STATUSES: 'Statuses',
      STREAMLINE_YOUR_WORKFLOW: 'Streamline your workflow',
      SUBJECT: 'Subject',
      SUPPORT: 'Support',
      SUPPORT_FORM: 'Support form',
      SUPPORT_FORM_TITLE: 'Something went wrong? Describe your problem here.',
      TAGS: 'Tags',
      TASK: 'task',
      TASK_C: 'Task',
      TASKS: 'tasks',
      TASK_ID: 'Task ID',
      TASK_INDEX: 'Task index',
      THEME: 'Theme',
      TITLE: 'Title',
      TIME: 'Time',
      TIME_SPEND: 'Time Spend',
      TOO_SHORT: 'Password must be more than 6 characters',
      TOOLTIP_MESSAGE_CANNOT_DELETE: 'Cannot delete: tasks exist',
      TOTAL: 'Total',
      TOTAL_TIME: 'Total time',
      TRANSPARENCY_OF_WORK: 'Transparency of work',
      TRY_WITHOUT_REGISTRATION: 'Try without registration',
      UNARCHIVE: 'Unarchive',
      UNARCHIVE_BOARD: 'Unarchive board',
      UPDATED: 'updated',
      USER: 'User',
      USERNAME: 'Username',
      USER_NOT_FOUND: 'User not found',
      USER_PROFILE: 'User profile',
      USER_TASKS: 'User tasks',
      USER_TIME_REPORT: 'User time report',
      VIEW_ALL: 'view all',
      VIEW_BOARDS: 'View boards',
      VISUALIZATION_OF_PROCESSES: 'Visualization of processes',
      WELCOME_TO: 'Welcome to',
      WHAT_IS: 'What is',
      WHAT_YOU_GET_WITH: 'What you get with',
      WRONG_EMAIL: 'Wrong email',
    },
  },
  uk: {
    translation: {
      ABOUT: 'Про додаток',
      ACCEPT: 'Прийняти',
      ADD: 'Додати',
      ADD_COLUMN: 'Додати стовпець',
      ADD_FAVORITE: 'Додати до обраного',
      ADD_FILTER: 'Додати фільтр',
      ADD_LINK: 'Додати посилання',
      ADD_STATUS: 'Додати статус',
      ADD_TAG: 'Додати тег',
      ADD_TASK: 'Додати завдання',
      ADD_TIME: 'Додати час',
      ADD_USER: 'Додати користувача',
      AGREEMENT: 'Я згоден з ',
      APPLICATION_NAME: 'Назва програми',
      APPLY: 'Застосувати',
      ARCHIVE: 'Архів',
      ASSIGNED: 'Призначений',
      ASSIGNED_USER: 'Виконавець',
      ARCHIVE_BOARD: 'Архівувати  дошку',
      ALREADY_HAVE_AN_ACCOUNT: 'Вже маєте аккаунт?',
      BACK_TO: 'Назад до',
      BOARD: 'Дошка',
      BOARDS: 'Дошки',
      BOARD_ACTIONS: 'Дії дошки',
      BOARD_CREATED: 'Дошка створена',
      BOARD_DELETED: 'Дошка видалена!',
      BOARD_INFO: 'Інформація до дошки',
      BOARD_PREFIX: 'Префікс дошки',
      BOARD_TEAM: 'Команда дошки',
      BOARD_TITLE: 'Заголовок дошки',
      BOARD_UPDATED: 'Дошка оновлена!',
      BACKLOG: 'Беклог',
      CANCEL: 'Відміна',
      CHANGE_BOARD: 'Змінити дошку',
      CHANGE_PASSWORD: 'Змінити пароль',
      CHANGE_STATUS: 'Змінити статус',
      CHANGING_TASK_INDEX: 'Зміна індексу завдання',
      CHECK_EMAIL: 'Перевірте свою пошту!',
      CHECKLIST: 'Список завдань',
      CLOSE_CHECKLIST: 'Закрити список завдань',
      COLOR_MARK: 'Кольорова мітка',
      COLOR_THEME: 'Кольорова тема',
      CONFIRM_PASSWORD: 'Підтвердьте пароль',
      CONTACT_US: 'Зв’яжіться з нами',
      CONTACT_FORM: 'Форма зворотнього зв’язку',
      CONTAIN_INJECTIONS: 'Вхідні дані містять ін’єкції',
      CONTINUE_WITH: 'Продожити з ',
      COPYRIGHT_TEXT: 'Авторський текст',
      CREATED_BY: 'Створено',
      CREATE_NEW_BOARD: 'Cтворити дошку',
      CREATE_NEW_COLUMN: 'Створити новий стовпець',
      CREATE_NEW_TASK: 'Створити нове завдання',
      CREATE_TASK: 'Створити завдання',
      CREATE_YOUR_ACCOUNT_BELLOW: 'Створіть свій обліковий запис нижче',
      CREATED: 'створеням',
      CURRENT_PASSWORD: 'Поточний пароль',
      CUSTOM: 'спеціальне',
      DARK: 'Темна',
      DATA_END: 'Дата кінця',
      DATA_NOT_SAVED: 'Дані не збережені',
      DATA_START: 'Дата початку',
      DATE: 'Дата',
      DELETE: 'Видалити',
      DELETE_ACCOUNT: 'Видалити аккаунт',
      DELETE_BOARD: 'Видалити дошку',
      DELETE_LOGGED_TIME: 'Видалити зареєстрований час',
      DELETE_RECORD: 'Видалити запис',
      DELETE_STATUS: 'Видалити статус',
      DELETE_TASK: 'Видалити завдання',
      DELETE_TIME: 'Видалити час',
      DELETE_USER: 'Видалити користувача',
      DESCRIPTION: 'Опис',
      DESCRIPTION_ERROR_403: 'Ви не маєте дозволу на перегляд цієї сторінки. Перевірте свої облікові дані та повторіть спробу.',
      DETAILS: 'Деталі',
      DEVELOPED_BY_LANADEV: 'Розроблено LanaDev',
      DONT_HAVE_AN_ACCOUNT: 'Не маєте облікового запису',
      EDIT: 'Редагувати',
      EDIT_TIME: 'Редагувати час',
      EMAIL: 'Email',
      EMPTY_FIELDS: 'Порожні поля',
      END: 'Кінець',
      ENTER_BOARD_NAME: 'Введіть назву дошки',
      ENTER_DATE: 'Введіть дату',
      ENTER_LINK: 'Введіть посилання',
      ENTER_NEW_ITEM: 'Введіть новий елемент',
      ENTER_NEW_TAG: 'Введіть новий тег',
      ENTER_TIME: 'Введіть час',
      ENTER_USER_NAME_OR_EMAIL:
        'Введіть ім’я користувача або електронну адресу',
      ENTER_YOUR_CREDENTIALS: 'Введіть свої облікові дані, щоб продовжити',
      ENTER_YOUR_EMAIL: 'Введіть свою електронну пошту',
      ENTER_YOUR_MESSAGE: 'Введіть своє повідомлення',
      ENTER_YOUR_NAME: 'Введіть своє ім’я',
      ENTER_YOUR_SUBJECT: 'Введіть тему',
      EQUAL_PASSWORDS: 'Паролі повинні бути однакові',
      ESTIMATED_TIME: 'Розрахунковий час',
      ESTIMATION: 'Оцінка',
      EXAMPLE: 'Приклад',
      FAQ: 'Питання та відповіді',
      FAVORITE: 'Ізбрані',
      FIELDS_NOT_EQUAL: 'Поля повинні бути рівними',
      FILTERING: 'Фільтрація',
      FILTERS: 'Фільтри:',
      FIND_USER: 'Знайти користувача',
      FIRST_NAME: 'Ім’я',
      FOLLOW_US: 'Слідкуйте за нами',
      FORGOT_PASSWORD: 'Забули пароль',
      FORGOT_YOUR_PASSWORD: 'Забули ваш пароль?',
      GENERAL_INFO: 'Загальна інформація',
      HIDE_ALL: 'сховати все',
      GET_IN_TOUCH: 'Зв’язатися',
      GO_TO_BOARD: 'Перейти до дошки',
      GO_TO_THE_PREVIOUS_PAGE: 'Повернутися на попередню сторінку',
      GO_BACK: 'Повернутись назад',
      GO_HOME: 'Повернутися додому',
      HIGH: 'Високий',
      HI_WELCOME_BACK: 'Привіт, з поверненням',
      HOME: 'Домашня',
      INCORRECT_DATA_FORMAT: 'Невірний формат даних!',
      INFO: 'Інформація',
      ITEMS: 'Елемент(-ів)',
      LANGUAGES: 'Мови',
      LAST_NAME: 'Прізвище',
      LEAVE: 'Покинути',
      LEAVE_BOARD: 'Покинути дошку',
      LESS_ACTIONS: 'Меньше дій',
      LIGHT: 'Світла',
      LINK: 'Посилання',
      LOAD: 'Завантажити',
      LOGGED_TIME: 'Зареєстрований час',
      LOGOUT: 'Вихiд',
      LOW: 'Низький',
      MEDIUM: 'Середній',
      MESSAGE: 'Повідомлення',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX_CREATED_TASK: 'Очікуваний індекс завдання {{expectedBoardIndex}} створене завдання змінено на {{newBoardIndex}}, оскільки індекс завдання {{expectedBoardIndex}} вже використовується на дошці.',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX: 'Індекс завдання був змінений з {{oldBoardIndex}} на {{newBoardIndex}}, оскільки індекс {{oldBoardIndex}} вже зайнятий на цій дошці.',
      MESSAGE_ABOUT_MOBILE_VERSION: 'Мобільна версія скоро буде доступна',
      MESSAGE_ABOUT_UNSAVED_DATA:
        'Зміни не збережені і будуть втрачені після закриття вікна. Будь ласка, підтвердіть дію',
      MESSAGE_ARELLA_IS_THE_VISUAL_TOOL:
        'Візуальний інструмент управління проектами для командної співпраці',
      MESSAGE_JUST_SIGN_UP_CREATE_A_BOARD_AND_YOU_ARE_OFF:
        'Просто зареєструйтеся, створіть дошку, і все!',
      MESSAGE_PROMO_CONTACT_FORM_A:
        'Надаючи свої контактні дані, ви погоджуєтеся на те, що наша команда може зв’язатися з вами за допомогою електронної пошти щодо своїх продуктів, послуг і подій',
      MESSAGE_PROMO_CONTACT_FORM_B:
        ' Якщо у вас є запитання щодо функцій, питання про додаток, чи вам потрібна підтримка програмного забезпечення? Або ви просто бажаєте сказати "Привіт", будь ласка, зв’яжіться з нами!',
      MESSAGE_TO_BACK_TO_SEARCH_RESULT: 'Повернутися до результатів пошуку',
      MESSAGE_TO_CHOOSE_USER:
        '{{userName}} буде додано до цієї дошки. {{userName}} матиме доступ до створення, оновлення та видалення всіх типів даних на цій дошці. Бажаєте продовжити?',
      MESSAGE_TO_COOKIE_BANNER:
        'Ми використовуємо файли cookie, щоб покращити ваш досвід на нашому веб-сайті.',
      MESSAGE_TO_CREATE_NEW_BOARD: 'Саме час створити нову дошку!',
      MESSAGE_TO_ARCHIVE_BOARD:
        'Ви збираєтеся заархівувати цю дошку. Будь ласка, підтвердьте свою дію.',
      MESSAGE_TO_DELETE_ACCOUNT: '{{userName}}, Ви впевнені, що хочете видалити свій обліковий запис? Цю дію неможливо скасувати, і всі ваші дані буде остаточно видалено. Перш ніж продовжити, подумайте двічі.',
      MESSAGE_TO_DELETE_BOARD:
        'Ви збираєтеся видалити цю дошку. Усі дані буде втрачено та не можна буде відновити! Будь ласка, підтвердьте видалення.',
      MESSAGE_TO_DELETE_USER:
        'Скасувати доступ для {{userName}} до цієї дошки.',
      MESSAGE_TO_ERROR_404: 'Ой! Сторінку не знайдено.',
      MESSAGE_TO_ERROR_403: 'Доступ заборонено',
      MESSAGE_TO_ERROR: 'Ой! Щось трапилося. Будь ласка спробуйте ще раз!',
      MESSAGE_TO_ERROR_CHANGE_BOARD_TASK:
        'Ця задача вже розташована в цій дошці',
      MESSAGE_TO_ERROR_CHANGE_PASSWORD: 'Дані доступу неправильні',
      MESSAGE_TO_ERROR_CHANGE_STATUS_TASK:
        'Ця задача вже закріплена в цьому статусі',
      MESSAGE_TO_ERROR_PAGE: 'Ой! Щось пішло не так.',
      MESSAGE_TO_LEAVE_BOARD: 'Підтвердіть ваші дії',
      MESSAGE_TO_REGISTER:
        'Будь ласка, зареєструйтеся, щоб отримати доступ до всіх функцій',
      MESSAGE_TO_RESTORE_PASSWORD:
        'Ми надішлемо вам тимчасовий пароль на вашу електронну пошту. Ви можете змінити це пізніше в налаштуваннях профілю.',
      MESSAGE_TO_UNARCHIVE_BOARD:
        'Ви збираєтеся розархівувати цю дошку. Будь ласка, підтвердьте свою дію.',
      MESSAGE_WHAT_IS_ARELLA: 'Що таке Arella?',
      METHODOLOGY_OF_AGILE: 'Методологія Agile',
      MORE_ACTIONS: 'Більше дій',
      NAME: 'Iм’я',
      NEW_BOARD: 'Нова дошка',
      NEW_PASSWORD: 'Новий пароль',
      NOT_ALLOWED_VALUE: 'Недозволене значення',
      NOT_FOUND: 'Не знайдено',
      NO_ASSIGNED_USER: 'Немає виконавця',
      NO_BOARDS_FOUND: 'Не знайдено дошок',
      NO_ITEMS: 'Немає елементів',
      NO_LOGGED_TIME: 'Немає зареєстрованого часу',
      NO_STATUSES_FOUND: 'Статусів не знайдено',
      NO_TAGS_ADDED: 'Теги не додано',
      NO_TASKS: 'Немає завдань',
      NO_VALID_DATA: 'Введені дані недійсні',
      NO_VALID_EMAIL: 'Електронна адреса недійсна',
      NO_VALID_PASSWORD_OR_EMAIL: 'Електронна пошта або пароль недійсні',
      OK: 'Ok',
      OOPS: 'Ой!',
      OPEN_CHECKLIST: 'Відкрити список завдань',
      OR_UC: 'АБО',
      SIGN_IN_WITH_EMAIL: 'увійдіть за допомогою електронної пошти',
      REGISTER_WITH_EMAIL: 'зареєструйтесь за допомогою електронної пошти',
      PAGE: 'Сторінка',
      PARTICIPANT: 'учасник',
      PARTICIPANTS: 'учасники',
      PASSWORD: 'Пароль',
      PASSWORD_SUGGESTION: 'Пароль має бути не менше 6 символів',
      PLEASE_CONFIRM_DELETE_ACTION: 'Будь ласка, підтвердіть видалення',
      PLEASE_CREATE_NEW_PASSWORD_TO_CONTINUE: 'Щоб продовжити, створіть новий пароль',
      PREFIX_SUGGESTION: 'Префікс має містити принаймні 1 символ',
      PRIORITY: 'Пріоритет',
      PRIVACY_POLICY: 'Політика конфіденційності',
      PRIVATE: 'Приватні',
      PROMO_FEATURE_METHODOLOGY_OF_AGILE:
        'Ми віримо в силу методології Agile для досягнення успіху в проекті. Agile — це більше ніж просто середа розробки. Це образ мислення, який сприяє адаптивності, співпраці та постійному вдосконаленню. Arella розроблена для безпроблемної інтеграції принципів Agile в ваш робочий процес',
      PROMO_FEATURE_SIMPLE_FLEXIBLE_AND_POWERFUL:
        'В Arella ми створили користувацький досвід, який органічно поєднує в собі простоту, гнучкість та потужність. Легко переміщуйтеся за допомогою нашого інтуїтивно зрозумілого інтерфейсу, де простота - це не лише функція, але і керівний принцип',
      PROMO_FEATURE_TRANSPARENCY_OF_WORK:
        'Arella прагне сприяти відкритому спілкуванню всередині команди. З нашими функціями прозорості всі члени команди можуть бути в курсі прогресу завдань. Оновлення в реальному часі забезпечують те, що члени команди знаходяться на одній хвилі, сприяючи кращій співпраці та координації',
      PROMO_FEATURE_VISUALIZATION_OF_PROCESSES:
        'Перетворіть абстрактне на відчутне завдяки нашим можливостям візуалізації. Зануртесь у візуальне представлення ваших робочих процесів, забезпечуючи ясність і розуміння кожного етапу. Спостерігайте, як завдання прогресують через динамічні візуальні дошки, які легко дозволяють виявляти вузькі місця і оптимізувати робочі процеси',
      REGISTER: 'Зареєструватися',
      REGISTER_HERE: 'Реєструйтеся тут!',
      REMEMBER_ME: 'Пам’ятай мене',
      REMOVE: 'Видалити',
      REQUIRED: 'Це поле є обов’язковим',
      RESET: 'Скинути',
      RESTORE: 'Відновлення',
      RIGHTS: 'Усі права захищені',
      SAVE: 'Зберегти',
      SAVED: 'Збережено!',
      SAVE_TASK: 'Зберегти завдання',
      SEARCH_BOARD: 'Пошук дошки',
      SEARCH_TASK: 'Пошук завдання',
      SEARCH_USER: 'Пошук користувача',
      SEE_WHAT_YOUR_TEAM_COULD_DO_WITH:
        'Подивіться на що спроможна ваша команда з',
      SECURITY: 'Безпека',
      SEND: 'Надіслати',
      SETTINGS: 'Налаштування',
      SHARED: 'Спільні',
      SHARED_WITH_FRIENDS: 'Поділитися з друзями',
      SIGN_IN: 'Увійти',
      SIGN_IN_HERE: 'Увійти!',
      SIMPLE_FLEXIBLE_AND_POWERFUL: 'Простота, гнучкість і потужність',
      SLOGAN_ARELLA_A:
        'Ідеальне рішення, щоб підняти ваш досвід управління проектами на новий рівень',
      SLOGAN_ARELLA_B:
        'Збільшуйте продуктивність, оптимізуйте співпрацю та випереджуйте свої цілі за допомогою наших функцій, спеціально розроблених для наших користувачів',
      SLOGAN_ARELLA_C:
        'Канбан-дошка — інструмент візуалізації робочого процесу, який забезпечує повну прозорість роботи',
      SLOGAN_ARELLA_D:
        'Усі робочі елементи візуалізовані у вигляді карток Канбан, що дозволяє кожному члену команди переміщати картки та бачити статус кожного завдання',
      SMALL_PASSWORD: 'Пароль має бути більше 6 символів',
      SORT_BY: 'Сортувати за:',
      START: 'Початок',
      STATUS: 'Статус',
      STATUSES: 'Статуси',
      STREAMLINE_YOUR_WORKFLOW: 'Оптимізуйте свій робочий процес',
      SUBJECT: 'Тема',
      SUPPORT: 'Підтримка',
      SUPPORT_FORM: 'Форма підтримки',
      SUPPORT_FORM_TITLE: 'Щось пішло не так? Опишіть свою проблему тут.',
      TAGS: 'Теги',
      TASK: 'завдання',
      TASK_C: 'Завдання',
      TASKS: 'завдання',
      TASK_ID: 'Ідентифікатор завдання',
      TASK_INDEX: 'Індекс завдання',
      THEME: 'Теми',
      TITLE: 'Заголовок',
      TIME: 'Час',
      TIME_SPEND: 'Витрачений час',
      TOO_SHORT: 'Пароль має бути більше 6 символів',
      TOOLTIP_MESSAGE_CANNOT_DELETE: 'Не можливо видалити: завдання існують',
      TOTAL: 'Всього',
      TOTAL_TIME: 'Загальний час',
      TRANSPARENCY_OF_WORK: 'Прозорість роботи',
      TRY_WITHOUT_REGISTRATION: 'Спробувати без реєстрації',
      UNARCHIVE: 'Розархівувати',
      UNARCHIVE_BOARD: 'Розархівувати доску',
      UPDATED: 'оновленням',
      USER: 'Користувач',
      USERNAME: 'Ім’я користувача',
      USER_NOT_FOUND: 'Користувач не знайден',
      USER_PROFILE: 'Профіль користувача',
      USER_TASKS: 'Завдання користувача',
      USER_TIME_REPORT: 'Звіт про час користувача',
      VIEW_ALL: 'переглянути все',
      VIEW_BOARDS: 'Переглянути дошки',
      VISUALIZATION_OF_PROCESSES: 'Візуалізація процесів',
      WELCOME_TO: 'Ласкаво просимо до',
      WHAT_IS: 'Що таке',
      WHAT_YOU_GET_WITH: 'Що ви отримуєте з',
      WRONG_EMAIL: 'Неправильна електронна адреса',
    },
  },
  ru: {
    translation: {
      ABOUT: 'О приложении',
      ACCEPT: 'Принять',
      ADD: 'Добавить',
      ADD_COLUMN: 'Добавить столбец',
      ADD_FAVORITE: 'Добавить в избранное',
      ADD_FILTER: 'Добавить фильтр',
      ADD_LINK: 'Добавить ссылку',
      ADD_STATUS: 'Добавить статус',
      ADD_TAG: 'Добавить тег',
      ADD_TASK: 'Добавить задачу',
      ADD_TIME: 'Добавить время',
      ADD_USER: 'Добавить пользователя',
      AGREEMENT: 'Я согласен с ',
      APPLICATION_NAME: 'Имя приложения',
      APPLY: 'Применить',
      ARCHIVE: 'Архив',
      ASSIGNED: 'Назначенный',
      ASSIGNED_USER: 'Исполнитель',
      ARCHIVE_BOARD: 'Архивировать доску',
      ALREADY_HAVE_AN_ACCOUNT: 'Уже имеете аккаунт?',
      BACK_TO: 'Назад к',
      BOARD: 'Доска',
      BOARDS: 'Доски',
      BOARD_ACTIONS: 'Действия для доски',
      BOARD_CREATED: 'Доска создана',
      BOARD_DELETED: 'Доска удалена!',
      BOARD_INFO: 'Информация по доске',
      BOARD_PREFIX: 'Префикс доски',
      BOARD_TEAM: 'Команда доски',
      BOARD_TITLE: 'Заголовок доски',
      BOARD_UPDATED: 'Доска обновлена!',
      BACKLOG: 'Бэклог',
      CANCEL: 'Отмена',
      CHANGE_BOARD: 'Изменить доску',
      CHANGE_PASSWORD: 'Изменить пароль',
      CHANGE_STATUS: 'Изменить статус',
      CHANGING_TASK_INDEX: 'Изменение индекса задачи',
      CHECK_EMAIL: 'Проверьте свою почту!',
      CHECKLIST: 'Список задач',
      CLOSE_CHECKLIST: 'Закрыть список задач',
      COLOR_MARK: 'Цветовая метка',
      COLOR_THEME: 'Цветовая тема',
      CONFIRM_PASSWORD: 'Подтвердить пароль',
      CONTACT_US: 'Свяжитесь с нами',
      CONTACT_FORM: 'Форма обратной связи',
      CONTAIN_INJECTIONS: 'Входные данные содержат инъекции',
      CONTINUE_WITH: 'Продолжить с ',
      COPYRIGHT_TEXT: 'Авторский текст',
      CREATED_BY: 'Создано',
      CREATE_NEW_BOARD: 'Создать доску',
      CREATE_NEW_COLUMN: 'Создать новую колонку',
      CREATE_NEW_TASK: 'Создать новую задачу',
      CREATE_TASK: 'Создать задачу',
      CREATE_YOUR_ACCOUNT_BELLOW: 'Создайте свой аккаунт ниже',
      CREATED: 'созданием',
      CURRENT_PASSWORD: 'Действующий пароль',
      CUSTOM: 'пользовательская',
      DARK: 'Тёмная',
      DATA_END: 'Дата конца',
      DATA_NOT_SAVED: 'Данные не сохранены',
      DATA_START: 'Дата начала',
      DATE: 'Дата',
      DELETE: 'Удалить',
      DELETE_ACCOUNT: 'Удалить аккаунт',
      DELETE_BOARD: 'Удалить доску',
      DELETE_LOGGED_TIME: 'Удалить зарегестрированное время',
      DELETE_RECORD: 'Удалить запись',
      DELETE_STATUS: 'Удалить статус',
      DELETE_TASK: 'Удалить задачу',
      DELETE_TIME: 'Удалить время',
      DELETE_USER: 'Удалить пользователя',
      DESCRIPTION: 'Описание',
      DESCRIPTION_ERROR_403: 'У вас нет разрешения на просмотр этой страницы. Проверьте свои учетные данные и повторите попытку.',
      DETAILS: 'Детали',
      DEVELOPED_BY_LANADEV: 'Разработано LanaDev',
      DONT_HAVE_AN_ACCOUNT: 'У вас нет аккаунта?',
      EDIT: 'Редактировать',
      EDIT_TIME: 'Редактировать время',
      EMAIL: 'Email',
      EMPTY_FIELDS: 'Пустые поля',
      END: 'Конец',
      ENTER_BOARD_NAME: 'Введите название доски',
      ENTER_DATE: 'Введите дату',
      ENTER_LINK: 'Введите ссылку',
      ENTER_NEW_ITEM: 'Введите новый элемент',
      ENTER_NEW_TAG: 'Введите новый тег',
      ENTER_TIME: 'Введите время',
      ENTER_USER_NAME_OR_EMAIL:
        'Введите имя пользователя или адрес электронной почты',
      ENTER_YOUR_CREDENTIALS: 'Введите свои учетные данные, чтобы продолжить',
      ENTER_YOUR_EMAIL: 'Введите свою электронную почту',
      ENTER_YOUR_MESSAGE: 'Введите свое сообщение',
      ENTER_YOUR_NAME: 'Введите свое имя',
      ENTER_YOUR_SUBJECT: 'Введите тему',

      EQUAL_PASSWORDS: 'Пароли должны быть равны',
      ESTIMATED_TIME: 'Расчетное время',
      ESTIMATION: 'Оценка',
      EXAMPLE: 'Пример',
      FAQ: 'Вопросы и ответы',
      FAVORITE: 'Избранные',
      FIELDS_NOT_EQUAL: 'Поля должны быть равными',
      FILTERING: 'Фильтрация',
      FILTERS: 'Фильтры:',
      FIND_USER: 'Найти пользователя',
      FIRST_NAME: 'Имя',
      FOLLOW_US: 'Следите за нами',
      FORGOT_PASSWORD: 'Забыл пароль',
      FORGOT_YOUR_PASSWORD: 'Забыли ваш пароль?',
      GENERAL_INFO: 'Общая информация',
      GET_IN_TOUCH: 'Связаться',
      GO_TO_BOARD: 'Перейти к доске',
      GO_TO_THE_PREVIOUS_PAGE: 'Вернутся на предыдущую страницу',
      GO_BACK: 'Вернутся назад',
      GO_HOME: 'Вернуться домой',
      HIGH: 'Высокий',
      HI_WELCOME_BACK: 'Привет, с возвращением',
      HIDE_ALL: 'скрыть все',
      HOME: 'Домашняя',
      INCORRECT_DATA_FORMAT: 'Неверный формат данных!',
      INFO: 'Информация',
      ITEMS: 'Элемент(-ов)',
      LANGUAGES: 'Языки',
      LAST_NAME: 'Фамилия',
      LEAVE: 'Покинуть',
      LEAVE_BOARD: 'Покинуть доску',
      LESS_ACTIONS: 'Меньше действий',
      LIGHT: 'Светлая',
      LINK: 'Ссылка',
      LOAD: 'Загрузить',
      LOGGED_TIME: 'Зарегистрированное время',
      LOGOUT: 'Выход',
      LOW: 'Низкий',
      MEDIUM: 'Средний',
      MESSAGE: 'Сообщение',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX_CREATED_TASK: 'Ожидаемый индекс задачи {{expectedBoardIndex}} созданная задача изменена на {{newBoardIndex}}, так как индекс задачи {{expectedBoardIndex}} уже используется на доске.',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX: 'Индекс задачи был изменен с {{oldBoardIndex}} на {{newBoardIndex}}, потому что индекс {{oldBoardIndex}} уже занят на этой доске.',
      MESSAGE_ABOUT_MOBILE_VERSION: 'Мобильная версия скоро будет доступна',
      MESSAGE_ABOUT_UNSAVED_DATA:
        'Изменения не сохранены и будут потеряны при закрытии окна. Пожалуйста, подтвердите действие',
      MESSAGE_ARELLA_IS_THE_VISUAL_TOOL:
        'Визуальный инструмент управления проектами для командной работы',
      MESSAGE_JUST_SIGN_UP_CREATE_A_BOARD_AND_YOU_ARE_OFF:
        'Просто зарегистрируйтесь, создайте доску, и все готово!',
      MESSAGE_PROMO_CONTACT_FORM_A:
        'Предоставляя свои контактные данные, вы соглашаетесь на то, что наша команда может связаться с вами по электронной почте относительно своих продуктов, услуг и мероприятий',
      MESSAGE_PROMO_CONTACT_FORM_B:
        'Если у вас есть вопросы по функционалу, вопросы по приложению, если вам нужна поддержка по программному обеспечению? Или просто хотите сказать "Привет", пожалуйста, свяжитесь с нами!',
      MESSAGE_TO_BACK_TO_SEARCH_RESULT: 'Назад к результатам поиска',
      MESSAGE_TO_CHOOSE_USER:
        '{{userName}} будет добавлен к этой доске. {{userName}} будет иметь доступ для создания, обновления и удаления всех типов данных на этой доске. Хотите продолжить?',
      MESSAGE_TO_COOKIE_BANNER:
        'Мы используем файлы cookie, чтобы улучшить ваше впечатление на нашем веб-сайте.',
      MESSAGE_TO_CREATE_NEW_BOARD: 'Пришло время создать новую доску!',
      MESSAGE_TO_ARCHIVE_BOARD:
        'Вы собираетесь заархивировать эту доску. Пожалуйста, подтвердите свои действия.',
      MESSAGE_TO_DELETE_ACCOUNT:
        '{{userName}}, вы уверены, что хотите удалить свою учетную запись? Это действие невозможно отменить, и все ваши данные будут удалены без возможности восстановления. Пожалуйста, подумайте дважды, прежде чем продолжить.',
      MESSAGE_TO_DELETE_BOARD:
        'Вы собираетесь удалить эту доску. Все данные будут потеряны и не могут быть восстановлены! Пожалуйста, подтвердите удаление.',
      MESSAGE_TO_DELETE_USER: 'Удалить доступ для {{userName}} к этой доске.',
      MESSAGE_TO_ERROR_404: 'Ой! Страница не найдена.',
      MESSAGE_TO_ERROR_403: 'Доступ запрещен',
      MESSAGE_TO_ERROR: 'Ой! Что-то произошло. Пожалуйста, попробуйте еще раз!',
      MESSAGE_TO_ERROR_CHANGE_BOARD_TASK:
        'Эта задача уже расположена в этой доске',
      MESSAGE_TO_ERROR_CHANGE_PASSWORD: 'Данные доступа неверны',
      MESSAGE_TO_ERROR_CHANGE_STATUS_TASK:
        'Эта задача уже закреплена закреплена в этом статусе',
      MESSAGE_TO_ERROR_PAGE: 'Ой! Что-то пошло не так.',
      MESSAGE_TO_LEAVE_BOARD: 'Подтвердите ваши действия',
      MESSAGE_TO_REGISTER:
        'Пожалуйста, зарегистрируйтесь, чтобы получить доступ ко всем функциям',
      MESSAGE_TO_RESTORE_PASSWORD:
        'Мы вышлем вам временный пароль на вашу электронную почту. Вы можете изменить его позже в настройках вашего профиля.',
      MESSAGE_TO_UNARCHIVE_BOARD:
        'Вы собираетесь разархивировать эту доску. Пожалуйста, подтвердите свои действия.',
      MESSAGE_WHAT_IS_ARELLA: 'Что такое Arella?',
      METHODOLOGY_OF_AGILE: 'Методология Agile',
      MORE_ACTIONS: 'Больше дейсвий',
      NAME: 'Имя',
      NEW_BOARD: 'Новая доска',
      NEW_PASSWORD: 'Новый пароль',
      NOT_ALLOWED_VALUE: 'Недопустимое значение',
      NOT_FOUND: 'Не найдено',
      NO_ASSIGNED_USER: 'Нет исполнителя',
      NO_BOARDS_FOUND: 'Досок не найдено',
      NO_ITEMS: 'Нет элементов',
      NO_LOGGED_TIME: 'Нет зарегистрированного времени',
      NO_STATUSES_FOUND: 'Статусов не найдено',
      NO_TAGS_ADDED: 'Теги не добавлены',
      NO_TASKS: 'Нет задач',
      NO_VALID_DATA: 'Входные данные недействительны',
      NO_VALID_EMAIL: 'Электронная почта не является допустимым',
      NO_VALID_PASSWORD_OR_EMAIL:
        'Электронная почта или пароль недействительны',
      OK: 'Ok',
      OOPS: 'Ой!',
      OPEN_CHECKLIST: 'Открыть список задач',
      OR_UC: 'ИЛИ',
      SIGN_IN_WITH_EMAIL: 'войдите с помощью электронной почты',
      REGISTER_WITH_EMAIL: 'зарегистрируйтесь с помощью электронной почты',
      PAGE: 'Страница',
      PARTICIPANT: 'участник',
      PARTICIPANTS: 'участники',
      PASSWORD: 'Пароль',
      PASSWORD_SUGGESTION: 'Пароль должен содержать не менее 6 символов',
      PLEASE_CONFIRM_DELETE_ACTION: 'Пожалуйста, подтвердите удаление',
      PLEASE_CREATE_NEW_PASSWORD_TO_CONTINUE: 'Пожалуйста, создайте новый пароль, чтобы продолжить',
      PREFIX_SUGGESTION: 'Префикс должен содержать не менее 1 символа',
      PRIORITY: 'Приоритет',
      PRIVACY_POLICY: 'Политика конфиденциальности',
      PRIVATE: 'Личные',
      PROMO_FEATURE_METHODOLOGY_OF_AGILE:
        'Мы верим в силу методологии Agile для достижения успеха проекта. Agile — это больше, чем просто среда разработки. Это образ мышления, который способствует адаптивности, сотрудничеству и постоянному совершенствованию. Arella спроектирована так, чтобы легко интегрировать принципы Agile в ваш рабочий процесс',
      PROMO_FEATURE_SIMPLE_FLEXIBLE_AND_POWERFUL:
        'В Arella мы создали пользовательский интерфейс, в котором органично сочетаются простота, гибкость и мощность. Легко перемещайтесь по нашему интуитивно понятному интерфейсу, где простота — это не просто особенность, а руководящий принцип',
      PROMO_FEATURE_TRANSPARENCY_OF_WORK:
        'Arella нацелена на содействие открытому общению внутри команд. С нашими функциями прозрачности каждый участник проекта может быть в курсе хода выполнения задач. Обновления в реальном времени гарантируют, что члены команды находятся на одной волне, облегчая более эффективное сотрудничество и координацию',
      PROMO_FEATURE_VISUALIZATION_OF_PROCESSES:
        'Превратите абстрактное в осязаемое с помощью наших функций визуализации. Погрузитесь в визуальное представление своих рабочих процессов, обеспечивая ясность и понимание каждого этапа. Наблюдайте за ходом выполнения задач с помощью динамических визуальных досок, которые позволяют легко выявлять узкие места и оптимизировать рабочие процессы',
      REGISTER: 'Регистрация',
      REGISTER_HERE: 'Зарегистрируйтесь здесь!',
      REMEMBER_ME: 'Запомнить меня',
      REMOVE: 'Удалить',
      REQUIRED: 'Это поле обязательно к заполнению',
      RESET: 'Сброс',
      RESTORE: 'Восстановить',
      RIGHTS: 'Все права защищены',
      SAVE: 'Сохранить',
      SAVED: 'Сохранено!',
      SAVE_TASK: 'Сохранить задачу',
      SEARCH_BOARD: 'Поиск доски',
      SEARCH_TASK: 'Поиск задачи',
      SEARCH_USER: 'Поиск пользователя',
      SECURITY: 'Безопасноть',
      SEE_WHAT_YOUR_TEAM_COULD_DO_WITH:
        'Посмотрите на что способна ваша команда с',
      SEND: 'Отправить',
      SETTINGS: 'Настройки',
      SHARED: 'Общие',
      SHARED_WITH_FRIENDS: 'Поделиться с друзьями',
      SIGN_IN: 'Войти',
      SIGN_IN_HERE: 'Войти!',
      SIMPLE_FLEXIBLE_AND_POWERFUL: 'Простота, гибкость и мощность',
      SLOGAN_ARELLA_A:
        'Идеальное решение, которое выведет Ваш опыт по управлению проектами на новый уровень',
      SLOGAN_ARELLA_B:
        'Повышайте свою продуктивность, оптимизируйте совместную работу и опережайте свои цели с помощью наших функций, разработанных специально для наших пользователей',
      SLOGAN_ARELLA_C:
        'Канбан-доска — инструмент визуализации рабочего процесса, обеспечивающий полную прозрачность работы ',
      SLOGAN_ARELLA_D:
        'Все рабочие элементы визуализируются в виде карточек Канбан, что позволяет каждому члену команды перемещать карточки и видеть статус каждой задачи',
      SMALL_PASSWORD: 'Пароль должен быть больше 6 символов',
      SORT_BY: 'Сортировать по:',
      START: 'Начало',
      STATUS: 'Статус',
      STATUSES: 'Статусы',
      STREAMLINE_YOUR_WORKFLOW: 'Оптимизируйте свой рабочий процесс',
      SUBJECT: 'Тема',
      SUPPORT: 'Поддержка',
      SUPPORT_FORM: 'Форма поддержки',
      SUPPORT_FORM_TITLE: 'Что-то пошло не так? Опишите здесь свою проблему.',
      TAGS: 'Теги',
      TASK: 'задача',
      TASK_C: 'Задача',
      TASKS: 'задачи',
      TASK_ID: 'Идентификатор задачи',
      TASK_INDEX: 'Индекс задачи',
      THEME: 'Темы',
      TITLE: 'Заголовок',
      TIME: 'Время',
      TIME_SPEND: 'Затраченное время',
      TOO_SHORT: 'Пароль должен быть более 6 символов',
      TOOLTIP_MESSAGE_CANNOT_DELETE: 'Невозможно удалить: существуют задачи',
      TOTAL: 'Итого',
      TOTAL_TIME: 'Общее время',
      TRANSPARENCY_OF_WORK: 'Прозрачность работы',
      TRY_WITHOUT_REGISTRATION: 'Попробовать без регистрации',
      UNARCHIVE: 'Разархивировать',
      UNARCHIVE_BOARD: 'Разархивировать доску',
      UPDATED: 'обновлением',
      USER: 'Пользователь',
      USERNAME: 'Имя пользователя',
      USER_NOT_FOUND: 'Пользователь не найден',
      USER_PROFILE: 'Профиль пользователя',
      USER_TASKS: 'Задачи пользователя',
      USER_TIME_REPORT: 'Отчет о времени пользователя',
      VIEW_ALL: 'посмотреть все',
      VIEW_BOARDS: 'Просмотреть доски',
      VISUALIZATION_OF_PROCESSES: 'Визуализация процессов',
      WELCOME_TO: 'Добро пожаловать в',
      WHAT_IS: 'Что такое',
      WHAT_YOU_GET_WITH: 'Что вы получаете с',
      WRONG_EMAIL: 'Неправильный адрес электронной почты',
    },
  },
  fr: {
    translation: {
      ABOUT: 'À propos de l’application',
      ACCEPT: 'Accepter',
      ADD: 'Ajouter',
      ADD_COLUMN: 'Ajouter une colonne',
      ADD_FAVORITE: 'Ajouter aux favoris',
      ADD_FILTER: 'Ajouter un filtre',
      ADD_LINK: 'Ajouter un lien',
      ADD_STATUS: 'Ajouter un statut',
      ADD_TAG: 'Ajouter une étiquette',
      ADD_TASK: 'Ajouter une tâche',
      ADD_TIME: 'Ajouter du temps',
      ADD_USER: 'Ajouter un utilisateur',
      AGREEMENT: 'Je suis d’accord avec ',
      APPLICATION_NAME: 'Nom de l’application',
      ARCHIVE: 'Archives',
      ASSIGNED: 'Attribué',
      ASSIGNED_USER: 'Attribué',
      ARCHIVE_BOARD: 'Archiver le tableau',
      ALREADY_HAVE_AN_ACCOUNT: 'Vous avez déjà un compte?',
      BACK_TO: 'Retour à',
      BOARD: 'Planche',
      BOARDS: 'Planches',
      BOARD_ACTIONS: 'Actions du conseil',
      BOARD_CREATED: 'Conseil créé',
      BOARD_DELETED: 'Tableau supprimé!',
      BOARD_INFO: 'Informations sur le conseil',
      BOARD_PREFIX: 'Préfixe de carte',
      BOARD_TEAM: 'Équipe du conseil',
      BOARD_TITLE: 'Titre du tableau',
      BOARD_UPDATED: 'Tableau mis à jour!',
      BACKLOG: 'Backlog',
      CANCEL: 'Annuler',
      CHANGE_BOARD: 'Сhanger de tableau',
      CHANGE_PASSWORD: 'Changer le mot de passe',
      CHANGE_STATUS: 'Changer de statut',
      CHANGING_TASK_INDEX: 'Changement de l’index de tâche',
      CHECK_EMAIL: 'Vérifiez votre e-mail!',
      CHECKLIST: 'Liste de contrôle',
      CLOSE_CHECKLIST: 'Fermer la liste de contrôle',
      COLOR_MARK: 'Marque de couleur',
      COLOR_THEME: 'Thème de couleur',
      CONFIRM_PASSWORD: 'Confirmez le mot de passe',
      CONTACT_US: 'Contactez-nous',
      CONTACT_FORM: 'Formulaire de contact',
      CONTAIN_INJECTIONS: 'Les données d\'entrée contiennent des injections',
      CONTINUE_WITH: 'Continuer avec ',
      COPYRIGHT_TEXT: 'Texte de droit d’auteur',
      CREATED_BY: 'Créé par',
      CREATE_NEW_BOARD: 'Créer un planche',
      CREATE_NEW_COLUMN: 'Créer une nouvelle colonne',
      CREATE_NEW_TASK: 'Créer une nouvelle tâche',
      CREATE_TASK: 'Créer une tâche',
      CREATE_YOUR_ACCOUNT_BELLOW: 'Créez votre compte ci-dessous',
      CREATED: 'créé',
      CURRENT_PASSWORD: 'Mot de passe actuel',
      CUSTOM: 'personnalisé',
      DARK: 'Foncée',
      DATA_END: 'Date de fin',
      DATA_NOT_SAVED: 'Données non enregistrées',
      DATA_START: 'Date de début',
      DATE: 'Date',
      DELETE: 'Supprimer',
      DELETE_ACCOUNT: 'Supprimer le compte',
      DELETE_BOARD: 'Supprimer le tableau',
      DELETE_LOGGED_TIME: 'Supprimer temps enregistré',
      DELETE_RECORD: 'Supprimer l’enregistrement',
      DELETE_STATUS: 'Supprimer le statut',
      DELETE_TASK: 'Supprimer la tâche',
      DELETE_USER: 'Supprimer l’utilisateur',
      DESCRIPTION: 'La description',
      DESCRIPTION_ERROR_403: 'Vous n’êtes pas autorisé à afficher cette page. Veuillez vérifier vos informations d’identification et réessayer.',
      DETAILS: 'Détails',
      DEVELOPED_BY_LANADEV: 'Développé par LanaDev',
      DONT_HAVE_AN_ACCOUNT: 'Vous n’avez pas de compte?',
      EDIT: 'Éditer',
      EDIT_TIME: "Éditer l'heure",
      EMAIL: 'E-mail',
      EMPTY_FIELDS: 'Champs vides',
      END: 'Fin',
      ENTER_BOARD_NAME: 'Entrez le nom du tableau',
      ENTER_DATE: 'Entrez la date',
      ENTER_LINK: 'Entrez le lien',
      ENTER_NEW_ITEM: 'Entrer un nouvel élément',
      ENTER_NEW_TAG: 'Entrer un nouveau tag',
      ENTER_TIME: 'Entrez l’heure',
      ENTER_USER_NAME_OR_EMAIL: 'Entrez le nom d’utilisateur ou l’e-mail',
      ENTER_YOUR_CREDENTIALS:
        'Entrez vos informations d’identification pour continuer',
      ENTER_YOUR_EMAIL: 'Entrez votre email',
      ENTER_YOUR_MESSAGE: 'Entrez votre message',
      ENTER_YOUR_NAME: 'Entrez votre nom',
      ENTER_YOUR_SUBJECT: 'Entrez le sujet',
      EQUAL_PASSWORDS: 'Les mots de passe doivent être égaux',
      ESTIMATED_TIME: 'Temps estimé',
      ESTIMATION: 'Estimation',
      EXAMPLE: 'Exemple',
      FAQ: 'Questions et réponses',
      FAVORITE: 'Favoris',
      FIELDS_NOT_EQUAL: 'Les champs doivent être égaux',
      FILTERING: 'Filtrage',
      FILTERS: 'Filtres:',
      FIND_USER: 'Rechercher un utilisateur',
      FIRST_NAME: 'Prénom',
      FOLLOW_US: 'Suivez-nous',
      FORGOT_PASSWORD: 'Mot de passe oublié',
      FORGOT_YOUR_PASSWORD: 'Vous avez oublié votre mot de passe?',
      GENERAL_INFO: 'Informations générales',
      HIDE_ALL: 'masquer tout',
      GET_IN_TOUCH: 'Prenez contact',
      GO_TO_BOARD: 'Aller au tableau',
      GO_TO_THE_PREVIOUS_PAGE: 'Retour à la page précédente',
      GO_BACK: 'Revenir en arrière',
      GO_HOME: 'Retour à la maison',
      HIGH: 'Haut',
      HI_WELCOME_BACK: 'Bonjour, bienvenue',
      HOME: 'Accueil',
      INCORRECT_DATA_FORMAT: 'Format de données incorrect!',
      INFO: 'Info',
      ITEMS: 'Élément(s)',
      LANGUAGES: 'Langages',
      LAST_NAME: 'Nom de famille',
      LEAVE: 'Abandonner.',
      LEAVE_BOARD: 'Quitter le tableau',
      LESS_ACTIONS: 'Moins d’actions',
      LIGHT: 'Claire',
      LINK: 'Lien',
      LOAD: 'CHARGEMENT',
      LOGGED_TIME: 'Temps enregistré',
      LOGOUT: 'Se déconnecter',
      LOW: 'Bas',
      MEDIUM: 'Moyen',
      MESSAGE: 'Message',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX_CREATED_TASK: 'L’index de tâche attendu {{expectedBoardIndex}} de la tâche créée est modifié en {{newBoardIndex}} car l’index de tâche {{expectedBoardIndex}} est déjà utilisé sur le tableau.',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX: 'L’index de la tâche a été modifié de {{oldBoardIndex}} à {{newBoardIndex}} car l’index {{oldBoardIndex}} est déjà occupé sur ce tableau.',
      MESSAGE_ABOUT_MOBILE_VERSION: 'Version mobile bientôt disponible',
      MESSAGE_ABOUT_UNSAVED_DATA:
        'Les modifications ne sont pas enregistrées et seront perdues lors de la fermeture de la fenêtre. Veuillez confirmer l’action',
      MESSAGE_ARELLA_IS_THE_VISUAL_TOOL:
        'Un outil visuel de gestion de projet pour la collaboration d’équipe',
      MESSAGE_JUST_SIGN_UP_CREATE_A_BOARD_AND_YOU_ARE_OFF:
        'Il vous suffit de vous inscrire, de créer un tableau et vous êtes prêt!',
      MESSAGE_PROMO_CONTACT_FORM_А:
        'En fournissant vos coordonnées, vous acceptez que notre équipe puisse vous contacter par e-mail concernant ses produits, services et événements',
      MESSAGE_PROMO_CONTACT_FORM_B:
        'Si vous avez des demandes de fonctionnalités, des questions sur l’application, si vous avez besoin d’une assistance logicielle? Ou si vous voulez simplement dire "Salut", n’hésitez pas à nous contacter!',
      MESSAGE_TO_BACK_TO_SEARCH_RESULT: 'Retour au résultat de la recherche',
      MESSAGE_TO_CHOOSE_USER:
        '{{userName}} sera ajouté à ce tableau. {{userName}} aurait accès pour créer, mettre à jour et supprimer tous les types de données sur ce tableau. Voulez-vous continuer?',
      MESSAGE_TO_COOKIE_BANNER:
        'Nous utilisons des cookies pour améliorer votre expérience sur notre site Web.',
      MESSAGE_TO_CREATE_NEW_BOARD: 'Il est temps de créer un nouveau planche!',
      MESSAGE_TO_ARCHIVE_BOARD:
        'Vous allez archiver ce tableau. Veuillez confirmer votre action.',
      MESSAGE_TO_DELETE_ACCOUNT:
        '{{userName}}, es-tu sûr de vouloir supprimer ton compte ? Cette action ne peut être annulée et toutes vos données seront définitivement supprimées. Veuillez réfléchir à deux fois avant de continuer.',
      MESSAGE_TO_DELETE_BOARD:
        'Vous allez supprimer ce tableau. Toutes les données seront perdues et ne pourront pas être restaurées! Veuillez confirmer la suppression.',
      MESSAGE_TO_DELETE_USER: 'Supprimez l’accès de {{userName}} à ce tableau.',
      MESSAGE_TO_ERROR_404: 'Oops! Page non trouvée.',
      MESSAGE_TO_ERROR_403: 'Accès refusé',
      MESSAGE_TO_ERROR: 'Oops! Quelque chose est arrivé. Veuillez réessayer!',
      MESSAGE_TO_ERROR_CHANGE_BOARD_TASK:
        'Cette tâche est déjà située dans ce tableau',
      MESSAGE_TO_ERROR_CHANGE_PASSWORD: 'Les données d`accès sont incorrectes',
      MESSAGE_TO_ERROR_CHANGE_STATUS_TASK:
        'Cette tâche est déjà attribuée à ce statut',
      MESSAGE_TO_ERROR_PAGE: 'Oops! Quelque chose s’est mal passé.',
      MESSAGE_TO_LEAVE_BOARD: 'Confirmez votre action',
      MESSAGE_TO_REGISTER:
        'Veuillez vous inscrire pour accéder à toutes les fonctionnalités',
      MESSAGE_TO_RESTORE_PASSWORD:
        'Nous vous envoyons un mot de passe temporaire sur votre email. Vous pourrez le modifier ultérieurement dans les paramètres de votre profil.',
      MESSAGE_TO_UNARCHIVE_BOARD:
        'Vous allez désarchiver ce tableau. Veuillez confirmer votre action.',
      MESSAGE_WHAT_IS_ARELLA: 'Qu’est-ce que Arella?',
      METHODOLOGY_OF_AGILE: 'Méthodologie Agile',
      MORE_ACTIONS: 'Plus d’actions',
      NAME: 'Nom',
      NEW_BOARD: 'Nouveau planche',
      NEW_PASSWORD: 'Nouveau mot de passe',
      NOT_ALLOWED_VALUE: 'Valeur non autorisée',
      NOT_FOUND: 'Non trouvé',
      NO_ASSIGNED_USER: 'Aucun utilisateur assigné',
      NO_BOARDS_FOUND: 'Aucun planches trouvé',
      NO_ITEMS: 'Aucun élément',
      NO_LOGGED_TIME: 'Aucun temps enregistré',
      NO_STATUSES_FOUND: 'Aucun statut trouvé',
      NO_TAGS_ADDED: 'Aucun tag ajouté',
      NO_TASKS: 'Aucune tâche',
      NO_VALID_DATA: 'Les données d entrée ne sont pas valides',
      NO_VALID_EMAIL: 'L’email n’est pas valide',
      NO_VALID_PASSWORD_OR_EMAIL:
        'L’e-mail ou le mot de passe n’est pas valide',
      OK: 'Ok',
      OOPS: 'Oups!',
      OPEN_CHECKLIST: 'Ouvrir la liste de contrôle',
      OR_UC: 'OU',
      SIGN_IN_WITH_EMAIL: 'connectez-vous avec votre adresse e-mail',
      REGISTER_WITH_EMAIL: 'inscrivez-vous avec votre adresse e-mail',
      PAGE: 'Page',
      PARTICIPANT: 'participant',
      PARTICIPANTS: 'participants',
      PASSWORD: 'Mot de passe',
      PASSWORD_SUGGESTION:
        'Le mot de passe doit contenir au moins 6 caractères',
      PLEASE_CONFIRM_DELETE_ACTION:
        'Veuillez confirmer l’action de suppression',
      PLEASE_CREATE_NEW_PASSWORD_TO_CONTINUE: 'Veuillez créer un nouveau mot de passe pour continuer',
      PREFIX_SUGGESTION: 'Le préfixe doit comporter au moins 1 caractère',
      PRIORITY: 'Priorité',
      PRIVACY_POLICY: ' Politique de confidentialité',
      PRIVATE: 'Privé',
      PROMO_FEATURE_METHODOLOGY_OF_AGILE:
        'Nous croyons en la puissance de la méthodologie Agile pour conduire le succès du projet. Agile est plus qu’un simple cadre de développement. C’est une mentalité qui favorise l’adaptabilité, la collaboration et l’amélioration continue. Arella est conçue pour intégrer de manière transparente les principes Agile dans votre flux de travail',
      PROMO_FEATURE_SIMPLE_FLEXIBLE_AND_POWERFUL:
        'Chez Arella, nous avons créé une expérience utilisateur qui combine harmonieusement simplicité, flexibilité et puissance. Naviguez sans effort à travers notre interface intuitive, où la simplicité n’est pas seulement une fonctionnalité, mais un principe directeur',
      PROMO_FEATURE_TRANSPARENCY_OF_WORK:
        'Arella s’engage à favoriser la communication ouverte au sein des équipes. Avec nos fonctionnalités de transparence, tous les participants à un projet peuvent être informés de l’avancement des tâches. Les mises à jour en temps réel garantissent que les membres de l’équipe sont sur la même longueur d’onde, facilitant une meilleure collaboration et coordination',
      PROMO_FEATURE_VISUALIZATION_OF_PROCESSES:
        'Transformez l’abstrait en tangible avec nos fonctionnalités de visualisation. Plongez dans une représentation visuelle de vos processus de travail, offrant clarté et compréhension à chaque étape. Suivez la progression des tâches à travers des tableaux visuels dynamiques, facilitant l’identification des goulots d’étranglement et l’optimisation des flux de travail',
      REGISTER: 'S’inscrire',
      REGISTER_HERE: 'S’inscrire ici!',
      REMEMBER_ME: 'Souviens-toi de moi',
      REMOVE: 'Retirer',
      REQUIRED: 'Ce champ est obligatoire',
      RESET: 'Réinitialisation',
      RESTORE: 'Restaurer',
      RIGHTS: 'Tous droits réservés',
      SAVE: 'Enregistrer',
      SAVED: 'Enregistré!',
      SAVE_TASK: 'Enregistrer la tâche',
      SEARCH_BOARD: 'Tableau de recherche',
      SEARCH_TASK: 'Recherche de tâche',
      SEARCH_USER: 'Rechercher un utilisateur',
      SEE_WHAT_YOUR_TEAM_COULD_DO_WITH:
        'Découvrez ce que votre équipe pourrait faire avec',
      SECURITY: 'Sécurité',
      SEND: 'Envoyer',
      SETTINGS: 'Réglages',
      SHARED: 'Partagés',
      SHARED_WITH_FRIENDS: 'Partagé avec des amis',
      SIGN_IN: "S'identifier",
      SIGN_IN_HERE: "S'identifier!",
      SIMPLE_FLEXIBLE_AND_POWERFUL: 'Simplicité, flexibilité et puissance',
      SLOGAN_ARELLA_A:
        'La solution parfaite pour élever votre expérience en gestion de projet à un niveau supérieur',
      SLOGAN_ARELLA_B:
        'Augmentez votre productivité, optimisez la collaboration et restez en avance sur vos objectifs avec nos fonctionnalités conçues spécifiquement pour nos utilisateurs',
      SLOGAN_ARELLA_C:
        'Le tableau Kanban est un outil de visualisation du flux de travail qui offre une transparence totale du travail',
      SLOGAN_ARELLA_D:
        'Tous les éléments de travail sont visualisés sous forme de cartes Kanban, permettant à chaque membre de l équipe de déplacer les cartes et de voir le statut de chaque tâche',
      SMALL_PASSWORD: 'Le mot de passe doit comporter plus de 6 caractères',
      SORT_BY: 'Trier par:',
      START: 'Démarrer',
      STATUS: 'Statut',
      STATUSES: 'Statuts',
      STREAMLINE_YOUR_WORKFLOW: 'Rationalisez votre flux de travail',
      SUBJECT: 'Sujet',
      SUPPORT: 'Soutien',
      SUPPORT_FORM: 'Formulaire de support',
      SUPPORT_FORM_TITLE:
        'Un problème est survenu ? Décrivez votre problème ici.',
      TAGS: 'Étiquettes',
      TASK: 'tâche',
      TASK_C: 'Tâche',
      TASKS: 'tâches',
      TASK_ID: 'Identifiant de tâche',
      TASK_INDEX: 'Index de tâche',
      THEME: 'Thème',
      TITLE: 'Titre',
      TIME: 'Temps',
      TIME_SPEND: 'Temps passé',
      TOO_SHORT: 'Le mot de passe doit comporter plus de 6 caractères',
      TOOLTIP_MESSAGE_CANNOT_DELETE: 'Impossible de supprimer : des tâches existent',
      TOTAL: 'Total',
      TOTAL_TIME: 'Temps total',
      TRANSPARENCY_OF_WORK: 'Transparence du travail',
      TRY_WITHOUT_REGISTRATION: 'Essayez sans inscription',
      UNARCHIVE: 'Désarchiver',
      UNARCHIVE_BOARD: 'Décompresser le tableau',
      UPDATED: 'mis à jour',
      USERNAME: 'Nom d’utilisateur',
      USER: 'Utilisateur',
      USER_NOT_FOUND: 'Utilisateur non trouvé',
      USER_PROFILE: 'Profil de l’utilisateur',
      USER_TASKS: 'Tâches de l’utilisateur',
      USER_TIME_REPORT: 'Rapport sur le temps utilisateur',
      VIEW_ALL: 'voir tout',
      VIEW_BOARDS: 'Voir les tableaux',
      VISUALIZATION_OF_PROCESSES: 'Visualisation des processus',
      WELCOME_TO: 'Willkommen bei',
      WHAT_IS: 'Qu’est-ce que',
      WHAT_YOU_GET_WITH: 'Qu’obtenez-vous avec',
      WRONG_EMAIL: 'Mauvaise adresse mail',
    },
  },
  de: {
    translation: {
      ABOUT: 'Über die Anwendung',
      ACCEPT: 'Akzeptieren',
      ADD: 'Hinzufügen',
      ADD_COLUMN: 'Spalte hinzufügen',
      ADD_FAVORITE: 'Zu den Favoriten hinzufügen',
      ADD_FILTER: 'Filter hinzufügen',
      ADD_LINK: 'Link hinzufügen',
      ADD_STATUS: 'Status hinzufügen',
      ADD_TAG: 'Tag hinzufügen',
      ADD_TASK: 'Aufgabe hinzufügen',
      ADD_TIME: 'Zeit hinzufügen',
      ADD_USER: 'Nutzer hinzufügen',
      AGREEMENT: 'Ich stimme dem zu ',
      APPLICATION_NAME: 'Anwendungsname',
      ARCHIVE: 'Archiv',
      ASSIGNED: 'Zugewiesen',
      ASSIGNED_USER: 'Zugewiesen',
      ARCHIVE_BOARD: 'Archivtafel',
      ALREADY_HAVE_AN_ACCOUNT: 'Sie haben bereits ein Konto?',
      BACK_TO: 'Zurück zu',
      BOARD: 'Brett',
      BOARDS: 'Bretter',
      BOARD_ACTIONS: 'Vorstandsaktionen',
      BOARD_CREATED: 'Board erstellt',
      BOARD_DELETED: 'Vorstand gelöscht!',
      BOARD_INFO: 'Board-Info',
      BOARD_PREFIX: 'Board-Präfix',
      BOARD_TEAM: 'Vorstandsteam',
      BOARD_TITLE: 'Titel der Board',
      BOARD_UPDATED: 'Vorstand aktualisiert!',
      BACKLOG: 'Backlog',
      CANCEL: 'Abbrechen',
      CHANGE_BOARD: 'Brett wechseln',
      CHANGE_PASSWORD: 'Passwort ändern',
      CHANGE_STATUS: 'Status ändern',
      CHANGING_TASK_INDEX: 'Ändern des Aufgabenindex',
      CHECK_EMAIL: 'Check deine E-Mails!',
      CHECKLIST: 'Checkliste',
      CLOSE_CHECKLIST: 'Checkliste schließen',
      COLOR_MARK: 'Farbmarke',
      COLOR_THEME: 'Farbe Thema',
      CONFIRM_PASSWORD: 'Kennwort bestätigen',
      CONTACT_US: 'Kontaktiere uns',
      CONTACT_FORM: 'Kontaktformular',
      CONTAIN_INJECTIONS: 'Eingabedaten enthalten Injektionen',
      CONTINUE_WITH: 'Weiter mit ',
      COPYRIGHT_TEXT: 'Copyright-Text',
      CREATED_BY: 'Erstellt von',
      CREATE_NEW_BOARD: 'Bretter erstellen',
      CREATE_NEW_COLUMN: 'Neuen status erstellen',
      CREATE_NEW_TASK: 'Neue Aufgabe erstellen',
      CREATE_TASK: 'Aufgabe erstellen',
      CREATE_YOUR_ACCOUNT_BELLOW: 'Erstellen Sie Ihr Konto unten',
      CREATED: 'erstellt',
      CURRENT_PASSWORD: 'Derzeitiges Passwort',
      CUSTOM: 'benutzerdefinierte',
      DARK: 'Dunkel',
      DATA_END: 'Endtermin',
      DATA_NOT_SAVED: 'Daten nicht gespeichert',
      DATA_START: 'Startdatum',
      DATE: 'Datum',
      DELETE: 'Löschen',
      DELETE_ACCOUNT: 'Konto löschen',
      DELETE_BOARD: 'Tafel löschen',
      DELETE_LOGGED_TIME: 'Protokollierte zeit löschen',
      DELETE_RECORD: 'Aufzeichnung löschen',
      DELETE_STATUS: 'Status löschen',
      DELETE_TASK: 'Aufgabe löschen',
      DELETE_TIME: 'Zeit löschen',
      DELETE_USER: 'Benutzer löschen',
      DESCRIPTION: 'Beschreibung',
      DESCRIPTION_ERROR_403: 'Sie haben keine Berechtigung, diese Seite anzuzeigen. Bitte überprüfen Sie Ihre Anmeldedaten und versuchen Sie es erneut.',
      DETAILS: 'Details',
      DEVELOPED_BY_LANADEV: 'Entwickelt von LanaDev',
      DONT_HAVE_AN_ACCOUNT: 'Sie haben noch kein Konto?',
      EDIT: 'Bearbeiten',
      EDIT_TIME: 'Zeit bearbeiten',
      EMAIL: 'Email',
      EMPTY_FIELDS: 'Leerfelder',
      END: 'Ende',
      ENTER_BOARD_NAME: 'Brettnamen eingeben',
      ENTER_DATE: 'Datum',
      ENTER_LINK: 'Link eingeben',
      ENTER_NEW_ITEM: 'Neues Element eingeben',
      ENTER_NEW_TAG: 'Neuen Tag eingeben',
      ENTER_TIME: 'Zeit eingeben',
      ENTER_USER_NAME_OR_EMAIL:
        'Geben Sie den Benutzernamen oder die E-Mail-Adresse ein',
      ENTER_YOUR_CREDENTIALS:
        'Geben Sie Ihre Anmeldedaten ein, um fortzufahren',
      ENTER_YOUR_EMAIL: 'Geben Sie Ihre E-Mail-Adresse ein',
      ENTER_YOUR_MESSAGE: 'Geben Sie Ihre Nachricht ein',
      ENTER_YOUR_NAME: 'Geben Sie Ihren Namen ein',
      ENTER_YOUR_SUBJECT: 'Geben Sie Ihr Thema ein',
      EQUAL_PASSWORDS: 'Passwörter sollten gleich sein',
      ESTIMATED_TIME: 'Geschätzte zeit',
      ESTIMATION: 'Estimation',
      EXAMPLE: 'Beispiel',
      FAQ: 'Fragen und antworten',
      FAVORITE: 'Favoriten',
      FIELDS_NOT_EQUAL: 'Les champs doivent être égaux',
      FILTERING: 'Filterung',
      FILTERS: 'Filter:',
      FIND_USER: 'Benutzer finden',
      FIRST_NAME: 'Vorname',
      FOLLOW_US: 'Folge uns',
      FORGOT_PASSWORD: 'Passwort vergessen',
      FORGOT_YOUR_PASSWORD: 'Haben Sie Ihr Passwort vergessen?',
      GENERAL_INFO: 'Allgemeine Information',
      HIDE_ALL: 'alle ausblenden',
      GET_IN_TOUCH: 'Treten sie in kontakt',
      GO_TO_BOARD: 'Zum Board gehen',
      GO_BACK: 'Zurück gehen',
      GO_HOME: 'Nach Hause gehen',
      GO_TO_THE_PREVIOUS_PAGE: 'Zurück zur vorherigen Seite',
      HIGH: 'Hoch',
      HI_WELCOME_BACK: 'Hallo, willkommen zurück',
      HOME: 'Startseite',
      INCORRECT_DATA_FORMAT: 'Falsches Datenformat!',
      INFO: 'Information',
      ITEMS: 'Element(e)',
      LANGUAGES: 'Sprachen',
      LAST_NAME: 'Nachname',
      LEAVE: 'Verlassen',
      LEAVE_BOARD: 'Verlassen des Brettes',
      LESS_ACTIONS: 'Weniger Aktionen',
      LIGHT: 'Hell',
      LINK: 'Link',
      LOAD: 'LADEN',
      LOGGED_TIME: 'Protokollierte zeit',
      LOGOUT: 'Ausloggen',
      LOW: 'Niedrig',
      MEDIUM: 'Mittel',
      MESSAGE: 'Nachricht',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX_CREATED_TASK: 'Der erwartete Aufgabenindex {{expectedBoardIndex}} der erstellten Aufgabe wurde geändert in {{newBoardIndex}}, da der Aufgabenindex {{expectedBoardIndex}} bereits auf dem Board verwendet wird.',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX: 'Der Aufgabenindex wurde von {{oldBoardIndex}} auf {{newBoardIndex}} geändert, da der Index {{oldBoardIndex}} bereits auf diesem Board belegt ist.',
      MESSAGE_ABOUT_MOBILE_VERSION: 'Mobile version bald verfügbar',
      MESSAGE_ABOUT_UNSAVED_DATA:
        'Die Änderungen wurden nicht gespeichert und gehen beim Schließen des Fensters verloren. Bitte bestätigen Sie die Aktion',
      MESSAGE_ARELLA_IS_THE_VISUAL_TOOL:
        'Ein visuelles projektmanagement-tool für teamzusammenarbeit',
      MESSAGE_JUST_SIGN_UP_CREATE_A_BOARD_AND_YOU_ARE_OFF:
        'Einfach anmelden, ein Board erstellen und los geht’s!',
      MESSAGE_PROMO_CONTACT_FORM_А:
        'Indem sie ihre kontaktdaten angeben, erklären sie sich damit einverstanden, dass unser team sie per e-mail über seine produkte, dienstleistungen und veranstaltungen kontaktieren darf',
      MESSAGE_PROMO_CONTACT_FORM_B:
        'Wenn sie anfragen zu funktionen haben, fragen zur anwendung, wenn sie unterstützung für die software benötigen? Oder einfach "Hallo" sagen möchten, kontaktieren sie uns bitte!',
      MESSAGE_TO_BACK_TO_SEARCH_RESULT: 'Zurück zum Suchergebnis',
      MESSAGE_TO_CHOOSE_USER:
        '{{userName}} wird zu diesem Board hinzugefügt. {{userName}} hätte Zugriff, um alle Arten von Daten auf diesem Board zu erstellen, zu aktualisieren und zu löschen. Möchten Sie fortfahren?',
      MESSAGE_TO_COOKIE_BANNER:
        'Wir verwenden Cookies, um Ihr Erlebnis auf unserer Website zu verbessern.',
      MESSAGE_TO_CREATE_NEW_BOARD:
        'Es ist Zeit, ein neues bretter zu erstellen!',
      MESSAGE_TO_ARCHIVE_BOARD:
        'Sie werden dieses Board archivieren. Bitte bestätigen Sie Ihre Aktion.',
      MESSAGE_TO_DELETE_ACCOUNT: '{{userName}}, Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden und alle Ihre Daten werden dauerhaft gelöscht. Bitte überlegen Sie es sich zweimal, bevor Sie fortfahren.',
      MESSAGE_TO_DELETE_BOARD:
        'Sie werden dieses Board entfernen. Alle Daten gehen verloren und können nicht wiederhergestellt werden! Bitte bestätigen Sie die Löschung.',
      MESSAGE_TO_DELETE_USER:
        'Zugriff für {{userName}} auf dieses Board entfernen.',
      MESSAGE_TO_ERROR_404: 'Hoppla! Seite nicht gefunden.',
      MESSAGE_TO_ERROR_403: 'Zugriff abgelehnt',
      MESSAGE_TO_ERROR: 'Hoppla! Etwas passiert. Bitte versuche es erneut!',
      MESSAGE_TO_ERROR_CHANGE_BOARD_TASK:
        'Diese Aufgabe befindet sich bereits auf diesem Board',
      MESSAGE_TO_ERROR_CHANGE_PASSWORD: 'Die Zugangsdaten sind falsch',
      MESSAGE_TO_ERROR_CHANGE_STATUS_TASK:
        'Diese Aufgabe ist bereits diesem Status zugewiesen',
      MESSAGE_TO_ERROR_PAGE: 'Hoppla! Etwas ist schief gelaufen.',
      MESSAGE_TO_LEAVE_BOARD: 'Bestätigen Sie Ihre Aktion',
      MESSAGE_TO_REGISTER:
        'Bitte registrieren Sie sich, um auf alle Funktionen zugreifen zu können',
      MESSAGE_TO_RESTORE_PASSWORD:
        'Wir senden Ihnen ein vorläufiges Passwort per E-Mail. Sie können es später in Ihren Profileinstellungen ändern.',
      MESSAGE_TO_UNARCHIVE_BOARD:
        'Du wirst dieses Board aus dem Archiv entfernen. Bitte bestätigen Sie Ihre Aktion.',
      MESSAGE_WHAT_IS_ARELLA: 'Was ist Arella?',
      METHODOLOGY_OF_AGILE: 'Agile Methodologie',
      MORE_ACTIONS: 'Mehr Aktionen',
      NAME: 'Name',
      NEW_BOARD: 'Neue brett',
      NEW_PASSWORD: 'Neues Passwort',
      NOT_ALLOWED_VALUE: 'Unzulässiger Wert',
      NOT_FOUND: 'Nicht gefunden',
      NO_ASSIGNED_USER: 'Kein zugewiesener Benutzer',
      NO_BOARDS_FOUND: 'Keine bretter gefunden',
      NO_ITEMS: 'Keine Elemente',
      NO_LOGGED_TIME: 'Keine erfasste Zeit',
      NO_STATUSES_FOUND: 'Keine Statusmeldungen gefunden',
      NO_TAGS_ADDED: 'Keine Tags hinzugefügt',
      NO_TASKS: 'Keine Aufgaben',
      NO_VALID_DATA: 'Die Eingabedaten sind ungültig',
      NO_VALID_EMAIL: 'Email ist ungültig',
      NO_VALID_PASSWORD_OR_EMAIL: 'E-Mail oder Passwort ist ungültig',
      OK: 'Ok',
      OOPS: 'Hoppla!',
      OPEN_CHECKLIST: 'Checkliste öffnen',
      OR_UC: 'ODER',
      SIGN_IN_WITH_EMAIL: 'mit E-Mail anmelden',
      REGISTER_WITH_EMAIL: 'mit E-Mail registrieren',
      PAGE: 'Seite',
      PARTICIPANT: 'teilnehmer',
      PARTICIPANTS: 'teilnehmer',
      PASSWORD: 'Passwort',
      PASSWORD_SUGGESTION: 'Das Passwort muss mindestens 6 Zeichen lang sein',
      PLEASE_CONFIRM_DELETE_ACTION: 'Bitte bestätigen Sie den Löschvorgang',
      PLEASE_CREATE_NEW_PASSWORD_TO_CONTINUE: 'Bitte erstellen Sie ein neues Passwort, um fortzufahren',
      PREFIX_SUGGESTION: 'Das Präfix muss mindestens 1 Zeichen lang sein',
      PRIORITY: 'Priorität',
      PRIVACY_POLICY: 'Datenschutzrichtlinie',
      PRIVATE: 'Privat',
      PROMO_FEATURE_METHODOLOGY_OF_AGILE:
        'Wir glauben an die kraft der agilen methodik, um den projekterfolg voranzutreiben. Agile ist mehr als nur ein entwicklungsframework. Es ist eine denkweise, die anpassungsfähigkeit, zusammenarbeit und kontinuierliche verbesserung fördert. Arella ist darauf ausgelegt, agile prinzipien nahtlos in ihren arbeitsablauf zu integrieren',
      PROMO_FEATURE_SIMPLE_FLEXIBLE_AND_POWERFUL:
        'Bei Arella haben wir eine benutzererfahrung geschaffen, die nahtlos einfachheit, flexibilität und leistung vereint. Navigieren sie mühelos durch unsere intuitive benutzeroberfläche, bei der einfachheit nicht nur eine funktion, sondern ein leitprinzip ist',
      PROMO_FEATURE_TRANSPARENCY_OF_WORK:
        'Arella setzt sich dafür ein, die offene kommunikation innerhalb von teams zu fördern. Mit unseren transparenz-funktionen können alle am projekt beteiligten über den fortschritt der aufgaben informiert bleiben. Echtzeit-updates stellen sicher, dass teammitglieder auf dem gleichen stand sind und erleichtern so eine bessere zusammenarbeit und koordination',
      PROMO_FEATURE_VISUALIZATION_OF_PROCESSES:
        'Verwandeln sie das abstrakte ins greifbare mit unseren visualisierungsfunktionen. Tauchen sie ein in eine visuelle darstellung ihrer arbeitsprozesse und schaffen sie klarheit und einblick in jeder phase. Verfolgen sie, wie aufgaben durch dynamische visuelle boards fortschreiten und erleichtern sie die identifizierung von engpässen und die optimierung von workflows',
      REGISTER: 'Registrieren',
      REGISTER_HERE: 'Hier anmelden!',
      REMEMBER_ME: 'Erinnere dich an mich',
      REMOVE: 'Entfernen',
      REQUIRED: 'Dieses Feld ist erforderlich',
      RESET: 'Reset',
      RESTORE: 'Wiederherstellen',
      RIGHTS: 'Alle Rechte vorbehalten',
      SAVE: 'Sparen',
      SAVED: 'Gerettet!',
      SAVE_TASK: 'Aufgabe speichern',
      SEARCH_BOARD: 'Suchen sie nach dem brett',
      SEARCH_TASK: 'Aufgabe suchen',
      SEARCH_USER: 'Benutzer suchen',
      SEE_WHAT_YOUR_TEAM_COULD_DO_WITH:
        'Sehen sie was ihr team mit Arella tun könnte',
      SECURITY: 'Sicherheit',
      SEND: 'Senden',
      SETTINGS: 'Einstellungen',
      SHARED: 'Geteilt',
      SHARED_WITH_FRIENDS: 'Geteilt mit Freunden',
      SIGN_IN: 'Einloggen',
      SIGN_IN_HERE: 'Einloggen!',
      SIMPLE_FLEXIBLE_AND_POWERFUL: 'Einfachheit, flexibilität und leistung',
      SLOGAN_ARELLA_A:
        'Die perfekte Lösung, um Ihre Projekterfahrung auf die nächste Stufe zu heben',
      SLOGAN_ARELLA_B:
        'Steigern sie ihre produktivität, optimieren sie die zusammenarbeit und bleiben sie mit unseren funktionen, die speziell für unsere Benutzer entwickelt wurden, auf kurs',
      SLOGAN_ARELLA_C:
        'Das Kanban-board ist ein tool zur visualisierung von arbeitsabläufen, das vollständige transparenz bei der arbeit bietet',
      SLOGAN_ARELLA_D:
        'Alle arbeitsgegenstände werden als Kanban-karten visualisiert, wodurch jedem teammitglied ermöglicht wird, karten zu verschieben und den status jeder aufgabe zu sehen',
      SMALL_PASSWORD: 'Das Passwort muss mehr als 6 Zeichen lang sein',
      SORT_BY: 'Sortieren nach:',
      START: 'Starten',
      STATUS: 'Status',
      STATUSES: 'Status',
      STREAMLINE_YOUR_WORKFLOW: 'Optimieren sie ihren arbeitsablauf',
      SUBJECT: 'Gegenstand',
      SUPPORT: 'Support',
      SUPPORT_FORM: 'Support-formular',
      SUPPORT_FORM_TITLE:
        'Etwas ist schiefgelaufen? Beschreiben sie ihr problem hier.',
      TAGS: 'Schlagwörter',
      TASK: 'aufgabe',
      TASK_C: 'Aufgabe',
      TASKS: 'aufgaben',
      TASK_ID: 'Aufgaben-ID',
      TASK_INDEX: 'Aufgabenindex',
      THEME: 'Thema',
      TITLE: 'Titel',
      TIME: 'Zeit',
      TIME_SPEND: 'Zeit verbringen',
      TOO_SHORT: 'Das Passwort muss mehr als 6 Zeichen lang sein',
      TOOLTIP_MESSAGE_CANNOT_DELETE: 'Kann nicht löschen: Aufgaben existieren',
      TOTAL: 'Gesamt',
      TOTAL_TIME: 'Gesamtzeit',
      TRANSPARENCY_OF_WORK: 'Transparenz der Arbeit',
      TRY_WITHOUT_REGISTRATION: 'Probieren Sie es ohne Registrierung aus',
      UNARCHIVE: 'Entpacken',
      UNARCHIVE_BOARD: 'Entpacken Sie das Board',
      UPDATED: 'Aktualisiert',
      USER: 'Benutzer',
      USERNAME: 'Nutzername',
      USER_NOT_FOUND: 'Benutzer nicht gefunden',
      USER_PROFILE: 'Benutzerprofil',
      USER_TASKS: 'Benutzeraufgaben',
      USER_TIME_REPORT: 'Bericht über die Nutzungszeit',
      VIEW_ALL: 'alle anzeigen',
      VIEW_BOARDS: 'Boards anzeigen',
      VISUALIZATION_OF_PROCESSES: 'Visualisierung von Prozessen',
      WELCOME_TO: 'Bienvenue sur',
      WHAT_IS: 'Was ist',
      WHAT_YOU_GET_WITH: 'Was bekommen Sie mit',
      WRONG_EMAIL: 'Falsche E-mail',
    },
  },
  es: {
    translation: {
      ABOUT: 'Acerca de la aplicación',
      ACCEPT: 'Aceptar',
      ADD: 'Añadir',
      ADD_COLUMN: 'Añadir columna',
      ADD_FAVORITE: 'Agregar a favoritos',
      ADD_FILTER: 'Agregar filtro',
      ADD_LINK: 'Añadir enlace',
      ADD_STATUS: 'Agregar estado',
      ADD_TAG: 'Agregar etiqueta',
      ADD_TASK: 'Agregar tarea',
      ADD_TIME: 'Agregar tiempo',
      ADD_USER: 'Agregar usuario',
      AGREEMENT: 'Estoy de acuerdo con el ',
      APPLICATION_NAME: 'Anwendungsname',
      ARCHIVE: 'Archivo',
      ASSIGNED: 'Asignada(-о)',
      ASSIGNED_USER: 'Asignada(-о)',
      ARCHIVE_BOARD: 'Tablero de archivo',
      ALREADY_HAVE_AN_ACCOUNT: '¿Ya tiene una cuenta?',
      BACK_TO: 'Volver a',
      BOARD: 'Tablero',
      BOARDS: 'Tableros',
      BOARD_ACTIONS: 'Acciones de la junta',
      BOARD_CREATED: 'Tablero creado',
      BOARD_DELETED: 'Tablero eliminado!',
      BOARD_INFO: 'Información del tablero',
      BOARD_PREFIX: 'Prefijo del tablero',
      BOARD_TEAM: 'Equipo de la junta',
      BOARD_TITLE: 'Título del tablero',
      BOARD_UPDATED: 'Tablero actualizado!',
      BACKLOG: 'Backlog',
      CANCEL: 'Cancelar',
      CHANGE_BOARD: 'Tablero de cambio',
      CHANGE_PASSWORD: 'Cambia la contraseña',
      CHANGE_STATUS: 'Cambiar estado',
      CHANGING_TASK_INDEX: 'Cambio de índice de tarea',
      CHECK_EMAIL: 'Consultar su correo electrónico!',
      CHECKLIST: 'Lista de verificación',
      CLOSE_CHECKLIST: 'Cerrar lista de verificación',
      COLOR_MARK: 'Marca de color',
      COLOR_THEME: 'Tema de color',
      CONFIRM_PASSWORD: 'Confirmar contraseña',
      CONTACT_US: 'Contáctenos',
      CONTACT_FORM: 'Formulario de contacto',
      CONTAIN_INJECTIONS: 'Los datos de entrada contienen inyecciones',
      CONTINUE_WITH: 'Continuar con ',
      COPYRIGHT_TEXT: 'Texto de derechos de autor',
      CREATED_BY: 'Creado por',
      CREATE_NEW_BOARD: 'Crear tablero',
      CREATE_NEW_COLUMN: 'Neue Spalte erstellen',
      CREATE_NEW_TASK: 'Crear nueva tarea',
      CREATE_TASK: 'Crear tarea',
      CREATE_YOUR_ACCOUNT_BELLOW: 'Cree su cuenta',
      CREATED: 'creada',
      CURRENT_PASSWORD: 'Contraseña actual',
      CUSTOM: 'personalizada',
      DARK: 'Oscura',
      DATA_END: 'Fecha final',
      DATA_NOT_SAVED: 'Datos no guardados',
      DATA_START: 'Fecha de inicio',
      DATE: 'Fecha',
      DELETE: 'Borrar',
      DELETE_ACCOUNT: 'Borrar cuenta',
      DELETE_BOARD: 'Eliminar tablero',
      DELETE_LOGGED_TIME: 'Eliminar tiempo registrado',
      DELETE_RECORD: 'Eliminar el registro',
      DELETE_STATUS: 'Eliminar estado',
      DELETE_TASK: 'Eliminar tarea',
      DELETE_TIME: 'Eliminar tiempo',
      DELETE_USER: 'Borrar usuario',
      DESCRIPTION: 'Descripción',
      DESCRIPTION_ERROR_403: 'No tienes permiso para ver esta página. Verifique sus credenciales y vuelva a intentarlo.',
      DETAILS: 'Detalles',
      DEVELOPED_BY_LANADEV: 'Desarrollado por LanaDev',
      DONT_HAVE_AN_ACCOUNT: '¿No tiene cuenta?',
      EDIT: 'Editar',
      EDIT_TIME: 'Editar hora',
      EMAIL: 'Correo electrónico',
      EMPTY_FIELDS: 'Campos vacíos',
      END: 'Fin',
      ENTER_BOARD_NAME: 'Introduce el nombre del tablero',
      ENTER_DATE: 'Fecha',
      ENTER_LINK: 'Ingresar enlace',
      ENTER_NEW_ITEM: 'Ingresar nuevo elemento',
      ENTER_NEW_TAG: 'Introducir una nueva etiqueta',
      ENTER_TIME: 'Ingresar tiempo',
      ENTER_USER_NAME_OR_EMAIL:
        'Ingrese nombre de usuario o correo electrónico',
      ENTER_YOUR_CREDENTIALS: 'Introduzca sus credenciales para continuar',
      ENTER_YOUR_EMAIL: 'Ingrese su correo electrónico',
      ENTER_YOUR_MESSAGE: 'Ingrese su mensaje',
      ENTER_YOUR_NAME: 'Ingrese su nombre',
      ENTER_YOUR_SUBJECT: 'Ingrese su tema',
      EQUAL_PASSWORDS: 'Las contraseñas deben ser iguales',
      ESTIMATED_TIME: 'Tiempo estimado',
      ESTIMATION: 'Estimacion',
      EXAMPLE: 'Ejemplo',
      FAQ: 'Preguntas y respuestas',
      FAVORITE: 'Favoritos',
      FIELDS_NOT_EQUAL: 'Los campos deben ser iguales',
      FILTERING: 'Filtrado',
      FILTERS: 'Filtros:',
      FIND_USER: 'Encontrar usuario',
      FIRST_NAME: 'Nombre',
      FOLLOW_US: 'Síguenos',
      FORGOT_PASSWORD: 'Se te olvidó tu contraseña',
      FORGOT_YOUR_PASSWORD: '¿Ha olvidado su contraseña?',
      GENERAL_INFO: 'Información general',
      HELLO_WELCOME_BACK: 'Hola, bienvenido de nuevo',
      HIDE_ALL: 'ocultar todo',
      GET_IN_TOUCH: 'Póngase en contacto',
      GO_TO_BOARD: 'Ir al tablón',
      GO_BACK: 'Volver atrás',
      GO_HOME: 'Ir a casa',
      GO_TO_THE_PREVIOUS_PAGE: 'Volver a la página anterior',
      HIGH: 'Alto',
      HOME: 'Inicio',
      INCORRECT_DATA_FORMAT: '¡Formato de datos incorrecto!',
      INFO: 'Información',
      ITEMS: 'Elemento(s)',
      LANGUAGES: 'Idiomas',
      LAST_NAME: 'Apellido',
      LEAVE: 'Abandonar',
      LEAVE_BOARD: 'Abandonar el tablero',
      LESS_ACTIONS: 'Menos acciones',
      LIGHT: 'Clara',
      LINK: 'Enlace',
      LOAD: 'CARGA',
      LOGGED_TIME: 'Tiempo registrado',
      LOGOUT: 'Cerrar sesión',
      LOW: 'Baja',
      MEDIUM: 'Medio',
      MESSAGE: 'Mensaje',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX_CREATED_TASK: 'El índice de tarea esperado {{expectedBoardIndex}} de la tarea creada se cambia a {{newBoardIndex}} ya que el índice de tarea {{expectedBoardIndex}} ya está en uso en el tablero.',
      MESSAGE_ABOUT_CHANGED_TASK_INDEX: 'El índice de tarea se ha cambiado de {{oldBoardIndex}} a {{newBoardIndex}} porque el índice {{oldBoardIndex}} ya está ocupado en este tablero.',
      MESSAGE_ABOUT_MOBILE_VERSION: 'Próximamente la versión móvil',
      MESSAGE_ABOUT_UNSAVED_DATA:
        'Los cambios no se han guardado y se perderán al cerrar la ventana. Por favor, confirme la acción',
      MESSAGE_ARELLA_IS_THE_VISUAL_TOOL:
        'Una herramienta visual de gestión de proyectos para la colaboración en equipo',
      MESSAGE_JUST_SIGN_UP_CREATE_A_BOARD_AND_YOU_ARE_OFF:
        '¡Simplemente regístrate, crea un tablero y estás listo!',
      MESSAGE_PROMO_CONTACT_FORM_A:
        'Al proporcionar su información de contacto, usted acepta que nuestro equipo pueda ponerse en contacto con usted por correo electrónico acerca de sus productos, servicios y eventos',
      MESSAGE_PROMO_CONTACT_FORM_B:
        'Si tienes alguna solicitud de funciones, preguntas sobre la aplicación, ¿necesitas soporte técnico? ¿O simplemente quieres decir "Hola", por favor contáctanos!',
      MESSAGE_TO_BACK_TO_SEARCH_RESULT: 'Volver al resultado de búsqueda',
      MESSAGE_TO_CHOOSE_USER:
        '{{userName}} se agregará a este foro. {{userName}} tendría acceso para crear, actualizar y eliminar todo tipo de datos en este foro. ¿Te gustaria continuar?',
      MESSAGE_TO_COOKIE_BANNER:
        'Utilizamos cookies para mejorar su experiencia en nuestro sitio web.',
      MESSAGE_TO_CREATE_NEW_BOARD: '¡Es hora de crear un nuevo tablero!',
      MESSAGE_TO_ARCHIVE_BOARD:
        'Vas a archivar este tablero. Por favor confirma tu acción.',
      MESSAGE_TO_DELETE_ACCOUNT:
        '{{userName}}, ¿Estás seguro de que quieres eliminar tu cuenta? Esta acción no se puede deshacer y todos sus datos se eliminarán permanentemente. Piénselo dos veces antes de continuar.',
      MESSAGE_TO_DELETE_BOARD:
        'Vas a quitar este tablero. ¡Todos los datos se perderán y no se podrán restaurar! Confirme la eliminación.',
      MESSAGE_TO_DELETE_USER: 'Elimina el acceso de {{userName}} a este foro.',
      MESSAGE_TO_ERROR_404: '¡Ups! Página no encontrada.',
      MESSAGE_TO_ERROR_403: 'Acceso denegado',
      MESSAGE_TO_ERROR: '¡Ups! Algo pasó. ¡Intentar otra vez!',
      MESSAGE_TO_ERROR_CHANGE_BOARD_TASK:
        'Esta tarea ya está ubicada en este tablero',
      MESSAGE_TO_ERROR_CHANGE_PASSWORD: 'Los datos de acceso son incorrectos',
      MESSAGE_TO_ERROR_CHANGE_STATUS_TASK:
        'Esta tarea ya está asignada a este estado',
      MESSAGE_TO_ERROR_PAGE: 'Oops! Something went wrong.',
      MESSAGE_TO_LEAVE_BOARD: 'Confirme su acción',
      MESSAGE_TO_REGISTER: 'Regístrese para acceder a todas las funciones',
      MESSAGE_TO_RESTORE_PASSWORD:
        'Le enviamos una contraseña temporal a su correo electrónico. Puedes cambiarlo más tarde en la configuración de tu perfil.',
      MESSAGE_TO_UNARCHIVE_BOARD:
        'Vas a desarchivar este tablero. Por favor confirma tu acción.',
      MESSAGE_WHAT_IS_ARELLA: '¿Qué es Arella?',
      METHODOLOGY_OF_AGILE: 'Metodología Agile',
      MORE_ACTIONS: 'Mas acciones',
      NAME: 'Nombre',
      NEW_BOARD: 'Nuevo tablero',
      NEW_PASSWORD: 'Nueva contraseña',
      NOT_ALLOWED_VALUE: 'Valor no permitido',
      NOT_FOUND: 'No encontrado',
      NO_ASSIGNED_USER: 'Sin usuario asignado',
      NO_BOARDS_FOUND: 'No se encontraron tableros',
      NO_ITEMS: 'Sin elementos',
      NO_LOGGED_TIME: 'Sin tiempo registrado',
      NO_STATUSES_FOUND: 'No se encontraron estados',
      NO_TAGS_ADDED: 'No se han añadido etiquetas',
      NO_TASKS: 'Sin tareas',
      NO_VALID_DATA: 'Los datos de entrada no son válidos',
      NO_VALID_EMAIL: 'El correo no es válido',
      NO_VALID_PASSWORD_OR_EMAIL:
        'El correo electrónico o la contraseña no son válidos',
      OK: 'Ok',
      OOPS: '¡Ups!',
      OPEN_CHECKLIST: 'Abrir lista de verificación',
      OR_UC: 'O',
      SIGN_IN_WITH_EMAIL: 'inicia sesión con tu correo electrónico',
      REGISTER_WITH_EMAIL: 'regístrate con tu correo electrónico',
      PAGE: 'Página',
      PARTICIPANT: 'participante',
      PARTICIPANTS: 'participantes',
      PASSWORD: 'Contraseña',
      PASSWORD_SUGGESTION:
        'La contraseña debe contener 6 caracteres como mínimo',
      PLEASE_CONFIRM_DELETE_ACTION:
        'Por favor, confirme la acción de eliminación',
      PLEASE_CREATE_NEW_PASSWORD_TO_CONTINUE: 'Por favor crea una nueva contraseña para continuar',
      PREFIX_SUGGESTION: 'El prefijo debe tener al menos 1 carácter',
      PRIORITY: 'Prioridad',
      PRIVACY_POLICY: 'Política de privacidad',
      PRIVATE: 'Privado',
      PROMO_FEATURE_METHODOLOGY_OF_AGILE:
        'Creemos en el poder de la metodología Agile para impulsar el éxito del proyecto. Agile es más que un simple marco de desarrollo. Es una mentalidad que promueve la adaptabilidad, la colaboración y la mejora continua. Arella está diseñada para integrar sin problemas los principios Agile en su flujo de trabajo',
      PROMO_FEATURE_SIMPLE_FLEXIBLE_AND_POWERFUL:
        'En Arella, hemos creado una experiencia de usuario que combina perfectamente la simplicidad, la flexibilidad y la potencia. Navega sin esfuerzo a través de nuestra interfaz intuitiva, donde la simplicidad no es solo una característica, sino un principio rector',
      PROMO_FEATURE_TRANSPARENCY_OF_WORK:
        'Arella se compromete a fomentar la comunicación abierta dentro de los equipos. Con nuestras características de transparencia, todos los involucrados en un proyecto pueden mantenerse informados sobre el progreso de las tareas. Las actualizaciones en tiempo real garantizan que los miembros del equipo estén en la misma página, facilitando una mejor colaboración y coordinación',
      PROMO_FEATURE_VISUALIZATION_OF_PROCESSES:
        'Transforma lo abstracto en lo tangible con nuestras funciones de visualización. Sumérgete en una representación visual de tus procesos de trabajo, brindando claridad y perspectiva en cada etapa. Observa cómo las tareas avanzan a través de tableros visuales dinámicos, facilitando la identificación de cuellos de botella y la optimización de flujos de trabajo',
      REGISTER: 'Registro',
      REGISTER_HERE: 'Regístrese aquí!',
      REMEMBER_ME: 'Recuérdame',
      REMOVE: 'Eliminar',
      REQUIRED: 'Este campo es obligatorio',
      RESET: 'Reset',
      RESTORE: 'Restaurar',
      RIGHTS: 'Todos los derechos reservados',
      SAVE: 'Ahorrar',
      SAVED: '¡Salvado!',
      SAVE_TASK: 'Guardar tarea',
      SEARCH_BOARD: 'Busca el tablero',
      SEARCH_TASK: 'Buscar tarea',
      SEARCH_USER: 'Buscar usuario',
      SECURITY: 'Seguridad',
      SEE_WHAT_YOUR_TEAM_COULD_DO_WITH:
        'Descubre lo que tu equipo podría hacer con',
      SEND: 'Enviar',
      SETTINGS: 'Ajustes',
      SHARED: 'Compartidos',
      SHARED_WITH_FRIENDS: 'Compartido con amigos',
      SIGN_IN: 'Iniciar sesión',
      SIGN_IN_HERE: 'Iniciar sesión!',
      SIMPLE_FLEXIBLE_AND_POWERFUL: 'Simplicidad, flexibilidad y potencia',
      SLOGAN_ARELLA_A:
        'La solución perfecta para llevar tu experiencia en gestión de proyectos al siguiente nivel',
      SLOGAN_ARELLA_B:
        'Aumenta tu productividad, optimiza la colaboración y mantente al frente de tus objetivos con nuestras funciones diseñadas específicamente para nuestros usuarios',
      SLOGAN_ARELLA_C:
        'El tablero Kanban es una herramienta de visualización de flujo de trabajo que proporciona total transparencia en el trabajo',
      SLOGAN_ARELLA_D:
        'Todos los elementos de trabajo se visualizan como tarjetas Kanban, lo que permite a cada miembro del equipo mover las tarjetas y ver el estado de cada tarea',
      SMALL_PASSWORD: 'La contraseña debe tener más de 6 caracteres',
      SORT_BY: 'Ordenar por:',
      START: 'Comenzar',
      STATUS: 'Estado',
      STATUSES: 'Estados',
      STREAMLINE_YOUR_WORKFLOW: 'Optimiza tu flujo de trabajo',
      SUBJECT: 'Tema',
      SUPPORT: 'Apoyo',
      SUPPORT_FORM: 'Formulario de soporte',
      SUPPORT_FORM_TITLE: '¿Algo salió mal? Describe tu problema aquí.',
      TAGS: 'Etiquetas',
      TASK: 'tarea',
      TASK_C: 'Tarea',
      TASKS: 'tareas',
      TASK_ID: 'Identificador de tarea',
      TASK_INDEX: 'Índice de tarea',
      THEME: 'Tema',
      TITLE: 'Título',
      TIME: 'Tiempo',
      TIME_SPEND: 'Tiempo invertido',
      TOO_SHORT: 'La contraseña debe tener más de 6 caracteres.',
      TOOLTIP_MESSAGE_CANNOT_DELETE: 'No se puede eliminar: existen tareas',
      TOTAL: 'Total',
      TOTAL_TIME: 'Tiempo total',
      TRANSPARENCY_OF_WORK: 'Transparencia en el trabajo',
      TRY_WITHOUT_REGISTRATION: 'Prueba sin registro',
      UNARCHIVE: 'Desarchivar',
      UNARCHIVE_BOARD: 'Descomprimir tablero',
      UPDATED: 'actualizada',
      USER: 'Usuario',
      USERNAME: 'Nombre de usuario',
      USER_NOT_FOUND: 'Usuario no encontrado',
      USER_PROFILE: 'Perfil del usuario',
      USER_TASKS: 'Tareas del usuario',
      USER_TIME_REPORT: 'Informe de tiempo del usuario',
      VIEW_ALL: 'ver todo',
      VIEW_BOARDS: 'Ver tableros',
      VISUALIZATION_OF_PROCESSES: 'Visualización de procesos',
      WELCOME_TO: 'Bienvenido a',
      WHAT_IS: '¿Qué es',
      WHAT_YOU_GET_WITH: '¿Qué obtienes con',
      WRONG_EMAIL: 'Email incorrecto',
    },
  },
};

export default messages;
