import React from 'react';
import Icon from '../Icon';

const IconLock = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-lock">
      <svg viewBox="0 0 60 66" xmlns="http://www.w3.org/2000/svg">
        <path d="M53.498 26.664H6.07002C4.10941 26.664 2.52002 28.1415 2.52002 29.964V61.38C2.52002 63.2025 4.10941 64.68 6.07002 64.68H53.498C55.4586 64.68 57.048 63.2025 57.048 61.38V29.964C57.048 28.1415 55.4586 26.664 53.498 26.664Z" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M16 26V13.8222C16 7.07128 22.0442 2 29.5 2C36.9558 2 43 7.07128 43 13.8222V26" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M29.7839 46.9392V51.3744" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M29.784 46.3056C31.6662 46.3056 33.192 44.8872 33.192 43.1376C33.192 41.388 31.6662 39.9696 29.784 39.9696C27.9018 39.9696 26.376 41.388 26.376 43.1376C26.376 44.8872 27.9018 46.3056 29.784 46.3056Z" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
      </svg>

    </Icon>
  );
};

export default IconLock;
