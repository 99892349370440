import React from 'react';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useSetRecoilState } from 'recoil';
import { useI18N } from '../../i18n/useI18N';

import { activeBoardState } from '../../store/atoms';

// Components
import ColorMarkBar from '../ColorMarkBar';
import {
  IconFolder,
  IconStar,
  IconUserShield,
  IconUsers,
} from '../Icons';

// Styles
import './BoardCard.scss';

const BoardCard = (props) => {
  const {
    data,
    isCurrentUserOwner,
    isFavorite,
    onMouseDown,
  } = props;

  const I18N = useI18N();

  const navigate = useNavigate();

  const setActiveBoard = useSetRecoilState(activeBoardState);

  const openBoard = () => {
    setActiveBoard(data);
    navigate(`/board/${data.id}`);
  };

  const getBoardIcon = () => {
    const isPrivate = data.usersCount === 1;
    if (isFavorite) {
      return (
        <div className="c-board-card__icons">
          <IconStar size="xs" />
          {isPrivate ? <IconUserShield size="xs" /> : <IconUsers size="xs" />}
        </div>
      );
    }

    if (data.isArchive) {
      return (
        <div className="c-board-card__icons">
          <IconFolder size="xs" />
          {isPrivate ? <IconUserShield size="xs" /> : <IconUsers size="xs" />}
        </div>
      );
    }

    if (isPrivate) {
      return (
        <IconUserShield size="sm" />
      );
    }

    return (
      <IconUsers size="sm" />
    );
  };

  const handleDragStart = () => false;

  return (
    <div
      className="c-board-card__wrapper"
      onClick={openBoard}
      onMouseDown={onMouseDown}
      onDragStart={handleDragStart}
    >
      <div className={`c-board-card ${isCurrentUserOwner ? 'owner' : ''}`}>
        <div className="c-board-card__header">
          <header className="c-board-card__title">{data.name}</header>
          {getBoardIcon()}
        </div>
        <div className="c-board-card__description">{data.description}</div>
        <footer className="c-board-card__footer">
          {data.tasksCount > 0 && (
            <span>
              { `${data.tasksCount} ${data.tasksCount > 1 ? I18N.TASKS : I18N.TASK}` }
            </span>
          )}
          <span>
            { `${data.usersCount} ${data.usersCount > 1 ? I18N.PARTICIPANTS : I18N.PARTICIPANT}` }
          </span>
        </footer>
      </div>
      <ColorMarkBar position="bottom" color={data.colorMark} />
    </div>
  );
};

export default BoardCard;
