import React from 'react';
import './BoardListEmptyState.scss';
import Button from '../Button';
import { useI18N } from '../../i18n/useI18N';
import Panel from '../Panel';

const BoardListEmptyState = (props) => {
  const { createBoard } = props;

  const I18N = useI18N();

  return (
    <div className="c-board-list-null">
      <Panel>
        <div className="c-board-list-null__content">
          <p className="c-board-list-null__title">{I18N.NO_BOARDS_FOUND}</p>
          <p className="c-board-list-null__message">{I18N.MESSAGE_TO_CREATE_NEW_BOARD}</p>
          <Button text={I18N.CREATE_NEW_BOARD} size="lg" onClick={createBoard} />
        </div>
      </Panel>
    </div>
  );
};

export default BoardListEmptyState;
