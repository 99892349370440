/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

// Configs
import { BOARD_SORT_TYPE, BOARD_TYPE } from '../../config/config';

// Store
import useBoardMutations from '../../store/mutations/useBoardMutations';
import { filterBoardSortMethodState } from '../../store/selector';
import { sortBoardState, userProfileState } from '../../store/atoms';

// Utils
import moveElement from '../../utils/moveElement';

// Components
import BoardCard from '../BoardCard';
import BoardPanelTop from '../BoardPanelTop';
import Panel from '../Panel';

// Styles
import './BoardPanel.scss';

const BoardPanel = (props) => {
  const {
    boardType,
    listBoards = [],
    name,
    onClick,
    userData,
  } = props;

  const [sortBoard, setSortBoard] = useRecoilState(sortBoardState(boardType));
  const methodSort = useRecoilValue(filterBoardSortMethodState(boardType));
  const userProfile = useRecoilValue(userProfileState);

  const boardPanelClassName = `c-board-panel ${boardType}`;

  const {
    addBoardToFavoriteMutation,
    deleteBoardFromFavoriteMutation,
    reorderBoardsMutation,
    updateBoardMutation,
  } = useBoardMutations();

  const changeSortOrder = () => {
    if (sortBoard.type) {
      setSortBoard({ ...sortBoard, orderBy: !sortBoard.orderBy });
    }
  };

  const sortBoards = (type, isCancelSort = false) => {
    if (isCancelSort) {
      setSortBoard({ type: null, orderBy: null });
    } else {
      setSortBoard({ ...sortBoard, type });
    }
  };

  const getBoards = (currentBoards) => {
    if (sortBoard.orderBy) {
      return currentBoards.sort(methodSort).reverse();
    }

    return currentBoards.sort(methodSort);
  };

  const updateBoardOrder = (elements) => {
    const currentBoards = getBoards(listBoards);

    const isNewSequence = elements.some((card, index) => {
      const order = Number(getComputedStyle(card).order);
      return index !== order;
    });

    if (!isNewSequence) {
      return;
    }

    const newBoardOrder = currentBoards.map((board, index) => {
      const node = elements[index];
      const order = Number(getComputedStyle(node).order);
      return { id: board.id, order };
    });

    reorderBoardsMutation({ [boardType]: newBoardOrder });

    elements.forEach((node) => {
      node.style = null;
    });
  };

  const changeBoardPanel = (boardId, newPanelName) => {
    const isFavBoard = userProfile.favoriteBoards.some((favBoard) => favBoard.boardId === boardId);

    if (BOARD_TYPE.FAVORITE === newPanelName && !isFavBoard) {
      addBoardToFavoriteMutation(boardId);
    }

    if (BOARD_TYPE.ARCHIVE === newPanelName) {
      updateBoardMutation({ id: boardId, isArchive: true });
    }

    if (BOARD_TYPE.PRIVATE === newPanelName || BOARD_TYPE.SHARED === newPanelName) {
      deleteBoardFromFavoriteMutation(boardId);
    }
  };

  const handleDragStart = (e, boardId) => {
    if (sortBoard.type !== BOARD_SORT_TYPE.CUSTOM || sortBoard.orderBy) {
      return;
    }

    const { currentTarget, clientX: startingCoordinateX, clientY: startingCoordinateY } = e;

    const currentCard = currentTarget;
    const { parentElement } = currentTarget;

    const nodePanels = document.querySelectorAll('.c-board-panel');
    const panels = [...nodePanels];
    let anotherPanel = boardType;
    let isAccessToChangePanel = false;

    const [privatePanel, sharedPanel] = panels.slice(1, 3);
    let newPanel = null;

    const draggableBoard = listBoards.find((board) => board.id === boardId);

    let cardStyles = currentCard.getBoundingClientRect();
    const { marginLeft } = getComputedStyle(currentCard);

    const emptyCard = currentCard.cloneNode(true);
    emptyCard.classList.toggle('draggable');
    emptyCard.style.top = `${cardStyles.top}px`;
    emptyCard.style.left = `${cardStyles.left - parseInt(marginLeft, 10)}px`;

    const cards = [...parentElement.children];
    cards.forEach((node, index) => {
      node.style.order = index;
    });
    const nonDraggableCards = cards.filter((node) => !node.isEqualNode(currentCard));

    const timeoutId = setTimeout(() => {
      currentCard.classList.add('empty-card');
      document.body.style.userSelect = 'none';
      document.body.style.cursor = 'grabbing';

      parentElement.prepend(emptyCard);
    }, 300);

    const handleDragMove = (event) => {
      const { clientX: currentCoordinateX, clientY: currentCoordinateY } = event;
      moveElement(emptyCard, {
        currentCoordinateX,
        currentCoordinateY,
        startingCoordinateX,
        startingCoordinateY,
      });

      emptyCard.hidden = true;
      const elemBelow = document.elementFromPoint(event.clientX, event.clientY);
      emptyCard.hidden = false;

      if (elemBelow) {
        anotherPanel = elemBelow.closest('.c-board-panel');
      }

      if (anotherPanel && anotherPanel.classList[1] !== newPanel) {
        panels.forEach((panel) => {
          panel.classList.remove('card-insertion');
          panel.style = null;
        });

        newPanel = anotherPanel.classList[1];

        if (newPanel === boardType) {
          isAccessToChangePanel = false;
          return;
        }

        if (
          draggableBoard.isArchive
            || (draggableBoard.usersCount !== 1 && anotherPanel === privatePanel)
            || (draggableBoard.usersCount === 1 && anotherPanel === sharedPanel)
        ) {
          anotherPanel.style.filter = 'blur(3px)';
          isAccessToChangePanel = false;
          return;
        }

        anotherPanel.classList.add('card-insertion');
        isAccessToChangePanel = true;
      }

      cardStyles = emptyCard.getBoundingClientRect();

      nonDraggableCards.forEach((card) => {
        const cardPosition = card.getBoundingClientRect();

        const isOverlapByX = cardStyles.x < cardPosition.x + cardPosition.width / 2
                && cardStyles.x + cardStyles.width / 2 > cardPosition.x;

        const isOverlapByY = cardStyles.y < cardPosition.y + cardPosition.height / 2
                && cardStyles.y + cardStyles.height / 2 > cardPosition.y;

        if (isOverlapByX && isOverlapByY) {
          const orderCardMoved = getComputedStyle(card).order;
          card.style.order = getComputedStyle(currentCard).order;
          currentCard.style.order = orderCardMoved;
        }
      });
    };

    const handleDragEnd = () => {
      clearTimeout(timeoutId);
      currentCard.classList.remove('empty-card');

      if (parentElement.querySelector('.draggable')) {
        parentElement.removeChild(emptyCard);
      }

      if (isAccessToChangePanel && newPanel !== boardType) {
        changeBoardPanel(boardId, newPanel);
      }

      if (!isAccessToChangePanel && newPanel === boardType) {
        updateBoardOrder(cards);
      }

      cards.forEach((node) => {
        node.style = null;
      });

      panels.forEach((panel) => {
        panel.classList.remove('card-insertion');
        panel.style = null;
      });

      document.onpointerup = null;
      document.onpointermove = null;
      document.body.style = null;
    };

    document.onpointermove = handleDragMove;
    document.onpointerup = handleDragEnd;
  };

  return (
    <div className={boardPanelClassName}>
      <Panel>
        <BoardPanelTop
          createNewBoard={onClick}
          isTempUser={userData.isTemp}
          selectedSortType={sortBoard.type}
          sortBoards={sortBoards}
          sortOrderDirections={sortBoard.orderBy}
          switchSortOrder={changeSortOrder}
          title={name}
          type={boardType}
        />
        <div className="c-board-panel__list-board">
          {getBoards(listBoards).map((board) => (
            <BoardCard
              data={board}
              isCurrentUserOwner={userData?.id === board.ownerId}
              isFavorite={boardType === BOARD_TYPE.FAVORITE}
              key={board.id}
              onMouseDown={(e) => handleDragStart(e, board.id)}
            />
          ))}
        </div>
      </Panel>
    </div>
  );
};

export default BoardPanel;
