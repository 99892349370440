import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Validator } from 'livr';

// Hooks
import { useI18N } from '../../i18n/useI18N';
import useBlockClick from '../../hooks/useBlockClick';

// Components
import Heading from '../Heading';
import Button from '../Button';
import Modal from '../Modal';
import InputControl from '../InputControl';

// Store
import { userProfileState, sessionState } from '../../store/atoms';
import useUserProfileMutations from '../../store/mutations/useUserProfileMutations';

// Style
import './Settings.scss';

const Settings = () => {
  const userProfile = useRecoilValue(userProfileState);

  const { updateUserProfile, updateUserPwd, removeUserAccount } = useUserProfileMutations();

  const I18N = useI18N({ userName: userProfile?.userName });

  const [isDeleteAccount, setIsDeleteAccount] = useState(false);
  const [isEditPasswordMode, setIsEditPasswordMode] = useState(false);
  const [isActiveChangePwd, setIsActiveChangePwd] = useState(false);
  const [currentPwdVisibility, setCurrentPwdVisibility] = useState(false);
  const [newPwdVisibility, setNewPwdVisibility] = useState(false);
  const [confirmPwdVisibility, setConfirmPwdVisibility] = useState(false);
  const [session, setSession] = useRecoilState(sessionState);

  const hiddenClassName = session.restorePwd ? 'hidden' : '';
  const userDeleteClassName = `c-settings__remove-user ${hiddenClassName}`;
  const restorePasswordClassName = session.restorePwd ? 'restore' : '';
  const securityClassName = `c-settings__security ${restorePasswordClassName}`;

  const pwdValidationRules = {
    password: ['required', { min_length: 6 }],
  };
  const newPwdValidationRules = {
    newPassword: ['required', { min_length: 6 }],
    confirmPassword: ['required', { equal_to_field: 'newPassword' }, { min_length: 6 }],
  };

  const pwdValidator = new Validator(pwdValidationRules);
  const newPwdValidator = new Validator(newPwdValidationRules);

  const refPwdWrapper = useBlockClick(session.restorePwd, 'c-main-layout', (event) => {
    if (event.target.innerText === 'Logout') return;
    if (event.target.closest('.c-notification')) return;
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
  });

  const [formData, setFormData] = useState({});

  const updateForm = (key, event) => {
    const isPwdForm = key === 'currentPassword' || key === 'newPassword' || key === 'confirmPassword';

    const newFormData = {
      ...formData,
      [key]: {
        value: event.currentTarget.value,
        error: null,
      },
    };
    setFormData(newFormData);

    if (isPwdForm) {
      const isValidPwdForm = session.restorePwd
        ? true : pwdValidator.validate({
          password: newFormData?.currentPassword?.value,
        });
      const isValidNewPwdForm = newPwdValidator.validate({
        newPassword: newFormData.newPassword.value,
        confirmPassword: newFormData.confirmPassword.value,
      });
      if (key === 'newPassword' && isValidNewPwdForm) {
        setFormData({
          ...newFormData,
          confirmPassword: {
            value: newFormData.confirmPassword.value,
            error: null,
          },
        });
      }

      if (key === 'confirmPassword' && isValidNewPwdForm) {
        setFormData({
          ...newFormData,
          newPassword: {
            value: newFormData.newPassword.value,
            error: null,
          },
        });
      }
      setIsActiveChangePwd(isValidPwdForm && isValidNewPwdForm);
    }
  };

  const resetField = (key) => {
    const isPwdForm = key === 'currentPassword' || key === 'newPassword' || key === 'confirmPassword';

    setFormData({
      ...formData,
      [key]: { value: '' },
    });

    if (isPwdForm) {
      setIsActiveChangePwd(false);
    }
  };

  const handleFocus = (event) => {
    const eventTarget = event.currentTarget;
    const clearCurrentError = {
      ...formData,
      [eventTarget.name]: {
        value: event.currentTarget.value,
        error: null,
      },
    };

    setFormData(clearCurrentError);

    if (
      !session.restorePwd
      && formData.currentPassword.error !== null
      && formData.currentPassword.error !== I18N.SMALL_PASSWORD
    ) {
      setFormData({
        ...formData,
        currentPassword: {
          value: formData.currentPassword.value,
          error: null,
        },
        newPassword: {
          value: formData.newPassword.value,
          error: null,
        },
        confirmPassword: {
          value: formData.confirmPassword.value,
          error: null,
        },
      });
      setIsActiveChangePwd(true);
    }
  };

  const toggleDeleteAccountModal = () => {
    setIsDeleteAccount(!isDeleteAccount);
  };

  const switchToEditPasswordMode = () => {
    setFormData({
      ...formData,
      currentPassword: {
        value: null,
        error: null,
      },
      newPassword: {
        value: null,
        error: null,
      },
      confirmPassword: {
        value: null,
        error: null,
      },
    });

    setIsEditPasswordMode(true);
  };

  const acceptDeleteAccount = () => {
    removeUserAccount(userProfile.id);
  };

  const handleBlur = (event) => {
    const { value, name } = event.currentTarget;
    let isValid = false;
    let error = null;

    switch (name) {
      case 'currentPassword':
        isValid = pwdValidator.validate({ password: value });
        error = I18N.SMALL_PASSWORD;
        break;
      case 'newPassword':
        isValid = newPwdValidator.validate({
          newPassword: value,
          confirmPassword: formData.confirmPassword.value,
        });
        error = I18N[newPwdValidator.getErrors()?.newPassword];
        break;
      case 'confirmPassword':
        isValid = newPwdValidator.validate({
          newPassword: formData.newPassword.value,
          confirmPassword: value,
        });
        error = I18N[newPwdValidator.getErrors()?.confirmPassword];
        break;
      default:
        break;
    }

    setFormData({
      ...formData,
      [name]: {
        value,
        error: isValid ? null : error,
      },
    });
  };

  const changePwd = () => {
    if (session.restorePwd) {
      return updateUserPwd({ newPassword: formData.newPassword.value });
    }
    return updateUserProfile(userProfile.id, {
      currentPassword: formData.currentPassword.value,
      newPassword: formData.newPassword.value,
    });
  };

  const changeCurrentPwdVisibility = () => {
    setCurrentPwdVisibility((prevState) => !prevState);
  };

  const changeNewPwdVisibility = () => {
    setNewPwdVisibility((prevState) => !prevState);
  };
  const changeConfirmPwdVisibility = () => {
    setConfirmPwdVisibility((prevState) => !prevState);
  };

  const handleKeyDownForInput = (key, event) => {
    if (event.code === 'Escape') {
      event.target.blur();
    }

    if (event.code === 'Delete') {
      resetField(key);
    }
  };

  const handleEnterKeyAction = useCallback((event) => {
    if (event.code === 'Enter' && isActiveChangePwd) {
      return changePwd();
    }
    return null;
  }, [isActiveChangePwd]);

  const handleDeleteKey = (event) => {
    if (event.code === 'Delete' && event.target.nodeName === 'BODY') {
      toggleDeleteAccountModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleDeleteKey);

    return () => {
      document.removeEventListener('keydown', handleDeleteKey);
    };
  }, [handleDeleteKey]);

  useEffect(() => {
    document.addEventListener('keydown', handleEnterKeyAction);
    return () => {
      document.removeEventListener('keydown', handleEnterKeyAction);
    };
  }, [handleEnterKeyAction]);

  useEffect(() => {
    if (session.hasError) {
      setFormData({
        ...formData,
        currentPassword: {
          value: formData.currentPassword.value,
          error: session.errorChangePwd,
        },
        newPassword: {
          value: formData.newPassword.value,
          error: true,
        },
        confirmPassword: {
          value: formData.confirmPassword.value,
          error: true,
        },
      });
      setIsActiveChangePwd(false);
      setSession({ ...session, errorChangePwd: null, hasError: null });
    }

    if (session.restorePwd) {
      setFormData({
        ...formData,
        newPassword: {
          value: null,
          error: null,
        },
        confirmPassword: {
          value: null,
          error: null,
        },
      });
      setSession({ ...session, errorChangePwd: null, hasError: null });
    }
  }, [session.hasError]);

  return (
    <>
      <div className="c-settings">
        <div ref={refPwdWrapper} className={securityClassName}>
          <Heading type="secondary" text={I18N.SECURITY} />
          {isEditPasswordMode || session.restorePwd ? (
            <>
              <InputControl
                type="password"
                label={I18N.CURRENT_PASSWORD}
                value={formData.currentPassword?.value || ''}
                name="currentPassword"
                onChange={(e) => updateForm('currentPassword', e)}
                errorMessage={formData.currentPassword?.error}
                onReset={() => resetField('currentPassword')}
                hidden={session.restorePwd}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onKeyDown={(e) => handleKeyDownForInput('currentPassword', e)}
                showPwd={currentPwdVisibility}
                changePwdVisibility={changeCurrentPwdVisibility}
              />
              <InputControl
                type="password"
                label={I18N.NEW_PASSWORD}
                value={formData.newPassword?.value || ''}
                name="newPassword"
                onChange={(e) => updateForm('newPassword', e)}
                errorMessage={formData.newPassword?.error}
                onReset={() => resetField('newPassword')}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onKeyDown={(e) => handleKeyDownForInput('newPassword', e)}
                showPwd={newPwdVisibility}
                changePwdVisibility={changeNewPwdVisibility}
              />
              <InputControl
                type="password"
                label={I18N.CONFIRM_PASSWORD}
                value={formData.confirmPassword?.value || ''}
                name="confirmPassword"
                onChange={(e) => updateForm('confirmPassword', e)}
                errorMessage={formData.confirmPassword?.error}
                onReset={() => resetField('confirmPassword')}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onKeyDown={(e) => handleKeyDownForInput('confirmPassword', e)}
                showPwd={confirmPwdVisibility}
                changePwdVisibility={changeConfirmPwdVisibility}
              />
              <div className="c-user-edit-profile__form-actions">
                <Button
                  text={I18N.CHANGE_PASSWORD}
                  onClick={changePwd}
                  disabled={!isActiveChangePwd}
                />
              </div>
            </>
          ) : (
            <Button
              text={I18N.CHANGE_PASSWORD}
              onClick={switchToEditPasswordMode}
            />
          )}
        </div>
        <div className={userDeleteClassName}>
          <Heading type="secondary" text={I18N.DELETE_ACCOUNT} />
          <Button
            text={I18N.DELETE}
            onClick={toggleDeleteAccountModal}
            type="danger-inverse"
          />
        </div>
      </div>
      <Modal
        type="WARNING"
        isVisible={isDeleteAccount}
        title={I18N.DELETE_ACCOUNT}
        onClose={toggleDeleteAccountModal}
        btnAcceptText={I18N.DELETE}
        btnAcceptHandler={acceptDeleteAccount}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={toggleDeleteAccountModal}
      >
        <div className="c-board-actions-panel__delete-user-description">
          {I18N.MESSAGE_TO_DELETE_ACCOUNT}
        </div>
      </Modal>
    </>
  );
};

export default Settings;
