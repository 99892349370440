import { useRecoilState, useSetRecoilState } from 'recoil';
import { matchPath, useNavigate } from 'react-router-dom';
import { Cookies, useCookies } from 'react-cookie';

import { useI18N } from '../../i18n/useI18N';

// Hooks
import { useApi } from '../../hooks/useApi';

// Store
import useProfileSettingsMutations from './useProfileSettingsMutations';
import useBoardMutations from './useBoardMutations';
import useAppStateMutations from './useAppStateMutations';

// Utils
import generateRandomStr from '../../utils/generateRandomStr';

// Constants
import {
  AUTH_FORM_TYPE,
  AUTH_STATUS,
  PAGE,
} from '../../config/config';

import {
  activeBoardState,
  userProfileState,
  sessionState,
  boardsState,
  toastersState,
} from '../atoms';

const useUserProfileMutations = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['jwt-token', 'i18next', 'theme', 'anonymousUserCreated']);
  const [fetch] = useApi();
  const I18N = useI18N();
  const navigate = useNavigate();
  const cookObj = new Cookies();

  const { processProfileSettings } = useProfileSettingsMutations();
  const { createBoardFromTemplateMutation, getBoardsByUserId } = useBoardMutations();
  const { setAppReadyState } = useAppStateMutations();

  const setUserProfile = useSetRecoilState(userProfileState);
  const setBoards = useSetRecoilState(boardsState);
  const [activeBoard, setActiveBoard] = useRecoilState(activeBoardState);
  const [session, setSession] = useRecoilState(sessionState);
  const [toasterList, setToasterList] = useRecoilState(toastersState);

  let mediatype = '';

  const setJwtToken = (token) => {
    const options = { expires: new Date(Date.now() + 86400e3 * 90), path: '/' };
    setCookie('jwt-token', token, options);
  };

  const moveFromRestorePwd = (userId, options) => {
    window.history.replaceState({ usr: { restoredPwd: true } }, '', `/user/${userId}`);

    if (options?.isTempUser) {
      navigate(PAGE.HOME.PATH);
      return;
    }

    navigate(`/user/${userId}`, { state: { restoredPwd: true } });
  };

  const updateUserProfile = async (id, data) => {
    try {
      const response = await fetch.patchUserById(id, data);
      setUserProfile(response.data);
      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'success',
          message: I18N.SAVED,
        },
      ]);
    } catch (error) {
      setSession({
        ...session,
        errorChangePwd: I18N.MESSAGE_TO_ERROR_CHANGE_PASSWORD,
        hasError: true,
      });
      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'error',
          message: I18N.MESSAGE_TO_ERROR_CHANGE_PASSWORD,
        },
      ]);
    }
  };

  const updateUserPwd = async (data) => {
    try {
      const response = await fetch.patchRestorePwd(data);
      if (response?.data) {
        setUserProfile(response.data);
        setJwtToken(session.jwt);
        setSession({
          ...session,
          restorePwd: false,
        });

        moveFromRestorePwd(response.data.id);

        setToasterList([
          ...toasterList,
          {
            id: generateRandomStr(),
            type: 'success',
            message: I18N.SAVED,
          },
        ]);
      }
    } catch (error) {
      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'error',
          message: I18N.MESSAGE_TO_ERROR,
        },
      ]);
    }
  };

  const getUserProfileById = async (userId) => {
    const response = await fetch.getUserById(userId);
    setSession({
      ...session,
      foreignUserProfile: response.data,
    });
  };

  const processUserProfile = async (profile) => {
    setUserProfile(profile);
    setSession({
      ...session,
      jwt: profile.access_token,
      hasError: null,
      authFormType: null,
      restorePwd: profile?.restorePwd || false,
      authStatus: AUTH_STATUS.SUCCESS,
    });
    await getBoardsByUserId(profile.id, profile.access_token);
    processProfileSettings(profile.settings);
    setAppReadyState();

    const isRecoveryPage = matchPath({ path: PAGE.RESTORE_USER.PATH }, window.location.pathname);

    if (window.location.pathname === '/auth') {
      navigate(PAGE.HOME.PATH);
    }

    if (isRecoveryPage && !(profile?.restorePwd)) {
      moveFromRestorePwd(profile.id, { isTempUser: profile?.isTemp, test: true });
    }
  };

  const registerUser = async (userData) => {
    try {
      const settings = { lang: cookies.i18next, theme: cookies.theme, order: null };
      const payload = { ...userData, settings };
      const response = await fetch.register(payload);
      setJwtToken(response.data.access_token);
      processUserProfile(response.data);
      navigate(PAGE.HOME.PATH);
    } catch (error) {
      setSession({
        ...session,
        hasError: true,
        errorRegister: error.response.data.error,
      });
    }
  };

  const loginUserWithGoogle = () => {
    try {
      fetch.loginWithGoogle();
    } catch (error) {
      setSession({
        ...session,
        hasError: true,
        errorRegister: error.response.data.error,
      });
    }
  };

  const loginUserWithFacebook = () => {
    try {
      fetch.loginWithFacebook();
    } catch (error) {
      setSession({
        ...session,
        hasError: true,
        errorRegister: error.response.data.error,
      });
    }
  };

  const registerAnonymousUser = async () => {
    if (cookies.anonymousUserCreated) {
      setAppReadyState();
      return;
    }

    const settings = { lang: cookies.i18next || 'en', theme: cookies.theme || 'light', order: null };
    const userTempData = {
      isTemp: true,
      settings,
    };
    try {
      const response = await fetch.register(userTempData);
      setUserProfile(response.data);
      setSession({
        ...session,
        jwt: response.data.access_token,
        hasError: null,
        authFormType: null,
        authStatus: AUTH_STATUS.SUCCESS,
      });
      setJwtToken(response.data.access_token);
      setCookie('anonymousUserCreated', 1, { path: '/', maxAge: 365 * 24 * 60 * 60 });
      const board = await createBoardFromTemplateMutation(response.data.access_token);
      setAppReadyState();
      navigate(`/board/${board.id}`);
    } catch (error) {
      setSession({
        ...session,
        hasError: true,
        errorRegister: error.response.data.error,
        authStatus: AUTH_STATUS.FAILED,
      });
    }
  };

  const loginUser = async (userData) => {
    try {
      const response = await fetch.login({ username: userData.email, password: userData.password });
      if (userData.remember) { // TODO: change remember to normal name
        setJwtToken(response.data.access_token);
      }
      await processUserProfile(response.data);
      navigate(PAGE.HOME.PATH);
    } catch (err) {
      setSession({
        ...session,
        hasError: true,
        errorLogin: I18N.NO_VALID_PASSWORD_OR_EMAIL,
        authStatus: AUTH_STATUS.FAILED,
      });
    }
  };

  const tempLoginUser = async (credentials) => {
    try {
      const response = await fetch.tempLogin({ kgb: credentials.email, jtc: credentials.tempPwd });
      await processUserProfile(response.data);

      return true;
    } catch (error) {
      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'error',
          message: error.response.data.error,
        },
      ]);

      if (!credentials.isAvailableToken) {
        navigate({
          pathname: `/${PAGE.AUTH.PATH}`,
          search: `?type=${AUTH_FORM_TYPE.REGISTER}`,
        });
      }

      return false;
    }
  };

  const getProfileByTokenMutation = async (token) => {
    try {
      const response = await fetch.getProfile(token);
      await processUserProfile({ ...response.data, access_token: token });
    } catch (error) {
      navigate(`/${PAGE.AUTH.PATH}`);
      setSession({ ...session, authFormType: AUTH_FORM_TYPE.LOGIN });
    }
  };

  const authUser = async (email, tempPwd) => {
    let isLoadedTempProfile = false;
    const token = cookObj.get('jwt-token');

    if (email && tempPwd) {
      isLoadedTempProfile = await tempLoginUser({ email, tempPwd, isAvailableToken: token });
    }

    if (!isLoadedTempProfile && token) {
      getProfileByTokenMutation(token);
    }

    if (!isLoadedTempProfile && !token) {
      registerAnonymousUser();
    }
  };

  const logoutUser = () => {
    setUserProfile(null);
    setBoards([]);
    removeCookie('jwt-token', { path: '/' });
    setSession({
      jwt: '',
      restorePwd: false,
      authFormType: AUTH_FORM_TYPE.LOGIN,
      authStatus: AUTH_STATUS.NOT_AUTH,
      errorLogin: null,
      errorRegister: null,
      errorChangePwd: null,
      hasError: null,
      foreignUserProfile: null,
    });

    if (activeBoard) {
      setActiveBoard(null);
    }

    navigate(`/${PAGE.AUTH.PATH}`);
  };

  const restoreUserPwd = async (email) => {
    try {
      await fetch.restoreUser({ email });
      setSession({
        ...session,
        hasError: null,
        restorePwd: true,
      });
    } catch (error) {
      setSession({
        ...session,
        hasError: true,
        restorePwd: error.response.data.error,
      });
    }
  };

  const removeUserAccount = async (id) => {
    try {
      await fetch.deleteUserAccount(id);
      logoutUser();
    } catch (error) {
      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'error',
          message: I18N.MESSAGE_TO_ERROR_PAGE,
        },
      ]);
    }
  };

  const uploadUserAvatar = async (file) => {
    mediatype = 'user-avatar';
    try {
      await fetch.sendAvatar(file, mediatype);
    } catch (error) {
      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'error',
          message: I18N.MESSAGE_TO_ERROR,
        },
      ]);
    }
    return true;
  };

  const deleteUserAvatar = async (fileUrl) => {
    mediatype = 'user-avatar';
    try {
      await fetch.deleteAvatar(fileUrl, mediatype);
    } catch (error) {
      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'error',
          message: I18N.MESSAGE_TO_ERROR,
        },
      ]);
    }
    return true;
  };

  return {
    authUser,
    deleteUserAvatar,
    getUserProfileById,
    tempLoginUser,
    loginUser,
    loginUserWithFacebook,
    loginUserWithGoogle,
    logoutUser,
    registerUser,
    restoreUserPwd,
    updateUserProfile,
    updateUserPwd,
    uploadUserAvatar,
    removeUserAccount,
  };
};

export default useUserProfileMutations;
