import React from 'react';
import Icon from '../Icon';

const IconRemoveUser = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-remove-user">
      <svg viewBox="0 0 678 543" xmlns="http://www.w3.org/2000/svg">
        <path d="M624.553 254.228L672.856 205.925C679.53 199.251 679.53 188.446 672.856 181.773L648.705 157.621C642.031 150.948 631.227 150.948 624.553 157.621L576.25 205.925L527.947 157.621C521.273 150.948 510.468 150.948 503.795 157.621L479.643 181.773C472.97 188.446 472.97 199.251 479.643 205.925L527.947 254.228L479.643 302.531C472.97 309.205 472.97 320.009 479.643 326.683L503.795 350.834C510.468 357.508 521.273 357.508 527.947 350.834L576.25 302.531L624.553 350.834C631.227 357.508 642.031 357.508 648.705 350.834L672.856 326.683C679.53 320.009 679.53 309.205 672.856 302.531L624.553 254.228ZM237.279 271.176C312.171 271.176 372.868 210.479 372.868 135.588C372.868 60.6969 312.171 0 237.279 0C162.388 0 101.691 60.6969 101.691 135.588C101.691 210.479 162.388 271.176 237.279 271.176ZM332.191 305.073H314.501C290.985 315.878 264.821 322.022 237.279 322.022C209.738 322.022 183.68 315.878 160.058 305.073H142.368C63.7688 305.073 0 368.842 0 447.441V491.507C0 519.578 22.7746 542.353 50.8456 542.353H423.713C451.784 542.353 474.559 519.578 474.559 491.507V447.441C474.559 368.842 410.79 305.073 332.191 305.073Z" />
      </svg>
    </Icon>
  );
};

export default IconRemoveUser;
