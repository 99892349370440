import React from 'react';

// Components
import UnitDateTime from './UnitDateTime';

// Hooks
import useCountdown from '../../hooks/useCountdown';

// Styles
import './Timer.scss';

const Timer = (props) => {
  const {
    targetDate,
    callback,
    showDays,
    showHours,
    showMinutes,
    showSeconds,
    showUnitDateTime,
    showAllUnitDateTime,
    suggestion,
    errorMessage,
    daysFinish,
    hoursFinish,
    minsFinish,
    secondsFinish,
    dangerDays,
    dangerHours,
    dangerMins,
    dangerSeconds,
  } = props;

  const [days, hours, minutes, seconds] = useCountdown(targetDate, callback);

  const isExpiredDataTime = days + hours + minutes + seconds <= 0;

  const timerMessage = errorMessage || suggestion || '';
  const errorMessageClassName = errorMessage ? 'error' : '';
  const messageClassName = `c-timer__message ${errorMessageClassName}`;

  const showUnitDateTimeClassName = showUnitDateTime ? 'show-unit-date-time' : '';
  const hiddenClassName = (isExpiredDataTime && !timerMessage) ? 'hidden' : '';
  const timerClassName = `c-timer ${showUnitDateTimeClassName} ${hiddenClassName}`;

  const shouldShowDays = showDays || showAllUnitDateTime;
  const shouldShowHours = showHours || showAllUnitDateTime;
  const shouldShowMinutes = showMinutes || showAllUnitDateTime;
  const shouldShowSeconds = showSeconds || showAllUnitDateTime;

  return (
    <div className={timerClassName}>
      {!isExpiredDataTime && (
        <>
          {shouldShowDays && (
            <>
              <UnitDateTime
                showType={showUnitDateTimeClassName}
                value={days}
                isSuccessFinish={days <= daysFinish}
                isDanger={days <= dangerDays}
              />
              <span className="c-countdown-timer__delimiter">:</span>
            </>
          )}
          {(shouldShowHours || shouldShowDays) && (
            <>
              <UnitDateTime
                showType={showUnitDateTimeClassName}
                value={hours}
                type="Hours"
                isSuccessFinish={hours <= hoursFinish}
                isDanger={hours <= dangerHours}
              />
              <span className="c-countdown-timer__delimiter">:</span>
            </>
          )}
          {(shouldShowMinutes || shouldShowHours) && (
            <>
              <UnitDateTime
                showType={showUnitDateTimeClassName}
                value={minutes}
                type="Mins"
                isSuccessFinish={minutes <= minsFinish}
                isDanger={minutes <= dangerMins}
              />
              <span className="c-countdown-timer__delimiter">:</span>
            </>
          )}
          {(shouldShowSeconds || shouldShowMinutes) && (
            <UnitDateTime
              showType={showUnitDateTimeClassName}
              value={seconds}
              type="Seconds"
              isSuccessFinish={seconds <= secondsFinish}
              isDanger={seconds <= dangerSeconds}
            />
          )}
        </>
      )}
      {isExpiredDataTime && timerMessage && (
        <span className={messageClassName}>{timerMessage}</span>
      )}
    </div>
  );
};

export default Timer;
