import React, { useState, useEffect } from 'react';

// Component
import Heading from '../Heading';
import {
  IconCopy,
  IconExpand,
} from '../Icons';

// Style
import './TaskHeader.scss';

// Hooks
import { useDebounce } from '../../hooks/useDebounce';

const TaskHeader = (props) => {
  const {
    data,
    updateActiveTaskMutation,
    openTaskPage,
    copyTaskLink,
    isModalMode,
  } = props;

  const isNewTask = !data.id;

  const [title, setTitle] = useState(data.title);

  const debouncedTitle = useDebounce(title, 300);

  const updateTitle = (value) => {
    const updatedTitle = {
      title: value,
      id: data.id,
    };
    updateActiveTaskMutation(updatedTitle);
  };

  const changeInputTitle = (event) => {
    setTitle(event.currentTarget.value);
  };

  useEffect(() => {
    if (debouncedTitle !== data.title) {
      updateTitle(debouncedTitle);
    }
  }, [debouncedTitle]);

  return (
    <div className="c-task-header">
      {!isNewTask && (
        <div className="c-task-header__board-index">
          {`[${data.boardIndex}]`}
          <span title="Copy file path">
            <IconCopy
              size="xs"
              onClick={copyTaskLink}
            />
          </span>
          {isModalMode && (
            <span title="Open in full screen">
              <IconExpand
                size="xs"
                onClick={openTaskPage}
              />
            </span>
          )}
        </div>
      )}
      <Heading
        text={title}
        type="secondary"
        editable
        onChange={changeInputTitle}
      />
    </div>
  );
};

export default TaskHeader;
