import React from 'react';
import Icon from '../Icon';

const IconColumns = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-columns">
      <svg viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5714 0H1.71429C0.7675 0 0 0.7675 0 1.71429V14.2857C0 15.2325 0.7675 16 1.71429 16H16.5714C17.5182 16 18.2857 15.2325 18.2857 14.2857V1.71429C18.2857 0.7675 17.5182 0 16.5714 0ZM8 13.7143H2.28571V4.57143H8V13.7143ZM16 13.7143H10.2857V4.57143H16V13.7143Z" />
      </svg>
    </Icon>
  );
};

export default IconColumns;
