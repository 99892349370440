import { atom } from 'recoil';

const usersSearchResultState = atom({
  key: 'usersSearchResult',
  default: {
    users: [],
    hasError: null,
  },
});

export default usersSearchResultState;
