/* eslint-disable react/no-danger */
import React from 'react';

import DOMPurify from 'dompurify';

// Style
import './TaskCardDescription.scss';

const TaskCardDescription = (props) => {
  const {
    data,
  } = props;

  const {
    description,
  } = data;

  return (
    <div className="c-task-card-description">
      {!!data.description.length && (
        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
        />
      )}
    </div>
  );
};

export default TaskCardDescription;
