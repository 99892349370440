import React, { useState } from 'react';

import { useI18N } from '../../i18n/useI18N';

import { BOARD_PROPERTY } from '../../config/config';

import { IconEdit } from '../Icons';
import TextareaControl from '../TextareaControl';
import Modal from '../Modal';

import './BoardInfo.scss';

const BoardInfo = (props) => {
  const {
    isChangeInfoMode,
    setIsChangeInfoMode,
    updateBoard,
    boardData,
    setBoardData,
  } = props;

  const [tmpBoardDesc, setTmpBoardDesc] = useState(boardData.description || '');

  const I18N = useI18N();

  const openChangeInfoModeModal = () => {
    setTmpBoardDesc(boardData.description || '');
    setIsChangeInfoMode(true);
  };

  const closeChangeInfoModeModal = () => {
    setTmpBoardDesc(boardData.description || '');
    setIsChangeInfoMode(false);
  };

  const acceptChangeInfoMode = () => {
    setBoardData({ ...boardData, description: tmpBoardDesc });
    closeChangeInfoModeModal();
    updateBoard({ type: BOARD_PROPERTY.INFO, value: tmpBoardDesc || null });
  };

  const cancelChangeInfoMode = () => {
    closeChangeInfoModeModal();
  };

  const changeBoardInfo = (event) => {
    setTmpBoardDesc(event.currentTarget.value);
  };

  return (
    <>
      <div className="c-board-actions-panel__info">
        <div className="c-board-actions-panel__info-header action-title">
          <span className="c-board-actions-panel__info-title action-subtitle">
            {I18N.BOARD_INFO}
          </span>
          <IconEdit
            size="xs"
            onClick={openChangeInfoModeModal}
          />
        </div>
        <span className="c-board-actions-panel__info-description">
          {boardData.description || `${I18N.BOARD_INFO}...`}
        </span>
      </div>
      <Modal
        isVisible={isChangeInfoMode}
        title={I18N.BOARD_INFO}
        onClose={closeChangeInfoModeModal}
        btnAcceptText={I18N.SAVE}
        btnAcceptHandler={acceptChangeInfoMode}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={cancelChangeInfoMode}
      >
        <TextareaControl
          name="description"
          type="text"
          onChange={changeBoardInfo}
          placeholder={`${I18N.INFO}...`}
          value={tmpBoardDesc}
        />
      </Modal>
    </>
  );
};

export default BoardInfo;
