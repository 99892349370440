import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// Component
import TagList from '../TagList';
import TaskActions from '../TaskActions';
import TaskAssignedUser from '../TaskAssignedUser';
import TaskAuthor from '../TaskAuthor';
import TaskBoardSelect from '../TaskBoardSelect';
import TaskChangeStatus from '../TaskChangeStatus';
import TaskColorMark from '../TaskColorMark';
import TaskDate from '../TaskDate';
import TaskLoggedTime from '../TaskLoggedTime';
import TextEditor from '../TextEditor';
import TaskEstimationTime from '../TaskEstimationTime';
import TaskHeader from '../TaskHeader';
import TaskPriority from '../TaskPriority';
import ToDoList from '../ToDoList';
import ColorMarkBar from '../ColorMarkBar';
import {
  IconChevronDown,
  IconChevronUp,
  IconArrowLeft,
} from '../Icons';
import Paragraph from '../Paragraph';

// Style
import './TaskMobile.scss';

// Store
import useTaskMutations from '../../store/mutations/useTaskMutations';
import { boardsState } from '../../store/atoms';

// Hooks
import { useToaster } from '../../hooks/useToast';
import { useI18N } from '../../i18n/useI18N';

// Configs
import { POSITION } from '../../config/config';

// Utils
import orderSort from '../../utils/orderSort';

const TaskMobile = (props) => {
  const {
    data,
    boardTeam,
    boardStatuses,
    setActiveTask,
    isMobile,
  } = props;

  const {
    changeBoardTaskMutation,
    changeStatusTaskMutation,
    deleteTaskMutation,
    saveNewTaskMutation,
    updateActiveTaskMutation,
    createTimeLogMutation,
    updateTimeLogMutation,
    deleteTimeLogMutation,
    createToDoMutation,
    updateToDoMutation,
    deleteToDoMutation,
  } = useTaskMutations();

  const isNewTask = !data.id;

  const boards = useRecoilValue(boardsState);

  const I18N = useI18N();

  const navigate = useNavigate();

  const [showSectionDetails, setShowSectionDetails] = useState(false);
  const [showSectionLoggedTime, setShowSectionLoggedTime] = useState(false);
  const [showSectionCheckList, setShowSectionCheckList] = useState(false);
  const [showSectionTagList, setShowSectionTagList] = useState(false);

  const { showToastSuccess, showToastError } = useToaster();

  const copyTaskLink = async () => {
    const taskLinkFull = window.location.href;
    const taskLink = taskLinkFull.split('?')[0];
    try {
      await navigator.clipboard.writeText(`${taskLink}`);
      showToastSuccess('Link copied!');
    } catch (error) {
      showToastError('Failed to copy URL to clipboard.');
    }
  };

  const handleCloseTask = () => {
    setActiveTask(null);
    navigate(`/board/${data.board.id}`);
  };

  const openSectionDetails = () => {
    setShowSectionDetails(true);
  };

  const closeSectionDetails = () => {
    setShowSectionDetails(false);
  };

  const openSectionLoggedTimeMobile = () => {
    setShowSectionLoggedTime(true);
  };

  const closeSectionLoggedTimeMobile = () => {
    setShowSectionLoggedTime(false);
  };

  const openSectionCheckListMobile = () => {
    setShowSectionCheckList(true);
  };

  const closeSectionCheckListMobile = () => {
    setShowSectionCheckList(false);
  };

  const openSectionTagListMobile = () => {
    setShowSectionTagList(true);
  };

  const closeSectionTagListMobile = () => {
    setShowSectionTagList(false);
  };

  return (
    <div className="c-task-mobile">
      <div className="c-task-mobile__back-page" onClick={handleCloseTask}>
        <IconArrowLeft />
        <Paragraph>
          {I18N.BACK_TO}
          {' '}
          <span className="c-task-mobile__back-page__board-name">{data.board.name}</span>
        </Paragraph>
      </div>
      <div className="c-task-mobile__header">
        <ColorMarkBar
          position={POSITION.LEFT}
          color={data.colorMark}
        />
        <TaskHeader
          data={data}
          updateActiveTaskMutation={updateActiveTaskMutation}
          copyTaskLink={copyTaskLink}
        />
      </div>
      <ul className="c-task-mobile__status-list">
        {orderSort(boardStatuses).map((taskStatus) => (
          <li
            key={taskStatus.id}
            className={taskStatus.id === data.statusId ? 'c-task-mobile__status-list__status active' : 'c-task-mobile__status-list__status'}
          >
            {taskStatus.name}
          </li>
        ))}
      </ul>
      <TextEditor
        data={data}
        updateActiveTaskMutation={updateActiveTaskMutation}
        isMobile={isMobile}
      />
      <div className="c-task-mobile__section">
        {!showSectionDetails ? (
          <div className="c-task-mobile__section__header">
            <span>{I18N.DETAILS}</span>
            <IconChevronDown
              size="xs"
              onClick={openSectionDetails}
            />
          </div>
        ) : (
          <>
            <div className="c-task-mobile__section__header open">
              <span>{I18N.DETAILS}</span>
              <IconChevronUp
                size="xs"
                onClick={closeSectionDetails}
              />
            </div>
            <TaskAssignedUser
              data={data}
              boardTeam={boardTeam}
              updateActiveTaskMutation={updateActiveTaskMutation}
              isMobile={isMobile}
            />
            <TaskAuthor
              data={data}
              boardTeam={boardTeam}
            />
            <TaskEstimationTime
              data={data}
              updateActiveTaskMutation={updateActiveTaskMutation}
              isMobile={isMobile}
            />
            <TaskDate
              data={data}
              updateActiveTaskMutation={updateActiveTaskMutation}
              isMobile={isMobile}
            />
            <TaskPriority
              data={data}
              updateActiveTaskMutation={updateActiveTaskMutation}
            />
            <TaskColorMark
              data={data}
              updateActiveTaskMutation={updateActiveTaskMutation}
            />
            {!isNewTask && (
              <>
                <TaskChangeStatus
                  data={data}
                  boardStatuses={boardStatuses}
                  updateActiveTaskMutation={changeStatusTaskMutation}
                  isMobile={isMobile}
                />
                <TaskBoardSelect
                  data={data}
                  changeBoardTaskMutation={changeBoardTaskMutation}
                  isMobile={isMobile}
                  isTaskPage
                  boards={boards}
                />
              </>
            )}
          </>
        )}
      </div>
      <div className="c-task-mobile__section">
        {!showSectionLoggedTime ? (
          <div className="c-task-mobile__section__header">
            <span>{I18N.TIME}</span>
            <IconChevronDown
              size="xs"
              onClick={openSectionLoggedTimeMobile}
            />
          </div>
        ) : (
          !isNewTask && (
            <TaskLoggedTime
              data={data}
              createTimeLogMutation={createTimeLogMutation}
              updateTimeLogMutation={updateTimeLogMutation}
              deleteTimeLogMutation={deleteTimeLogMutation}
              boardTeam={boardTeam}
              isMobile={isMobile}
              closeSectionLoggedTimeMobile={closeSectionLoggedTimeMobile}
            />
          )
        )}
      </div>
      <div className="c-task-mobile__section">
        {!showSectionCheckList ? (
          <div className="c-task-mobile__section__header">
            <span>{I18N.CHECKLIST}</span>
            <IconChevronDown
              size="xs"
              onClick={openSectionCheckListMobile}
            />
          </div>
        ) : (
          !isNewTask && (
            <ToDoList
              data={data}
              createToDoMutation={createToDoMutation}
              updateToDoMutation={updateToDoMutation}
              deleteToDoMutation={deleteToDoMutation}
              isMobile={isMobile}
              closeSectionCheckListMobile={closeSectionCheckListMobile}
            />
          )
        )}
      </div>
      <div className="c-task-mobile__section">
        {!showSectionTagList ? (
          <div className="c-task-mobile__section__header">
            <span>{I18N.TAGS}</span>
            <IconChevronDown
              size="xs"
              onClick={openSectionTagListMobile}
            />
          </div>
        ) : (
          <TagList
            updateActiveTaskMutation={updateActiveTaskMutation}
            data={data}
            isMobile={isMobile}
            closeSectionTagListMobile={closeSectionTagListMobile}
          />
        )}
      </div>
      <TaskActions
        data={data}
        deleteTaskMutation={deleteTaskMutation}
        saveNewTaskMutation={saveNewTaskMutation}
        handleCloseTask={handleCloseTask}
        isNewTask={isNewTask}
        isMobile={isMobile}
      />
    </div>
  );
};

export default TaskMobile;
