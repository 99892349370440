import React from 'react';

// Styles
import './Timer.scss';

const UnitDateTime = (props) => {
  const {
    value,
    type,
    isDanger,
    showType,
    isSuccessFinish,
  } = props;

  const dangerClassName = isDanger ? 'danger' : '';
  const successFinishClassName = isSuccessFinish ? 'success-finish' : '';
  const unitDateTimeClassName = `c-unit-date-time ${dangerClassName} ${successFinishClassName}`;

  return (
    <div className={unitDateTimeClassName}>
      <span className="c-unit-date-time__value">{value}</span>
      {showType && <span className="c-unit-date-time__type">{type}</span>}
    </div>
  );
};

export default UnitDateTime;
