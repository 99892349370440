import React, { useState } from 'react';

// Component
import Button from '../Button';
import {
  IconPlus,
  IconBin,
  IconSelect,
  IconChange,
} from '../Icons';
import Modal from '../Modal';
import UserField from '../UserField';
import InputControl from '../InputControl';

// Style
import './TaskAssignedUser.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const TaskAssignedUser = (props) => {
  const {
    data,
    boardTeam,
    updateActiveTaskMutation,
    isMobile,
  } = props;

  const I18N = useI18N();
  const user = data.assignToUser;
  const [isOpenModalAddUser, setIsOpenModalAddUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(user);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBoardTeam, setFilteredBoardTeam] = useState(boardTeam);
  const [isOpenModalDeleteAssignedUser, setIsOpenModalDeleteAssignedUser] = useState(false);

  const taskAssignedUserClassName = !isMobile ? 'c-task-assigned-user' : 'c-task-assigned-user mobile';

  // Logic ModalAddUser
  const openModalAddUser = () => {
    setIsOpenModalAddUser(true);
  };

  const closeModalAddUser = () => {
    setIsOpenModalAddUser(false);
  };

  const handleChangeInput = (event) => {
    const { value } = event.currentTarget;
    setSearchQuery(value);
    const newBoardTeam = boardTeam.filter((member) => member.email.indexOf(value) >= 0);
    setFilteredBoardTeam(newBoardTeam);
  };

  const resetInput = () => {
    setSearchQuery('');
    setFilteredBoardTeam(boardTeam);
  };

  const selectUser = (memberBoardTeam) => {
    setSelectedUser(memberBoardTeam);
  };

  const updateAssignedUser = () => {
    const userId = user ? user.id : null;
    if (selectedUser !== null && selectedUser.id !== userId) {
      const updatedAssignedToUserId = {
        assignedToUserId: selectedUser.id,
        assignToUser: selectedUser,
        id: data.id,
      };
      updateActiveTaskMutation(updatedAssignedToUserId);
    } else {
      setSelectedUser(user);
    }
  };

  const acceptAddUserHandler = () => {
    updateAssignedUser();
    resetInput();
    closeModalAddUser();
  };

  const cancelAddUserHandler = () => {
    setSelectedUser(user);
    resetInput();
    closeModalAddUser();
  };

  // Logic ModalDeleteAssignedUser
  const openModalDeleteAssignedUser = () => {
    setIsOpenModalDeleteAssignedUser(true);
  };

  const closeModalDeleteAssignedUser = () => {
    setIsOpenModalDeleteAssignedUser(false);
  };

  const deleteAssignedUser = () => {
    const updatedAssignedToUserId = {
      assignedToUserId: null,
      assignToUser: null,
      id: data.id,
    };
    updateActiveTaskMutation(updatedAssignedToUserId);
  };

  const acceptDeleteAssignedUserHandler = () => {
    deleteAssignedUser();
    setSelectedUser(null);
    closeModalDeleteAssignedUser();
  };

  const cancelDeleteAssignedUserHandler = () => {
    closeModalDeleteAssignedUser();
  };

  return (
    <div className={taskAssignedUserClassName}>
      <div className="c-task-assigned-user__header">
        <span className="c-task-assigned-user__header__title">
          {I18N.ASSIGNED_USER}
        </span>
        {!isMobile && (
          <Button
            type="primary-inverse"
            text={!user ? I18N.ADD : I18N.USER}
            iconLeft={!user ? (<IconPlus />) : (<IconChange />)}
            size="xs"
            onClick={openModalAddUser}
          />
        )}
        {(isMobile && (
          <IconChange
            size="sm"
            onClick={openModalAddUser}
          />
        ))}
      </div>
      {user ? (
        <>
          <div className="c-task-assigned-user__user">
            <UserField
              user={user}
              size="sm"
            />
            <IconBin
              size="xs"
              onClick={openModalDeleteAssignedUser}
              role="button"
            />
          </div>
          <Modal
            isVisible={isOpenModalDeleteAssignedUser}
            onClose={closeModalDeleteAssignedUser}
            title={I18N.DELETE_USER}
            content={I18N.PLEASE_CONFIRM_DELETE_ACTION}
            type="WARNING"
            btnAcceptText={I18N.DELETE_USER}
            btnAcceptHandler={acceptDeleteAssignedUserHandler}
            btnCancelHandler={cancelDeleteAssignedUserHandler}
            isMobile={isMobile}
          />
        </>
      ) : (
        <p className="c-task-assigned-user__empty-state">
          {I18N.NO_ASSIGNED_USER}
        </p>
      )}
      <Modal
        isVisible={isOpenModalAddUser}
        onClose={cancelAddUserHandler}
        title={I18N.ASSIGNED_USER}
        btnAcceptText={I18N.ADD_USER}
        btnAcceptHandler={acceptAddUserHandler}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={cancelAddUserHandler}
        className="modal-assigned-user"
        isMobile={isMobile}
      >
        <InputControl
          label={I18N.SEARCH_USER}
          type="text"
          name="email"
          placeholder={I18N.EMAIL}
          value={searchQuery}
          onChange={handleChangeInput}
          onReset={resetInput}
        />
        {filteredBoardTeam.length > 0
          ? (
            <div className="c-task-assigned-user__board-team">
              <h4 className="c-task-assigned-user__board-team__title">
                {I18N.BOARD_TEAM}
              </h4>
              <ul className="c-task-assigned-user__board-team__list">
                {filteredBoardTeam.map((memberBoardTeam) => (
                  <li
                    className={`c-task-assigned-user__board-team__list__member ${selectedUser?.id === memberBoardTeam.id ? 'selected' : ''}`}
                    key={memberBoardTeam.id}
                    onClick={() => selectUser(memberBoardTeam)}
                  >
                    <UserField
                      user={memberBoardTeam}
                      size="sm"
                      showEmail
                    />
                    {selectedUser?.id === memberBoardTeam.id
                      && (
                        <IconSelect
                          size="xs"
                        />
                      )}
                  </li>
                ))}
              </ul>
            </div>
          )
          : (
            <div className="c-task-assigned-user__board-team">
              <h4 className="c-task-assigned-user__board-team__title">
                {I18N.BOARD_TEAM}
              </h4>
              <div className="c-task-assigned-user__board-team__message">
                {I18N.USER_NOT_FOUND}
              </div>
            </div>
          )}
      </Modal>
    </div>
  );
};

export default TaskAssignedUser;
