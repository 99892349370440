import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import pack from '../package.json';

import router from './router';

import './i18n/i18n';

import reportWebVitals from './reportWebVitals';

import './assets/styles/index.scss';
import DebugObserver from './utils/debug';

// eslint-disable-next-line no-console
console.info(`Arella app version ${pack.version}`);

const container = document.getElementById('root');
const root = createRoot(container);
const isDevelop = process.env.NODE_ENV !== 'production';
root.render(
  <RecoilRoot>
    {isDevelop && <DebugObserver />}
    <RouterProvider router={router} />
  </RecoilRoot>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
