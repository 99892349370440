import React from 'react';
import Icon from '../Icon';

const IconUserShield = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-user-shield">
      <svg viewBox="0 0 20 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.4469 8.47188L15.8469 7.06563C15.7188 7.01562 15.4531 6.95 15.1531 7.06563L11.5531 8.47188C11.2188 8.60313 11 8.90938 11 9.25C11 12.7375 13.1469 15.15 15.1531 15.9344C15.4531 16.05 15.7156 15.9844 15.8469 15.9344C17.45 15.3094 20 13.1406 20 9.25C20 8.90938 19.7812 8.60313 19.4469 8.47188ZM15.5 14.45V8.54062L18.4844 9.70625C18.3094 12.4281 16.5812 13.9375 15.5 14.45ZM7 8C9.20938 8 11 6.20937 11 4C11 1.79063 9.20938 0 7 0C4.79063 0 3 1.79063 3 4C3 6.20937 4.79063 8 7 8ZM10 9.25C10 9.17188 10.025 9.1 10.0344 9.025C9.95625 9.02187 9.88125 9 9.8 9H9.27812C8.58437 9.31875 7.8125 9.5 7 9.5C6.1875 9.5 5.41875 9.31875 4.72188 9H4.2C1.88125 9 0 10.8813 0 13.2V14.5C0 15.3281 0.671875 16 1.5 16H12.5C12.7125 16 12.9156 15.9531 13.1 15.875C11.4125 14.5344 10 12.2281 10 9.25Z" />
      </svg>
    </Icon>
  );
};

export default IconUserShield;
