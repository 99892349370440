import React from 'react';

// Components
import { IconCrossRound, IconBlockedRound } from '../Icons';

// Style
import './TextareaControl.scss';

const TextareaControl = (props) => {
  const {
    label,
    name,
    value,
    onChange,
    placeholder,
    disabled,
    errorMessage,
    onReset,
    suggestion,
    isValid,
    required,
    onBlur,
    onFocus,
    onKeyDown,
    hidden,
  } = props;

  // Component Logic
  const hiddenClassName = hidden ? 'hidden' : '';
  const labelClassName = label ? 'label' : '';
  const validClassName = isValid ? 'valid' : '';
  const errorClassName = errorMessage ? 'error' : '';
  const disabledClassName = disabled ? 'disabled' : '';
  const requiredClassName = required ? 'c-textarea_required' : '';
  const bottomText = errorMessage || suggestion || '';
  const showResetIcon = typeof onReset === 'function' && value.length > 0 && !disabled;
  const blockTextareaClassName = `c-textarea ${requiredClassName} ${disabledClassName} ${validClassName} ${errorClassName} ${labelClassName} ${hiddenClassName}`;

  const handleChange = (event) => (typeof onChange === 'function' ? onChange(event) : null);

  const handleFocus = (event) => (typeof onFocus === 'function' ? onFocus(event) : null);

  const handleBlur = (event) => (typeof onBlur === 'function' ? onBlur(event) : null);

  const handleKeyDown = (event) => (typeof onKeyDown === 'function' ? onKeyDown(event) : null);

  return (
    <label className={blockTextareaClassName}>
      {label && <p className="c-textarea__label-text">{label}</p>}
      <textarea
        className="c-textarea__textarea"
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
      />
      {disabled && (
        <IconBlockedRound
          size="sm"
        />
      )}
      {showResetIcon && (
        <IconCrossRound
          size="sm"
          onClick={onReset}
        />
      )}
      <span className="c-input__bottom-text">
        {bottomText}
      </span>
    </label>
  );
};

export default TextareaControl;
