/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useEffect,
  useState,
  Suspense,
  lazy,
} from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilValue, useRecoilState } from 'recoil';
import {
  Outlet,
  useParams,
  useNavigate,
  useSearchParams,
  matchPath,
} from 'react-router-dom';

// Components
import BreadCrumb from '../BreadCrumb';
import FooterLayout from '../FooterLayout';
import HeaderLayout from '../HeaderLayout';
import Toaster from '../Toaster';
import Loader from '../Loader';
import CookieBanner from '../CookieBanner';

// Context
import LayoutContext from '../../context/LayoutContext';

// Configs
import {
  AUTH_FORM_TYPE,
  PAGE,
  AUTH_STATUS,
} from '../../config/config';

// Store
import { userProfileState, sessionState } from '../../store/atoms';
import useUserProfileMutations from '../../store/mutations/useUserProfileMutations';
import useProfileSettingsMutations from '../../store/mutations/useProfileSettingsMutations';
import isAppReadyState from '../../store/selector/isAppReady';

// Styles
import './MainLayout.scss';

const MainLayout = () => {
  const SideBarLayout = lazy(() => import('../SideBarLayout'));

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const { token } = Object.fromEntries(searchParams.entries());

  const [session, setSession] = useRecoilState(sessionState);
  const userProfile = useRecoilValue(userProfileState);
  const isAppReady = useRecoilValue(isAppReadyState);
  const [cookies, setCookie] = useCookies(['cookieConsent', 'onboardingApp', 'jwt-token', 'recent-page']);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [showBanner, setShowBanner] = useState(!cookies.cookieConsent);

  // Modules
  const { authUser, logoutUser } = useUserProfileMutations();
  const { setDefaultSettings } = useProfileSettingsMutations();

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = { isMobile }; // TODO check for extra rerender

  const showSideBar = false;

  const passwordRecovery = useParams();

  const setJwtToken = (jwtToken) => {
    const options = { expires: new Date(Date.now() + 86400e3 * 90), path: '/' };
    setCookie('jwt-token', jwtToken, options);
    setSearchParams(searchParams.delete('token'));
  };

  const handleResize = () => setIsMobile(window.innerWidth < 768);

  const handleAcceptCookies = () => {
    setCookie('cookieConsent', true, { path: '/', maxAge: 365 * 24 * 60 * 60 });
    setShowBanner(false);
  };

  const signOutUser = () => {
    logoutUser();
  };

  const handleLogin = () => {
    navigate(`/${PAGE.AUTH.PATH}`);
  };

  const updateRecentPage = () => {
    const pathWithQuery = window.location.pathname + window.location.search;
    setCookie('recent-page', pathWithQuery, { path: '/' });
  };

  const handleRegister = () => {
    setSession({
      ...session,
      authStatus: AUTH_STATUS.NOT_AUTH,
    });
    if (!cookies.onboardingApp) {
      setCookie('onboardingApp', true, { path: '/', maxAge: 365 * 24 * 60 * 60 });
    }
    navigate({
      pathname: `/${PAGE.AUTH.PATH}`,
      search: `?type=${AUTH_FORM_TYPE.REGISTER}`,
    });
  };

  const recentPage = cookies['recent-page'];

  const shouldRecoveryRecentPage = () => {
    if (!recentPage) return false;

    if (matchPath({ path: PAGE.HOME.PATH }, recentPage)) {
      return true;
    }

    return matchPath({ path: PAGE.RESTORE_USER.PATH }, recentPage);
  };

  const processNavigation = () => {
    if (PAGE.HOME.PATH === window.location.pathname) {
      shouldRecoveryRecentPage();
      navigate(recentPage);
    }

    if (token) {
      setJwtToken(token);
    }

    if (!userProfile) {
      authUser(passwordRecovery.email, passwordRecovery.tempPwd);
    }
  };

  useEffect(() => {
    setDefaultSettings();

    processNavigation();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    updateRecentPage();
  }, [navigate]);

  // Render function
  return (
    <div className="c-main-layout">
      <Toaster />
      <LayoutContext.Provider value={contextValue}>
        <HeaderLayout
          user={userProfile}
          handleLogout={signOutUser}
          handleLogin={handleLogin}
          handleRegister={handleRegister}
        />

        <main className="app-content">
          {isAppReady && (
            <>
              {showBanner && (
                <Suspense fallback={<></>}>
                  <CookieBanner handleCookies={handleAcceptCookies} />
                </Suspense>
              )}
              {showSideBar && <SideBarLayout />}
              {!isMobile && <BreadCrumb />}
              <Outlet />
            </>
          )}
          {!isAppReady && (
            <div className="v-loader">
              <Loader />
            </div>
          )}
        </main>
        <FooterLayout />
        <div id="portal" />
      </LayoutContext.Provider>
    </div>
  );
};

export default MainLayout;
