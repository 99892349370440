import React from 'react';

import './Button.scss';

const Button = (props) => {
  const {
    style,
    size,
    type,
    text,
    submit,
    onClick,
    disabled,
    iconLeft,
    iconRight,
    id,
  } = props;
  const classSize = size ? `c-button_${size}` : 'c-button_md';
  const classType = type || 'primary';

  const handleClick = (event) => {
    if (typeof onClick === 'function') {
      return onClick(event);
    }
    return null;
  };

  return (
    <button
      style={style}
      className={`c-button ${classSize} ${classType}`}
      onClick={handleClick}
      disabled={disabled}
      type={submit ? 'submit' : 'button'}
      id={id}
    >
      {iconLeft && <span className="c-button__icon">{iconLeft}</span>}
      <span>{text}</span>
      {iconRight && <span className="c-button__icon">{iconRight}</span>}
    </button>
  );
};

export default Button;
