import React from 'react';
import Icon from '../Icon';

const IconEnvelope = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-envelope">
      <svg
        viewBox="0 0 20 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.578888 3.5257C1.62325 4.34492 2.27712 4.85784 7.13231 8.3815C7.24267 8.46197 7.37126 8.56143 7.51315 8.67117C8.21626 9.215 9.24576 10.0113 10 9.99994C10.7536 10.0117 11.7842 9.21464 12.4871 8.671C12.629 8.56129 12.7575 8.4619 12.8677 8.38156C17.7234 4.85751 18.3761 4.34551 19.4212 3.52562C19.596 3.38851 19.7818 3.24279 20 3.07294V1.5C20 0.671562 19.3284 0 18.5 0H1.5C0.671561 0 0 0.671562 0 1.5V3.07294C0.218269 3.24282 0.404051 3.38855 0.578888 3.5257Z" />
        <path d="M20 14.5V5.61094C19.1276 6.2855 17.9172 7.18806 14.0448 9.99837C13.9218 10.0881 13.7853 10.1936 13.6367 10.3084C12.752 10.9922 11.4386 12.0071 9.99981 11.9999C8.56817 12.0072 7.27002 11.0066 6.37097 10.3137C6.22133 10.1984 6.08274 10.0915 5.95644 9.99931C2.08306 7.18819 0.872375 6.28556 0 5.61094V14.5C0 15.3284 0.671561 16 1.5 16H18.5C19.3284 16 20 15.3284 20 14.5Z" />
      </svg>
    </Icon>
  );
};

export default IconEnvelope;
