import { useEffect, useRef } from 'react';

const useBlockClick = (conditionValue, nameAreaBlock, callback) => {
  const ref = useRef();

  useEffect(() => {
    function handleBLockClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }

    if (conditionValue) {
      document.querySelector(`.${nameAreaBlock}`).addEventListener('click', handleBLockClick);
    }

    return () => {
      document.querySelector(`.${nameAreaBlock}`).removeEventListener('click', handleBLockClick);
    };
  }, [conditionValue]);

  return (ref);
};

export default useBlockClick;
