import React from 'react';
import { useRecoilValue } from 'recoil';
import { userProfileState } from '../../store/atoms';

// Components
import BoardPanelList from '../../components/BoardPanelList';
import Loader from '../../components/Loader';

// Hooks
// import useInitHomeView from '../../hooks/useInitHomeView';

// Style
import './HomeView.scss';

const HomeView = () => {
  const userProfile = useRecoilValue(userProfileState);

  // const initHomeView = useInitHomeView();

  // eslint-disable-next-line no-console
  // console.log('initHomeView', initHomeView);

  // useEffect(() => {
  //   initHomeView();
  // }, []);

  return (
    <div className="v-app">
      {userProfile ? <BoardPanelList /> : <Loader />}
    </div>
  );
};

export default HomeView;
