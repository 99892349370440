import React from 'react';

// Style
import './TaskCardFooter.scss';

// Components
import UserList from '../UserList';
import PriorityIcon from '../PriorityIcon';
import UserAvatar from '../UserAvatar';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Utils
import { convertMinuteToEstimationString } from '../../utils/timeUtils';

const TaskCardFooter = (props) => {
  const {
    data,
    user,
  } = props;

  const {
    estimation,
    timeLogs,
    startDate,
    endDate,
  } = data;

  const I18N = useI18N();

  const getDate = (value) => {
    const currentDate = new Date(value);
    let date = currentDate.getDate();
    if (date < 10) {
      date = `0${date}`;
    }
    let month = currentDate.getMonth() + 1;
    if (month < 10) {
      month = `0${month}`;
    }
    const year = currentDate.getFullYear().toString().slice(-2);
    const formatCurrentDate = `${date}.${month}.${parseInt(year, 10)}`;
    return formatCurrentDate;
  };

  const timeLogsSorted = [...timeLogs].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA - dateB;
  });

  const totalLogTime = timeLogsSorted.reduce((sum, current) => sum + current.value, 0);

  const totalLogTimeClassName = estimation && totalLogTime > estimation
    ? 'c-task-card-footer__time__log-time high-level'
    : 'c-task-card-footer__time__log-time';

  const endDataClassName = endDate && (new Date().getTime() > new Date(new Date(endDate).getTime() + 86400000).getTime()) ? ' high-level' : '';
  return (
    <div className="c-task-card-footer">
      <div className="c-task-card-footer__user">
        {user ? (
          <UserList
            users={[user]}
            size="xs"
          />
        ) : (
          <UserAvatar
            size="xs"
          />
        )}
      </div>
      <div className="c-task-card-footer__time">
        {estimation && (
          <span
            className="c-task-card-footer__time__estimation"
            title={I18N.ESTIMATION}
          >
            {`E ${convertMinuteToEstimationString(estimation)}`}
          </span>
        )}
        {timeLogs.length > 0 && (
          <span
            className={totalLogTimeClassName}
            title={I18N.LOGGED_TIME}
          >
            {`L ${convertMinuteToEstimationString(totalLogTime)}`}
          </span>
        )}
      </div>
      <div className="c-task-card-footer__date">
        {startDate && (
          <div title={I18N.DATA_START}>
            {getDate(startDate)}
          </div>
        )}
        {endDate && (
          <div title={I18N.DATA_END} className={`c-task-card-footer__date ${endDataClassName}`}>
            {getDate(endDate)}
          </div>
        )}
      </div>
      <span title={I18N.PRIORITY}>
        <PriorityIcon
          priority={data.priority}
        />
      </span>
    </div>
  );
};

export default TaskCardFooter;
