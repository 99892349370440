import React, { useCallback, useState } from 'react';
import { Tooltip } from 'react-tooltip';

// Style
import './TextEditor.scss';
import 'react-tooltip/dist/react-tooltip.css';

// Component
import * as Icons from './Icons';
import ColorPalette from '../ColorPalette';
import Button from '../Button';
import Modal from '../Modal';
import InputControl from '../InputControl';

// Hooks
import useClickOutside from '../../hooks/useClickOutside';
import { useI18N } from '../../i18n/useI18N';

// Configs
import { COLOR_PALETTE_DEFAULT } from '../../config/config';

const IconAlign = ({ align }) => {
  switch (align) {
    case 'justify':
      return <Icons.AlignJustify />;
    case 'center':
      return <Icons.AlignCenter />;
    case 'right':
      return <Icons.AlignRight />;
    default:
      return <Icons.AlignLeft />;
  }
};

const TextEditorToolbar = ({ editor, isMobile }) => {
  const I18N = useI18N();
  const palette = COLOR_PALETTE_DEFAULT;

  const toggleBold = useCallback(() => {
    editor.chain().focus().toggleBold().run();
  }, [editor]);

  const toggleItalic = useCallback(() => {
    editor.chain().focus().toggleItalic().run();
  }, [editor]);

  const toggleUnderline = useCallback(() => {
    editor.chain().focus().toggleUnderline().run();
  }, [editor]);

  const toggleCode = useCallback(() => {
    editor.chain().focus().toggleCode().run();
  }, [editor]);

  const toggleCodeBlock = useCallback(() => {
    editor.chain().focus().toggleCodeBlock().run();
  }, [editor]);

  const toggleStrike = useCallback(() => {
    editor.chain().focus().toggleStrike().run();
  }, [editor]);

  const toggleSubscript = useCallback(() => {
    editor.chain().focus().toggleSubscript().run();
  }, [editor]);

  const toggleSuperscript = useCallback(() => {
    editor.chain().focus().toggleSuperscript().run();
  }, [editor]);

  // Link
  const [isOpenModalAddLink, setIsOpenModalAddLink] = useState(false);
  const [url, setUrl] = useState(null);

  const openModalLink = () => {
    const previousUrl = editor.getAttributes('link').href;
    setUrl(previousUrl);
    setIsOpenModalAddLink(true);
  };

  const closeModalLink = () => {
    setIsOpenModalAddLink(false);
  };

  const setLink = () => {
    if (url === null) {
      return;
    }
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run();
      return;
    }
    editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' })
      .run();
    closeModalLink();
  };

  // Dropdown of Heading
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const headingTeam = [1, 2, 3, 4, 5, 6];
  const wrapperHeadingRef = useClickOutside(() => setIsOpenDropdown(false));

  const selectHeading = ({ level }) => {
    editor.chain().focus().setHeading({ level }).run();
    setIsOpenDropdown(false);
  };

  const selectParagraph = () => {
    editor.chain().focus().setParagraph().run();
    setIsOpenDropdown(false);
  };

  const getCurrentHeading = () => {
    let result = 'Paragraph';
    headingTeam.forEach((heading) => {
      if (editor.isActive('heading', { level: heading })) {
        result = `Heading ${heading}`;
      }
    });
    return result;
  };

  // Dropdown of Text Background Color
  const [isShowTextBackgroundColorPalette, setIsShowTextBackgroundColorPalette] = useState(false);
  const wrapperTextBackgroundColorRef = useClickOutside(
    () => setIsShowTextBackgroundColorPalette(false),
  );

  const setTextBackgroundColor = (color) => {
    editor.chain().focus().toggleHighlight({ color }).run();
    setIsShowTextBackgroundColorPalette(false);
  };

  const unSetTextBackgroundColor = () => {
    editor.chain().focus().unsetHighlight().run();
    setIsShowTextBackgroundColorPalette(false);
  };

  const getCurrentTextBackgroundColor = () => {
    let result = 'none';
    palette.forEach((color) => {
      if (editor.isActive('highlight', { color })) {
        result = { color };
      }
    });
    return result;
  };

  // Dropdown of Text Color
  const [isShowTextColorPalette, setIsShowTextColorPalette] = useState(false);
  const wrapperTextColorRef = useClickOutside(() => setIsShowTextColorPalette(false));

  const setTextColor = (color) => {
    editor.chain().focus().setColor(color).run();
    setIsShowTextColorPalette(false);
  };

  const unSetTextColor = () => {
    editor.chain().focus().unsetColor().run();
    setIsShowTextColorPalette(false);
  };

  const getCurrentTextColor = () => {
    let result = 'none';
    palette.forEach((color) => {
      if (editor.isActive('textStyle', { color })) {
        result = { color };
      }
    });
    return result;
  };

  // Dropdown of Text Align
  const [isOpenAlignDropdown, setIsOpenAlignDropdown] = useState(false);
  const alignTeam = ['left', 'center', 'right', 'justify'];
  const wrapperAlignRef = useClickOutside(() => setIsOpenAlignDropdown(false));

  const selectAlign = (align) => {
    editor.chain().focus().setTextAlign(align).run();
    setIsOpenAlignDropdown(false);
  };

  const getCurrentAlign = () => {
    let result = <IconAlign align="left" />;
    alignTeam.forEach((align) => {
      if (editor.isActive({ textAlign: align })) {
        result = <IconAlign align={align} />;
      }
    });
    return result;
  };

  return (
    <div className="c-text-editor__toolbar">
      <Tooltip id="text-editor-tooltip" />
      <div className="c-text-editor__toolbar__block">
        <div className="c-text-editor__toolbar__heading">
          <div
            onClick={() => setIsOpenDropdown(!isOpenDropdown)}
            className="c-text-editor__toolbar__heading__button"
            data-tooltip-id="text-editor-tooltip"
            data-tooltip-content="Style heading"
          >
            {getCurrentHeading()}
            {isOpenDropdown ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
          </div>
          <span ref={wrapperHeadingRef}>
            <div
              className="c-text-editor__toolbar__heading__dropdown"
              style={{ display: isOpenDropdown ? 'block' : 'none' }}
            >
              <div
                className="c-text-editor__toolbar__heading__dropdown__item"
                onClick={selectParagraph}
              >
                Paragraph
              </div>
              {headingTeam.map((heading) => (
                <div
                  key={heading}
                  className="c-text-editor__toolbar__heading__dropdown__item"
                  onClick={() => selectHeading({ level: heading })}
                >
                  Heading
                  {' '}
                  {heading}
                </div>
              ))}
            </div>
          </span>
        </div>
        <button
          className="c-text-editor__toolbar__button"
          onClick={() => editor.chain().focus().undo().run()}
          disabled={!editor.can().undo()}
          type="button"
          data-tooltip-id="text-editor-tooltip"
          data-tooltip-content="Undo (Ctrl+Z)"
        >
          <Icons.RotateLeft />
        </button>
        <button
          className="c-text-editor__toolbar__button"
          onClick={() => editor.chain().focus().redo().run()}
          disabled={!editor.can().redo()}
          type="button"
          data-tooltip-id="text-editor-tooltip"
          data-tooltip-content="Redo (Ctrl+Y)"
        >
          <Icons.RotateRight />
        </button>
        <button
          className={editor.isActive('bold') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
          onClick={toggleBold}
          type="button"
          data-tooltip-id="text-editor-tooltip"
          data-tooltip-content="Add bold text (Ctrl+B)"
        >
          <Icons.Bold />
        </button>
        <button
          className={editor.isActive('italic') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
          onClick={toggleItalic}
          type="button"
          data-tooltip-id="text-editor-tooltip"
          data-tooltip-content="Add italic text (Ctrl+I)"
        >
          <Icons.Italic />
        </button>
        <button
          className={editor.isActive('underline') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
          onClick={toggleUnderline}
          type="button"
          data-tooltip-id="text-editor-tooltip"
          data-tooltip-content="Add underline text (Ctrl+U)"
        >
          <Icons.Underline />
        </button>
        {!isMobile && (
          <>
            <button
              className={editor.isActive('code') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
              onClick={toggleCode}
              type="button"
              data-tooltip-id="text-editor-tooltip"
              data-tooltip-content="Insert code"
            >
              <Icons.Code />
            </button>
            <button
              className={editor.isActive('strike') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
              onClick={toggleStrike}
              type="button"
              data-tooltip-id="text-editor-tooltip"
              data-tooltip-content="Add strikethrough text (Ctrl+Shift+X)"
            >
              <Icons.Strike />
            </button>
            <button
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={editor.isActive('blockquote') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
              type="button"
              data-tooltip-id="text-editor-tooltip"
              data-tooltip-content="Insert a quote"
            >
              <Icons.Blockquote />
            </button>
          </>

        )}
      </div>
      <div className="c-text-editor__toolbar__block">
        <div className="c-text-editor__toolbar__align">
          <div
            onClick={() => setIsOpenAlignDropdown(true)}
            className="c-text-editor__toolbar__align__button"
            data-tooltip-id="text-editor-tooltip"
            data-tooltip-content="Text alignment"
          >
            {getCurrentAlign()}
            {isOpenAlignDropdown ? <Icons.ChevronUp /> : <Icons.ChevronDown />}
          </div>
          <span ref={wrapperAlignRef}>
            <div
              className="c-text-editor__toolbar__align__dropdown"
              style={{ display: isOpenAlignDropdown ? 'flex' : 'none' }}
            >
              {alignTeam.map((align) => (
                <div
                  key={align}
                  className="c-text-editor__toolbar__align__dropdown__item"
                  onClick={() => selectAlign(align)}
                  data-tooltip-id="text-editor-tooltip"
                  data-tooltip-content={`Text align: ${align}`}
                >
                  <IconAlign align={align} />
                </div>
              ))}
            </div>
          </span>
        </div>
        <button
          className={editor.isActive('link') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
          onClick={openModalLink}
          type="button"
          data-tooltip-id="text-editor-tooltip"
          data-tooltip-content="Add link"
        >
          <Icons.Link />
        </button>
        <Modal
          title={I18N.ADD_LINK}
          isVisible={isOpenModalAddLink}
          onClose={closeModalLink}
          btnAcceptHandler={setLink}
          btnCancelHandler={closeModalLink}
        >
          <InputControl
            label={I18N.LINK}
            type="text"
            name="link"
            placeholder={I18N.ENTER_LINK}
            value={url}
            onChange={(event) => { setUrl(event.currentTarget.value); }}
          />
        </Modal>
        <button
          className="c-text-editor__toolbar__button"
          onClick={() => editor.chain().focus().unsetLink().run()}
          disabled={!editor.isActive('link')}
          type="button"
          data-tooltip-id="text-editor-tooltip"
          data-tooltip-content="Delete link"
        >
          <Icons.Unlink />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
          type="button"
          data-tooltip-id="text-editor-tooltip"
          data-tooltip-content="Add a bullet list"
        >
          <Icons.ListBulletItem />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={editor.isActive('orderedList') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
          type="button"
          data-tooltip-id="text-editor-tooltip"
          data-tooltip-content="Add a numbered list"
        >
          <Icons.ListOrderItem />
        </button>
        <div className="c-text-editor__toolbar__color">
          <button
            onClick={() => setIsShowTextBackgroundColorPalette(true)}
            className="c-text-editor__toolbar__button"
            type="button"
            data-tooltip-id="text-editor-tooltip"
            data-tooltip-content="Add text background color"
          >
            <Icons.TextBackgroundColor
              color={getCurrentTextBackgroundColor().color}
            />
          </button>
          <span ref={wrapperTextBackgroundColorRef}>
            {isShowTextBackgroundColorPalette && (
              <div className="c-text-editor__toolbar__color__palette">
                <ColorPalette
                  onClick={setTextBackgroundColor}
                  selectedColor={getCurrentTextBackgroundColor}
                  palette={palette}
                />
                <Button
                  onClick={unSetTextBackgroundColor}
                  disabled={!editor.isActive('highlight')}
                  text={I18N.RESET}
                  size="xs"
                  type="primary"
                />
              </div>
            )}
          </span>
        </div>
        <div className="c-text-editor__toolbar__color">
          <button
            onClick={() => setIsShowTextColorPalette(true)}
            className="c-text-editor__toolbar__button"
            type="button"
            data-tooltip-id="text-editor-tooltip"
            data-tooltip-content="Add text color"
          >
            <Icons.TextColor
              color={getCurrentTextColor().color}
            />
          </button>
          <span ref={wrapperTextColorRef}>
            {isShowTextColorPalette && (
              <div className="c-text-editor__toolbar__color__palette">
                <ColorPalette
                  onClick={setTextColor}
                  selectedColor={getCurrentTextColor}
                  palette={palette}
                />
                <Button
                  onClick={unSetTextColor}
                  disabled={!editor.isActive('textStyle')}
                  text={I18N.RESET}
                  size="xs"
                  type="primary"
                />
              </div>
            )}
          </span>
        </div>
        {!isMobile && (
          <>
            <button
              className={editor.isActive('subscript') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
              onClick={toggleSubscript}
              type="button"
              data-tooltip-id="text-editor-tooltip"
              data-tooltip-content="Add subscript text"
            >
              <Icons.Subscript />
            </button>
            <button
              className={editor.isActive('superscript') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
              onClick={toggleSuperscript}
              type="button"
              data-tooltip-id="text-editor-tooltip"
              data-tooltip-content="Add superscript text"
            >
              <Icons.Superscript />
            </button>
            <button
              onClick={toggleCodeBlock}
              className={editor.isActive('codeBlock') ? 'is-active c-text-editor__toolbar__button' : 'c-text-editor__toolbar__button'}
              type="button"
              data-tooltip-id="text-editor-tooltip"
              data-tooltip-content="Insert block code"
            >
              <Icons.CodeBlock />
            </button>
          </>
        )}

      </div>
    </div>
  );
};

export default TextEditorToolbar;
