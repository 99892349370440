import { useTranslation } from 'react-i18next';

import { messages } from './translations';

export const useI18N = (options) => {
  const { t } = useTranslation();

  const translations = {};

  const keys = Object.keys(messages.en.translation);

  keys.forEach((key) => {
    translations[key] = t(key, options);
  });

  return translations;
};

export default useI18N;
