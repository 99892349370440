import axios from 'axios';
import { useRecoilValue } from 'recoil';

import { BASE_URL } from '../config/config';

import sessionState from '../store/atoms/sessionAtom';

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
};

export const useApi = () => {
  const session = useRecoilValue(sessionState);

  axios.interceptors.request.use((config) => {
    if (!config.headers.Authorization) {
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${session.jwt}`,
        },
      };
    }

    return config;
  });

  // Auth
  const register = (credentials) => axios.post('/auth/register', credentials);
  const login = (credentials) => axios.post('/auth/login', credentials);
  const tempLogin = (credentials) => axios.post('auth/temp-login', credentials);
  // const loginWithGoogle = () => axios.get('http://localhost:3001/api/auth/google/');
  const loginWithGoogle = () => {
    window.open(`${BASE_URL}/auth/google`);
  };
  const loginWithFacebook = () => {
    window.open(`${BASE_URL}/auth/facebook`);
  };
  const registerWithGoogle = () => 'register with google';

  // User
  const getProfile = (accessToken) => {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    return axios.get('/profile', config);
  };
  const patchUserById = (id, data) => axios.patch(`users/${id}`, data);
  const patchRestorePwd = (data) => axios.patch('users/restore', data);
  const getUserById = (id) => axios.get(`/users/${id}`);
  const searchUser = (params) => axios.get('/users/search', { params });
  const patchSettingsById = (id, data) => axios.patch(`/profile-settings/${id}`, data);
  const restoreUser = (credentials) => axios.post('/users/restore-password', credentials);
  const deleteUserAccount = (id) => axios.delete(`/users/${id}`);

  // Board
  const createBoard = (board) => axios.post('/boards', board);
  const getBoardsByOwnerId = (userId) => axios.get(`/boards/user/${userId}`);
  const getTasksByUserId = (userId) => axios.get(`/task/user/${userId}`);
  const getBoardById = (id) => axios.get(`/boards/${id}`);
  const patchBoardById = (id, data) => axios.patch(`/boards/${id}`, data);
  const deleteBoardById = (id) => axios.delete(`/boards/${id}`);
  const copyBoard = (boardId, data) => axios.post(`/board-template/create/from/board/${boardId}`, data);
  const addFavoriteBoard = (data) => axios.post('/users/board-favorite', data);
  const deleteBoardFromFavorite = (id) => axios.delete(`/users/board-favorite/${id}`);
  const getBoardsByUserId = (id, accessToken) => {
    if (accessToken) {
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      return axios.get(`boards/user-boards/${id}`, config);
    }

    return axios.get(`boards/user-boards/${id}`);
  };

  // Board Template
  const createBoardFromTemplate = (accessToken) => {
    const config = {};
    if (accessToken) {
      config.headers = { Authorization: `Bearer ${accessToken}` };
    }
    return axios.post('/board-template/create/from/template/', {}, config);
  };

  // TaskStatuses
  const createNewStatus = (status) => axios.post('/task-status', status);
  const patchStatusById = (id, data) => axios.patch(`/task-status/${id}`, data);
  const patchStatusesOrder = (data) => axios.patch('/task-status/multiple', data);
  const deleteStatusById = (id) => axios.delete(`/task-status/${id}`);

  // Tasks
  const createTask = (task) => axios.post('/task', task);
  const patchTaskById = (id, data) => axios.patch(`/task/${id}`, data);
  const patchTasksOrders = (data) => axios.patch('/task/multiple', data);
  const deleteTaskById = (id) => axios.delete(`/task/${id}`);
  const getTaskById = (id) => axios.get(`/task/${id}`);

  // LogTime
  const createTimeLog = (timeRecord) => axios.post('/time-logs', timeRecord);
  const patchTimeLogById = (id, data) => axios.patch(`/time-logs/${id}`, data);
  const deleteTimeLogById = (id) => axios.delete(`/time-logs/${id}`);

  // TimeLogsReport
  const getTimeLogsReport = (id, data) => axios.get(`/time-logs-report/${id}`, data);

  // Tags
  const createTag = (tagName) => axios.post('/tags', tagName);
  const deleteTag = (id) => axios.delete(`/tags/${id}`);
  const getTag = () => axios.get('/tags');

  // ToDo
  const createToDo = (todoItem) => axios.post('/todo-list', todoItem);
  const deleteToDoById = (id) => axios.delete(`/todo-list/${id}`);
  const patchToDoById = (id, data) => axios.patch(`/todo-list/${id}`, data);

  // Contact/Support Form
  const sendForm = (data) => axios.post('/form', data);

  // UpdateUserAvatar
  const sendAvatar = (file, mediatype) => axios.post(`/data-storage/upload/${mediatype}`, file);
  const deleteAvatar = (fileUrl, mediatype) => axios.delete(`/data-storage/delete/${mediatype}`, { data: { fileUrl } });

  return [{
    addFavoriteBoard,
    copyBoard,
    createBoard,
    createBoardFromTemplate,
    createNewStatus,
    createTag,
    createTask,
    createTimeLog,
    createToDo,
    deleteAvatar,
    deleteBoardById,
    deleteBoardFromFavorite,
    deleteStatusById,
    deleteTag,
    deleteTaskById,
    deleteTimeLogById,
    deleteToDoById,
    deleteUserAccount,
    getBoardById,
    getBoardsByOwnerId,
    getBoardsByUserId,
    getProfile,
    getTag,
    getTaskById,
    getTasksByUserId,
    getTimeLogsReport,
    getUserById,
    login,
    loginWithFacebook,
    loginWithGoogle,
    patchBoardById,
    patchRestorePwd,
    patchSettingsById,
    patchStatusById,
    patchStatusesOrder,
    patchTaskById,
    patchTasksOrders,
    patchTimeLogById,
    patchToDoById,
    patchUserById,
    register,
    registerWithGoogle,
    restoreUser,
    searchUser,
    sendAvatar,
    sendForm,
    tempLogin,
  }];
};

export { axios };
