import React from 'react';
import { useRecoilState } from 'recoil';

// Component
import Copyright from '../Copyright';
import {
  IconEnvelope,
  IconFacebookCustom,
  IconLinkedin,
  IconTwitter,
} from '../Icons';
import LinkRouter from '../LinkRouter';
import LinkUrl from '../LinkUrl';
import Logo from '../Logo';

// Style
import './FooterLayout.scss';

// Configs
import { PAGE, CONTACT_EMAIL } from '../../config/config';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// State
import {
  sessionState,
} from '../../store/atoms';

const FooterLayout = () => {
  const I18N = useI18N();
  const [session, setSession] = useRecoilState(sessionState);

  const handleClickLink = () => {
    setSession({ ...session, authFormType: null });
  };

  return (
    <footer className="c-footer-layout">
      <div className="c-footer-layout__block-1">
        <div className="c-footer-layout__block-1__logo">
          <LinkRouter
            page={PAGE.HOME.PATH}
          >
            <Logo
              imgUrl="/img/logo_circle.png"
              size="md"
            />
          </LinkRouter>
        </div>
        <div className="c-footer-layout__block-1__page">
          <LinkRouter
            page={PAGE.ABOUT.PATH}
            onClick={handleClickLink}
          >
            <span className="c-footer-layout__title">{I18N.ABOUT}</span>
          </LinkRouter>
          <LinkRouter
            page={PAGE.PRIVACY_POLICY.PATH}
            onClick={handleClickLink}
          >
            <span className="c-footer-layout__title">{I18N.PRIVACY_POLICY}</span>
          </LinkRouter>
          <LinkRouter
            page={PAGE.SUPPORT.PATH}
            onClick={handleClickLink}
          >
            <span className="c-footer-layout__title">{I18N.SUPPORT}</span>
          </LinkRouter>
          <LinkRouter
            page={PAGE.FAQ.PATH}
            onClick={handleClickLink}
          >
            <span className="c-footer-layout__title">{I18N.FAQ}</span>
          </LinkRouter>
        </div>
        <div className="c-footer-layout__block-1__contact">
          <p className="c-footer-layout__title">
            {I18N.CONTACT_US}
          </p>
          <div className="c-footer-layout__block-1__contact__mail">
            <IconEnvelope />
            <LinkUrl
              newTab
              typeUrl="mailto"
              url={CONTACT_EMAIL}
            >
              <span>{CONTACT_EMAIL}</span>
            </LinkUrl>
          </div>
        </div>
      </div>
      <div className="c-footer-layout__block-2">
        <Copyright />
        <div className="c-footer-layout__block-2__author">
          <p className="c-footer-layout__title">
            {I18N.CREATED_BY}
          </p>
          <LinkUrl
            newTab
            url="https://lanadev.com"
          >
            <img
              src="/img/lanadev.png"
              alt="lanadev"
              className="c-footer-layout__block-2__author__logo"
            />
          </LinkUrl>
        </div>
        <div className="c-footer-layout__block-2__social-media">
          <p className="c-footer-layout__title">
            {I18N.FOLLOW_US}
          </p>
          <div className="c-footer-layout__block-2__social-media__list">
            <LinkUrl
              newTab
              url="https://www.facebook.com/profile.php?id=61556524142014"
            >
              <IconFacebookCustom />
            </LinkUrl>
            <LinkUrl
              newTab
              url="https://twitter.com/ITLanaDev"
            >
              <IconTwitter />
            </LinkUrl>
            <LinkUrl
              newTab
              url="https://www.linkedin.com/company/lanadev"
            >
              <IconLinkedin />
            </LinkUrl>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterLayout;
