/* eslint-disable max-len */
import React from 'react';
import Icon from '../Icon';

const IconDots = (props) => {
  const {
    size,
    inverse,
    onClick,
    role,
  } = props;
  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-dots"
      role={role}
    >
      <svg
        viewBox="0 0 16 4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="14" cy="2" r="2" />
        <circle cx="8" cy="2" r="2" />
        <circle cx="2" cy="2" r="2" />
      </svg>
    </Icon>
  );
};

export default IconDots;
