import React from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Components
import Button from '../Button';
import Modal from '../Modal';
import {
  IconFolder,
  IconPlus,
} from '../Icons';
import Tooltip from '../Tooltip';

// Styles
import './BoardActions.scss';

// Configs
import {
  POSITION,
  AUTH_FORM_TYPE,
  AUTH_STATUS,
  PAGE,
} from '../../config/config';

// State
import { sessionState } from '../../store/atoms';

const TooltipCTA = (props) => {
  const {
    onClick,
  } = props;

  const I18N = useI18N();

  return (
    <div className="c-tooltip-massage">
      <p className="c-tooltip-massage__text">
        {I18N.MESSAGE_TO_REGISTER}
      </p>
      <Button
        text={I18N.REGISTER}
        size="xs"
        onClick={onClick}
      />
    </div>
  );
};

const BoardActions = (props) => {
  const {
    acceptLeaveBoard,
    changeArchiveStatus,
    closeArchiveBoardModal,
    closeDeleteBoardModal,
    closeLeaveBoardModal,
    copyAndCreateNewBoard,
    handleDeleteBoard,
    isArchiveBoard,
    isArchiveBoardModal,
    isBoardOwner,
    isDeleteBoard,
    isLeaveBoard,
    openArchiveBoardModal,
    openDeleteBoardModal,
    openLeaveBoardModal,
    userProfile,
    disabledAddUser,
  } = props;

  const I18N = useI18N();
  const [session, setSession] = useRecoilState(sessionState);
  const navigate = useNavigate();

  const modalArchiveTitle = isArchiveBoard ? I18N.UNARCHIVE_BOARD : I18N.ARCHIVE_BOARD;
  const btnArchiveText = isArchiveBoard ? I18N.UNARCHIVE : I18N.ARCHIVE;
  const archiveMessage = isArchiveBoard
    ? I18N.MESSAGE_TO_UNARCHIVE_BOARD
    : I18N.MESSAGE_TO_ARCHIVE_BOARD;

  const handleRegister = () => {
    setSession({
      ...session,
      authFormType: AUTH_FORM_TYPE.REGISTER,
      authStatus: AUTH_STATUS.NOT_AUTH,
    });
    navigate(`/${PAGE.AUTH.PATH}`);
  };

  return (
    <>
      <div className="c-board-actions-panel__board-actions">
        <p className="c-board-actions-panel__board-actions-title action-subtitle">
          {I18N.BOARD_ACTIONS}
        </p>
        <Tooltip
          info={<TooltipCTA onClick={handleRegister} />}
          position={POSITION.TOP}
          disabled={!disabledAddUser}
        >
          <Button
            text={I18N.CREATE_NEW_BOARD}
            iconLeft={<IconPlus />}
            size="sm"
            onClick={copyAndCreateNewBoard}
            disabled={userProfile?.isTemp}
            type="primary"
          />
        </Tooltip>
        {isBoardOwner ? (
          <>
            <Tooltip
              info={<TooltipCTA onClick={handleRegister} />}
              position={POSITION.BOTTOM}
              disabled={!disabledAddUser}
            >
              <Button
                text={btnArchiveText}
                size="sm"
                onClick={openArchiveBoardModal}
                disabled={userProfile?.isTemp}
                type={isArchiveBoard ? 'confirm-inverse' : 'secondary-inverse'}
                iconLeft={<IconFolder size="sm" />}
              />
            </Tooltip>
            <Button
              type="danger-inverse"
              text={I18N.DELETE}
              size="sm"
              onClick={openDeleteBoardModal}
            />
          </>
        ) : (
          <Button
            type="danger-inverse"
            text={I18N.LEAVE_BOARD}
            size="sm"
            onClick={openLeaveBoardModal}
          />
        )}
      </div>
      <Modal
        type="WARNING"
        isVisible={isDeleteBoard}
        title={I18N.DELETE_BOARD}
        onClose={closeDeleteBoardModal}
        btnAcceptText={I18N.DELETE}
        btnAcceptHandler={handleDeleteBoard}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={closeDeleteBoardModal}
      >
        <span className="c-board-actions-panel__board-actions-description">
          {I18N.MESSAGE_TO_DELETE_BOARD}
        </span>
      </Modal>
      <Modal
        type="WARNING"
        isVisible={isArchiveBoardModal}
        title={modalArchiveTitle}
        onClose={closeArchiveBoardModal}
        btnAcceptText={btnArchiveText}
        btnAcceptHandler={changeArchiveStatus}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={closeArchiveBoardModal}
      >
        <div className="c-board-actions-panel__board-actions-description">
          {archiveMessage}
        </div>
      </Modal>
      <Modal
        type="WARNING"
        isVisible={isLeaveBoard}
        title={I18N.LEAVE_BOARD}
        onClose={closeLeaveBoardModal}
        btnAcceptText={I18N.LEAVE}
        btnAcceptHandler={acceptLeaveBoard}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={closeLeaveBoardModal}
      >
        <div className="c-board-actions-panel__board-actions-description">
          {I18N.MESSAGE_TO_LEAVE_BOARD}
        </div>
      </Modal>
    </>
  );
};

export default BoardActions;
