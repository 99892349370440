import React from 'react';
import { useNavigate } from 'react-router-dom';

// Configs
import { POSITION } from '../../config/config';

// Components
import ColorMarkBar from '../ColorMarkBar';
import TaskCardHeader from '../TaskCardHeader';
import TaskCardDescription from '../TaskCardDescription';
import TaskCardFooter from '../TaskCardFooter/TaskCardFooter';

// Style
import './TaskCard.scss';

// Store
import useTaskMutations from '../../store/mutations/useTaskMutations';
import { IconChevronRight } from '../Icons';

const TaskCard = (props) => {
  const {
    data,
    isDraggable,
    boardTeam,
    boardId,
    isMobile,
  } = props;

  const {
    colorMark,
    boardIndex,
  } = data;

  const navigate = useNavigate();

  const user = boardTeam.length && data.assignedToUserId
    ? boardTeam.filter((item) => item.id === data.assignedToUserId)[0]
    : null;

  const {
    fetchActiveTaskMutation,
  } = useTaskMutations();

  const draggingClassName = isDraggable ? 'c-task-card_dragging' : '';
  const taskCardClassName = `c-task-card ${draggingClassName}`;

  const openTask = () => {
    fetchActiveTaskMutation(data.id);
    if (isMobile) {
      navigate(`/board/${boardId}/task/${boardIndex}`);
    }
  };

  return (
    <div className={taskCardClassName}>
      <TaskCardHeader
        boardIndex={data.boardIndex}
        title={data.title}
        onClick={openTask}
      />
      <TaskCardDescription
        data={data}
      />
      <TaskCardFooter
        data={data}
        user={user}
      />
      <IconChevronRight />
      <ColorMarkBar
        position={POSITION.LEFT}
        color={colorMark}
      />
    </div>
  );
};

export default TaskCard;
