import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

// Translations
import { useI18N } from '../../i18n/useI18N';

// Configs
import { PAGE } from '../../config/config';

// Components
import Table from '../Table';
import LinkRouter from '../LinkRouter';
import Loader from '../Loader';

// Store
import useTaskMutations from '../../store/mutations/useTaskMutations';
import { userProfileTasksState } from '../../store/atoms';

// Style
import './UserTasks.scss';

// Utils
import { convertMinuteToEstimationString } from '../../utils/timeUtils';

const UserTasks = (props) => {
  const { userId } = props;
  const { getTasksByUserIdMutation } = useTaskMutations();

  const I18N = useI18N();

  const [foreignUserTasks, setForeignUserTasks] = useRecoilState(userProfileTasksState);
  const userTasksTableHeader = [
    I18N.TASK_ID,
    I18N.TITLE,
    I18N.PRIORITY,
    I18N.ESTIMATION,
    I18N.TIME_SPEND,
    I18N.PROGRESS,
    I18N.BOARD,
  ];
  useEffect(() => {
    getTasksByUserIdMutation(userId);

    return () => {
      setForeignUserTasks(null);
    };
  }, [userId]);

  const getColorClass = (progressPercentage, index, estimation) => {
    if (!estimation || estimation <= 0) {
      return 'gray';
    }

    if (progressPercentage > 200) {
      return 'red';
    }

    if (progressPercentage > 100) {
      return 'orange';
    }

    if (progressPercentage >= (index + 1) * 10) {
      return 'aqua';
    }

    return 'gray';
  };

  const tableData = [];

  if (foreignUserTasks) {
    foreignUserTasks
      .filter((task) => task.boardIndex && task.board)
      .forEach((task) => {
        const totalTimeSpend = task.timeLogs.length > 0
          ? task.timeLogs.reduce((total, timeLog) => total + timeLog.value, 0)
          : 0;

        const timeSpendClassName = task.estimation && totalTimeSpend > task.estimation
          ? 'high-value'
          : '';

        const rowData = {
          key: task.id,
          taskId: (
            <LinkRouter
              fromRoot
              page={PAGE.TASK.PATH}
              data={{ index: task.boardIndex, boardId: task.board?.id }}
            >
              {task.boardIndex}
            </LinkRouter>
          ),
          title: task.title,
          priority: task.priority,
          estimation: (
            <div>
              {task.estimation ? convertMinuteToEstimationString(task.estimation) : '-'}
            </div>
          ),
          timeSpend: (
            <div className={timeSpendClassName}>
              {totalTimeSpend ? convertMinuteToEstimationString(totalTimeSpend) : '-'}
            </div>
          ),
          progress: (
            <div className="c-progress-icons">
              {Array.from({ length: 10 }, (_, index) => {
                const progressPercentage = Math.round(
                  (totalTimeSpend / (task.estimation || 1)) * 100,
                );
                const colorClass = getColorClass(progressPercentage, index, task.estimation);

                return (
                  <span className="c-icon-colors" key={index}>
                    <span className={`c-icon ${colorClass}`} />
                  </span>
                );
              })}
            </div>
          ),
          board: (
            <LinkRouter
              fromRoot
              page={PAGE.BOARD.PATH}
              data={{ boardId: task.board?.id }}
            >
              {task.board.name}
            </LinkRouter>
          ),
        };
        tableData.push(rowData);
      });
  }

  return (
    <div className="c-user-tasks">
      {foreignUserTasks ? (
        <Table
          titles={userTasksTableHeader}
          rows={
          tableData.map(
            (rowData) => [
              rowData.taskId,
              rowData.title,
              rowData.priority,
              rowData.estimation,
              rowData.timeSpend,
              rowData.progress,
              rowData.board,
            ],
          )
          }
        />
      ) : <Loader />}
    </div>
  );
};

export default UserTasks;
