const getDateModal = (value) => {
  const currentDate = value ? new Date(value) : new Date();
  const year = currentDate.getFullYear();
  let date = currentDate.getDate();
  if (date < 10) {
    date = `0${date}`;
  }
  let month = currentDate.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  const formatCurrentDate = `${year}-${month}-${date}`;
  return formatCurrentDate;
};

export default getDateModal;
