import { useSetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

// Hooks
import useColorTheme from '../../hooks/useColorTheme';
import { useApi } from '../../hooks/useApi';

// Store
import { profileSettingsState } from '../atoms';

const useProfileSettingsMutations = () => {
  const [fetch] = useApi();
  const [cookies, setCookie] = useCookies(['i18next', 'theme']);
  const { changeColorTheme } = useColorTheme();
  const { i18n } = useTranslation();

  const setProfileSettings = useSetRecoilState(profileSettingsState);

  const updateProfileSettingsMutation = async (id, data) => {
    const response = await fetch.patchSettingsById(id, data);
    if (response?.data) {
      setProfileSettings(response.data);
    }
  };

  const setDefaultSettings = () => {
    const lang = cookies.i18next || navigator.language.split('-')[0];
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
      setCookie('i18next', lang, { path: '/' });
    }
    const theme = cookies.theme || 'light';
    if (theme) {
      changeColorTheme(theme);
      setCookie('theme', theme, { path: '/' });
    }
  };

  const processProfileSettings = (settings) => {
    if (!settings?.data) {
      setDefaultSettings();
      return;
    }
    setProfileSettings(settings);
    const { lang, theme } = settings.data;
    if (lang !== i18n.language) {
      i18n.changeLanguage(lang);
      setCookie('i18next', lang, { path: '/' });
    }
    if (theme) {
      changeColorTheme(theme);
      setCookie('theme', theme, { path: '/' });
    }
  };

  return {
    processProfileSettings,
    setDefaultSettings,
    updateProfileSettingsMutation,
  };
};

export default useProfileSettingsMutations;
