import React, {
  useState,
  useRef,
  useEffect,
  useContext,
} from 'react';
import { useRecoilValue } from 'recoil';
import { Tooltip } from 'react-tooltip';

// Store
import useBoardMutations from '../../store/mutations/useBoardMutations';
import { filterActiveBoardState } from '../../store/selector/filterActiveBoard';
import { activeBoardState } from '../../store/atoms';

// Translations
import { useI18N } from '../../i18n/useI18N';

// Constants
import { POSITION, STATUS_CARD } from '../../config/config';

// Components
import Button from '../Button';
import ColorMarkBar from '../ColorMarkBar';
import ColorPalette from '../ColorPalette';
import {
  IconCrossRound,
  IconDots,
  IconColorPattern,
  IconBin,
  IconChevronUp,
  IconChevronDown,
} from '../Icons';
import Modal from '../Modal';

// Styles
import './StatusCard.scss';
import 'react-tooltip/dist/react-tooltip.css';

// Context
import LayoutContext from '../../context/LayoutContext';

const StatusCard = (props) => {
  const {
    boardId,
    children,
    data,
    isNewStatus,
    tmpStatus,
    order,
    onPointerDown,
    isDraggable,
    isOpen,
    setIsOpen,
  } = props;

  const I18N = useI18N();

  // Global State
  const tasks = useRecoilValue(filterActiveBoardState);
  const activeBoard = useRecoilValue(activeBoardState);

  // Mutations
  const {
    createBoardMutation,
    createNewStatusMutation,
    deleteStatusMutation,
    updateStatusMutation,
  } = useBoardMutations();

  // Default State
  const defaultTaskStatus = isNewStatus ? {
    name: '',
    boardId,
    order,
    colorMark: STATUS_CARD.COLOR_MARK_DEFAULT,
  } : data;

  // State
  const [isEditMode, setIsEditMode] = useState(isNewStatus);
  const [currentStatus, setCurrentStatus] = useState(defaultTaskStatus);
  const [statusColorMark, setStatusColorMark] = useState(
    currentStatus.colorMark || STATUS_CARD.COLOR_MARK_DEFAULT,
  );
  const [createNewStatus, setCreateNewStatus] = useState(null);
  const [haveTasks, setHaveTasks] = useState(true);
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [isOpenModalDeleteStatus, setIsOpenModalDeleteStatus] = useState(false);
  const { isMobile } = useContext(LayoutContext);

  // Component Logic
  const refInput = useRef();
  const amountCurrentTasks = tasks.filter((task) => task.statusId === currentStatus.id);
  const draggingClassName = isDraggable ? 'c-status-card_dragging' : '';
  const classStatusCard = `c-status-card ${draggingClassName}`;

  const saveNewStatus = (newStatus) => {
    setCreateNewStatus(newStatus);
  };

  const updateStatusName = (e) => {
    setCurrentStatus({
      ...currentStatus,
      name: e.currentTarget.value,
    });
  };

  const toggleColorPalette = () => {
    setShowColorPalette(!showColorPalette);
  };

  const handleEditStatus = () => {
    if (amountCurrentTasks.length === 0) {
      setHaveTasks(false);
    }
    setIsEditMode(true);
  };

  const handleOpenModalDeleteStatus = () => {
    setIsOpenModalDeleteStatus(true);
  };

  const handleCloseModalDeleteStatus = () => {
    setIsOpenModalDeleteStatus(false);
  };

  const handleDeleteStatus = () => {
    deleteStatusMutation(currentStatus.id, boardId);
    handleCloseModalDeleteStatus();
  };

  const handleSaveTaskStatus = () => {
    if (!currentStatus.name.length) return;
    if (isNewStatus) {
      saveNewStatus(currentStatus, boardId);
    } else {
      updateStatusMutation(currentStatus);
    }
    setShowColorPalette(false);
    setIsEditMode(false);
  };

  const cancelTaskStatus = () => {
    if (isNewStatus) {
      tmpStatus(null);
      return;
    }
    setShowColorPalette(false);
    setCurrentStatus({
      ...currentStatus,
      name: data.name,
      colorMark: data.colorMark,
    });
    setStatusColorMark(data.colorMark);
    setIsEditMode(false);
  };

  const handleClickColor = (item) => {
    setStatusColorMark(item);
  };

  const handleUpdateStatus = () => {
    if (createNewStatus && activeBoard.id === 'new') {
      createBoardMutation({ ...activeBoard, id: null });
    }

    if (createNewStatus && activeBoard.id !== 'new') {
      if (createNewStatus.name) {
        createNewStatusMutation({ ...createNewStatus, boardId: activeBoard.id });
      }
      tmpStatus(null);
      setCreateNewStatus(null);
    }
  };

  const handleKeyDown = (event) => {
    if (event.code === 'Escape') {
      cancelTaskStatus();
    }

    if (event.code === 'Delete' && !haveTasks) {
      handleDeleteStatus();
    }

    if (event.code === 'Enter') {
      handleSaveTaskStatus();
    }
  };

  useEffect(() => {
    if (order !== currentStatus.order) {
      setCurrentStatus({
        ...currentStatus,
        order: order ?? currentStatus.order,
      });
    }

    if (isEditMode) {
      refInput.current.focus();
    }

    if (statusColorMark !== currentStatus.colorMark) {
      setCurrentStatus({
        ...currentStatus,
        colorMark: statusColorMark,
      });
      toggleColorPalette();
    }

    handleUpdateStatus();
  }, [order, statusColorMark, isEditMode, createNewStatus, activeBoard.id]);

  useEffect(() => {
    if (data && !isEditMode) {
      setCurrentStatus(data);
    }
  }, [data]);

  return (
    <section
      className={classStatusCard}
    >
      <Tooltip id="status-card-tooltip" />
      <ColorMarkBar
        color={statusColorMark}
        position={POSITION.TOP}
        onPointerDown={(e) => onPointerDown(e, data.id)}
      />
      <header className="c-status-card__header">
        <h4 className="c-status-card__header__title">{currentStatus?.name}</h4>
        <IconDots
          size="xs"
          onClick={handleEditStatus}
        />
        {isOpen ? <IconChevronUp onClick={setIsOpen} />
          : <IconChevronDown onClick={setIsOpen} />}
      </header>
      {isEditMode && (
        <>
          <div className="c-status-card__edit-mode__wrapper">
            <div className="c-status-card__edit-mode">
              <input
                className="c-status-card__edit-mode__input"
                type="text"
                value={currentStatus?.name}
                placeholder="Title"
                onChange={updateStatusName}
                ref={refInput}
                onKeyDown={handleKeyDown}
              />
              <div className="c-status-card__edit-mode__options">
                <div className="c-status-card__edit-mode__options__primary">
                  <Button
                    onClick={handleSaveTaskStatus}
                    size="xs"
                    text={I18N.SAVE}
                  />
                  <IconColorPattern
                    onClick={toggleColorPalette}
                    size="xs"
                  />
                  {!haveTasks && (
                    <>
                      <IconBin
                        onClick={handleOpenModalDeleteStatus}
                        size="xs"
                      />
                      <Modal
                        isVisible={isOpenModalDeleteStatus}
                        onClose={handleCloseModalDeleteStatus}
                        title={I18N.DELETE_STATUS}
                        content={I18N.PLEASE_CONFIRM_DELETE_ACTION}
                        type="WARNING"
                        btnAcceptText={I18N.DELETE_STATUS}
                        btnAcceptHandler={handleDeleteStatus}
                        btnCancelHandler={handleCloseModalDeleteStatus}
                        isMobile={isMobile}
                        className="modal-status-action"
                      />
                    </>
                  )}
                  {haveTasks && (
                    <IconBin
                      size="xs"
                      inverse="inverse"
                      dataTooltipId="status-card-tooltip"
                      dataTooltipContent={I18N.TOOLTIP_MESSAGE_CANNOT_DELETE}
                    />
                  )}
                  {showColorPalette && (
                    <ColorPalette
                      selectedColor={statusColorMark}
                      onClick={handleClickColor}
                    />
                  )}
                </div>
                <div className="c-status-card__edit-mode__options__secondary">
                  <IconCrossRound
                    onClick={cancelTaskStatus}
                    size="xs"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="c-status-card__overlay"
            onClick={cancelTaskStatus}
          />
        </>
      )}
      {children}
    </section>
  );
};

export default StatusCard;
