import { useRecoilState } from 'recoil';
import { toastersState } from '../store/atoms';

import generateRandomStr from '../utils/generateRandomStr';

export const useToaster = () => {
  const [toasterList, setToasterList] = useRecoilState(toastersState);

  const showToastSuccess = (message) => {
    setToasterList([
      ...toasterList,
      {
        id: generateRandomStr(),
        type: 'success',
        message,
      },
    ]);
  };

  const showToastError = (message) => {
    setToasterList([
      ...toasterList,
      {
        id: generateRandomStr(),
        type: 'error',
        message,
      },
    ]);
  };

  return {
    showToastSuccess,
    showToastError,
  };
};

export default useToaster;
