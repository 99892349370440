import { selector } from 'recoil';
import { appState } from '../atoms';

const isAppReadyState = selector({
  key: 'isAppReady',
  get: ({ get }) => {
    const state = get(appState);
    return state === 'READY';
  },
});

export default isAppReadyState;
