import React from 'react';

// Component
import Button from '../Button';
import { IconChevronDown } from '../Icons';
import LinkRouter from '../LinkRouter';
import Logo from '../Logo';
import UserField from '../UserField';

// Style
import './MobileMenu.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Configs
import { PAGE } from '../../config/config';

const MobileMenu = (props) => {
  const {
    user,
    handleLogout,
    handleLogin,
    handleRegister,
    closeMobileMenu,
    showLogoutButton,
    showRegisterButton,
    showLoginButton,
  } = props;

  const I18N = useI18N();

  const logout = () => {
    handleLogout();
    closeMobileMenu();
  };

  const login = () => {
    handleLogin();
    closeMobileMenu();
  };

  const register = () => {
    handleRegister();
    closeMobileMenu();
  };

  return (
    <div className="c-mobile-menu">
      <div className="c-mobile-menu__header">
        <IconChevronDown
          onClick={closeMobileMenu}
          size="sm"
        />
        <LinkRouter
          page={PAGE.HOME.PATH}
        >
          <Logo
            imgUrl="/img/logo_circle.png"
            text="ΛRELLΛ"
          />
        </LinkRouter>
      </div>
      <div className="c-mobile-menu__user">
        {user && !user.isTemp && (
          <LinkRouter
            page={PAGE.USER_PROFILE.PATH}
            data={{ userId: user.id }}
            onClick={closeMobileMenu}
          >
            <UserField
              user={user}
              size="lg"
            />
          </LinkRouter>
        )}
      </div>
      <div className="c-mobile-menu__list">
        <LinkRouter
          page={PAGE.HOME.PATH}
          onClick={closeMobileMenu}
        >
          <span>{I18N.HOME}</span>
          <IconChevronDown size="xs" />
        </LinkRouter>
        {user && !user.isTemp && (
          <LinkRouter
            page={PAGE.USER_PROFILE.PATH}
            data={{ userId: user.id }}
            onClick={closeMobileMenu}
          >
            <span>{I18N.USER_PROFILE}</span>
            <IconChevronDown size="xs" />
          </LinkRouter>
        )}
        <LinkRouter
          page={PAGE.ABOUT.PATH}
          onClick={closeMobileMenu}
        >
          <span>{I18N.ABOUT}</span>
          <IconChevronDown size="xs" />
        </LinkRouter>
        <LinkRouter
          page={PAGE.PRIVACY_POLICY.PATH}
          onClick={closeMobileMenu}
        >
          <span>{I18N.PRIVACY_POLICY}</span>
          <IconChevronDown size="xs" />
        </LinkRouter>
        <LinkRouter
          page={PAGE.SUPPORT.PATH}
          onClick={closeMobileMenu}
        >
          <span>{I18N.SUPPORT}</span>
          <IconChevronDown size="xs" />
        </LinkRouter>
      </div>
      <div className="c-mobile-menu__footer">
        {showLogoutButton() && (
          <Button
            text={I18N.LOGOUT}
            onClick={logout}
            type="brand"
            size="lg"
          />
        )}
        {showRegisterButton() && (
          <Button
            text={I18N.REGISTER}
            onClick={register}
            type="brand"
            size="lg"
          />
        )}
        {showLoginButton() && (
          <Button
            text={I18N.SIGN_IN}
            onClick={login}
            type="brand"
            size="lg"
          />
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
