import React from 'react';

// Styles
import './SortBoard.scss';

const SortType = (props) => {
  const {
    name,
    onClick,
    selected,
  } = props;

  const classNameSelected = selected ? 'c-sort-type_selected' : '';
  const classNameSortType = `c-sort-type ${classNameSelected}`;

  return (
    <li
      className={classNameSortType}
      onClick={onClick}
    >
      {name}
    </li>
  );
};

export default SortType;
