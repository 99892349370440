import React, { useEffect, useState, useRef } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Component
import { IconPlus, IconCrossRound } from '../Icons';
import Tag from '../Tag';
import AutocompleteTags from '../AutoCompleteTags/AutoCompleteTags';

// Store
import { activeBoardState, filterConfigState } from '../../store/atoms';

// Style
import './BoardFilter.scss';
import Button from '../Button';

const BoardFilter = (props) => {
  const {
    filterParams,
    onClick,
    onResetFilters,
    updatePriorityFilter,
    boardData,
    searchParams,
    updateTagsFilter,
  } = props;

  const refForInput = useRef(null);

  // Global State
  const activeBoard = useRecoilValue(activeBoardState);
  const [filterData, setFilterData] = useRecoilState(filterConfigState(boardData.id));

  // State
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [inputTags, setInputTags] = useState([]);

  const setInputActive = () => {
    setInputVisible(true);
    setTimeout(() => {
      if (refForInput.current) {
        refForInput.current.focus();
      }
    }, 0);
  };

  const tagsArray = (boardData.tasks)
    ? Array.from(new Set(boardData.tasks.flatMap(
      (task) => (task.tags ? task.tags.map((tag) => tag.name) : []),
    )))
    : [];

  const handleInputChange = (input) => {
    setInputValue(input);
  };

  const handleInputKeyDown = (event) => {
    if ((event.key === 'Enter' || event.type === 'click') && inputValue !== null) {
      event.preventDefault();
      if (!inputTags.some((tag) => tag.name === inputValue)) {
        setInputTags([...inputTags, { name: inputValue }]);
        updateTagsFilter(inputValue);
      }
      setInputValue(null);
    }

    if (event.key === 'Escape') { setInputVisible(null); }
  };

  const updateFilterDataFromSearchParams = () => {
    const obj = Object.fromEntries(searchParams.entries());
    const updatedData = { ...filterData };
    if (obj.priority) {
      updatedData.priority = true;
    }
    if (obj.tags || filterParams.tagsIds.length) {
      updatedData.tags = true;
    }
    setFilterData(updatedData);
  };

  // Component Logic
  const I18N = useI18N();

  const btnsPriority = [
    {
      value: 'high',
      text: I18N.HIGH,
    },
    {
      value: 'medium',
      text: I18N.MEDIUM,
    },
    {
      value: 'low',
      text: I18N.LOW,
    },
  ];
  const emptyFilterClassName = !filterParams.userIds.length
    && !filterData.priority
  && (!filterParams.tagsIds.length && !filterData.tags) ? 'empty-filter' : '';

  const boardFilterClassName = `c-board-filter ${emptyFilterClassName}`;

  const handleUserClassName = (id) => (id ? 'c-board-filter__select-user' : 'c-board-filter__select-user c-board-filter__select-user_no-assigned');

  const handleSelectUsers = (arr) => {
    const selectUsers = [];
    arr.forEach((userId) => {
      if (userId === null) {
        return selectUsers.push({
          id: userId,
          userName: 'no assigned user',
        });
      }

      const findUser = activeBoard.users.find(
        (activeUser) => activeUser.id === userId,
      );
      return selectUsers.push(findUser);
    });

    return selectUsers;
  };

  const handleResetFilters = () => {
    onResetFilters();
    setInputTags([]);
  };

  const removeTag = (tagName) => {
    setInputTags((existTags) => existTags.filter((tag) => tag.name !== tagName.name));
    updateTagsFilter(tagName.name);
    setInputVisible(true);
  };

  useEffect(() => {
    updateFilterDataFromSearchParams();
  }, []);

  useEffect(() => {
    if (filterParams.tagsIds.length > 0) {
      setInputTags(filterParams.tagsIds.map((tag) => ({ name: String(tag) })));
    }
  }, [filterParams.tagsIds]);

  return (
    <div className={boardFilterClassName}>
      <span className="c-board-filter__title">{I18N.FILTERS}</span>
      {handleSelectUsers(filterParams.userIds).map((user) => (
        <div key={user?.id} className={handleUserClassName(user?.id)}>
          <IconPlus onClick={() => onClick(user?.id)} size="xs" />
          <span className="c-board-filter__select-user-name">
            {user?.userName}
          </span>
        </div>
      ))}
      <span className="c-board-filter__priority">
        {(filterData.priority) && (
          <>
            {btnsPriority.map((btn) => (
              <button
                className={`c-board-filter__priority__btn${
                  filterParams.priority.includes(btn.value) ? `__${btn.value}` : ''
                }`}
                key={`btn_${btn.value}`}
                type="button"
                onClick={updatePriorityFilter}
                value={btn.value}
              >
                {btn.text}
              </button>
            ))}
          </>
        )}
      </span>
      <span className="c-board-filter__tags">
        {(filterParams.tagsIds.length > 0 || filterData.tags) && (
          <>
            <button
              type="button"
              className="c-board-filter__add_tag"
              onClick={setInputActive}
            >
              <IconPlus size="xs" />
              {I18N.ADD_TAG}
            </button>
            <ul className="c-board-filter__input_tags">
              {inputTags.map((tagItem) => (
                <li className="c-board-filter__input_tag" key={tagItem.id}>
                  <Tag
                    tag={tagItem}
                  />
                  <IconCrossRound
                    size="xs"
                    onClick={() => removeTag(tagItem)}
                  />
                </li>
              ))}
            </ul>
            {(inputVisible || filterParams.tagsIds.length > 0) && (
              <div className="c-board-filter__input-wrapper">
                <AutocompleteTags
                  existingTags={tagsArray}
                  onInputKeyDown={handleInputKeyDown}
                  onInputChange={handleInputChange}
                />
              </div>
            )}
          </>
        )}
      </span>
      <Button
        onClick={handleResetFilters}
        size="sm"
        text={I18N.RESET}
      />
    </div>
  );
};

export default BoardFilter;
