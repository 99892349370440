import React from 'react';

import './TaskCardHeader.scss';

const TaskCardHeader = (props) => {
  const {
    title,
    boardIndex,
    onClick,
  } = props;

  return (
    <header
      className="c-task-card-header"
      onClick={onClick}
    >
      <span className="c-task-card-header__board-index">
        {`[${boardIndex}]`}
      </span>
      {' '}
      {title}
    </header>
  );
};

export default TaskCardHeader;
