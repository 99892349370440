import React, { useState } from 'react';

// Component
import {
  IconClock,
} from '../Icons';

// Style
import './TaskEstimationTime.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';
import { useToaster } from '../../hooks/useToast';

// Utils
import {
  convertMinuteToEstimationString,
  convertEstimationStringToMinute,
} from '../../utils/timeUtils';

const TaskEstimationTime = (props) => {
  const {
    data,
    updateActiveTaskMutation,
  } = props;

  const I18N = useI18N();

  const [estimation, setEstimation] = useState(convertMinuteToEstimationString(data.estimation) || '');
  const { showToastError, showToastSuccess } = useToaster();

  const handleChangeEstimation = (event) => {
    setEstimation(event.currentTarget.value);
  };

  const validateTimeData = (value) => {
    const myRegExp = /^(\d{1,2}d\s*)?(\d{1,2}h\s*)?(\d{1,2}m\s*)?$/;
    return myRegExp.test(value);
  };

  const updateEstimation = (minute) => {
    const updatedEstimationTime = {
      estimation: minute,
      id: data.id,
    };
    return updateActiveTaskMutation(updatedEstimationTime);
  };

  const handleUpdateEstimation = () => {
    if (estimation === convertMinuteToEstimationString(data.estimation)) {
      return;
    }
    if (estimation === '' || estimation === '0') {
      setEstimation(null);
      updateEstimation(null);
      return;
    }
    if (!validateTimeData(estimation)) {
      setEstimation(convertMinuteToEstimationString(data.estimation));
      showToastError(I18N.INCORRECT_DATA_FORMAT);
      return;
    }
    showToastSuccess('Estimation saved!');
    const minute = convertEstimationStringToMinute(estimation);
    updateEstimation(minute);
  };

  return (
    <div
      className="c-task-estimation-time"
      data-title={`${I18N.EXAMPLE}: 1d 2h 15m`}
    >
      <div className="c-task__title">{I18N.ESTIMATION}</div>
      <input
        type="text"
        name="estimate"
        value={estimation === null ? '' : estimation}
        onChange={handleChangeEstimation}
        onBlur={handleUpdateEstimation}
        className="c-task-estimation-time__input"
        placeholder={I18N.ENTER_TIME}
      />
      <IconClock size="sm" />
    </div>
  );
};

export default TaskEstimationTime;
