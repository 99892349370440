import React from 'react';

// Styles
import './AuthFormNav.scss';

const AuthFormNav = (props) => {
  const {
    formType = '',
    handleClickLogin,
    handleClickRegister,
    I18N = {},
  } = props;

  return (
    <nav className="c-auth-form-nav">
      <ul className={`c-auth-form-nav__tabs ${formType.toLowerCase()}`}>
        <li className="c-auth-form-nav__tab login" onClick={handleClickLogin}>
          {I18N.SIGN_IN}
        </li>
        <li className="c-auth-form-nav__tab register" onClick={handleClickRegister}>
          {I18N.REGISTER}
        </li>
      </ul>
    </nav>
  );
};

export default AuthFormNav;
