import React from 'react';

// Components
import Heading from '../Heading';
import Paragraph from '../Paragraph';

// Styles
import './Panel.scss';

const Panel = (props) => {
  const {
    title,
    children,
    content,
    backgroundColor,
  } = props;

  const style = backgroundColor ? { backgroundColor } : {};

  return (
    <div className="c-panel" style={style}>
      { title && (
        <Heading type="secondary" text={title} />
      )}
      <div className="c-panel__content">
        { children || (
          <Paragraph text={content} />
        )}
      </div>
    </div>
  );
};

export default Panel;
