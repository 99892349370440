import { selector } from 'recoil';
import filterConfigState from '../atoms/filterConfigAtom';

const filterConfigSelector = selector({
  key: 'filterConfigSelector',
  get: ({ get }) => {
    const { boardId } = get(filterConfigState);
    const filterConfig = get(filterConfigState(boardId));
    return filterConfig;
  },
});

export default filterConfigSelector;
