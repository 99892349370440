import React from 'react';

// Components
import Heading from '../Heading';
import Logo from '../Logo';
import Paragraph from '../Paragraph';

// Constants
import {
  AUTH_FORM_TYPE,
} from '../../config/config';

// Styles
import './AuthFormGreeting.scss';

const AuthFormGreeting = (props) => {
  const { formType = '', I18N = {} } = props;
  const className = 'c-auth-form-greetings';

  const headingText = {
    [AUTH_FORM_TYPE.LOGIN]: I18N.HI_WELCOME_BACK,
    [AUTH_FORM_TYPE.REGISTER]: `${I18N.WELCOME_TO} Arella!`,
  };

  const greetingText = {
    [AUTH_FORM_TYPE.LOGIN]: I18N.ENTER_YOUR_CREDENTIALS,
    [AUTH_FORM_TYPE.REGISTER]: I18N.CREATE_YOUR_ACCOUNT_BELLOW,
  };

  return (
    <div className={className}>
      <Logo imgUrl="/img/logo_circle.png" size="sm" />
      <div className="c-auth-form-greetings_text">
        <Heading text={headingText[formType]} />
        <Paragraph text={greetingText[formType]} />
      </div>
    </div>
  );
};

export default AuthFormGreeting;
