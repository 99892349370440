import { useRecoilState } from 'recoil';
import { appState } from '../atoms';

const useAppStateMutations = () => {
  const [, setState] = useRecoilState(appState);

  const setAppReadyState = () => setState('READY');

  return {
    setAppReadyState,
  };
};

export default useAppStateMutations;
