import React from 'react';
import Icon from '../Icon';

const IconUser = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-user">
      <svg viewBox="0 0 53 60" xmlns="http://www.w3.org/2000/svg">
        <path d="M26.25 30C34.5352 30 41.25 23.2852 41.25 15C41.25 6.71484 34.5352 0 26.25 0C17.9648 0 11.25 6.71484 11.25 15C11.25 23.2852 17.9648 30 26.25 30ZM36.75 33.75H34.793C32.1914 34.9453 29.2969 35.625 26.25 35.625C23.2031 35.625 20.3203 34.9453 17.707 33.75H15.75C7.05469 33.75 0 40.8047 0 49.5V54.375C0 57.4805 2.51953 60 5.625 60H46.875C49.9805 60 52.5 57.4805 52.5 54.375V49.5C52.5 40.8047 45.4453 33.75 36.75 33.75Z" />
      </svg>
    </Icon>
  );
};

export default IconUser;
