import React, { useRef } from 'react';

// Component
import { IconPlus } from '../Icons';

// Constant
import { NOTIFICATION_TYPE } from '../../config/config';

// Style
import './Notification.scss';

const Notification = (props) => {
  const {
    text,
    onClick,
    type = NOTIFICATION_TYPE.SUCCESS,
  } = props;

  const notifyRef = useRef();

  const successClassName = type === NOTIFICATION_TYPE.ERROR ? 'success' : '';
  const errorClassName = type === NOTIFICATION_TYPE.ERROR ? 'error' : '';
  const warningClassName = type === NOTIFICATION_TYPE.WARNING ? 'warning' : '';
  const notificationClassName = `c-notification ${successClassName} ${errorClassName} ${warningClassName}`;

  const handleClick = (event) => {
    notifyRef.current.style.display = 'none';
    if (typeof onClick === 'function') {
      return onClick(event);
    }
    return null;
  };

  return (
    <div ref={notifyRef} className={notificationClassName}>
      <span className="c-notification__text">{text}</span>
      <IconPlus onClick={handleClick} size="xs" />
    </div>
  );
};

export default Notification;
