import React from 'react';
import Icon from '../Icon';

const IconFacebookCustom = (props) => {
  const {
    size,
    inverse,
    onClick,
    role,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-facebook-custom"
      role={role}
    >
      <svg
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V28C0 29.1046 0.89543 30 2 30H28C29.1046 30 30 29.1046 30 28V2C30 0.895431 29.1046 0 28 0H2ZM20.7835 18.4029V29.9999H16.1006V18.4029H12.2031V13.8836H16.1006V10.551C16.1006 6.67688 18.4775 4.57662 21.929 4.57662C23.0961 4.57044 24.2626 4.62788 25.4235 4.74869V8.79739H23.0262C21.1485 8.79739 20.7835 9.69063 20.7835 10.9989V13.8836H25.2638L24.681 18.4029H20.7835Z" />
      </svg>
    </Icon>
  );
};

export default IconFacebookCustom;
