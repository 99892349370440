import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// Hooks
import { useI18N } from '../../i18n/useI18N';
import { useToaster } from '../../hooks/useToast';

// Configs
import { BOARD_PROPERTY } from '../../config/config';

// Components
import BoardColorMark from '../BoardColorMark';
import BoardActions from '../BoardActions';
import BoardInfo from '../BoardInfo';
import BoardPrefix from '../BoardPrefix';
import BoardTeam from '../BoardTeam';
import Panel from '../Panel';
import {
  IconChevronDown,
} from '../Icons';

// Store
import useBoardMutations from '../../store/mutations/useBoardMutations';
import { activeBoardState, userProfileState } from '../../store/atoms';

// Style
import './BoardActionsPanel.scss';

const BoardActionsPanel = (props) => {
  const {
    btnAddUser,
    closeActionsPanel,
    disabledAddUser,
  } = props;

  // Global State
  const activeBoard = useRecoilValue(activeBoardState);
  const userProfile = useRecoilValue(userProfileState);

  // Mutations
  const {
    deleteBoardMutation,
    updateBoardMutation,
    createBoardMutation,
    copyBoardMutation,
    deleteBoardFromFavoriteMutation,
  } = useBoardMutations();

  // State
  const [boardData, setBoardData] = useState(activeBoard);
  const [isChangeInfoMode, setIsChangeInfoMode] = useState(false);
  const [isChangePrefixMode, setIsChangePrefixMode] = useState(false);
  const [isDeleteBoard, setIsDeleteBoard] = useState(false);
  const [isLeaveBoard, setIsLeaveBoard] = useState(false);
  const [isArchiveBoard, setIsArchiveBoard] = useState(false);

  // Component Logic
  const I18N = useI18N();
  const isUserTemp = boardData.users[0].isTemp;

  const { showToastError } = useToaster();

  const isBoardOwner = activeBoard.ownerId === userProfile?.id;

  const navigate = useNavigate();

  const favoriteBoardData = (userProfile?.favoriteBoards)
    ? userProfile.favoriteBoards
      .find((favBoard) => favBoard.boardId === +boardData.id)
    : null;

  const handleChangeBoardField = (event) => {
    const targetName = event.currentTarget.name;
    const targetValue = targetName === 'taskIndexPrefix'
      ? event.currentTarget.value.toUpperCase() : event.currentTarget.value;
    setBoardData({ ...boardData, [targetName]: targetValue });
  };

  const openDeleteBoardModal = () => {
    setIsDeleteBoard(true);
  };

  const closeDeleteBoardModal = () => {
    setIsDeleteBoard(false);
  };

  const openArchiveBoardModal = () => {
    setIsArchiveBoard(true);
  };

  const closeArchiveBoardModal = () => {
    setIsArchiveBoard(false);
  };

  const updateBoard = (data) => {
    if (activeBoard.id === 'new') {
      return createBoardMutation({ ...boardData, id: null });
    }

    if (data.type === BOARD_PROPERTY.PREFIX && !data.value) {
      setBoardData(activeBoard);
      return showToastError(I18N.INCORRECT_DATA_FORMAT);
    }

    return updateBoardMutation({ id: activeBoard.id, [data.type]: data.value });
  };

  const copyAndCreateNewBoard = () => {
    copyBoardMutation(boardData.id, userProfile.id);
  };

  const removeFromFavorites = () => {
    deleteBoardFromFavoriteMutation(activeBoard.id);
  };

  const handleDeleteBoard = () => {
    if (favoriteBoardData?.boardId === boardData.id) {
      removeFromFavorites();
    }
    deleteBoardMutation(activeBoard.id);
    closeDeleteBoardModal();
    navigate('/');
  };

  const applyChangesBoardData = () => {
    if (isChangeInfoMode) {
      return setBoardData({ ...activeBoard, description: boardData.description });
    }

    if (isChangePrefixMode) {
      return setBoardData({ ...activeBoard, taskIndexPrefix: boardData.taskIndexPrefix });
    }

    return setBoardData(activeBoard);
  };

  // Logic LeaveBoardModal
  const openLeaveBoardModal = () => {
    setIsLeaveBoard(true);
  };

  const closeLeaveBoardModal = () => {
    setIsLeaveBoard(false);
  };

  const acceptLeaveBoard = () => {
    updateBoardMutation({ ...boardData, removeUserId: userProfile.id });
    navigate('/');
    closeLeaveBoardModal();
  };

  const changeArchiveStatus = () => {
    if (favoriteBoardData?.boardId === boardData.id) {
      removeFromFavorites();
    }
    updateBoardMutation({ id: boardData.id, isArchive: !activeBoard.isArchive });
    closeArchiveBoardModal();
  };

  useEffect(() => {
    applyChangesBoardData();
  }, [activeBoard]);

  return (
    <Panel>
      <div className="c-board-actions-panel">
        <div className="c-board-actions-panel__wrapper">
          <div className="c-board-actions-panel__actions-header action-title">
            <span className="c-board-actions-panel__title">
              {I18N.BOARD_ACTIONS}
            </span>
            <IconChevronDown
              size="sm"
              onClick={closeActionsPanel}
            />
          </div>
          {!isUserTemp && (
          <BoardTeam
            activeBoard={activeBoard}
            btnAddUser={btnAddUser}
            updateBoardMutation={updateBoardMutation}
            boardData={boardData}
          />
          )}
          <BoardInfo
            isChangeInfoMode={isChangeInfoMode}
            setIsChangeInfoMode={setIsChangeInfoMode}
            updateBoard={updateBoard}
            setBoardData={setBoardData}
            activeBoard={activeBoard}
            boardData={boardData}
            handleChangeBoardField={handleChangeBoardField}
          />
          <BoardPrefix
            isChangePrefixMode={isChangePrefixMode}
            setIsChangePrefixMode={setIsChangePrefixMode}
            boardData={boardData}
            activeBoard={activeBoard}
            updateBoard={updateBoard}
            setBoardData={setBoardData}
            handleChangeBoardField={handleChangeBoardField}
          />
          <BoardColorMark
            boardData={boardData}
            setBoardData={setBoardData}
            updateBoard={updateBoard}
          />
        </div>
        <BoardActions
          acceptLeaveBoard={acceptLeaveBoard}
          changeArchiveStatus={changeArchiveStatus}
          closeDeleteBoardModal={closeDeleteBoardModal}
          closeLeaveBoardModal={closeLeaveBoardModal}
          copyAndCreateNewBoard={copyAndCreateNewBoard}
          handleDeleteBoard={handleDeleteBoard}
          isArchiveBoard={activeBoard.isArchive}
          isBoardOwner={isBoardOwner}
          isDeleteBoard={isDeleteBoard}
          isLeaveBoard={isLeaveBoard}
          isArchiveBoardModal={isArchiveBoard}
          openDeleteBoardModal={openDeleteBoardModal}
          openLeaveBoardModal={openLeaveBoardModal}
          userProfile={userProfile}
          openArchiveBoardModal={openArchiveBoardModal}
          closeArchiveBoardModal={closeArchiveBoardModal}
          disabledAddUser={disabledAddUser}
        />
      </div>
    </Panel>
  );
};

export default BoardActionsPanel;
