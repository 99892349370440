import React from 'react';

// Component
import Button from '../Button';
import {
  IconRegister,
  // IconEntry,
} from '../Icons';

// Style
import './PromoTop.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const PromoTop = (props) => {
  const {
    handleRegister,
    handleLogin,
    token,
    userProfile,
  } = props;

  const I18N = useI18N();

  return (
    <div className="c-promo-top">
      <div className="c-promo__container">
        <div className="c-promo-top__screen" />
        {/* {token && userProfile.isTemp && (
        <div className="c-promo-top__title">
          <span className="c-promo-top__welcome">{I18N.WELCOME_TO}</span>
          <span className="c-promo__logo">
            <strong>ΛRE</strong>
            LLΛ
            <span className="c-promo-top__welcome"> !</span>
          </span>
        </div>
        )} */}
        {!token && (
          <div className="c-promo-top__title">
            <span>{I18N.WELCOME_TO}</span>
            <span className="c-promo-not-user__logo">
              <strong>ΛRE</strong>
              LLΛ
              <span className="c-promo__white">!</span>
            </span>
          </div>
        )}
        {token && (
          <div className="c-promo-top__title">
            <span className="c-promo-top__welcome">{I18N.WELCOME_TO}</span>
            <span className="c-promo__logo">
              <strong>ΛRE</strong>
              LLΛ
              <span className="c-promo-top__welcome"> !</span>
            </span>
          </div>
        )}
        <div className="c-promo-top__description">
          {I18N.MESSAGE_ARELLA_IS_THE_VISUAL_TOOL}
        </div>
        {token && userProfile.isTemp && (
          <Button
            text={I18N.REGISTER}
            iconLeft={<IconRegister size="xl" />}
            size="xl"
            onClick={handleRegister}
            type="brand"
          />
        )}
        {!token && (
          <Button
            text={I18N.SIGN_IN}
            // iconLeft={<IconRegister size="xl" />}
            size="xl"
            onClick={handleLogin}
            type="brand"
          />
        )}
      </div>
    </div>
  );
};

export default PromoTop;
