import React from 'react';
import { useI18N } from '../../../i18n/useI18N';

import Icon from '../Icon';

const IconStar = (props) => {
  const { size, inverse, onClick } = props;
  const I18N = useI18N();

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-star"
      dataTooltipId="panel-tooltip"
      dataTooltipContent={`${I18N.FAVORITE}`}
    >
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.5623 15.8304C3.54273 16.389 2.3511 15.4868 2.54583 14.3036L3.20237 10.3144L0.421211 7.48925C-0.403637 6.65135 0.0515244 5.19148 1.19144 5.01887L5.0349 4.43685L6.75375 0.807342C7.26353 -0.269114 8.73647 -0.269114 9.24625 0.807342L10.9651 4.43685L14.8086 5.01887C15.9485 5.19148 16.4036 6.65135 15.5788 7.48925L12.7976 10.3144L13.4542 14.3036C13.6489 15.4868 12.4573 16.389 11.4377 15.8304L8 13.947L4.5623 15.8304Z" />
      </svg>
    </Icon>
  );
};

export default IconStar;
