import React, { useEffect, useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';

// Store
import { activeBoardState, activeTaskState } from '../../store/atoms';
import useBoardMutations from '../../store/mutations/useBoardMutations';
import useTaskMutations from '../../store/mutations/useTaskMutations';

// Components
import Loader from '../../components/Loader';
import Task from '../../components/Task';
import TaskMobile from '../../components/TaskMobile';

// Style
import './TaskView.scss';

// Context
import LayoutContext from '../../context/LayoutContext';

const TaskView = () => {
  const { boardId, index } = useParams();
  const boardIdParam = parseInt(boardId, 10);
  const [activeTask, setActiveTask] = useRecoilState(activeTaskState);
  const activeBoard = useRecoilValue(activeBoardState);
  const { setActiveBoardById } = useBoardMutations();
  const { setActiveTaskById } = useTaskMutations();
  const { isMobile } = useContext(LayoutContext);

  const showTaskMobile = activeTask && isMobile;
  const showTask = activeTask && !isMobile;
  const showLoader = !activeTask;

  // TODO: Make a check for an active task

  useEffect(() => {
    setActiveBoardById(boardIdParam);

    return () => {
      setActiveTask(null);
    };
  }, []);

  useEffect(() => {
    if (activeBoard && index) {
      const task = activeBoard.tasks.find(
        (item) => item.boardIndex.toLowerCase() === index.toLowerCase(),
      );
      if (task) {
        setActiveTaskById(task.id);
      }
    }
  }, [activeBoard]);

  return (
    <div className="v-task">
      {showTask && (
        <Task
          boardTeam={activeBoard ? activeBoard.users : []}
          boardStatuses={activeBoard ? activeBoard.statuses : []}
          data={activeTask}
          setActiveTask={setActiveTask}
        />
      )}
      {showTaskMobile && (
        <TaskMobile
          boardTeam={activeBoard ? activeBoard.users : []}
          boardStatuses={activeBoard ? activeBoard.statuses : []}
          data={activeTask}
          setActiveTask={setActiveTask}
          isMobile={isMobile}
        />
      )}
      {showLoader && (
        <Loader />
      )}
    </div>
  );
};

export default TaskView;
