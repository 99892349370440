import React from 'react';
import { Link, useMatches } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// Style
import './BreadCrumb.scss';

// Store
import {
  activeBoardState,
  activeTaskState,
} from '../../store/atoms';

const BreadCrumb = () => {
  const MAX_SYMBOLS_LENGTH = 30;

  const matches = useMatches();
  const activeBoard = useRecoilValue(activeBoardState);
  const activeTask = useRecoilValue(activeTaskState);

  const hideCrumbs = matches.filter((match) => match.handle.hideCrumbs);
  if (hideCrumbs.length > 0) return null;

  const crumbs = matches.map((match) => {
    const { handle } = match;
    const { crumb: crumbCallback } = handle;

    let crumbElement;

    const formatBreadcrumbSegment = (value) => {
      const limitTitle = value.length > MAX_SYMBOLS_LENGTH ? `${value.substring(0, MAX_SYMBOLS_LENGTH)}...` : value;
      return limitTitle;
    };

    switch (match.id) {
      case '0-2':
        crumbElement = crumbCallback(formatBreadcrumbSegment(activeBoard?.name || ''));
        break;
      case '0-3':
        if (!activeTask || !activeBoard) return null;
        return (
          <>
            <Link
              className="c-breadcrumb__link"
              key="0-2"
              to={`board/${activeBoard?.id}`}
            >
              {formatBreadcrumbSegment(activeBoard?.name || '')}
            </Link>
            <Link
              className="c-breadcrumb__link"
              key={match.id}
              to={match.pathname}
            >
              {crumbCallback(activeTask?.boardIndex || '')}
            </Link>
          </>
        );
      default:
        crumbElement = crumbCallback();
    }

    return (
      <Link
        className="c-breadcrumb__link"
        key={match.id}
        to={match.pathname}
      >
        {crumbElement}
      </Link>
    );
  });

  return (
    <div className="c-breadcrumb">
      {crumbs.map((crumb) => crumb)}
    </div>
  );
};

export default BreadCrumb;
