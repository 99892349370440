import React from 'react';
import Icon from '../Icon';

const IconQuestionCircle = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-question-circle">
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 0C4.02979 0 0 4.03124 0 9C0 13.9717 4.02979 18 9 18C13.9702 18 18 13.9717 18 9C18 4.03124 13.9702 0 9 0ZM9 16.2581C4.98876 16.2581 1.74194 13.0126 1.74194 9C1.74194 4.9901 4.9889 1.74194 9 1.74194C13.0098 1.74194 16.2581 4.98887 16.2581 9C16.2581 13.0112 13.0126 16.2581 9 16.2581ZM12.8919 6.99677C12.8919 9.43011 10.2637 9.46756 10.2637 10.3668V10.5968C10.2637 10.8373 10.0687 11.0323 9.82825 11.0323H8.17171C7.93121 11.0323 7.73623 10.8373 7.73623 10.5968V10.2825C7.73623 8.98534 8.71969 8.46679 9.46288 8.0501C10.1002 7.69282 10.4908 7.44982 10.4908 6.97667C10.4908 6.35081 9.69242 5.93539 9.047 5.93539C8.20546 5.93539 7.81697 6.33375 7.27087 7.02298C7.12364 7.20878 6.85499 7.24329 6.66606 7.10006L5.65632 6.3344C5.47098 6.19389 5.42947 5.93281 5.56037 5.74058C6.4178 4.48153 7.50992 3.77419 9.21027 3.77419C10.9911 3.77419 12.8919 5.16426 12.8919 6.99677ZM10.5242 13.0645C10.5242 13.905 9.84045 14.5887 9 14.5887C8.15955 14.5887 7.47581 13.905 7.47581 13.0645C7.47581 12.2241 8.15955 11.5403 9 11.5403C9.84045 11.5403 10.5242 12.2241 10.5242 13.0645Z" />
      </svg>
    </Icon>
  );
};

export default IconQuestionCircle;
