import React from 'react';

// Components
import FAQ from '../../components/FAQ';

// Style
import './FAQView.scss';

const FAQView = () => (
  <div className="v-faq">
    <FAQ />
  </div>
);

export default FAQView;
