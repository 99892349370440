import React from 'react';
import Icon from '../Icon/Icon';

const IconStarDisable = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;
  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-star-disable">
      <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.9041 6.85879L18.9044 6.85884C19.4774 6.94132 19.6976 7.63627 19.2899 8.03033L19.2898 8.03047L15.3384 11.8523L15.1482 12.0362L15.1933 12.2969L16.1278 17.6953C16.2231 18.2482 15.6313 18.6858 15.1173 18.4176L15.1172 18.4175L10.2313 15.8684L10 15.7478L9.76873 15.8684L4.88275 18.4175L4.88119 18.4184C4.37078 18.6869 3.77661 18.2514 3.87212 17.6957C3.87213 17.6957 3.87213 17.6956 3.87214 17.6956C3.87215 17.6955 3.87217 17.6954 3.87218 17.6953L4.8067 12.2969L4.85184 12.0362L4.66164 11.8523L0.710244 8.03047L0.710102 8.03033C0.302357 7.63627 0.522623 6.94132 1.09556 6.85884L1.09592 6.85879L6.55759 6.06846L6.81708 6.03091L6.93375 5.79611L9.37478 0.883621C9.37481 0.883558 9.37484 0.883495 9.37487 0.883432C9.62955 0.3718 10.3696 0.372392 10.6254 0.884025C10.6255 0.884136 10.6255 0.884246 10.6256 0.884357L13.0662 5.79611L13.1829 6.03091L13.4424 6.06846L18.9041 6.85879Z" stroke="#F6BE00" />
      </svg>
    </Icon>
  );
};

export default IconStarDisable;
