import React, { useMemo, useState } from 'react';

// Translations
import { useI18N } from '../../i18n/useI18N';

// Utils
import getDate from '../../utils/getDate';
import getDateModal from '../../utils/getDateModal';

// Components
import Button from '../Button';
import { IconCalendar } from '../Icons';

// Style
import './TimeReportFilter.scss';

const TimeReportFilter = (props) => {
  const { userId, getTimeLogList } = props;

  const I18N = useI18N();

  const getFirstDayMonth = (value) => {
    const currentDate = value ? new Date(value) : new Date();

    const month = currentDate.getMonth() < 9 ? `0${currentDate.getMonth() + 1}` : currentDate.getMonth();
    const year = currentDate.getFullYear();

    const formatFirstDayMonth = `${year}-${month}-01`;
    return formatFirstDayMonth;
  };

  const defaultSearchDate = {
    startDate: getFirstDayMonth(),
    endDate: getDateModal(),
  };

  const [searchDate, setSearchDate] = useState(defaultSearchDate);

  const handleChange = (event) => {
    const newSearchDate = {
      ...searchDate,
      [event.target.name]: event.target.value,
    };
    setSearchDate(newSearchDate);
  };

  const handleSubmit = () => {
    getTimeLogList(userId, searchDate);
  };

  const isDisableBtn = useMemo(() => !searchDate.startDate || !searchDate.endDate, [searchDate]);

  return (
    <div className="c-time-report-filter">
      <div className="c-time-report-filter__input-date">
        <span className="c-time-report-filter__input-label">{`${I18N.START} :`}</span>
        <div className="c-time-report-filter__wrapper-input">
          <input
            className="c-time-report-filter__input"
            type="date"
            name="startDate"
            value={searchDate.startDate}
            onChange={handleChange}
          />
          <span className="c-time-report-filter__input-value">{getDate(searchDate.startDate)}</span>
        </div>
        <IconCalendar size="xs" />
      </div>
      <div className="c-time-report-filter__input-date">
        <span className="c-time-report-filter__input-label">{`${I18N.END} :`}</span>
        <div className="c-time-report-filter__wrapper-input">
          <input
            className="c-time-report-filter__input"
            type="date"
            name="endDate"
            value={searchDate.endDate}
            onChange={handleChange}
          />
          <span className="c-time-report-filter__input-value">{getDate(searchDate.endDate)}</span>
        </div>
        <IconCalendar size="xs" />
      </div>
      <Button
        text={I18N.LOAD}
        size="md"
        type="confirm"
        onClick={handleSubmit}
        disabled={isDisableBtn}
      />
    </div>
  );
};

export default TimeReportFilter;
