import React from 'react';

// Style
import './ToDoItem.scss';

const ToDoItem = (props) => {
  const { todoItem, isChecked } = props;

  return (
    <span className={`c-todo-item ${isChecked ? 'selected' : ''} ${todoItem.status === 'DONE' ? 'done' : ''}`}>
      {todoItem.title}
    </span>
  );
};

export default ToDoItem;
