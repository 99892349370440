import React from 'react';
import Icon from '../Icon';

const IconFilter = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-filter">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path d="M15.2492 0H0.750871C0.0846841 0 -0.251472 0.808312 0.220559 1.28034L6 7.06066V13.5C6 13.7447 6.1194 13.9741 6.3199 14.1144L8.8199 15.8638C9.31312 16.2091 10 15.8592 10 15.2494V7.06066L15.7796 1.28034C16.2506 0.80925 15.9168 0 15.2492 0Z" />
      </svg>
    </Icon>
  );
};

export default IconFilter;
