/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useContext,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Tooltip } from 'react-tooltip';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Translations
import { useI18N } from '../../i18n/useI18N';

// Configs
import { POSITION } from '../../config/config';

// Utils
import orderSort from '../../utils/orderSort';
import moveElement from '../../utils/moveElement';
import { createDefaultNewBoardIndex } from '../../utils/boardIndexUtils';

// Components
import AddUserForm from '../AddUserForm';
import BoardActionsPanel from '../BoardActionsPanel';
import BoardButtonBar from '../BoardButtonBar';
import BoardFilter from '../BoardFilter';
import BoardNewMobile from '../BoardNewMobile';
// import Button from '../Button';
import ColorMarkBar from '../ColorMarkBar';
import FilterModal from '../FilterModal';
import Heading from '../Heading';
import Panel from '../Panel';
import StatusCard from '../StatusCard';
import StatusCardAddButton from '../StatusCardAddButton';
import StatusList from '../StatusList';
import Task from '../Task';
import TaskCard from '../TaskCard';
import UserList from '../UserList';
import {
  IconAdditionalActions,
  IconFolder,
  // IconPlus,
  IconSearch,
  IconStar,
  IconStarDisable,
} from '../Icons';

// Store
import useBoardMutations from '../../store/mutations/useBoardMutations';
import useTaskMutations from '../../store/mutations/useTaskMutations';
import {
  activeBoardState,
  activeTaskState,
  activeFiltersState,
} from '../../store/atoms';

// Style
import './Board.scss';

// Context
import LayoutContext from '../../context/LayoutContext';

const Board = (props) => {
  const { favoriteBoardData, setIsDraggableItem } = props;

  const I18N = useI18N();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isMobile } = useContext(LayoutContext);

  const searchParamsToFilterParams = () => {
    const obj = Object.fromEntries(searchParams.entries());
    // eslint-disable-next-line no-restricted-syntax
    for (const key in obj) {
      if (Object.hasOwn(obj, key)) {
        obj[key] = obj[key].split(',').map((item) => {
          if (item === 'noAssignedUser') {
            return null;
          }
          return !Number.isNaN(+item) ? +item : item;
        });
      }
    }
    return obj;
  };

  const filterParamsToSearchParams = (filters) => {
    Object.keys(filters).forEach((key) => {
      const value = filters[key];
      if (Array.isArray(value)) {
        if (value.length === 0) {
          searchParams.delete(key);
        } else {
          searchParams.set(key, value.map((item) => (item === null ? 'noAssignedUser' : item)).join(','));
        }
      } else if (value) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
    });
    setSearchParams(searchParams);
  };

  // Default State
  const defaultFilterParams = {
    userIds: [],
    tagsIds: [],
    priority: [],
  };

  const defaultDraggableData = {
    id: null,
    item: null,
  };

  // Global State
  const activeBoard = useRecoilValue(activeBoardState);
  const [activeTask, setActiveTask] = useRecoilState(activeTaskState);
  const [filterState, setFilterState] = useRecoilState(activeFiltersState(activeBoard.id));

  // Mutations
  const {
    updateBoardMutation,
    createBoardMutation,
    reorderStatusesMutation,
    addBoardToFavoriteMutation,
    deleteBoardFromFavoriteMutation,
  } = useBoardMutations();

  const {
    createNewTaskMutation,
    reorderTasksMutation,
  } = useTaskMutations();

  // State
  const [tmpStatus, setTmpStatus] = useState(null);
  const [isFilterModalOpen, setFilterModalOpen] = useState(false);
  const [showAddUserForm, setShowAddUserForm] = useState(false);

  const [isVisibleModal, setIsVisibleModal] = useState(activeBoard.id === 'new' && isMobile);

  const params = filterState && !searchParams.size
    ? { ...defaultFilterParams, ...filterState }
    : { ...defaultFilterParams, ...searchParamsToFilterParams() };

  const [draggableItem, setDraggableItem] = useState(defaultDraggableData);
  const [filterParams, setFilterParams] = useState(params);
  const [isActionsMode, setIsActionsMode] = useState(false);
  const [isEditTitle, setIsEditTitle] = useState(activeBoard.id === 'new');
  const [isSaveHeading, setIsSaveHeading] = useState(false);

  const [boardData, setBoardData] = useState(activeBoard);
  const [filteredTasks, setFilteredTasks] = useState(boardData.tasks);
  const [boardStatuses, setBoardStatuses] = useState(boardData.statuses);

  const getDefaultBoardsState = (boardStat) => {
    let obj = {};
    boardStat.forEach((boardStatus) => {
      obj = { ...obj, [boardStatus.id]: false };
    });
    return obj;
  };

  const defaultBoardsState = useMemo(
    () => getDefaultBoardsState(boardData.statuses),
    [boardData.statuses],
  );

  const [boardsState, setBoardsState] = useState(defaultBoardsState);
  const [word, setWord] = useState('');

  const isUserTemp = boardData.users[0].isTemp;

  const isTaskInStatus = (taskStatus) => {
    const tasksInStatus = filteredTasks.filter((task) => task.statusId === taskStatus.id);
    return orderSort(tasksInStatus);
  };

  const highestOrderStatus = activeBoard.statuses.length
    ? orderSort(activeBoard.statuses).at(-1).order + 1
    : 0;

  const refStatusList = useRef();
  const refBoard = useRef();

  const onToggleBoardState = (boardId) => {
    setBoardsState((prev) => ({ ...prev, [boardId]: !boardsState[boardId] }));
  };

  const isBoardOpen = (boardId) => boardsState[boardId];

  const handleChangeSearchTask = (e) => {
    setWord(e.target.value);
  };

  // was bug
  const onSearchTask = (w) => {
    const findTasks = boardData.tasks
      .filter((task) => task.title.toLowerCase().includes(w.toLowerCase()));

    const newBoardsState = findTasks.reduce((acc, task) => {
      acc[task.statusId] = true;
      return acc;
    }, {});

    const newBoardStatuses = boardData.statuses
      .filter((boardStatus) => !!boardStatus.id === newBoardsState[boardStatus.id]);

    setBoardStatuses(newBoardStatuses);
    setFilteredTasks(findTasks);
    setBoardsState((prev) => ({ ...prev, ...newBoardsState }));
  };

  const updateBoardTitle = () => {
    if (boardData.id === 'new') {
      createBoardMutation({ ...boardData, id: null });
      setBoardData({ ...boardData, id: null });
    } else {
      updateBoardMutation({ id: activeBoard.id, name: boardData.name });
    }

    setIsSaveHeading(false);
  };

  const saveHeading = () => {
    setIsSaveHeading(true);
    setIsEditTitle(!isEditTitle);
  };

  const createTask = () => {
    const firstStatus = orderSort(activeBoard.statuses)[0];
    const tasksInFirstStatus = activeBoard.tasks
      ? orderSort(activeBoard.tasks).filter((tasks) => tasks.statusId === firstStatus.id)
      : [];
    const lastTask = tasksInFirstStatus.at(-1);

    const newBoardIndex = createDefaultNewBoardIndex(
      boardData.taskIndexCounter,
      boardData.taskIndexPrefix,
    );

    const newTaskIndexCounter = Number(newBoardIndex.replace(`${boardData.taskIndexPrefix}-`, ''));

    createNewTaskMutation({
      boardId: boardData.id,
      statusId: firstStatus.id,
      boardIndex: newBoardIndex,
      taskIndexCounter: newTaskIndexCounter,
      order: lastTask ? lastTask.order + 1 : 0,
    });
  };

  const changeBoardName = (event) => {
    setBoardData({ ...boardData, name: event.currentTarget.value });
  };

  const changeBoardDescription = (event) => {
    setBoardData({ ...boardData, description: event.currentTarget.value });
  };

  const handleTmpStatus = (setDate) => {
    setTmpStatus(setDate);
  };

  const applyFilter = () => {
    let newFilter = boardData.tasks || [];
    // eslint-disable-next-line no-restricted-syntax
    for (const key in filterParams) {
      // eslint-disable-next-line no-continue
      if (filterParams[key].length === 0) continue;
      newFilter = newFilter.map((task) => {
        let filtered = false;
        if (key === 'userIds') {
          if (!filterParams[key].includes(task.assignedToUserId)) {
            filtered = true;
          }
        }
        if (key === 'tagsIds') {
          // eslint-disable-next-line no-restricted-syntax
          for (const param of filterParams[key]) {
            if (task.tags.length === 0) {
              filtered = true;
              break;
            }
            // eslint-disable-next-line no-restricted-syntax
            const paramMatches = task.tags.some((tag) => String(param) === tag.name);

            if (!paramMatches) {
              filtered = true;
              break;
            }
          }
        }

        if (key === 'priority') {
          if (!filterParams[key].includes(task.priority)) {
            filtered = true;
          }
        }
        return { ...task, filtered };
      }).filter((task) => !task.filtered);
    }

    setFilteredTasks(newFilter);
  };

  const handleFilterUsers = (id) => {
    if (filterParams.userIds.includes(id)) {
      const updatedUserIds = filterParams.userIds.filter((item) => item !== id);
      setFilterParams(
        {
          ...filterParams,
          userIds: updatedUserIds,
        },
      );
      setFilterState((prevParams) => ({
        ...prevParams,
        userIds: updatedUserIds,
      }));
    } else {
      const updatedUserIds = filterParams.userIds.concat(id);
      setFilterParams({ ...filterParams, userIds: updatedUserIds });
      setFilterState({ ...filterState, userIds: updatedUserIds });
    }
  };

  const filtrationTaskUser = (taskOfUser) => (filterParams.userIds.length
    ? !filterParams.userIds.includes(taskOfUser)
    : false);

  const updateStatusOrder = (newStatuses) => {
    const sortedStatuses = newStatuses
      .map((status, index) => (status.order !== index ? { ...status, order: index } : []))
      .flat();

    const newStatusesSequence = boardStatuses.map((boardStatus) => {
      const finderStatus = sortedStatuses.find((status) => status.id === boardStatus.id);
      if (finderStatus) {
        return finderStatus;
      }

      return boardStatus;
    });

    const statusesData = sortedStatuses.map((status) => ({ id: status.id, order: status.order }));

    setBoardStatuses(newStatusesSequence);
    reorderStatusesMutation(statusesData);
    setIsDraggableItem(false);
  };

  const handleDragStartBoard = (e) => {
    if (!e.target.classList.contains('c-task-card')) {
      e.preventDefault();
    }
  };

  const handleDragStartStatus = (e, statusId) => {
    if (isMobile) return;
    setIsDraggableItem(true);
    setDraggableItem({
      item: 'status',
      id: statusId,
    });

    const statusList = refStatusList.current;
    const board = refBoard.current;

    const statusNodes = [...statusList.childNodes].slice(0, -1);
    const sortedStatuses = orderSort(boardStatuses);

    const newStatusesSequence = sortedStatuses.filter((status) => status.id !== statusId);

    const dragData = sortedStatuses.find((status) => status.id === statusId);
    let statusIndex = sortedStatuses.indexOf(dragData);

    const dragStatus = statusNodes[statusIndex];
    const restDraggableStatuses = statusNodes.slice(statusIndex + 1);
    const notDragonStatuses = statusNodes.filter((_, index) => index !== statusIndex);

    const statusListPosition = statusList.getBoundingClientRect();
    let dragStatusPosition = dragStatus.getBoundingClientRect();

    const indentationStatuses = window.getComputedStyle(statusNodes[0]).marginRight;
    const space = parseInt(indentationStatuses, 10);

    const scrollOffset = 120;

    const x = e.clientX;
    const y = e.clientY;

    board.classList.toggle('dragging-status');

    dragStatus.style.position = 'fixed';
    dragStatus.style.zIndex = 5000;
    dragStatus.style.width = `${(dragStatusPosition.width)}px`;
    dragStatus.style.height = `${dragStatusPosition.height}px`;
    dragStatus.style.top = `${dragStatusPosition.top}px`;
    dragStatus.style.left = `${dragStatusPosition.left}px`;

    document.body.style.cursor = 'grabbing';

    const emptyStatus = document.createElement('section');
    emptyStatus.style.minWidth = `${dragStatusPosition.width}px`;
    emptyStatus.style.height = `${dragStatusPosition.height}px`;
    emptyStatus.style.pointerEvents = 'none';
    statusList.appendChild(emptyStatus);

    const distance = dragStatusPosition.width + space;

    restDraggableStatuses.forEach((status) => {
      status.style.transform = `translateX(${distance}px)`;
    });

    const handleDragMove = (event) => {
      if (isMobile) return;
      dragStatusPosition = dragStatus.getBoundingClientRect();

      const posX = event.clientX - x;
      const posY = event.clientY - y;

      dragStatus.style.transform = `translate(${posX}px, ${posY}px)`;

      notDragonStatuses.forEach((status) => {
        const statusPosition = status.getBoundingClientRect();

        const isOverLapping = dragStatusPosition.x < statusPosition.x + statusPosition.width / 2
                && dragStatusPosition.x + dragStatusPosition.width / 2 > statusPosition.x;

        if (dragStatusPosition.left < statusListPosition.left) {
          status.style.transform = `translateX(${distance}px)`;
          statusIndex = 0;
          statusList.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        }

        if (dragStatusPosition.right > statusListPosition.right
          || dragStatusPosition.right > (statusPosition.width + space) * statusNodes.length) {
          status.style.transform = null;
          statusIndex = newStatusesSequence.length;
          statusList.scrollTo({
            left: statusList.scrollWidth,
            behavior: 'smooth',
          });
        }

        if (isOverLapping) {
          if (status.style.transform) {
            status.style.transform = null;
            statusIndex++;
            statusList.scrollBy({
              left: scrollOffset,
              behavior: 'smooth',
            });
          } else {
            status.style.transform = `translateX(${distance}px)`;
            statusIndex--;
            statusList.scrollBy({
              left: -scrollOffset,
              behavior: 'smooth',
            });
          }
        }
      });
    };

    const handleDragEnd = () => {
      if (isMobile) return;
      setDraggableItem(defaultDraggableData);

      board.classList.toggle('dragging-status');
      statusList.removeChild(emptyStatus);

      document.onpointerup = null;
      document.onpointermove = null;
      document.body.style = null;
      dragStatus.style = null;

      statusNodes.forEach((status) => {
        status.style = null;
      });

      newStatusesSequence.splice(statusIndex, 0, dragData);

      const isVariousStatuses = sortedStatuses
        .some((status, index) => status.id === dragData.id && index !== statusIndex);

      if (isVariousStatuses) {
        updateStatusOrder(newStatusesSequence);
      }
    };

    document.onpointerup = handleDragEnd;
    document.onpointermove = handleDragMove;
  };

  const updateTaskOrder = (tasks, isFilteredTasks) => {
    let modifiedCards = [];
    const newTaskOrderInFilter = filteredTasks.map((filteredTask) => {
      const finderTask = tasks.find((task) => task.id === filteredTask.id);
      if (finderTask
          && (finderTask.order !== filteredTask.order
          || finderTask.statusId !== filteredTask.statusId)) {
        modifiedCards.push(finderTask);
        return finderTask;
      }

      return filteredTask;
    });

    if (isFilteredTasks) {
      modifiedCards = [];
      const newTaskOrder = boardData.tasks.map((filteredTask) => {
        const finderTask = tasks.find((task) => task.id === filteredTask.id);
        if (finderTask
            && (finderTask.order !== filteredTask.order
            || finderTask.statusId !== filteredTask.statusId)) {
          modifiedCards.push(finderTask);
          return finderTask;
        }

        return filteredTask;
      });
      setBoardData({ ...boardData, tasks: newTaskOrder });
    }

    const tasksData = modifiedCards.map((task) => ({
      id: task.id,
      order: task.order,
      statusId: task.statusId,
    }));

    setFilteredTasks(newTaskOrderInFilter);
    reorderTasksMutation(tasksData);
    setIsDraggableItem(false);
  };

  const handleDragStartTask = (e, taskId) => {
    if (isMobile) return;
    const STATUS_ELEMENT_COUNT = 2;

    const {
      target,
      currentTarget: currentCardNode,
      clientX: startingCoordinateX,
      clientY: startingCoordinateY,
    } = e;

    if (target.closest('.c-task-card-header')) {
      return;
    }
    setIsDraggableItem(true);
    setDraggableItem({
      item: 'task',
      id: taskId,
    });

    const { parentElement } = currentCardNode;
    const originalStatusNode = parentElement;
    let currentStatusNode = parentElement;
    let listCurrentTaskNodes = [...currentStatusNode.childNodes].slice(STATUS_ELEMENT_COUNT);

    const draggableData = filteredTasks.find((task) => task.id === taskId);
    const originalStatus = boardStatuses.find((status) => status.id === draggableData.statusId);

    const sortedStatuses = orderSort(boardStatuses);
    const statusListNode = refStatusList.current;
    const statusListBoundingInfo = statusListNode.getBoundingClientRect();

    const listStatusNodes = [...statusListNode.childNodes].slice(0, -1);

    let cardBoundingInfo = currentCardNode.getBoundingClientRect();

    const emptyCardNode = currentCardNode.cloneNode(false);
    emptyCardNode.style.height = `${cardBoundingInfo.height}px`;
    emptyCardNode.style.width = `${cardBoundingInfo.width}px`;
    emptyCardNode.classList.toggle('empty-card');
    parentElement.append(emptyCardNode);

    currentCardNode.classList.toggle('draggable');
    currentCardNode.style.top = `${cardBoundingInfo.top}px`;
    currentCardNode.style.left = `${cardBoundingInfo.left}px`;
    currentCardNode.style.width = `${cardBoundingInfo.width}px`;

    document.body.style.userSelect = 'none';
    document.body.style.cursor = 'grabbing';

    let nonDraggableCards = listCurrentTaskNodes
      .filter((node) => !node.isEqualNode(currentCardNode) && !node.isEqualNode(emptyCardNode));

    listCurrentTaskNodes.forEach((node, index) => {
      if (node.isEqualNode(currentCardNode)) {
        emptyCardNode.style.order = index;
      } else {
        node.style.order = index;
      }
    });

    const dragMove = (event) => {
      const {
        clientX: currentCoordinateX,
        clientY: currentCoordinateY,
        movementX,
        movementY,
      } = event;
      moveElement(currentCardNode, {
        currentCoordinateX,
        currentCoordinateY,
        startingCoordinateX,
        startingCoordinateY,
      });

      currentCardNode.hidden = true;
      const elemBelow = document.elementFromPoint(event.clientX, event.clientY);
      currentCardNode.hidden = false;

      cardBoundingInfo = currentCardNode.getBoundingClientRect();

      if (currentStatusNode) {
        const statusBoundingInfo = currentStatusNode.getBoundingClientRect();

        const isShiftRight = (movementX > 0
          && cardBoundingInfo.right >= statusBoundingInfo.left
          && statusBoundingInfo.right >= statusListBoundingInfo.right)
          || (cardBoundingInfo.right > statusListBoundingInfo.right);

        if (isShiftRight) {
          statusListNode.scrollBy({
            left: statusBoundingInfo.width,
            behavior: 'smooth',
          });
        }

        const isShiftLeft = (movementX < 0
          && cardBoundingInfo.left < statusBoundingInfo.right
          && statusBoundingInfo.left < statusListBoundingInfo.left)
          || (cardBoundingInfo.left < statusListBoundingInfo.left);

        if (isShiftLeft) {
          statusListNode.scrollBy({
            left: -statusBoundingInfo.width,
            behavior: 'smooth',
          });
        }

        if (cardBoundingInfo.bottom > document.documentElement.clientHeight && movementY > 0) {
          window.scrollBy({
            top: cardBoundingInfo.height,
            behavior: 'smooth',
          });
        }

        if (cardBoundingInfo.top < 70 && movementY < 0) {
          window.scrollBy({
            top: -cardBoundingInfo.height,
            behavior: 'smooth',
          });
        }
      }

      if (elemBelow) {
        const newStatusNode = elemBelow.closest(`.${parentElement.className}`);

        if (newStatusNode
            && newStatusNode.classList.contains(`${parentElement.className.trim()}`)
            && !newStatusNode.isEqualNode(currentStatusNode)
        ) {
          emptyCardNode.remove();
          newStatusNode.append(emptyCardNode);

          nonDraggableCards.forEach((node) => {
            node.style = null;
          });

          const newCards = [...newStatusNode.childNodes].slice(STATUS_ELEMENT_COUNT);
          listCurrentTaskNodes = newCards.filter((node) => !node.isEqualNode(currentCardNode));
          listCurrentTaskNodes.forEach((node, index) => {
            node.style.order = index;
          });
          nonDraggableCards = listCurrentTaskNodes
            .filter((node) => !node.isEqualNode(currentCardNode)
              && !node.isEqualNode(emptyCardNode));

          currentStatusNode = newStatusNode;
        }

        if (!newStatusNode) {
          emptyCardNode.remove();
          currentStatusNode = null;
        }
      }

      nonDraggableCards.forEach((card) => {
        const nonDragCardBoundingInfo = card.getBoundingClientRect();

        const isOverlapByY = (cardBoundingInfo.y < nonDragCardBoundingInfo.y
            + nonDragCardBoundingInfo.height / 2) && (cardBoundingInfo.y
              + cardBoundingInfo.height / 2 > nonDragCardBoundingInfo.y);

        if (isOverlapByY) {
          const orderCardMoved = getComputedStyle(card).order;
          const currentOrder = getComputedStyle(emptyCardNode).order;

          nonDraggableCards.forEach((node, index) => {
            if (Number(currentOrder) < Number(orderCardMoved)
              && index < Number(orderCardMoved)) {
              node.style.order = index;
            }

            if (Number(currentOrder) > Number(orderCardMoved)
              && index + 1 > Number(orderCardMoved)) {
              node.style.order = index + 1;
            }
          });
          emptyCardNode.style.order = orderCardMoved;
        }
      });
    };

    const handleDragEnd = () => {
      setDraggableItem(defaultDraggableData);

      const changedCardNode = [];
      let response = [];

      document.onpointerup = null;
      document.onpointermove = null;
      document.body.style = null;

      const statusIndex = listStatusNodes.indexOf(currentStatusNode);
      const newStatus = sortedStatuses[Number(statusIndex)];

      const { order: draggableTaskOrder } = getComputedStyle(emptyCardNode);
      const draggableTaskIndex = [...originalStatusNode.childNodes]
        .slice(STATUS_ELEMENT_COUNT)
        .indexOf(currentCardNode);

      currentCardNode.classList.remove('draggable');
      currentCardNode.style = null;
      currentCardNode.style.order = draggableTaskOrder;
      emptyCardNode.remove();

      if (!newStatus
          || (newStatus?.id === originalStatus.id
              && Number(draggableTaskOrder) === draggableTaskIndex)) {
        listCurrentTaskNodes.forEach((node) => {
          node.style = null;
        });
        setIsDraggableItem(false);
        return;
      }

      [...currentStatusNode.childNodes].slice(STATUS_ELEMENT_COUNT).forEach((node, index) => {
        const { order: updatedOrder } = getComputedStyle(node);
        if (Number(updatedOrder) !== index
            && !node.isEqualNode(currentCardNode) && !node.isEqualNode(emptyCardNode)) {
          changedCardNode.push({
            oldOrder: index,
            newOrder: Number(updatedOrder),
            statusId: newStatus.id,
          });
        }
      });

      [...currentStatusNode.childNodes].slice(STATUS_ELEMENT_COUNT).forEach((node) => {
        node.style = null;
      });

      const filteredTasksByStatusId = filteredTasks.filter(
        (task) => task.statusId === newStatus.id,
      );

      const tasksByStatusId = boardData.tasks.filter(
        (task) => task.statusId === newStatus.id,
      );

      const sortedFilteredTasksByStatusId = orderSort(filteredTasksByStatusId);
      const sortedTasksByStatusId = orderSort(tasksByStatusId);

      const firstChangedTask = (draggableTaskOrder > draggableTaskIndex
          && newStatus.id === originalStatus.id)
        ? changedCardNode.at(-1) : changedCardNode.at(0);

      if (firstChangedTask) {
        const firstChangedTaskData = sortedFilteredTasksByStatusId.find(
          (_, index) => index === firstChangedTask.oldOrder,
        );

        let firstChangedTaskIndex = null;

        sortedTasksByStatusId.forEach((task, index) => {
          if (task.id === firstChangedTaskData.id) {
            firstChangedTaskIndex = index;
          }
        });

        response = [...sortedTasksByStatusId]
          .filter((task) => task.id !== taskId);

        response.splice(firstChangedTaskIndex, 0, { ...draggableData, statusId: newStatus.id });

        response = response.map((task, index) => ({ ...task, order: index }));
      } else {
        response = [...sortedTasksByStatusId]
          .filter((task) => task.id !== taskId);
        response.push({
          ...draggableData,
          order: tasksByStatusId.length,
          statusId: newStatus.id,
        });
        response = response.map((task, index) => ({ ...task, order: index }));
      }

      updateTaskOrder(response, filteredTasks.length !== boardData.tasks.length);
    };

    document.onpointermove = dragMove;
    document.onpointerup = handleDragEnd;
  };

  const resetFilters = () => {
    setFilterParams(defaultFilterParams);
    setFilterState(defaultFilterParams);
  };

  const toggleActionsMode = () => {
    refBoard.current.classList.toggle('actions-mode');
    refBoard.current.classList.toggle('actions-mode_open');

    if (!refBoard.current.classList.contains('actions-mode_open')) {
      refBoard.current.classList.add('actions-mode_close');
    } else {
      refBoard.current.classList.remove('actions-mode_close');
    }

    setIsActionsMode(!isActionsMode);
  };

  const toggleFilterModal = () => {
    setFilterModalOpen(!isFilterModalOpen);
  };

  const createNewStatus = () => {
    setTmpStatus(true);

    if (isActionsMode) {
      toggleActionsMode();
    }
  };

  const closeFilterModal = () => {
    setFilterModalOpen(!isFilterModalOpen);
  };

  const toggleEditTitle = () => {
    setIsEditTitle(!isEditTitle);
  };

  const openAddUserFormModal = () => {
    setShowAddUserForm(true);
  };

  const closeAddUserFormModal = () => {
    setShowAddUserForm(false);
  };

  const updatePriorityFilter = (event) => {
    const { value } = event.target;
    const updatedPriority = filterParams.priority.includes(value)
      ? filterParams.priority.filter((param) => param !== value)
      : [...filterParams.priority, value];

    setFilterParams((prevParams) => ({
      ...prevParams,
      priority: updatedPriority,
    }));
    setFilterState((prevParams) => ({
      ...prevParams,
      priority: updatedPriority,
    }));
  };

  const updateTagsFilter = (tag) => {
    const updateFilterTags = filterParams.tagsIds.some((param) => String(param) === tag)
      ? filterParams.tagsIds.filter((param) => String(param) !== tag)
      : [...filterParams.tagsIds, tag];

    setFilterParams((prevParams) => ({
      ...prevParams,
      tagsIds: updateFilterTags,
    }));
    setFilterState((prevParams) => ({
      ...prevParams,
      tagsIds: updateFilterTags,
    }));
  };

  const removeFromFavorites = () => {
    deleteBoardFromFavoriteMutation(activeBoard.id);
  };

  useEffect(() => {
    const iconEdit = document.querySelector('.c-icon-edit:first-child');
    if (iconEdit && isEditTitle) {
      iconEdit.click();
    }

    if (!isEditTitle) {
      setBoardData(activeBoard);
    } else {
      setBoardData({ ...activeBoard, name: boardData.name });
    }
    setBoardStatuses(activeBoard.statuses);

    if (isSaveHeading) {
      setBoardData({ ...boardData, name: boardData.name });
      updateBoardTitle();
    }
  }, [activeBoard, isEditTitle]);

  useEffect(() => {
    applyFilter();
    filterParamsToSearchParams(filterParams);
  }, [filterParams, boardData]);

  useEffect(() => {
    let timeoutId;
    if (!word.trim().length) {
      setFilteredTasks(boardData.tasks);
      setBoardStatuses(boardData.statuses);
      setBoardsState(getDefaultBoardsState(boardData.statuses));
    } else {
      timeoutId = setTimeout(() => {
        onSearchTask(word);
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  }, [word]);

  if (isMobile && boardData.id === 'new') {
    const onCloseCreateBoardModal = () => {
      setIsVisibleModal(false);
      navigate('/');
    };
    const onCreateBoardHandler = () => {
      setBoardData({ ...activeBoard, name: boardData.name, description: boardData.description });
      saveHeading();
      setIsVisibleModal(false);
    };
    return (
      <BoardNewMobile
        boardData={boardData}
        isVisibleModal={isVisibleModal}
        onClose={onCloseCreateBoardModal}
        onCreate={onCreateBoardHandler}
        changeBoardName={changeBoardName}
        changeBoardDescription={changeBoardDescription}
        setIsEditTitle={() => setIsEditTitle(true)}
      />
    );
  }

  return (
    <section
      ref={refBoard}
      className="c-board"
      onDragStart={handleDragStartBoard}
    >
      <Panel>
        <div className="c-board-search">
          <IconSearch />
          <input
            className="c-board-search__input"
            name="searchTask"
            type="text"
            onChange={handleChangeSearchTask}
            placeholder={I18N.SEARCH_TASK}
            value={word}
          />
        </div>
        <ColorMarkBar color={activeBoard.colorMark} position={POSITION.TOP} />
        <Tooltip id="panel-tooltip" className="panel-tooltip" />
        <header className="c-board__header">
          {isMobile && (
            <div className="c-board__type-board">
              {activeBoard.isArchive && (<IconFolder size="sm" />)}
              {(favoriteBoardData && !activeBoard.isArchive) && <IconStar size="sm" onClick={removeFromFavorites} />}
              {(!favoriteBoardData && !activeBoard.isArchive) && <IconStarDisable size="sm" onClick={() => addBoardToFavoriteMutation(activeBoard.id)} />}
            </div>
          )}
          <Heading
            type="secondary"
            text={boardData.name}
            onChange={changeBoardName}
            onReset={toggleEditTitle}
            onSave={saveHeading}
            onFocus={() => setIsEditTitle(true)}
            editable
            maxSymbol={isMobile ? '28' : '60'}
          />
          {isMobile && <IconAdditionalActions size="sm" onClick={toggleActionsMode} />}
          {!isEditTitle && (
          <div className="c-additional-actions">
            {(favoriteBoardData && !activeBoard.isArchive) && (
              <IconStar size="md" onClick={removeFromFavorites} />
            )}
            {activeBoard.isArchive && (
              <IconFolder
                size="md"
              />
            )}
            {boardData.users?.length <= 5 && (
              <UserList
                users={boardData.users}
                size={isMobile ? 'sm' : 'md'}
                onClick={handleFilterUsers}
                maxAmount={5}
                isNotAssignedUser
                isDisableAddUser={isUserTemp}
              />
            )}
            <BoardButtonBar
              btnCreateTask={createTask}
              btnCreateNewStatus={createNewStatus}
              btnAddUserForm={openAddUserFormModal}
              btnShowActions={toggleActionsMode}
              btnAddFilter={toggleFilterModal}
              btnAddFavoriteBoard={() => addBoardToFavoriteMutation(activeBoard.id)}
              disabledAddUser={isUserTemp}
              disabledAddTask={!boardData.statuses?.length}
              isActionsMode={isActionsMode}
              isAccessToMarkFavorites={boardData.id !== 'new' && !favoriteBoardData && !activeBoard.isArchive}
            />
          </div>
          )}
        </header>
        {/* <Tooltip id="user-list-tooltip" className="user-list-tooltip" /> */}
        {(boardData.users?.length > 5 || isMobile) && (
          <div className="c-board__users-panel">
            <UserList
              users={boardData.users}
              size={isMobile ? 'sm' : 'md'}
              onClick={handleFilterUsers}
              maxAmount={boardData.users?.length}
              setShowUserFormModal={openAddUserFormModal}
              isNotAssignedUser
              isDisableAddUser={isUserTemp}
            />
          </div>
        )}
        <BoardFilter
          onResetFilters={resetFilters}
          filterParams={filterParams}
          setFilterParams={setFilterParams}
          onClick={handleFilterUsers}
          boardData={boardData}
          updatePriorityFilter={updatePriorityFilter}
          searchParams={searchParams}
          updateTagsFilter={updateTagsFilter}
        />
        <StatusList refStatusList={refStatusList}>
          {orderSort(boardStatuses).map((taskStatus) => (
            <StatusCard
              tmpStatus={handleTmpStatus}
              boardId={boardData.id}
              key={taskStatus.id}
              data={taskStatus}
              order={taskStatus.order}
              onPointerDown={handleDragStartStatus}
              isDraggable={draggableItem.id === taskStatus.id && draggableItem.item === 'status'}
              isOpen={isBoardOpen(taskStatus.id)}
              setIsOpen={() => onToggleBoardState(taskStatus.id)}
            >
              {(isBoardOpen(taskStatus.id) || !isMobile) && (
                <>
                  {orderSort(filteredTasks)?.map((task) => task.statusId === taskStatus.id && (
                    <div
                      className="c-board__task"
                      key={task.id}
                      data={task}
                      hidden={filtrationTaskUser(task.assignedToUserId)}
                      onPointerDown={(e) => handleDragStartTask(e, task.id)}
                    >
                      <TaskCard
                        key={task.id}
                        data={task}
                        boardTeam={boardData.users}
                        isDraggable={draggableItem.id === task.id && draggableItem.item === 'task'}
                        isMobile={isMobile}
                        boardId={boardData.id}
                      />
                    </div>
                  ))}
                  {isTaskInStatus(taskStatus).length < 1 && isMobile && (
                    <div className="c-status-card__empty-mobile">{I18N.NO_TASKS}</div>
                  )}
                  {/* {isMobile && (
                  <Button
                    text={I18N.ADD_TASK}
                    iconLeft={<IconPlus />}
                    size="sm"
                    onClick={createTask}
                    disabled={!boardData.statuses?.length}
                  />
                  )} */}
                </>
              )}
            </StatusCard>
          ))}
          {tmpStatus && (
            <StatusCard
              tmpStatus={handleTmpStatus}
              boardId={boardData.id}
              boardTeam={boardData.users}
              order={highestOrderStatus}
              isNewStatus
            />
          )}
          {!tmpStatus && (
            <StatusCardAddButton
              handleCreateNewStatus={createNewStatus}
            />
          )}
        </StatusList>
      </Panel>
      {isFilterModalOpen && (
        <FilterModal
          boardData={boardData}
          closeFilterModal={closeFilterModal}
        />
      )}
      <BoardActionsPanel
        closeActionsPanel={toggleActionsMode}
        btnAddUser={openAddUserFormModal}
        disabledAddUser={isUserTemp}
      />
      {activeTask && !isMobile && (
        <Task
          boardTeam={boardData.users}
          data={activeTask}
          isModalMode
          setActiveTask={setActiveTask}
          boardStatuses={activeBoard.statuses}
        />
      )}
      {showAddUserForm && (
        <AddUserForm
          activeBoard={activeBoard}
          closeForm={closeAddUserFormModal}
        />
      )}
    </section>
  );
};

export default Board;
