import React from 'react';

// Translate
import { useI18N } from '../../i18n/useI18N';

// Component
import Modal from '../Modal';

// Style
import './TaskWarning.scss';

const TaskWarning = (props) => {
  const { acceptChange, closeWarning, data } = props;

  const I18N = useI18N(data.optionsTranslate);

  return (
    <Modal
      className="changed-task-index"
      isVisible
      onClose={closeWarning}
      title={I18N[data.keyTitle]}
      content={I18N[data.keyDescription]}
      btnCancelText={I18N.ACCEPT}
      btnAcceptText={I18N.CANCEL}
      btnAcceptHandler={closeWarning}
      btnCancelHandler={acceptChange}
      type="WARNING"
    />
  );
};

export default TaskWarning;
