import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

// Store
import { sessionState, userProfileState } from '../../store/atoms';
import useUserProfileMutations from '../../store/mutations/useUserProfileMutations';

// Context
import LayoutContext from '../../context/LayoutContext';

// Configs
import { AUTH_FORM_TYPE, AUTH_STATUS, PAGE } from '../../config/config';

// Components
import HeaderLayout from '../HeaderLayout';
import FooterLayout from '../FooterLayout';

// Style
import './ErrorLayout.scss';

const ErrorLayout = (props) => {
  const { children } = props;

  const { logoutUser } = useUserProfileMutations();

  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['onboardingApp', 'recent-page']);

  // Global store
  const userProfile = useRecoilValue(userProfileState);
  const [session, setSession] = useRecoilState(sessionState);

  // Local store
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = { isMobile };

  // Component logic
  const signOutUser = () => {
    logoutUser();
  };

  const handleLogin = () => {
    navigate(`/${PAGE.AUTH.PATH}`);
  };

  const handleRegister = () => {
    setSession({
      ...session,
      authStatus: AUTH_STATUS.NOT_AUTH,
    });
    if (!cookies.onboardingApp) {
      setCookie('onboardingApp', true, { path: '/', maxAge: 365 * 24 * 60 * 60 });
    }
    navigate({
      pathname: `/${PAGE.AUTH.PATH}`,
      search: `?type=${AUTH_FORM_TYPE.REGISTER}`,
    });
  };

  const handleResize = () => setIsMobile(window.innerWidth < 768);

  useEffect(() => {
    removeCookie('recent-page', { path: '/' });

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="c-error-layout">
      <LayoutContext.Provider value={contextValue}>
        <HeaderLayout
          user={userProfile}
          handleLogout={signOutUser}
          handleLogin={handleLogin}
          handleRegister={handleRegister}
        />
        <main className="c-error-layout__content">
          {children}
        </main>
        <FooterLayout />
      </LayoutContext.Provider>
    </div>
  );
};

export default ErrorLayout;
