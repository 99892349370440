import React, { useEffect, useState } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';

// import Tiptap Extensions
import Blockquote from '@tiptap/extension-blockquote';
import Bold from '@tiptap/extension-bold';
import BulletList from '@tiptap/extension-bullet-list';
import Code from '@tiptap/extension-code';
import CodeBlock from '@tiptap/extension-code-block';
import { Color } from '@tiptap/extension-color';
import Document from '@tiptap/extension-document';
import Heading from '@tiptap/extension-heading';
import Highlight from '@tiptap/extension-highlight';
import History from '@tiptap/extension-history';
import Italic from '@tiptap/extension-italic';
import Link from '@tiptap/extension-link';
import ListItem from '@tiptap/extension-list-item';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';
import Strike from '@tiptap/extension-strike';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Text from '@tiptap/extension-text';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';

// Component
import TextEditorToolbar from './TextEditorToolbar';

// Hooks
import { useDebounce } from '../../hooks/useDebounce';

// Style
import './TextEditor.scss';

const TextEditor = (props) => {
  const {
    data,
    updateActiveTaskMutation,
    isMobile,
  } = props;

  const defaultContentState = data.description;
  const [contentState, setContentState] = useState(defaultContentState);
  const textEditorClassName = !isMobile ? 'c-text-editor' : 'c-text-editor mobile';

  const debouncedContent = useDebounce(contentState, 3000);

  const updateDescription = (value) => {
    const updatedDescription = {
      description: value,
      id: data.id,
    };
    updateActiveTaskMutation(updatedDescription);
  };

  const editor = useEditor({
    extensions: [
      Blockquote,
      Bold,
      BulletList,
      Code,
      CodeBlock,
      Color.configure({
        types: ['textStyle'],
      }),
      Document,
      Heading.configure({
        levels: [1, 2, 3, 4, 5, 6],
      }),
      Highlight.configure({ multicolor: true }),
      History,
      Italic,
      Link,
      ListItem,
      OrderedList,
      Paragraph,
      Strike,
      Subscript,
      Superscript,
      Text,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TextStyle,
      Underline,
    ],
    content: contentState,
    onUpdate() {
      setContentState(editor.getHTML());
    },
    onBlur() {
      if (contentState !== defaultContentState) {
        updateDescription(contentState);
      }
    },
  });

  useEffect(() => {
    if (debouncedContent !== defaultContentState) {
      updateDescription(debouncedContent);
    }
  }, [debouncedContent]);

  if (!editor) {
    return null;
  }

  return (
    <div className={textEditorClassName}>
      <TextEditorToolbar
        editor={editor}
        isMobile={isMobile}
      />
      <EditorContent
        editor={editor}
      />
    </div>
  );
};

export default TextEditor;
