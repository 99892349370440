import React from 'react';

// Components
import PrivacyPolicy from '../../components/PrivacyPolicy';

// Style
import './PrivacyPolicyView.scss';

const PrivacyPolicyView = () => (
  <div className="v-privacy-policy">
    <PrivacyPolicy />
  </div>
);

export default PrivacyPolicyView;
