const FAQText = {
  en: [
    {
      id: 1,
      question: 'How to start using the application?',
      answer: 'To start using all the features of the application, you need to go through the registration process at https://arella.io/auth. An unregistered user can also use the application, but not all functions.',
    },
    {
      id: 2,
      question: 'What are the main advantages of using the Kanban method for project management?',
      answer: 'The Kanban method helps visualize the workflow, manage tasks, and increase team productivity. It provides transparency of task status, improves workflow, and contributes to more effective time management.',
    },
    {
      id: 3,
      question: 'What user data does Arella collect and how is it used?',
      answer: 'Arella collects only the minimal necessary data required to provide project management services. This may include user information (such as name, surname, email address) and project data (such as project name, task descriptions). This data is used solely within the functionality of the application and is not shared with third parties without the user’s consent.',
    },
    {
      id: 4,
      question: 'How is the security of personal data ensured in Arella?',
      answer: 'We take measures to ensure the security and protection of our users’ personal data from unauthorized access, alteration, disclosure, or destruction. This includes the use of secure servers, encryption of data in transit and at rest, as well as regular security system updates.',
    },
    {
      id: 5,
      question: 'How to delete an account in the Arella app?',
      answer: 'To delete your account in the Arella app, please follow these steps: go to the user profile, find the "Delete Account" section. Follow the instructions on the screen to confirm the deletion of the account. Please note that after deleting the account, all your data and information will be permanently deleted, and you will no longer be able to restore your account. Make sure you really want to delete your account before continuing the process.',
    },
    {
      id: 6,
      question: 'How can I suggest a new feature or improvement?',
      answer: 'We always welcome feedback from our users! Please send your suggestions via email (contact@lanadev.com), and we will consider them.',
    },

  ],
  uk: [
    {
      id: 1,
      question: 'Як почати використовувати додаток?',
      answer: 'Для початку використання всіх функцій додатка потрібно пройти процедуру реєстрації на https://arella.io/auth. Незареєстрований користувач також може використовувати додаток, але не всі функції.',
    },
    {
      id: 2,
      question: 'Які основні переваги використання методики Канбан для управління проектами?',
      answer: 'Методика канбан допомагає візуалізувати робочий процес, керувати завданнями та підвищувати продуктивність команди. Вона забезпечує прозорість статусу завдань, покращує потік роботи та сприяє більш ефективному управлінню часом.',
    },
    {
      id: 3,
      question: 'Які дані користувачів Arella збирає і як вони використовуються?',
      answer: 'Arella збирає лише мінімально необхідні дані, необхідні для надання послуг з управління проектами. Це може включати інформацію про користувача (таку як ім’я, прізвище, адреса електронної пошти) та дані проектів (такі як назва проекту, опис завдань). Ці дані використовуються виключно в межах функціональності додатка і не передаються третім сторонам без згоди користувача.',
    },
    {
      id: 4,
      question: 'Як забезпечується безпека персональних даних у Arella?',
      answer: 'Ми приймаємо заходи для забезпечення безпеки та захисту персональних даних наших користувачів від несанкціонованого доступу, зміни, розголошення чи знищення. Це включає в себе використання захищених серверів, шифрування даних у транспортуванні та у спокої, а також регулярне оновлення систем безпеки.',
    },
    {
      id: 5,
      question: 'Як видалити аккаунт у додатку Arella?',
      answer: 'Для видалення свого облікового запису в додатку Arella, будь ласка, виконайте наступні кроки: зайдіть у профіль користувача, знайдіть розділ "Видалити обліковий запис". Дотримуйтесь інструкцій на екрані для підтвердження видалення облікового запису. Зверніть увагу, що після видалення облікового запису всі ваші дані та інформація будуть безповоротно видалені, і ви більше не зможете відновити свій обліковий запис. Переконайтеся, що ви дійсно хочете видалити обліковий запис, перш ніж продовжувати процес.',
    },
    {
      id: 6,
      question: 'Як я можу запропонувати нову функцію або вдосконалення?',
      answer: 'Ми завжди раді отримувати зворотній зв’язок від наших користувачів! Будь ласка, надішліть свої пропозиції за допомогою електронної пошти (contact@lanadev.com), і ми розглянемо їх.',
    },

  ],
  ru: [
    {
      id: 1,
      question: 'Как начать использовать приложение?',
      answer: 'Для начала использования всех функций приложения необходимо пройти процедуру регистрации https://arella.io/auth. Не зарегистрированный пользователь также может пользоваться приложением, но не всеми функциями.',
    },
    {
      id: 2,
      question: 'Какие основные преимущества использования методики Канбан для управления проектами?',
      answer: 'Методика канбан помогает визуализировать рабочий процесс, управлять задачами и повышать производительность команды. Она обеспечивает прозрачность статуса задач, улучшает поток работы и способствует более эффективному управлению временем.',
    },
    {
      id: 3,
      question: 'Какие данные пользователей Arella собирает и как они используются?',
      answer: 'Arella собирает только минимально необходимые данные, необходимые для предоставления услуг по управлению проектами. Это может включать информацию о пользователе (например, имя, фамилия, адрес электронной почты) и данные проектов (например, название проекта, описание задач). Эти данные используются исключительно в рамках функциональности приложения и не передаются третьим лицам без согласия пользователя.',
    },
    {
      id: 4,
      question: 'Как обеспечивается безопасность персональных данных в Arella?',
      answer: 'Мы принимаем меры для обеспечения безопасности и защиты персональных данных наших пользователей от несанкционированного доступа, изменения, раскрытия или уничтожения. Это включает в себя использование защищенных серверов, шифрование данных в пути и в покое, а также регулярное обновление систем безопасности.',
    },
    {
      id: 5,
      question: 'Как удалить аккаунт в приложении Arella?',
      answer: 'Чтобы удалить свой аккаунт в приложении Arella, пожалуйста, выполните следующие шаги: зайдите в профиль пользователя, найдите раздел "Удалить аккаунт". Следуйте инструкциям на экране для подтверждения удаления аккаунта. Пожалуйста, обратите внимание, что после удаления аккаунта все ваши данные и информация будут безвозвратно удалены, и вы больше не сможете восстановить свой аккаунт. Убедитесь, что вы действительно хотите удалить аккаунт, прежде чем продолжить процесс.',
    },
    {
      id: 6,
      question: 'Каким образом я могу предложить новую функцию или улучшение?',
      answer: 'Мы всегда рады получить обратную связь от наших пользователей! Пожалуйста, отправьте свои предложения через электронную почту (contact@lanadev.com), и мы рассмотрим их.',
    },
  ],
  fr: [
    {
      id: 1,
      question: 'Comment commencer à utiliser l’application?',
      answer: 'Pour commencer à utiliser toutes les fonctionnalités de l’application, vous devez passer par le processus d’inscription sur https://arella.io/auth. Un utilisateur non enregistré peut également utiliser l’application, mais pas toutes les fonctions.',
    },
    {
      id: 2,
      question: 'Quels sont les principaux avantages de l’utilisation de la méthode Kanban pour la gestion de projet?',
      answer: 'La méthode Kanban aide à visualiser le flux de travail, à gérer les tâches et à augmenter la productivité de l’équipe. Elle assure la transparence du statut des tâches, améliore le flux de travail et contribue à une gestion du temps plus efficace.',
    },
    {
      id: 3,
      question: 'Quelles données d’utilisateurs collecte Arella et comment sont-elles utilisées?',
      answer: 'Arella collecte uniquement les données strictement nécessaires pour fournir des services de gestion de projet. Cela peut inclure des informations sur l’utilisateur (telles que le nom, le prénom, l’adresse e-mail) et des données de projet (telles que le nom du projet, les descriptions de tâches). Ces données sont utilisées uniquement dans le cadre des fonctionnalités de l’application et ne sont pas partagées avec des tiers sans le consentement de l’utilisateur.',
    },
    {
      id: 4,
      question: 'Comment la sécurité des données personnelles est-elle assurée dans Arella?',
      answer: 'Nous prenons des mesures pour garantir la sécurité et la protection des données personnelles de nos utilisateurs contre tout accès non autorisé, toute altération, divulgation ou destruction. Cela inclut l’utilisation de serveurs sécurisés, le cryptage des données en transit et au repos, ainsi que des mises à jour régulières du système de sécurité.',
    },
    {
      id: 5,
      question: 'Comment supprimer un compte dans l’application Arella?',
      answer: 'Pour supprimer votre compte dans l’application Arella, veuillez suivre ces étapes : accédez au profil utilisateur, trouvez la section "Supprimer le compte". Suivez les instructions à l’écran pour confirmer la suppression du compte. Veuillez noter qu’après la suppression du compte, toutes vos données et informations seront définitivement supprimées, et vous ne pourrez plus restaurer votre compte. Assurez-vous que vous voulez vraiment supprimer votre compte avant de continuer le processus.',
    },
    {
      id: 6,
      question: 'Comment puis-je suggérer une nouvelle fonctionnalité ou une amélioration?',
      answer: 'Nous sommes toujours heureux de recevoir des commentaires de nos utilisateurs ! Veuillez envoyer vos suggestions par e-mail (contact@lanadev.com), et nous les examinerons.',
    },
  ],
  de: [
    {
      id: 1,
      question: 'Wie fange ich an, die Anwendung zu verwenden?',
      answer: 'Um alle Funktionen der Anwendung nutzen zu können, müssen Sie den Registrierungsprozess unter https://arella.io/auth durchlaufen. Ein nicht registrierter Benutzer kann die Anwendung ebenfalls nutzen, jedoch nicht alle Funktionen.',
    },
    {
      id: 2,
      question: 'Was sind die Hauptvorteile der Verwendung der Kanban-Methode für das Projektmanagement?',
      answer: 'Die Kanban-Methode hilft, den Arbeitsablauf zu visualisieren, Aufgaben zu verwalten und die Teamproduktivität zu steigern. Sie sorgt für Transparenz des Aufgabenstatus, verbessert den Arbeitsfluss und trägt zu einer effektiveren Zeitplanung bei.',
    },
    {
      id: 3,
      question: 'Welche Benutzerdaten sammelt Arella und wie werden sie verwendet?',
      answer: 'Arella erhebt nur die minimal notwendigen Daten, die für die Bereitstellung von Projektmanagementdiensten erforderlich sind. Dazu gehören möglicherweise Benutzerinformationen (wie Name, Nachname, E-Mail-Adresse) und Projektdaten (wie Projektname, Aufgabenbeschreibungen). Diese Daten werden ausschließlich im Rahmen der Anwendungsfunktionalität verwendet und ohne die Zustimmung des Benutzers nicht an Dritte weitergegeben.',
    },
    {
      id: 4,
      question: 'Wie wird die Sicherheit personenbezogener Daten in Arella gewährleistet?',
      answer: 'Wir ergreifen Maßnahmen, um die Sicherheit und den Schutz der personenbezogenen Daten unserer Benutzer vor unbefugtem Zugriff, Veränderung, Offenlegung oder Zerstörung zu gewährleisten. Dazu gehören die Verwendung von sicheren Servern, die Verschlüsselung von Daten während der Übertragung und im Ruhezustand sowie regelmäßige Sicherheitsupdates.',
    },
    {
      id: 5,
      question: 'Wie lösche ich ein Konto in der Arella-App?',
      answer: 'Um Ihr Konto in der Arella-App zu löschen, befolgen Sie bitte diese Schritte: Gehen Sie zum Benutzerprofil, finden Sie den Abschnitt "Konto löschen". Befolgen Sie die Anweisungen auf dem Bildschirm, um die Löschung des Kontos zu bestätigen. Bitte beachten Sie, dass nach dem Löschen des Kontos alle Ihre Daten und Informationen dauerhaft gelöscht werden und Sie Ihr Konto nicht mehr wiederherstellen können. Stellen Sie sicher, dass Sie Ihr Konto wirklich löschen möchten, bevor Sie den Vorgang fortsetzen.',
    },
    {
      id: 6,
      question: 'Wie kann ich eine neue Funktion oder Verbesserung vorschlagen?',
      answer: 'Wir freuen uns immer über Feedback von unseren Benutzern! Bitte senden Sie Ihre Vorschläge per E-Mail (contact@lanadev.com), und wir werden sie prüfen.',
    },
  ],
  es: [
    {
      id: 1,
      question: '¿Cómo empezar a usar la aplicación?',
      answer: 'Para empezar a usar todas las funciones de la aplicación, necesitas pasar por el proceso de registro en https://arella.io/auth. Un usuario no registrado también puede usar la aplicación, pero no todas las funciones.',
    },
    {
      id: 2,
      question: '¿Cuáles son las principales ventajas de utilizar el método Kanban para la gestión de proyectos?',
      answer: 'El método Kanban ayuda a visualizar el flujo de trabajo, gestionar tareas y aumentar la productividad del equipo. Proporciona transparencia del estado de las tareas, mejora el flujo de trabajo y contribuye a una gestión del tiempo más efectiva.',
    },
    {
      id: 3,
      question: '¿Qué datos de usuario recopila Arella y cómo se utilizan?',
      answer: 'Arella recopila solo los datos mínimamente necesarios para proporcionar servicios de gestión de proyectos. Esto puede incluir información del usuario (como nombre, apellido, dirección de correo electrónico) y datos del proyecto (como nombre del proyecto, descripciones de tareas). Estos datos se utilizan únicamente dentro de la funcionalidad de la aplicación y no se comparten con terceros sin el consentimiento del usuario.',
    },
    {
      id: 4,
      question: '¿Cómo se garantiza la seguridad de los datos personales en Arella?',
      answer: 'Tomamos medidas para garantizar la seguridad y protección de los datos personales de nuestros usuarios contra el acceso no autorizado, la alteración, la divulgación o la destrucción. Esto incluye el uso de servidores seguros, la encriptación de datos en tránsito y en reposo, así como actualizaciones regulares del sistema de seguridad.',
    },
    {
      id: 5,
      question: '¿Cómo eliminar una cuenta en la aplicación Arella?',
      answer: 'Para eliminar su cuenta en la aplicación Arella, siga estos pasos: vaya al perfil de usuario, encuentre la sección "Eliminar cuenta". Siga las instrucciones en la pantalla para confirmar la eliminación de la cuenta. Tenga en cuenta que después de eliminar la cuenta, todos sus datos e información se eliminarán permanentemente, y ya no podrá restaurar su cuenta. Asegúrese de que realmente desea eliminar su cuenta antes de continuar con el proceso.',
    },
    {
      id: 6,
      question: '¿Cómo puedo sugerir una nueva función o mejora?',
      answer: 'Siempre estamos encantados de recibir comentarios de nuestros usuarios. Por favor, envía tus sugerencias por correo electrónico (contact@lanadev.com), y las consideraremos.',
    },
  ],
};

export default FAQText;
