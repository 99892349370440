import React from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// Store
import { sessionState } from '../../store/atoms';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Utils
import generateRandomStr from '../../utils/generateRandomStr';

// Configs
import {
  AUTH_FORM_TYPE,
  AUTH_STATUS,
  BOARD_SORT_TYPE,
  BOARD_TYPE,
  PAGE,
  POSITION,
} from '../../config/config';

// Components
import Button from '../Button';
import HeadingWrapper from '../Heading/HeadingWrapper';
import Tooltip from '../Tooltip';
import { SortBoard, SortType } from '../SortBoard';
import {
  IconFolder,
  IconPlus,
  IconStar,
  IconUserShield,
  IconUsers,
} from '../Icons';

// Styles
import './BoardPanelTop.scss';

const TooltipCTA = (props) => {
  const { onClick } = props;

  const I18N = useI18N();

  return (
    <div className="c-tooltip-massage">
      <p className="c-tooltip-massage__text">{I18N.MESSAGE_TO_REGISTER}</p>
      <Button text={I18N.REGISTER} size="xs" onClick={onClick} />
    </div>
  );
};

const BoardPanelTop = (props) => {
  const {
    createNewBoard,
    isTempUser,
    selectedSortType,
    sortBoards,
    sortOrderDirections,
    switchSortOrder,
    title,
    type,
  } = props;

  const I18N = useI18N();
  const navigate = useNavigate();

  const [session, setSession] = useRecoilState(sessionState);

  const types = {
    CREATED: I18N.CREATED,
    UPDATED: I18N.UPDATED,
    CUSTOM: I18N.CUSTOM,
  };

  const openRegisterPage = () => {
    setSession({
      ...session,
      authFormType: AUTH_FORM_TYPE.REGISTER,
      authStatus: AUTH_STATUS.NOT_AUTH,
    });
    navigate(`/${PAGE.AUTH.PATH}`);
  };

  const getIconByTypeBoard = (typeBoard) => {
    switch (typeBoard) {
      case BOARD_TYPE.FAVORITE:
        return <IconStar />;
      case BOARD_TYPE.PRIVATE:
        return <IconUserShield />;
      case BOARD_TYPE.SHARED:
        return <IconUsers />;
      default:
        return <IconFolder />;
    }
  };

  return (
    <div className="c-board-panel-top">
      <HeadingWrapper classType="secondary">
        <span className="c-board-panel-top__title">{title}</span>
        {getIconByTypeBoard(type)}
      </HeadingWrapper>
      <SortBoard
        onClick={switchSortOrder}
        sortOrderDirections={sortOrderDirections}
      >
        {Object.values(BOARD_SORT_TYPE).map(
          (sortName) => !(
            type === BOARD_TYPE.PRIVATE && sortName === BOARD_SORT_TYPE.CUSTOM
          )
            && !(
              type === BOARD_TYPE.SHARED && sortName === BOARD_SORT_TYPE.CUSTOM
            )
            && !(
              type === BOARD_TYPE.ARCHIVE && sortName === BOARD_SORT_TYPE.CUSTOM
            ) && (
              <SortType
                name={types[sortName]}
                onClick={() => sortBoards(sortName, selectedSortType === sortName)}
                key={generateRandomStr()}
                selected={selectedSortType === sortName}
              />
          ),
        )}
      </SortBoard>
      <IconPlus
        size="md"
        onClick={createNewBoard}
      />
      <Tooltip
        info={<TooltipCTA onClick={openRegisterPage} />}
        position={POSITION.LEFT}
        disabled={!isTempUser}
      >
        <Button
          text={I18N.CREATE_NEW_BOARD}
          iconLeft={<IconPlus />}
          size="sm"
          onClick={createNewBoard}
          disabled={isTempUser}
        />
      </Tooltip>
    </div>
  );
};

export default BoardPanelTop;
