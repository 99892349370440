import {
  React,
  useState,
  useRef,
} from 'react';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Style
import './AutoCompleteTags.scss';

const AutocompleteTags = (props) => {
  const {
    existingTags,
    onInputChange,
    onInputKeyDown,
  } = props;

  const inputRef = useRef(null);

  const [activeTagList, setActiveTagList] = useState(0);
  const [filteredTagList, setFilteredTagList] = useState([]);
  const [showTagList, setShowTagList] = useState(false);
  const [userInput, setUserInput] = useState('');

  const I18N = useI18N();

  const onChangeInputTag = (event) => {
    const inputValue = event.currentTarget.value;
    const filteredTag = existingTags
      .filter((tag) => tag.includes(inputValue))
      .sort((prevTag, nextTag) => prevTag.length - nextTag.length);

    setActiveTagList(-1);
    setFilteredTagList(filteredTag);
    setShowTagList(true);
    setUserInput(inputValue);
    onInputChange(inputValue);
  };

  const clickOnTag = (event) => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 0);
    setActiveTagList(0);
    setFilteredTagList([]);
    setShowTagList(false);
    const finalClickValue = event.target.innerText.slice(1);
    setUserInput(finalClickValue);
    onInputChange(finalClickValue);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      setActiveTagList(0);
      setShowTagList(false);
      onInputChange(filteredTagList[activeTagList]);
      onInputKeyDown(event);
      setUserInput('');
    }
  };

  const renderTagsFilteredList = () => {
    if (showTagList && userInput) {
      if (filteredTagList.length) {
        return (
          <ul className="c-autocomplete-tags__tag-list">
            {filteredTagList.map((tag, index) => {
              const className = index === activeTagList ? 'c-autocomplete-tags__tags-active' : '';

              return (
                <li
                  className={className}
                  key={tag.id}
                  onClick={clickOnTag}
                >
                  #
                  {tag}
                </li>
              );
            })}
          </ul>
        );
      }
      return (
        <div className="c-autocomplete-tags__no-tags">
          <p>{I18N.NOT_ALLOWED_VALUE}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="c-autocomplete-tags">
      <input
        ref={inputRef}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        className="c-autocomplete-tags__input"
        type="text"
        onChange={onChangeInputTag}
        onKeyDown={handleKeyDown}
        value={userInput}
        placeholder={I18N.ENTER_NEW_TAG}
      />
      {renderTagsFilteredList()}
    </div>
  );
};

export default AutocompleteTags;
