import React from 'react';
import Icon from '../Icon';

const IconUsers = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-users">
      <svg viewBox="0 0 23 16" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.42857 6.85714C4.68929 6.85714 5.71429 5.83214 5.71429 4.57143C5.71429 3.31071 4.68929 2.28571 3.42857 2.28571C2.16786 2.28571 1.14286 3.31071 1.14286 4.57143C1.14286 5.83214 2.16786 6.85714 3.42857 6.85714ZM19.4286 6.85714C20.6893 6.85714 21.7143 5.83214 21.7143 4.57143C21.7143 3.31071 20.6893 2.28571 19.4286 2.28571C18.1679 2.28571 17.1429 3.31071 17.1429 4.57143C17.1429 5.83214 18.1679 6.85714 19.4286 6.85714ZM20.5714 8H18.2857C17.6571 8 17.0893 8.25357 16.675 8.66429C18.1143 9.45357 19.1357 10.8786 19.3571 12.5714H21.7143C22.3464 12.5714 22.8571 12.0607 22.8571 11.4286V10.2857C22.8571 9.025 21.8321 8 20.5714 8ZM11.4286 8C13.6393 8 15.4286 6.21071 15.4286 4C15.4286 1.78929 13.6393 0 11.4286 0C9.21786 0 7.42857 1.78929 7.42857 4C7.42857 6.21071 9.21786 8 11.4286 8ZM14.1714 9.14286H13.875C13.1321 9.5 12.3071 9.71429 11.4286 9.71429C10.55 9.71429 9.72857 9.5 8.98214 9.14286H8.68571C6.41429 9.14286 4.57143 10.9857 4.57143 13.2571V14.2857C4.57143 15.2321 5.33929 16 6.28571 16H16.5714C17.5179 16 18.2857 15.2321 18.2857 14.2857V13.2571C18.2857 10.9857 16.4429 9.14286 14.1714 9.14286ZM6.18214 8.66429C5.76786 8.25357 5.2 8 4.57143 8H2.28571C1.025 8 0 9.025 0 10.2857V11.4286C0 12.0607 0.510714 12.5714 1.14286 12.5714H3.49643C3.72143 10.8786 4.74286 9.45357 6.18214 8.66429Z" />
      </svg>
    </Icon>
  );
};

export default IconUsers;
