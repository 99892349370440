import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useI18N } from '../../i18n/useI18N';

import Button from '../Button';
import UserField from '../UserField';
import { IconCrossRound, IconPlus, IconUser } from '../Icons';
import Modal from '../Modal';

import './BoardTeam.scss';

const BoardTeam = (props) => {
  const {
    btnAddUser,
    activeBoard,
    updateBoardMutation,
    boardData,
  } = props;

  const [userForDelete, setUserForDelete] = useState(null);

  const I18N = useI18N({ userName: userForDelete?.userName });
  const navigate = useNavigate();

  const openUserForDeleteModal = (user) => {
    setUserForDelete(user);
  };

  const closeUserForDeleteModal = () => {
    setUserForDelete(null);
  };

  const acceptUserForDelete = () => {
    updateBoardMutation({
      id: boardData.id,
      removeUserId: userForDelete.id,
    });
    closeUserForDeleteModal();
  };

  const transitionUserProfile = (id) => {
    navigate(`/user/${id}`);
  };

  const cancelUserForDelete = () => {
    closeUserForDeleteModal();
  };

  return (
    <>
      <div className="c-board-actions-panel__team">
        <span className="c-board-actions-panel__team-title action-subtitle">
          {I18N.BOARD_TEAM}
          <IconPlus size="xs" onClick={btnAddUser} />
        </span>
        <div className="c-board-actions-panel__wrapper-users">
          {activeBoard.users.map((user) => (
            <div key={user?.id} className="c-board-actions-panel__user">
              <UserField
                user={user}
                size="xs"
                onClick={() => transitionUserProfile(user?.id)}
              />
              {activeBoard.ownerId !== user?.id && (
                <IconCrossRound
                  size="xs"
                  onClick={() => openUserForDeleteModal(user)}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      <Button // TODO move to c-board-actions-panel__team
        text={I18N.ADD_USER}
        iconLeft={<IconUser />}
        size="sm"
        onClick={btnAddUser}
      />
      <Modal
        type="WARNING"
        isVisible={userForDelete}
        title={I18N.DELETE_USER}
        onClose={closeUserForDeleteModal}
        btnAcceptText={I18N.REMOVE}
        btnAcceptHandler={acceptUserForDelete}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={cancelUserForDelete}
      >
        <div className="c-board-actions-panel__delete-user-description">
          {I18N.MESSAGE_TO_DELETE_USER}
        </div>
      </Modal>
    </>
  );
};

export default BoardTeam;
