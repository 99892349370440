import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

// Hooks
import { useI18N } from '../../i18n/useI18N';
import { useDebounce } from '../../hooks/useDebounce';

// Store
import useBoardMutations from '../../store/mutations/useBoardMutations';
import { usersSearchResultState } from '../../store/atoms';

// Components
import Modal from '../Modal';
import Button from '../Button';
import UserField from '../UserField';
import InputControl from '../InputControl';
import { IconChevronDown } from '../Icons';

// Style
import './AddUserForm.scss';

const AddUserForm = (props) => {
  const {
    activeBoard,
    closeForm,
  } = props;

  const boardUsers = activeBoard.users;

  // Default State
  const defaultError = {
    email: null,
  };

  // Global State
  const [usersSearchResult, setUsersSearchResult] = useRecoilState(usersSearchResultState);

  // Mutations
  const {
    updateBoardMutation,
    searchUsersMutation,
    createBoardMutation,
  } = useBoardMutations();

  // State
  const [error, setError] = useState(defaultError);
  const [searchQuery, setSearchQuery] = useState(null);
  const [selectUser, setSelectUser] = useState(null);
  const [isSelectUser, setIsSelectUser] = useState(false);
  const [addUser, setAddUser] = useState(false);

  // Component Logic
  const I18N = useI18N({ userName: selectUser?.userName });

  const selectUserClassName = (isSelectUser) ? 'select-user' : '';
  const modalClassName = `modal-top ${selectUserClassName}`;

  const boardUserIds = boardUsers ? boardUsers.map((item) => item.id) : [];

  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const handleChangeInput = (event) => {
    const { value } = event.currentTarget;
    setSearchQuery(value);
  };

  const handleClickSelectUser = (user) => {
    setSelectUser(user);
    setIsSelectUser(!isSelectUser);
  };

  const handleClickBackToSearch = () => {
    setIsSelectUser(!isSelectUser);
  };

  const handleAddUser = () => {
    if (activeBoard.id === 'new') {
      createBoardMutation({ ...activeBoard, id: null });
    }

    if (activeBoard.id !== 'new') {
      updateBoardMutation({
        id: activeBoard.id,
        user: selectUser,
      });
      setAddUser(!addUser);
      closeForm();
    }
  };

  const handleKeyDown = (event) => {
    if (event.code === 'Enter' && isSelectUser && !boardUserIds.includes(selectUser.id)) {
      handleAddUser();
    }

    if (event.code === 'ArrowLeft' && isSelectUser) {
      handleClickBackToSearch();
    }
  };

  const resetInput = () => {
    setSearchQuery('');
  };

  const showEmptyState = error.email && usersSearchResult.users.length === 0;

  useEffect(() => {
    if (isSelectUser) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isSelectUser]);

  useEffect(() => {
    if (debouncedSearchQuery?.length > 2) {
      searchUsersMutation(debouncedSearchQuery);
    } else {
      setUsersSearchResult({ users: [], hasError: null });
    }

    if (usersSearchResult.hasError) {
      setError({ ...defaultError, email: I18N.USER_NOT_FOUND });
    } else {
      setError(defaultError);
    }

    if (addUser) {
      handleAddUser();
    }
  }, [debouncedSearchQuery, usersSearchResult.hasError, addUser, activeBoard.id]);

  return (
    <Modal
      className={modalClassName}
      isVisible
      onClose={closeForm}
      title={I18N.SEARCH_USER}
    >
      <div className="c-add-user-form">
        {!isSelectUser && (
          <>
            <InputControl
              type="text"
              name="email"
              placeholder={`${I18N.EMAIL}...`}
              value={searchQuery || ''}
              onChange={handleChangeInput}
              suggestion={I18N.ENTER_USER_NAME_OR_EMAIL}
              autoComplete="off"
              onReset={resetInput}
            />
            {usersSearchResult.users.length > 0 && (
              <ul className="c-add-user-form__user-list">
                {usersSearchResult.users.slice(0, 10).map((user) => (
                  <li
                    key={user.id}
                    className="c-add-user-form__user-list-item"
                    onClick={() => handleClickSelectUser(user)}
                  >
                    <UserField
                      user={user}
                      size="xs"
                      showEmail
                    />
                  </li>
                ))}
              </ul>
            )}
            {showEmptyState && (
            <div className="c-add-user-form__not-found-user">
              <span className="c-add-user-form__not-found-user__text">
                {error.email}
              </span>
            </div>
            )}
          </>
        )}
        {isSelectUser && (
        <div className="c-add-user-form__wrapper-select">
          <div className="c-add-user-form__description">
            {I18N.MESSAGE_TO_CHOOSE_USER}
          </div>
          <div
            onClick={handleClickBackToSearch}
            className="c-add-user-form__back-to-search"
          >
            <IconChevronDown
              size="xs"
            />
            <span className="c-add-user-form__link-search">
              {I18N.MESSAGE_TO_BACK_TO_SEARCH_RESULT}
            </span>
          </div>
          <div className="c-add-user-form__buttons">
            <Button
              type="confirm"
              text={I18N.ADD_USER}
              onClick={() => setAddUser(!addUser)}
              disabled={boardUserIds.includes(selectUser.id)}
            />
            <Button
              type="primary-inverse"
              text={I18N.CANCEL}
              onClick={closeForm}
            />
          </div>
        </div>
        )}
      </div>
    </Modal>
  );
};

export default AddUserForm;
