import { selector } from 'recoil';
import { activeFiltersState } from '../atoms';

const priorityConfigSelector = selector({
  key: 'filterSelector',
  get: ({ get }) => {
    const { boardId } = get(activeFiltersState);
    const filtersConfig = get(activeFiltersState(boardId));
    return filtersConfig;
  },
});
export default priorityConfigSelector;
