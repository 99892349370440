import React from 'react';

import './StatusList.scss';

const StatusList = (props) => {
  const { children, refStatusList } = props;
  return (
    <div ref={refStatusList} className="c-status-list">
      {children}
    </div>
  );
};

export default StatusList;
