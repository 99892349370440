import { selectorFamily } from 'recoil';

// Store
import { profileSettingsState, sortBoardState } from '../atoms';

// Configs
import { BOARD_SORT_TYPE } from '../../config/config';

const filterBoardSortMethodState = selectorFamily({
  key: 'filterBoardSortMethod',
  get: (type) => ({ get }) => {
    const data = get(sortBoardState((type)));

    const settings = get(profileSettingsState);
    const orderByPanel = settings?.data.order ?? null;

    if (data.type === BOARD_SORT_TYPE.UPDATED) {
      return (a, b) => new Date(b.updateDateTime)
        .getTime() - new Date(a.updateDateTime).getTime();
    }

    if (data.type === BOARD_SORT_TYPE.CUSTOM && orderByPanel && orderByPanel[type]) {
      return (a, b) => {
        const orderA = orderByPanel[type]
          .find((item) => item.id === a.id)?.order ?? 99;
        const orderB = orderByPanel[type]
          .find((item) => item.id === b.id)?.order ?? 99;
        return orderA - orderB;
      };
    }

    return (a, b) => new Date(b.createDateTime)
      .getTime() - new Date(a.createDateTime).getTime();
  },
});

export default filterBoardSortMethodState;
