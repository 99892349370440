import React from 'react';

// Component
import PrioritySelect from '../PrioritySelect';
import PriorityIcon from '../PriorityIcon';

// Style
import './TaskPriority.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const TaskPriority = (props) => {
  const {
    data,
    updateActiveTaskMutation,
  } = props;

  const {
    priority,
  } = data;

  const I18N = useI18N();

  const updatePriority = (value) => {
    const updatedPriority = {
      priority: value,
      id: data.id,
    };
    updateActiveTaskMutation(updatedPriority);
  };

  return (
    <div className="c-task-priority">
      <div className="c-task-priority__header">
        <div className="c-task__title ">
          {I18N.PRIORITY}
        </div>
        <div className="c-task-priority__priority">
          <span>{I18N[priority.toUpperCase()]}</span>
          <PriorityIcon priority={priority} />
        </div>
      </div>
      <div>
        <PrioritySelect
          priority={priority}
          onChange={(value) => updatePriority(value)}
        />
      </div>
    </div>
  );
};

export default TaskPriority;
