import React from 'react';

// Component
import Icon from '../Icon';

const IconArrow = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-arrow">
      <svg viewBox="0 0 13 21" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M5.30614 2.31402L1.34389 7.45067C1.0909 7.77865 0.619933 7.83943 0.291957 7.58644C-0.03602 7.33345 -0.0968081 6.86249 0.156182 6.53451L4.67128 0.681147C5.37183 -0.227048 6.74205 -0.227049 7.4426 0.681147L11.9577 6.53451C12.2107 6.86249 12.1499 7.33345 11.8219 7.58644C11.4939 7.83943 11.023 7.77865 10.77 7.45067L6.80614 2.31194V20.0969C6.80614 20.5111 6.47035 20.8469 6.05614 20.8469C5.64193 20.8469 5.30614 20.5111 5.30614 20.0969V2.31402Z" />
      </svg>

    </Icon>
  );
};

export default IconArrow;
