import React from 'react';
import Icon from '../Icon';

const IconSimple = (props) => {
  const {
    size,
    inverse,
    onClick,
    role,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-simple"
      role={role}
    >
      <svg
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M45.1978 22.9167C45.1969 20.5754 44.5175 18.2846 43.242 16.3213C41.9664 14.358 40.1492 12.8064 38.0103 11.8542C37.2531 10.158 36.1157 8.65893 34.6862 7.47291C33.2566 6.2869 31.5733 5.4458 29.7665 5.01475C27.9597 4.5837 26.0779 4.57427 24.2669 4.98717C22.4559 5.40007 20.7642 6.22424 19.3228 7.39586H19.0103C17.3176 7.39746 15.644 7.75378 14.0974 8.44185C12.5508 9.12991 11.1656 10.1345 10.0311 11.3907C8.89654 12.6469 8.03785 14.127 7.51039 15.7355C6.98292 17.3439 6.79838 19.045 6.96868 20.7292C5.94911 22.1957 5.26703 23.8698 4.97145 25.6313C4.67586 27.3928 4.77411 29.1978 5.25913 30.9168C5.74415 32.6358 6.6039 34.226 7.77661 35.5732C8.94931 36.9204 10.4059 37.9911 12.0416 38.7084C12.8492 40.2923 13.9964 41.6786 15.4013 42.7682C16.8063 43.8578 18.4344 44.624 20.1695 45.012C21.9046 45.4 23.704 45.4003 25.4393 45.0129C27.1745 44.6255 28.8029 43.86 30.2083 42.7709C30.5103 42.7709 30.802 42.7709 31.1041 42.7709C34.3135 42.7764 37.3939 41.5082 39.6692 39.2447C41.9444 36.9812 43.2285 33.9073 43.2395 30.6979C43.2395 30.3334 43.2395 29.9792 43.177 29.6563C44.5095 27.6624 45.2134 25.3148 45.1978 22.9167Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.96875 20.7292C7.95833 26.2813 9.84375 27.8751 11.3333 29.3751C12.8229 30.8751 17.5312 34.8438 18.625 36.448"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43.1768 29.6249C42.1351 24.0728 40.3018 22.4687 38.8122 20.9791C37.3226 19.4895 32.6143 15.4999 31.5205 13.8958"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.208 42.7708C34.5205 39.125 34.9684 36.6979 35.5101 34.6563C36.0518 32.6146 36.4372 28.9583 37.9893 24.8021C39.6182 20.647 39.6182 16.0301 37.9893 11.875"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.3233 7.39575C15.0108 11.0416 15.0629 13.8853 14.5212 15.927C13.9275 19.2659 13.0991 22.5588 12.0421 25.7812C10.4131 29.9362 10.4131 34.5532 12.0421 38.7083"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.98926 18.0416C14.8955 14.5729 17.7288 15.1666 21.4476 13.875C23.6559 13.1354 25.0101 11.5937 31.8018 11.1458C36.2601 10.8541 37.9684 11.8333 37.9684 11.8333"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.3128 11.5834C26.6774 9.85425 23.0524 7.41675 19.3232 7.41675"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.667 39.0105C23.9124 41.3335 26.979 42.6836 30.2087 42.7709"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.0622 32.5417C35.1559 36.0209 32.3226 35.4167 28.6038 36.7084C26.3955 37.448 25.0413 38.9897 18.2497 39.4376C13.7913 39.7397 12.083 38.7501 12.083 38.7501"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Icon>
  );
};
export default IconSimple;
