import React from 'react';

const HeadingWrapper = (props) => {
  const {
    classType,
    children,
  } = props;

  if (classType === 'primary') {
    return (
      <h1 className={`c-heading ${classType}`}>{children}</h1>
    );
  }
  return (
    <h2 className={`c-heading ${classType}`}>{children}</h2>
  );
};

export default HeadingWrapper;
