import React from 'react';

// Component
import UserField from '../UserField';

// Style
import './TaskAuthor.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Utils
import formatDate from '../../utils/formatDate';

const TaskAuthor = (props) => {
  const {
    data,
    boardTeam,
  } = props;

  const I18N = useI18N();

  const author = boardTeam.length
    ? boardTeam.find((item) => item.id === data.createdByUserId)
    : null;

  return (
    <div className="c-task-author">
      <div className="c-task__title">
        {I18N.CREATED_BY}
      </div>
      <UserField
        user={author}
        size="sm"
        showWithoutAuthor
      />
      <div className="c-task-author__date-create">
        {formatDate(data.createDateTime)}
      </div>
    </div>
  );
};

export default TaskAuthor;
