import React from 'react';

// Style
import './PromoFeatureHero.scss';

// Component
import {
  IconSimple,
  IconAgile,
  IconVisualization,
  IconTransparency,
} from '../Icons';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const PromoFeatureHero = () => {
  const I18N = useI18N();

  return (
    <div className="c-promo-feature-hero">
      <div className="c-promo__container">
        <div className="c-promo-feature-hero__screen" />
        <div className="c-promo-feature-hero__list">
          <div className="c-promo-feature-hero__list__item">
            <IconSimple />
            <div className="c-promo-feature-hero__list__item__title">
              <span>{I18N.SIMPLE_FLEXIBLE_AND_POWERFUL}</span>
            </div>
            <div className="c-promo-feature-hero__list__item__description">
              {I18N.PROMO_FEATURE_SIMPLE_FLEXIBLE_AND_POWERFUL}
            </div>
          </div>
          <div className="c-promo-feature-hero__list__item">
            <IconVisualization />
            <div className="c-promo-feature-hero__list__item__title">
              <span>{I18N.VISUALIZATION_OF_PROCESSES}</span>
            </div>
            <div className="c-promo-feature-hero__list__item__description">
              {I18N.PROMO_FEATURE_VISUALIZATION_OF_PROCESSES}
            </div>
          </div>
          <div className="c-promo-feature-hero__list__item">
            <IconTransparency />
            <div className="c-promo-feature-hero__list__item__title">
              <span>{I18N.TRANSPARENCY_OF_WORK}</span>
            </div>
            <div className="c-promo-feature-hero__list__item__description">
              {I18N.PROMO_FEATURE_TRANSPARENCY_OF_WORK}
            </div>
          </div>
          <div className="c-promo-feature-hero__list__item">
            <IconAgile />
            <div className="c-promo-feature-hero__list__item__title">
              <span>{I18N.METHODOLOGY_OF_AGILE}</span>
            </div>
            <div className="c-promo-feature-hero__list__item__description">
              {I18N.PROMO_FEATURE_METHODOLOGY_OF_AGILE}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoFeatureHero;
