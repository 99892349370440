import React from 'react';

// Components
import About from '../../components/About';

// Style
import './AboutView.scss';

const AboutView = () => (
  <div className="v-about">
    <About />
  </div>
);

export default AboutView;
