import React from 'react';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Components
import Panel from '../../components/Panel';
import ErrorMessage from '../../components/ErrorMessage';
import { IconErrorPage } from '../../components/Icons';

// Style
import './ErrorView.scss';

const ErrorView = () => {
  // Component logic
  const I18N = useI18N();

  return (
    <div className="v-error">
      <Panel>
        <ErrorMessage
          isNavigateHome
          name={I18N.MESSAGE_TO_ERROR_PAGE}
          icon={<IconErrorPage />}
        />
      </Panel>
    </div>
  );
};

export default ErrorView;
