import React from 'react';

import './PrivacyPolicy.scss';

import { POSITION } from '../../config/config';

// Components
import ColorMarkBar from '../ColorMarkBar';
import Heading from '../Heading';

const PrivacyPolicy = () => (
  <div className="c-privacy-policy">
    <aside className="c-privacy-policy__sidebar">
      <Heading
        type="secondary"
        text="Table of content"
      />
      <ul>
        <li className="c-privacy-policy__sidebar__item">
          <a
            className="c-privacy-policy__sidebar__item__link"
            href="#c-privacy-policy__sidebar__item__link-1"
          >
            What Kinds of Information Do We Collect?
          </a>
        </li>
        <li className="c-privacy-policy__sidebar__item">
          <a
            className="c-privacy-policy__sidebar__item__link"
            href="#c-privacy-policy__sidebar__item__link-2"
          >
            Cookie Policy
          </a>
        </li>
        <li className="c-privacy-policy__sidebar__item">
          <a
            className="c-privacy-policy__sidebar__item__link"
            href="#c-privacy-policy__sidebar__item__link-3"
          >
            How Do We Use Information?
          </a>
        </li>
        <li className="c-privacy-policy__sidebar__item">
          <a
            className="c-privacy-policy__sidebar__item__link"
            href="#c-privacy-policy__sidebar__item__link-4"
          >
            Children&apos;s Information
          </a>
        </li>
        <li className="c-privacy-policy__sidebar__item">
          <a
            className="c-privacy-policy__sidebar__item__link"
            href="#c-privacy-policy__sidebar__item__link-5"
          >
            Third-Party Links
          </a>
        </li>
        <li className="c-privacy-policy__sidebar__item">
          <a
            className="c-privacy-policy__sidebar__item__link"
            href="#c-privacy-policy__sidebar__item__link-6"
          >
            Data Retention
          </a>
        </li>
        <li className="c-privacy-policy__sidebar__item">
          <a
            className="c-privacy-policy__sidebar__item__link"
            href="#c-privacy-policy__sidebar__item__link-7"
          >
            How We Share Information
          </a>
        </li>
        <li className="c-privacy-policy__sidebar__item">
          <a
            className="c-privacy-policy__sidebar__item__link"
            href="#c-privacy-policy__sidebar__item__link-8"
          >
            Changes to this Policy
          </a>
        </li>
        <li className="c-privacy-policy__sidebar__item">
          <a
            className="c-privacy-policy__sidebar__item__link"
            href="#c-privacy-policy__sidebar__item__link-9"
          >
            Questions
          </a>
        </li>
      </ul>
      <ColorMarkBar
        position={POSITION.RIGHT}
        color="var(--color-brand)"
      />
    </aside>
    <main className="c-privacy-policy__content">
      <article className="c-privacy-policy__content__wrapper">
        <Heading
          type="secondary"
          text="Privacy Policy"
        />
        <p className="c-privacy-policy__content__date">
          Effective Date: May 09, 2023
        </p>
        <p>
          This Privacy Policy describes Application
          (&quot;Arella&quot;, &quot;Product&quot;, &quot;we&quot;,
          &quot;us&quot; or &quot;our&quot;)
          practices for handling your information in connection with this website.
          This Privacy Policy describes the personal information we process to support our Product.
        </p>
        <br />
        <section
          className="c-privacy-policy__content__section"
          id="c-privacy-policy__sidebar__item__link-1"
        >
          <h3
            className="c-privacy-policy__content__section__title"
          >
            What Kinds of Information Do We Collect?
          </h3>
          <p>
            When you interact with us through our Product,
            we may collect or receive the following types of information:
          </p>
          <p>
            <b>Information you provide directly to us. </b>
            For certain activities, we may collect the following types of information:
          </p>
          <ul className="c-privacy-policy__content__section__list">
            <li>
              <b>Contact information, </b>
              such as name, email address and contact details;
            </li>
            <li>
              <b>Other information </b>
              you provide to us.
            </li>
          </ul>
          <p>
            <b>Information we collect automatically. </b>
            Depending on the type of device you use and how you interact with us, we
            may also collect certain information automatically when you use our Services, such as:
          </p>
          <ul className="c-privacy-policy__content__section__list">
            <li>
              <b>Device attributes, </b>
              including information such as the operating system, hardware and
              software versions, battery level, signal strength,
              available storage space, browser type, app and file names and types, and plugins.
            </li>
            <li>
              <b>Device operations, </b>
              including information about operations performed
              on the Services as well as pages visited.
            </li>
            <li>
              <b>Identifiers, </b>
              including information such as unique identifiers, device IDs,
              and other identifiers,
              and Family Device IDs associated with the same device or account.
            </li>
            <li>
              <b>Network and connections, </b>
              information such as the name of your mobile operator or ISP,
              language, time zone, mobile phone number, IP address, connection speed,
              information about other devices that are nearby or on your network,
              and Wi-Fi hotspots you connect to using our Services.
            </li>
            <li>
              <b>Cookie data, </b>
              data from cookies and other similar technologies that
              are stored on your device, including cookie IDs and settings.
            </li>
          </ul>
          <p>
            <b>Information you provide to us through our app (where applicable). </b>
            Some of our Services include the provision of an app. In such cases,
            we may receive certain video and text content that you choose to provide
            to us through our app. We may analyze this depending
            on the context and content in accordance with the purposes described below.
          </p>
          <p>
            <b>Integrations with other services from third parties. </b>
            Some of our Services allow you to enable or log in via various online
            services. By including these Login Services,
            we aim to make your online experiences richer and more personalized.
            When you add a Login Service account to our Services or log in to our
            Services using such an account, we may collect relevant information necessary
            to enable our Services to facilitate your login, such as your email address.
            We may also share your information with that
            Login Service to facilitate or enhance the delivery of that platform or our Services.
          </p>
          <p>
            As part of an integration with a Login Service,
            that Login Service may provide us with access to certain
            information that you have provided to their platform,
            and we will use, store, and disclose such information
            in accordance with this Privacy Policy. However, please
            remember that the manner in which platforms use, store,
            and disclose your information is governed by the policies
            that apply to those platforms. Information collected
            by Login Services is subject to their own terms and policies, not this one.
          </p>
        </section>
        <section
          className="c-privacy-policy__content__section"
          id="c-privacy-policy__sidebar__item__link-2"
        >
          <h3 className="c-privacy-policy__content__section__title">
            Cookie Policy
          </h3>
          <p>
            Cookies are small pieces of text used to store information on web browsers.
            Cookies are used to store and receive identifiers and other
            information on computers, phones and other devices.
            Other technologies, including data we store on the web browser
            or device, identifiers associated with your device, and other
            software, are used for similar purposes.
            In this policy, we refer to all of these technologies as &quot;cookies&quot;.
          </p>
          <p>
            Cookies help us provide, protect and improve our Application,
            such as personalizing content, and providing a safer experience.
            While the cookies that we use may change from time to time as we
            improve and update our Website, we use them for the following purposes:
          </p>
          <ul className="c-privacy-policy__content__section__list">
            <li>
              <b>Security. </b>
              We use cookies to help us keep the Product safe and secure. For example,
              cookies are used to help us identify and impose additional
              security measures against particular types of software attacks against websites.
            </li>
            <li>
              <b>Performance. </b>
              We use cookies to provide you with the best experience possible.
            </li>
            <li>
              <b>Functional cookies. </b>
              We use cookies to enhance the functionality of our Application.
              We use these cookies to make your
              online experiences richer and more personalized.
              We think that these cookies will improve your experience when using
              the Website but are non-essential to their use.
            </li>
            <li>
              <b>Analytics. </b>
              We use cookies served by Google Analytics to collect limited data directly
              from end-user browsers to enable us to better understand your use of the Website,
              including making use of the demographics and interests reports services provided
              by Google Analytics.
              <p>
                Further information on how Google collects and uses this data is
                available on Google&apos;s website at:
              </p>
              <a
                className="c-privacy-policy__content__section__link"
                href="https://www.google.com/policies/privacy/partners/"
                target="_blank"
                rel="noreferrer"
              >
                https://www.google.com/policies/privacy/partners/.
              </a>
              <p>
                You can opt-out of all Google supported analytics within the Services by visiting:
              </p>
              <a
                className="c-privacy-policy__content__section__link"
                href="https://www.google.com/policies/privacy/partners/"
                target="_blank"
                rel="noreferrer"
              >
                https://tools.google.com/dlpage/gaoptout.
              </a>
            </li>
          </ul>
          <p>
            You may be able to refuse or disable cookies by adjusting your
            web browser settings. Because each web browser is different,
            please consult the instructions provided by your web browser
            (typically in the &quot;help&quot; section). If you choose to refuse, disable,
            or delete these technologies,
            some of the functionality of the Website may no available to you.
          </p>
          <p>
            We may update this Cookie Policy from time to time in order to reflect,
            for example, changes to the cookies and related technologies we use or
            for other operational, legal or regulatory reasons.
            Each time you use our Website, the current version of the Cookie Policy
            will apply. When you use our Website, you should check the date of this Cookie Policy
            (which appears at the top of this document) and review any changes since
            the last version.
            To contact us with your questions or comments regarding this Cookie Policy
            or the information collection and dissemination practices of the Website,
            you can email us at
            <a
              className="c-privacy-policy__content__section__link"
              href="mailto:contact@arella.io"
            >
              &nbsp;contact@arella.io.
            </a>
          </p>
        </section>
        <section
          className="c-privacy-policy__content__section"
          id="c-privacy-policy__sidebar__item__link-3"
        >
          <h3
            className="c-privacy-policy__content__section__title"
          >
            How Do We Use Information?
          </h3>
          <p>
            We will use the information described above
            for the purpose of operating and providing the Websites and our Services.
          </p>
        </section>
        <section
          className="c-privacy-policy__content__section"
          id="c-privacy-policy__sidebar__item__link-4"
        >
          <h3
            className="c-privacy-policy__content__section__title"
          >
            How We Share Information
          </h3>
          <p>
            There are certain circumstances in which we may share your information
            with certain third parties without further notice to you, as set forth below:
          </p>
          <ul className="c-privacy-policy__content__section__list">
            <li>
              <b>Service providers and third-party vendors. </b>
              We share your information with third-party vendors and
              service providers that support the Services, for example
              cloud hosting providers (to provide data storage and processing services),
              communications providers (to process new queries and to manage our emails)
              and analytics providers to perform analysis on the Services. These service
              providers are limited from using
              your information for any purpose other than to perform services for us.
            </li>
            <li>
              <b>Legal purposes. </b>
              We may disclose information for legal purposes,
              such as to respond to subpoenas, court orders, legal process,
              law enforcement requests, legal claims or government inquiries,
              detect fraud, and to protect and defend the rights, interests, safety,
              and security of Meta, our affiliates, owner, users, or the public.
            </li>
            <li>
              <b>Business transfers. </b>
              We may share your information in connection with a substantial
              corporate transaction, such as the sale of a website, a merger,
              consolidation, asset sale, or in the unlikely event of bankruptcy.
            </li>
            <li>
              <b>With your consent. </b>
              We may share information for any other purposes disclosed to you
              at the time we collect the information or pursuant to your consent.
            </li>
          </ul>
        </section>
        <section
          className="c-privacy-policy__content__section"
          id="c-privacy-policy__sidebar__item__link-5"
        >
          <h3
            className="c-privacy-policy__content__section__title"
          >
            Children&apos;s Information
          </h3>
          <p>
            Application does not knowingly collect or store information from
            children under the age of 13, unless permitted by law.
            We will delete any information we may have inadvertently received
            from a child under 13. If you have reason to believe that a child under the age of 13
            has provided personal information through our Product  please contact us.
          </p>
        </section>
        <section
          className="c-privacy-policy__content__section"
          id="c-privacy-policy__sidebar__item__link-6"
        >
          <h3
            className="c-privacy-policy__content__section__title"
          >
            Third-Party Links
          </h3>
          <p>
            Our Product may contain links to other sites that we do not own or operate.
            Except as provided in this Privacy Policy, we do not provide your information
            to these third parties without your consent. The linked websites have separate
            and independent privacy statements, notices and terms of use, which we recommend
            you carefully review.
            We do not have any control over such websites, and therefore have no liability or
            responsibility for the linked websites&apos; personal information practices.
          </p>
        </section>
        <section
          className="c-privacy-policy__content__section"
          id="c-privacy-policy__sidebar__item__link-7"
        >
          <h3
            className="c-privacy-policy__content__section__title"
          >
            Data Retention
          </h3>
          <p>
            We retain information relating to the Services until it is no longer necessary
            to serve the purposes for which it was collected, such as to provide
            the Services to you or improve the Services.
          </p>
        </section>
        <section
          className="c-privacy-policy__content__section"
          id="c-privacy-policy__sidebar__item__link-8"
        >
          <h3
            className="c-privacy-policy__content__section__title"
          >
            Changes to this Policy
          </h3>
          <p>
            We may update or modify this Privacy Policy at any time without prior notice.
            We recommend that you review the Privacy Policy each time you visit the Product
            to stay informed of our privacy practices.
            Any changes will be effective when we post the revised policy.
          </p>
        </section>
        <section
          className="c-privacy-policy__content__section"
          id="c-privacy-policy__sidebar__item__link-9"
        >
          <h3
            className="c-privacy-policy__content__section__title"
          >
            Questions
          </h3>
          <p>
            If you have any questions about this Privacy Policy
            or our practices, please contact us at
            <a
              className="c-privacy-policy__content__section__link"
              href="mailto:contact@arella.io"
            >
              &nbsp;contact@arella.io.
            </a>
          </p>
        </section>
      </article>
    </main>
  </div>
);

export default PrivacyPolicy;
