import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Validator } from 'livr';

// Store
import { userProfileState } from '../../store/atoms';
import useFormMutations from '../../store/mutations/useFormMutations';

// Component
import Button from '../Button';
import InputControl from '../InputControl';
import Heading from '../Heading';
import Paragraph from '../Paragraph';
import TextareaControl from '../TextareaControl';

// Style
import './ContactForm.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const ContactForm = (props) => {
  const {
    formType,
    title,
    description,
  } = props;

  const userProfile = useRecoilValue(userProfileState);

  const defaultFormData = {
    name: userProfile && !userProfile.isTemp ? `${userProfile.firstName} ${userProfile.lastName}` : '',
    email: userProfile && !userProfile.isTemp ? userProfile.email : '',
    subject: '',
    type: formType,
    message: '',
    userId: userProfile ? userProfile.id : '',
  };

  const defaultFormErrors = {
    name: '',
    email: '',
    subject: '',
    message: '',
    hasError: false,
  };

  const formValidationRules = {
    name: ['required', { max_length: 100 }],
    email: ['required', 'email'],
    subject: [{ max_length: 100 }],
    message: ['required', { max_length: 1000 }],
  };

  const [formData, setFormData] = useState(defaultFormData);
  const [formErrors, setFormErrors] = useState(defaultFormErrors);
  const [isBtnActive, setIsBtnActive] = useState(true);

  const I18N = useI18N();
  const formValidator = new Validator(formValidationRules);
  const { sendFormMutation } = useFormMutations();

  const handleChange = (event) => {
    const { name, value } = event.target;

    const escapedValue = value
      .replace(/</g, '')
      .replace(/>/g, '')
      .replace(/"/g, '')
      .replace(/'/g, '');

    setFormData({
      ...formData,
      [name]: escapedValue,
    });
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    const fieldValidator = new Validator({ [name]: formValidationRules[name] });
    fieldValidator.validate({
      [name]: value,
    });

    const errorMessages = fieldValidator.getErrors() || {};
    const errors = {};
    Object.keys(errorMessages).forEach((key) => {
      errors[key] = I18N[errorMessages[key]];
    });

    setFormErrors((prevState) => ({
      ...prevState,
      ...errors,
      hasError: !!fieldValidator.errors,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    sendFormMutation(formData);
    setFormData(defaultFormData);
  };

  const handleFocus = (event) => {
    const { name } = event.target;
    setFormErrors((prevState) => ({
      ...prevState,
      [name]: '',
    }));
  };

  useEffect(() => {
    formValidator.validate(formData);
    setIsBtnActive(formValidator.errors === null);
  }, [formErrors, formData]);

  return (
    <div className="c-contact-form">
      {title && (<Heading text={title} />)}
      {description && (<Paragraph>{description}</Paragraph>)}
      <InputControl
        label={I18N.NAME}
        name="name"
        placeholder={I18N.ENTER_YOUR_NAME}
        value={formData.name || ''}
        onChange={handleChange}
        errorMessage={formErrors.name}
        isValid={formErrors.name === '' && formData.name !== ''}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <InputControl
        label={I18N.EMAIL}
        name="email"
        placeholder={I18N.ENTER_YOUR_EMAIL}
        value={formData.email || ''}
        onChange={handleChange}
        errorMessage={formErrors.email}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <InputControl
        label={I18N.SUBJECT}
        name="subject"
        placeholder={I18N.ENTER_YOUR_SUBJECT}
        value={formData.subject || ''}
        onChange={handleChange}
        errorMessage={formErrors.subject}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <TextareaControl
        label={I18N.MESSAGE}
        name="message"
        placeholder={I18N.ENTER_YOUR_MESSAGE}
        value={formData.message || ''}
        onChange={handleChange}
        errorMessage={formErrors.message}
        onBlur={handleBlur}
        onFocus={handleFocus}
      />
      <Button
        text={I18N.SEND}
        disabled={!isBtnActive}
        style={{ marginTop: '20px' }}
        onClick={handleSubmit}
      />
      <div className="c-contact-form__notification">
        {I18N.MESSAGE_PROMO_CONTACT_FORM_A}
      </div>
    </div>
  );
};

export default ContactForm;
