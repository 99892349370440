import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useRecoilState, useRecoilValue } from 'recoil';

// Component
import PromoTop from '../PromoTop';
import PromoFeatureHero from '../PromoFeatureHero';
import PromoCTA from '../PromoCTA';
import PromoFeatureList from '../PromoFeatureList';
import PromoContactForm from '../PromoContactForm';

// Style
import './About.scss';

// Configs
import {
  AUTH_FORM_TYPE,
  PAGE,
  AUTH_STATUS,
} from '../../config/config';

// Store
import { sessionState, userProfileState } from '../../store/atoms';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const About = () => {
  const navigate = useNavigate();
  const [session, setSession] = useRecoilState(sessionState);
  const [cookies] = useCookies(['anonymousUserCreated, jwt-token']);
  const userProfile = useRecoilValue(userProfileState);
  const token = cookies['jwt-token'];
  const I18N = useI18N();

  const handleRegister = () => {
    setSession({
      ...session,
      authStatus: AUTH_STATUS.NOT_AUTH,
    });
    navigate({
      pathname: `/${PAGE.AUTH.PATH}`,
      search: `?type=${AUTH_FORM_TYPE.REGISTER}`,
    });
  };

  const handleLogin = () => {
    navigate(`/${PAGE.AUTH.PATH}`);
  };

  const navigateToHome = () => {
    navigate(PAGE.HOME.PATH);
  };

  const navigateToSupportForm = () => {
    navigate(`/${PAGE.SUPPORT.PATH}`);
  };

  return (
    <div className="c-promo">
      <PromoTop
        handleRegister={handleRegister}
        handleLogin={handleLogin}
        token={token}
        userProfile={userProfile}
      />
      <PromoFeatureHero />
      <PromoCTA
        handleRegister={handleRegister}
        handleLogin={handleLogin}
        navigateToHome={navigateToHome}
        token={token}
        userProfile={userProfile}
        ctaLeft
        sloganPrimary={I18N.SLOGAN_ARELLA_A}
        sloganSecondary={I18N.SLOGAN_ARELLA_B}
      />
      <PromoFeatureList
        navigateToSupportForm={navigateToSupportForm}
      />
      <PromoCTA
        handleRegister={handleRegister}
        handleLogin={handleLogin}
        navigateToHome={navigateToHome}
        token={token}
        userProfile={userProfile}
        ctaRight
        sloganPrimary={I18N.SLOGAN_ARELLA_C}
        sloganSecondary={I18N.SLOGAN_ARELLA_D}
      />
      <PromoContactForm
        navigateToHome={navigateToHome}
        token={token}
        userProfile={userProfile}
      />
    </div>
  );
};

export default About;
