import React from 'react';
import Icon from '../Icon';

const IconChevronUp = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-chevron-up"
    >

      <svg
        viewBox="0 0 19 10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0406 9.69992C17.363 9.99019 17.808 10.0774 18.2081 9.92856C18.6081 9.77977 18.9023 9.41764 18.9799 8.97858C19.0575 8.53951 18.9067 8.09022 18.5843 7.79995L10.2719 0.300076C9.82748 -0.100026 9.17252 -0.100026 8.72813 0.300076L0.415703 7.79995C-0.0827298 8.24867 -0.141212 9.03776 0.285079 9.56242C0.71137 10.0871 1.46101 10.1486 1.95944 9.69992L9.5 2.89753L17.0406 9.69742V9.69992Z"
        />
      </svg>
    </Icon>
  );
};

export default IconChevronUp;
