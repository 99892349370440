import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';

import { defaultToasterTimeout } from '../../config/config';

// Store
import { toastersState } from '../../store/atoms';

// Style
import './Toaster.scss';

const Toaster = (props) => {
  const { toasterTimeout } = props;

  // Global State
  const [toasters, setToasters] = useRecoilState(toastersState);

  // State
  const [toasterHeight, setToasterHeight] = useState(null);
  const [toastList, setToastList] = useState([]);

  // Component Logic
  const toasterRef = useRef(null);
  const autoDeleteTime = toasterTimeout || defaultToasterTimeout;

  const closeToaster = (index) => {
    const newToasterList = [...toasters];
    newToasterList.splice(index, 1);
    setToasters(newToasterList);

    toastList.splice(index, 1);
    setToastList([...toastList]);
  };

  useEffect(() => {
    const { height } = toasterRef.current.getBoundingClientRect();

    setToasterHeight(height + 10);

    if (toasters.length && toastList[toastList.length - 1] !== toasters[toasters.length - 1]) {
      setToastList(toastList.concat(toasters[toasters.length - 1]));
      if (toasters.length > 2) {
        closeToaster(0);
      }
    }

    const intervalToaster = setInterval(() => {
      if (toasters.length) {
        closeToaster(0);
      }
    }, autoDeleteTime);

    return () => {
      clearInterval(intervalToaster);
    };
  }, [toasters, toastList]);

  return (
    <div ref={toasterRef} className="c-toaster">
      {
            toastList.map((toast, index) => (
              <div
                key={toast.id}
                onClick={() => closeToaster(index)}
                className={`c-toaster__toast c-toaster__toast_${toast.type}`}
                style={{
                  '--toaster-height': `${toasterHeight}px`,
                }}
              >
                <p className="c-toaster__title">{toast.message}</p>
              </div>
            ))
        }
    </div>
  );
};

export default Toaster;
