import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children }) => {
  const [portalRoot, setPortalRoot] = useState(null);

  useEffect(() => {
    const el = document.getElementById('portal');
    setPortalRoot(el);
  }, []);

  return (portalRoot && ReactDOM.createPortal(children, portalRoot));
};

export default Portal;
