import React from 'react';
import Icon from '../Icon';

const IconWarning = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-warning">
      <svg viewBox="0 0 57 50" xmlns="http://www.w3.org/2000/svg">
        <path d="M55.6169 42.97C57.4195 46.0944 55.1569 50 51.5567 50H4.6928C1.08567 50 -1.16648 46.0884 0.632548 42.97L24.0648 2.34229C25.8682 -0.783593 30.3851 -0.777929 32.1853 2.34229L55.6169 42.97ZM28.125 34.5703C25.6441 34.5703 23.6328 36.5815 23.6328 39.0625C23.6328 41.5435 25.6441 43.5547 28.125 43.5547C30.606 43.5547 32.6172 41.5435 32.6172 39.0625C32.6172 36.5815 30.606 34.5703 28.125 34.5703ZM23.8601 18.4232L24.5845 31.7045C24.6184 32.326 25.1323 32.8125 25.7546 32.8125H30.4954C31.1178 32.8125 31.6317 32.326 31.6656 31.7045L32.39 18.4232C32.4266 17.752 31.8921 17.1875 31.2199 17.1875H25.0301C24.3578 17.1875 23.8235 17.752 23.8601 18.4232V18.4232Z" />
      </svg>
    </Icon>
  );
};

export default IconWarning;
