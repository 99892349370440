import React from 'react';
import Icon from '../Icon';

const IconChevronRight = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-chevron-right">
      <svg width="6" height="12" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.180048 1.23754C0.0058844 1.0339 -0.0464103 0.752817 0.0428634 0.500165C0.132137 0.247514 0.349416 0.0616817 0.612854 0.0126696C0.876292 -0.0363417 1.14587 0.0589123 1.32003 0.262552L5.81995 5.51251C6.06002 5.79317 6.06002 6.20683 5.81995 6.4875L1.32003 11.7375C1.0508 12.0523 0.577344 12.0892 0.262547 11.82C-0.05225 11.5507 -0.0891859 11.0773 0.180048 10.7625L4.26148 6L0.181548 1.23754H0.180048Z" fill="#515151" />
      </svg>
    </Icon>
  );
};

export default IconChevronRight;
