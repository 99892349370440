import { useEffect, useState } from 'react';

const useCountdown = (targetDate, callback) => {
  const timeLeft = targetDate - new Date().getTime();

  const [countDown, setCountDown] = useState(timeLeft);

  const getReturnValues = (countDownValue) => {
    const days = Math.floor(countDownValue / (1000 * 60 * 60 * 24));
    const hours = Math.floor((countDownValue % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((countDownValue % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.round((countDownValue % (1000 * 60)) / 1000);

    return [days, hours, minutes, seconds];
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime();
      const timeRemain = targetDate - currentTime;

      if (timeRemain <= 0) {
        clearInterval(interval);
        callback();
        setCountDown(0);
      } else {
        setCountDown(timeRemain);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  if (timeLeft < 100) {
    return [0, 0, 0, 0];
  }

  return getReturnValues(countDown || timeLeft);
};

export default useCountdown;
