import {
  React,
  useState,
  useRef,
  useEffect,
} from 'react';

// Components
import Button from '../Button';
import {
  IconPlus,
  IconCrossRound,
  IconSave,
  IconBin,
  IconEdit,
  IconChevronUp,
} from '../Icons';
import ToDoItem from '../ToDoItem';

// Style
import './ToDoList.scss';
import 'react-tooltip/dist/react-tooltip.css';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const ToDoList = (props) => {
  const {
    data,
    createToDoMutation,
    updateToDoMutation,
    deleteToDoMutation,
    isModalMode,
    isMobile,
    closeSectionCheckListMobile,
  } = props;

  const I18N = useI18N();

  const refForInput = useRef(null);
  const isCheckboxDisabledRef = useRef(false);

  // State
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [todoListVisible, setToDoListVisible] = useState(false);

  // State for CheckBox
  const [checkedItems, setCheckedItems] = useState({});

  // State for editing ToDoItem
  const [editingItemId, setEditingItemId] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');

  const setInputFocus = (delay) => {
    setTimeout(() => {
      if (refForInput.current) {
        refForInput.current.focus();
      }
    }, delay);
  };

  const setInputActive = () => {
    setInputVisible(true);
    let delay = 0;
    if (!todoListVisible && isModalMode) {
      setToDoListVisible(true);
      delay = 500;
    }
    setInputFocus(delay);
  };

  const closeInput = () => {
    setInputVisible(false);
  };

  const updateInputValue = (event) => {
    setInputValue(event.target.value);
  };

  const createToDoItem = (event) => {
    if (event.type === 'click' && inputValue !== null) {
      setInputValue(null);
      setInputVisible(false);
      createToDoMutation({ taskId: data.id, title: inputValue });

      setTimeout(() => {
        setInputVisible(true);
        setInputFocus(500);
      }, 0);
    }
  };

  const showToDoList = () => {
    if (todoListVisible === true) {
      setToDoListVisible(false);
      closeInput();
    } else {
      setToDoListVisible(true);
    }
  };

  const deleteToDoItem = (todoItem) => {
    deleteToDoMutation({ id: todoItem.id, title: todoItem.title });
  };

  useEffect(() => {
    const updatedIsChecked = {};
    if (data.todoList) {
      data.todoList.forEach((todoItem) => {
        updatedIsChecked[todoItem.id] = todoItem.status === 'DONE';
      });
    }
    setCheckedItems(updatedIsChecked);
  }, [data]);

  const handleCheckboxChange = (todoItem) => {
    if (isCheckboxDisabledRef.current) {
      return;
    }

    setCheckedItems((prevCheckedItems) => {
      const updatedCheckedItems = { ...prevCheckedItems };
      updatedCheckedItems[todoItem.id] = !updatedCheckedItems[todoItem.id];

      updateToDoMutation({
        id: todoItem.id,
        status: updatedCheckedItems[todoItem.id] ? 'DONE' : 'ACTIVE',
        title: todoItem.title,
        taskId: data.id,
      });

      return updatedCheckedItems;
    });
    isCheckboxDisabledRef.current = true;

    setTimeout(() => {
      isCheckboxDisabledRef.current = false;
    }, 1000);
  };

  const handleInputKeyDown = (event) => {
    if (event.key === 'Enter' && inputValue !== null) {
      setInputValue(null);
      setInputVisible(false);
      createToDoMutation({ taskId: data.id, title: inputValue });

      setTimeout(() => {
        setInputVisible(true);
        setInputFocus(500);
      }, 0);
    } else if (event.key === 'Escape') {
      setInputVisible(false);
    }
  };

  // Methods for editing todoItem
  const handleEditClick = (todoItem) => {
    setTimeout(() => {
      if (refForInput.current) {
        refForInput.current.focus();
      }
    }, 0);
    setEditingItemId(todoItem.id);
    setEditedTitle(todoItem.title);
  };

  const handleEditSave = (todoItem) => {
    updateToDoMutation({ id: todoItem.id, title: editedTitle, status: todoItem.status });
    setEditingItemId(null);
  };

  const handleKeyPress = (event, todoItem) => {
    if (event.key === 'Enter') {
      handleEditSave(todoItem);
    }
  };

  const EditTitleBlur = (todoItem) => {
    handleEditSave(todoItem);
  };

  const todoListModalClassName = !todoListVisible ? 'c-todo-list' : 'c-todo-list visible';
  const todoListClassName = !isMobile ? todoListModalClassName : `${todoListModalClassName} mobile`;

  let sortedToDoList = [];

  if (Array.isArray(data.todoList) && data.todoList.length) {
    sortedToDoList = [...data.todoList].sort(
      (prevTodo, nextTodo) => prevTodo.id - nextTodo.id,
    );
  }

  return (
    <div className={todoListClassName}>
      <div className="c-todo-list__header">
        <div className="c-todo-list__header__title">
          <span className="c-task__title">{I18N.CHECKLIST}</span>
          {isMobile && (
            <IconPlus
              size="xs"
              onClick={setInputActive}
            />
          )}
        </div>
        {!isMobile && (
          <Button
            text={I18N.ADD}
            iconLeft={<IconPlus />}
            size="xs"
            onClick={setInputActive}
          />
        )}
        {isMobile && (
          <IconChevronUp
            size="xs"
            onClick={closeSectionCheckListMobile}
          />
        )}
      </div>
      <div className="c-todo-list__summary-data">
        <span className="c-todo-list__summary-data__title">
          {I18N.TOTAL}
        </span>
        {data.todoList?.length > 0 ? (
          <span className="c-todo-list__summary-data__total">
            <p>
              {I18N.ITEMS}
              {': '}
              {data.todoList.length}
            </p>
          </span>
        ) : (
          <span className="c-todo-list__summary-data__empty-state">
            {I18N.NO_ITEMS}
          </span>
        )}
        {isModalMode && (
          <span
            className="c-todo-list__summary-data__view"
            onClick={showToDoList}
          >
            {todoListVisible ? I18N.HIDE_ALL : I18N.VIEW_ALL}
          </span>
        )}
      </div>
      {(!isModalMode || todoListVisible) && (
        <ul className="c-todo-list__list">
          {data.todoList?.length > 0 && sortedToDoList.map((todoItem) => (
            <li
              key={todoItem.id}
              className="c-todo-list__list__item"
            >
              {todoItem.id !== editingItemId && (
                <input
                  className="c-todo-list__list__item__checkbox"
                  type="checkbox"
                  checked={checkedItems[todoItem.id] || false}
                  onChange={() => handleCheckboxChange(todoItem)}
                />
              )}
              {editingItemId === todoItem.id ? (
                <>
                  <input
                    className="c-todo-list__list__item__checkbox"
                    type="checkbox"
                  />
                  <input
                    ref={refForInput}
                    className="c-todo-list__list__item__edit__field"
                    type="text"
                    value={editedTitle}
                    onChange={(event) => setEditedTitle(event.target.value)}
                    onBlur={() => EditTitleBlur(todoItem)}
                    onKeyDown={(event) => handleKeyPress(event, todoItem)}
                  />
                </>
              ) : (
                <>
                  <ToDoItem
                    todoItem={todoItem}
                    isChecked={checkedItems[todoItem.id] || false}
                  />
                  <div className="c-todo-list__list__item__options">
                    <IconEdit size="xs" onClick={() => handleEditClick(todoItem)} />
                    <IconBin size="xs" onClick={() => deleteToDoItem(todoItem)} />
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      )}
      {inputVisible && (
        <div className="c-todo-list__input">
          <input
            ref={refForInput}
            className="c-todo-list__input__field"
            type="text"
            placeholder={I18N.ENTER_NEW_ITEM}
            value={inputValue || ''}
            onChange={updateInputValue}
            onKeyDown={handleInputKeyDown}
          />
          <div className="c-todo-list__input__options">
            <IconSave
              size="xs"
              onClick={createToDoItem}
            />
            <IconCrossRound
              size="xs"
              onClick={closeInput}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ToDoList;
