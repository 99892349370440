import React, { useRef } from 'react';

import './Logo.scss';

const Logo = (props) => {
  const {
    imgUrl, text, size, imgPosition,
  } = props;
  const refImg = useRef();
  const refIcon = useRef();

  // Component logic
  const classImgPosition = imgPosition === 'right' ? 'right-side-image' : '';
  const classLogoSize = size || 'md';

  const getText = () => (
    <div className="c-logo__name">
      <div>
        <b>{text || 'ΛRE'}</b>
        {text ? null : 'LLΛ'}
      </div>
    </div>
  );
  // Functions
  const handleLoadImageError = () => {
    refImg.current.hidden = true;
    refIcon.current.style.justifyContent = 'flex-start';
    refImg.current.onerror = null;
  };

  // Render function
  return (
    <div className={`c-logo c-logo_${classLogoSize} ${classImgPosition}`}>
      <div ref={refIcon} className="c-logo__wrapper">
        <div className="c-logo__icon">
          <img
            ref={refImg}
            src={imgUrl}
            alt="logo"
            onError={handleLoadImageError}
          />
        </div>
        {getText()}
      </div>
    </div>
  );
};

export default Logo;
