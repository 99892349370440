import React from 'react';
import Icon from '../Icon';

const IconHorizontalSliders = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-horizontal-sliders">
      <svg viewBox="0 0 21 18" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.9286 14.1429H6.42857V13.5C6.42857 13.1464 6.13929 12.8571 5.78571 12.8571H4.5C4.14643 12.8571 3.85714 13.1464 3.85714 13.5V14.1429H0.642857C0.289286 14.1429 0 14.4321 0 14.7857V16.0714C0 16.425 0.289286 16.7143 0.642857 16.7143H3.85714V17.3571C3.85714 17.7107 4.14643 18 4.5 18H5.78571C6.13929 18 6.42857 17.7107 6.42857 17.3571V16.7143H19.9286C20.2821 16.7143 20.5714 16.425 20.5714 16.0714V14.7857C20.5714 14.4321 20.2821 14.1429 19.9286 14.1429ZM19.9286 7.71429H16.7143V7.07143C16.7143 6.71786 16.425 6.42857 16.0714 6.42857H14.7857C14.4321 6.42857 14.1429 6.71786 14.1429 7.07143V7.71429H0.642857C0.289286 7.71429 0 8.00357 0 8.35714V9.64286C0 9.99643 0.289286 10.2857 0.642857 10.2857H14.1429V10.9286C14.1429 11.2821 14.4321 11.5714 14.7857 11.5714H16.0714C16.425 11.5714 16.7143 11.2821 16.7143 10.9286V10.2857H19.9286C20.2821 10.2857 20.5714 9.99643 20.5714 9.64286V8.35714C20.5714 8.00357 20.2821 7.71429 19.9286 7.71429ZM19.9286 1.28571H11.5714V0.642857C11.5714 0.289286 11.2821 0 10.9286 0H9.64286C9.28929 0 9 0.289286 9 0.642857V1.28571H0.642857C0.289286 1.28571 0 1.575 0 1.92857V3.21429C0 3.56786 0.289286 3.85714 0.642857 3.85714H9V4.5C9 4.85357 9.28929 5.14286 9.64286 5.14286H10.9286C11.2821 5.14286 11.5714 4.85357 11.5714 4.5V3.85714H19.9286C20.2821 3.85714 20.5714 3.56786 20.5714 3.21429V1.92857C20.5714 1.575 20.2821 1.28571 19.9286 1.28571Z" />
      </svg>
    </Icon>
  );
};

export default IconHorizontalSliders;
