const FeatureListText = {
  en: [
    {
      number: 1,
      title: 'Unlimited number tools',
      description: 'Say goodbye to limitations. Create an unrestricted number of boards, columns (statuses) and task cards, empowering you to manage your projects with the freedom and flexibility you deserve. Whether your project is small or extensive, our platform adapts to your needs, ensuring a seamless and limitless task management experience',
    },
    {
      number: 2,
      title: 'Advanced filters and sorting',
      description: 'Optimize your task management with our advanced filters and sorting functionality that lets you easily organize boards into private, shared, favorite and archive,  filter boards based on creation and update dates. Dive deeper into your tasks with flexible filtering options based on priorities, assigned users, and tags, providing unprecedented customization to suit your unique workflow preferences',
    },
    {
      number: 3,
      title: 'Custom color marks',
      description: 'Elevate your task management experience with custom color marks. Infuse personality into your boards, columns (statuses), and task cards by applying custom color marks. Tailor your workspace to match your preferences, adding an extra layer of vibrancy and clarity. With the ability to personalize every detail, make your work environment uniquely yours.',
    },
    {
      number: 4,
      title: 'Collaborate seamlessly',
      description: 'Unleash the full potential of teamwork. Take advantage of advanced team features like shared boards and real-time collaboration. Whether your team works side by side or is located across the globe, Arella ensures everyone is in sync, promoting effective collaboration',
    },
    {
      number: 5,
      title: 'Dynamic work process visibility',
      description: 'Immerse yourself in dynamic work process visibility. Effortlessly move tasks between columns using intuitive drag-and-drop gestures, offering a transparent and ever-optimizing workflow. Our interface ensures clear visibility at every task stage, promoting transparency and a quick grasp of the workflow dynamics with just a glance',
    },
    {
      number: 6,
      title: 'Time tracking',
      description: 'Dive into the intricacies of project management in our application, where each task has important data. Track task progress with start and end dates, ensuring accurate deadlines. Estimating tasks allows for careful planning and estimation of the time required to complete a task. But the depth does not end there. Our app goes even further by allowing users to log actual time spent on tasks. This detailed report not only promotes accurate time management, but also serves as a valuable resource for performance analysis. Identify patterns, refine your estimates, and gain actionable insights into your work habits, paving the way for continuous improvement',
    },
    {
      number: 7,
      title: 'Support',
      description: 'Should you have questions regarding the functionality of our platform, require guidance in the setup process, or encounter any challenges, our support team is here to extend personalized attention. Our commitment goes beyond merely addressing issues – we aim to empower you with comprehensive insights, ensuring you not only resolve immediate concerns but also understand the nuances of our platform. Your success is our priority, and we are dedicated to providing a support experience that is responsive, informative, and aligned with your goals',
    },
    {
      number: 8,
      title: 'Basic application settings',
      description: 'Explore the versatility of Basic Application Settings, where customization meets convenience. Adjust the visual aesthetics to match your preference by personalizing the color scheme, ensuring a visually pleasing interface that resonates with your style. Tailor the language settings to suit your communication preferences, providing a comfortable user experience. Additionally, express your individuality by adding a user avatar. Make your presence felt across the application, fostering a sense of personal connection. Basic Application Settings put you in control, allowing you to fine-tune every detail to create an environment that truly reflects your unique identity and enhances your overall user experience',
    },
  ],
  uk: [
    {
      number: 1,
      title: 'Необмежена кількість інструментів',
      description: 'Прощавайте обмеження. Створюйте необмежену кількість дошок, стовпців (статусів) та карток завдань, що надає вам можливість керувати своїми проектами з тією свободою і гнучкістю, яку ви заслуговуєте.Незважаючи на те,  маленький чи великий ваш проект, наша платформа адаптується до ваших потреб, забезпечуючи безперешкодний та необмежений досвід управління завданнями',
    },
    {
      number: 2,
      title: 'Розширені фільтри та сортування',
      description: 'Оптимізуйте управління завданнями за допомогою наших розширених фільтрів та функцій сортування, які дозволяють вам легко організовувати дошки в особисті, загальні, улюблені та архівні, фільтрувати дошки за датами створення та оновлення. Зануриться глибше у свої завдання з гнучкими опціями фільтрації за пріоритетами, призначеними користувачами та тегами, забезпечуючи безпрецедентне налаштування у відповідності до ваших унікальних вподобань робочого процесу',
    },
    {
      number: 3,
      title: 'Користувацькі кольорові мітки',
      description: 'Підвищте ваш досвід управління завданнями за допомогою користувацьких кольорових міток. Надайте індивідуальність вашим дошкам, колонкам (статусам) та карткам завдань, застосовуючи користувацькі кольорові мітки. Налаштуйте свій робочий простір відповідно до ваших уподобань, додавши додатковий шар яскравості та ясності. З можливістю персоналізації кожної деталі, ваше робоче середовище стане унікальним',
    },
    {
      number: 4,
      title: 'Безперешкодне співробітництво',
      description: 'Розкрийте повний потенціал командної роботи. Скористайтеся перевагами розширених командних функцій, таких як спільні дошки та співпраця в реальному часі. Незалежно від того, чи працює ваша команда поруч чи розташована по всьому світу, Arella забезпечує синхронізацію всіх, сприяючи ефективній співпраці',
    },
    {
      number: 5,
      title: 'Динамічна видимість робочого процесу',
      description: 'Зануртеся в динамічну видимість робочого процесу. Легко переміщуйте завдання між стовпцями за допомогою інтуїтивних жестів перетягування, пропонуючи прозорий та постійно оптимізований робочий процес. Наш інтерфейс забезпечує чітку видимість на кожному етапі завдання, сприяючи прозорості та швидкому розумінню динаміки робочого процесу всього в один погляд',
    },
    {
      number: 6,
      title: 'Облік часу',
      description: 'Зануртеся в тонкощі управління проектом у нашому додатку, де кожне завдання має важливі дані. Відстежуйте прогрес завдань з вказівкою дати початку і дати завершення, забезпечуючи точні терміни. Оцінка завдань дозволяє ретельно планувати і визначати час, необхідний для виконання завдання. Проте глибина не обмежується на цьому. Наш додаток йде ще далі, дозволяючи користувачам реєструвати фактичний час, витрачений на завдання. Цей детальний звіт не тільки сприяє точному управлінню часом, але й є цінним ресурсом для аналізу продуктивності. Визначайте закономірності, уточнюйте ваші оцінки та отримуйте практичні висновки про ваши робочі звички, відкриваючи шлях до постійного вдосконалення',
    },
    {
      number: 7,
      title: 'Підтримка',
      description: 'У разі виникнення питань щодо функціоналу нашої платформи, необхідності керівництва в процесі налаштування чи зіткнення з будь-якими труднощами, наша служба підтримки готова надати персоналізовану увагу. Наше зобов’язання виходить за межі простого вирішення питань – ми прагнемо надати вам всебічні уявлення, забезпечуючи, що ви не лише вирішуєте термінові питання, але й розумієте тонкощі нашої платформи. Ваш успіх – наш пріоритет, і ми присвячені наданню підтримки, яка є чуйною, інформативною та відповідає вашим цілям',
    },
    {
      number: 8,
      title: 'Базові налаштування додатка',
      description: 'Відкрийте для себе універсальність базових налаштувань додатка, де налаштування поєднується зі зручністю. Регулюйте візуальну естетику відповідно до своїх уподобань, персоналізуючи кольорову схему і забезпечуючи візуально приємний інтерфейс, який відповідає вашому стилю. Налаштовуйте мовні параметри відповідно до своїх уподобань комунікації, забезпечуючи комфортний користувацький досвід. Крім того, виразіть свою індивідуальність, додавши аватар користувача. Робіть свою присутність помітною в додатку, створюючи відчуття особистого зв’язку. Базові налаштування додатка дають вам можливість контролювати кожну деталь, щоб створити середовище, що дійсно відображає вашу унікальну ідентичність і поліпшує ваш користувацький досвід',
    },
  ],
  ru: [
    {
      number: 1,
      title: 'Неограниченное количество инструментов',
      description: 'Попрощайтесь с ограничениями. Создавайте неограниченное количество досок, столбцов (статусов) и карточек задач, что позволит вам управлять своими проектами с той свободой и гибкостью, которую вы заслуживаете. Независимо от того, является ли ваш проект небольшим или обширным, наша платформа адаптируется к вашим потребностям, обеспечивая беспрепятственное и безграничное управление задачами',
    },
    {
      number: 2,
      title: 'Расширенные фильтры и сортировка',
      description: 'Оптимизируйте управление задачами с помощью наших расширенных фильтров и функций сортировки, которые позволяют легко организовывать доски в частные, общие, избранные и архивные, фильтровать доски по датам создания и обновления. Погрузитесь глубже в свои задачи с помощью гибких параметров фильтрации на основе приоритетов, назначенных пользователей и тегов, обеспечивая беспрецедентную настройку в соответствии с вашими уникальными предпочтениями рабочего процесса',
    },
    {
      number: 3,
      title: 'Пользовательские цветовые метки',
      description: 'Повысьте качество управления задачами с помощью настраиваемых цветовых меток. Придайте индивидуальность своим доскам, столбцам (статусам) и карточкам задач, применяя специальные цветовые метки. Настройте свое рабочее пространство в соответствии со своими предпочтениями, добавив дополнительный уровень яркости и ясности. Благодаря возможности персонализировать каждую деталь ваша рабочая среда станет уникальной',
    },
    {
      number: 4,
      title: 'Беспрепятственное сотрудничество',
      description: 'Раскройте весь потенциал командной работы. Воспользуйтесь преимуществами расширенных командных функций, таких как общие доски и совместная работа в режиме реального времени. Независимо от того, работает ли ваша команда бок о бок или находится по всему миру, Arella гарантирует, что все будут синхронизированы, способствуя эффективному сотрудничеству',
    },
    {
      number: 5,
      title: 'Динамичная видимость рабочего процесса',
      description: 'Погрузитесь в динамическую видимость рабочего процесса. Без труда перемещайте задачи между столбцами с помощью интуитивно понятных жестов перетаскивания, обеспечивая прозрачный и постоянно оптимизированный рабочий процесс. Наш интерфейс обеспечивает четкую видимость на каждом этапе задачи, обеспечивая прозрачность и быстрое понимание динамики рабочего процесса с первого взгляда',
    },
    {
      number: 6,
      title: 'Учет времени',
      description: 'Погрузитесь в тонкости управления проектами в нашем приложении, где каждая задача имеет важные данные. Отслеживайте ход выполнения задач с указанием дат начала и окончания, обеспечивая точные сроки. Оценивание задач позволяет осуществить тщательное планирование и оценить необходимое время на выполнение задачи. Но на этом глубина не заканчивается. Наше приложение идет еще дальше, позволяя пользователям регистрировать фактическое время, потраченное на выполнение задач. Этот подробный отчет не только способствует точному управлению временем, но и служит ценным ресурсом для анализа производительности. Выявляйте закономерности, уточняйте ваши оценки и получайте практические идеи о ваших рабочих привычках, открывая путь к непрерывному улучшению',
    },
    {
      number: 7,
      title: 'Поддержка',
      description: 'Если у вас возникнут вопросы относительно функционала нашей платформы, вам потребуется помощь в процессе настройки или вы столкнетесь с какими-либо проблемами, наша служба поддержки всегда готова оказать вам индивидуальный подход. Наше обязательство выходит за рамки простого решения проблем — мы стремимся предоставить вам исчерпывающую информацию, гарантируя, что вы не только решите насущные проблемы, но и поймете нюансы нашей платформы. Ваш успех является нашим приоритетом, и мы стремимся предоставить поддержку, которая будет оперативной, информативной и соответствующей вашим целям',
    },
    {
      number: 8,
      title: 'Базовые настройки приложения',
      description: 'Откройте для себя универсальность базовых настроек приложения, где настройка сочетается с удобством. Настройте визуальную эстетику в соответствии со своими предпочтениями, персонализировав цветовую схему и обеспечив визуально приятный интерфейс, который соответствует вашему стилю. Настройте языковые параметры в соответствии со своими предпочтениями в общении, обеспечивая удобство работы пользователя. Кроме того, выразите свою индивидуальность, добавив аватар пользователя. Сделайте свое присутствие ощутимым в приложении, создавая ощущение личной связи. Базовые настройки приложения дают вам возможность контролировать каждую деталь, чтобы создать среду, которая действительно отражает вашу уникальную индивидуальность и улучшает общий пользовательский опыт',
    },
  ],
  fr: [
    {
      number: 1,
      title: 'Nombre illimité d’outils',
      description: 'Dites adieu aux limitations. Créez un nombre illimité de tableaux, de colonnes (statuts) et de cartes de tâches, vous donnant le pouvoir de gérer vos projets avec la liberté et la flexibilité que vous méritez. Que votre projet soit petit ou étendu, notre plateforme s’adapte à vos besoins, garantissant une expérience de gestion des tâches fluide et sans limites',
    },
    {
      number: 2,
      title: 'Filtres avancés et tri',
      description: 'Optimisez la gestion de vos tâches avec notre fonctionnalité avancée de filtres et de tri qui vous permet de facilement organiser les tableaux en privé, partagé, favori et archivé, de filtrer les tableaux en fonction des dates de création et de mise à jour. Plongez plus profondément dans vos tâches avec des options de filtrage flexibles basées sur les priorités, les utilisateurs assignés et les tags, offrant une personnalisation sans précédent pour répondre à vos préférences uniques de flux de travail',
    },
    {
      number: 3,
      title: 'Marques de couleur personnalisées',
      description: 'Rehaussez votre expérience de gestion des tâches avec des marques de couleur personnalisées. Insufflez de la personnalité dans vos tableaux, colonnes (statuts) et cartes de tâches en appliquant des marques de couleur personnalisées. Adaptez votre espace de travail à vos préférences, ajoutant une couche supplémentaire de dynamisme et de clarté. Avec la possibilité de personnaliser chaque détail, faites de votre environnement de travail quelque chose d’unique',
    },
    {
      number: 4,
      title: 'Collaborez de manière transparente',
      description: 'Libérez tout le potentiel de l’équipe. Profitez des fonctionnalités avancées d’équipe telles que les tableaux partagés et la collaboration en temps réel. Que votre équipe travaille côte à côte ou soit répartie dans le monde entier, Arella assure que tout le monde est synchronisé, favorisant une collaboration efficace',
    },
    {
      number: 5,
      title: 'Visibilité dynamique du processus de travail',
      description: 'Plongez-vous dans la visibilité dynamique du processus de travail. Déplacez facilement les tâches entre les colonnes à l’aide de gestes intuitifs de glisser-déposer, offrant un flux de travail transparent et en constante optimisation. Notre interface assure une visibilité claire à chaque étape de la tâche, favorisant la transparence et une compréhension rapide de la dynamique du flux de travail en un seul coup d’œil',
    },
    {
      number: 6,
      title: 'Suivi du temps',
      description: 'Plongez dans les subtilités de la gestion de projet dans notre application, où chaque tâche a des données importantes. Suivez la progression des tâches avec les dates de début et de fin, assurant des délais précis. L’estimation des tâches permet une planification minutieuse et une estimation du temps nécessaire pour accomplir une tâche. Mais la profondeur ne s’arrête pas là. Notre application va encore plus loin en permettant aux utilisateurs d’enregistrer le temps réellement passé sur les tâches. Ce rapport détaillé favorise non seulement une gestion précise du temps, mais sert également de ressource précieuse pour l’analyse des performances. Identifiez des tendances, affinez vos estimations et obtenez des informations exploitables sur vos habitudes de travail, ouvrant la voie à l’amélioration continue',
    },
    {
      number: 7,
      title: 'Support',
      description: 'Si vous avez des questions concernant la fonctionnalité de notre plateforme, si vous avez besoin d’orientation dans le processus de configuration ou si vous rencontrez des défis, notre équipe de support est là pour vous offrir une attention personnalisée. Notre engagement va au-delà de la simple résolution des problèmes – nous visons à vous autonomiser avec des informations complètes, garantissant que vous résolvez non seulement les préoccupations immédiates, mais comprenez également les subtilités de notre plateforme. Votre réussite est notre priorité, et nous sommes déterminés à fournir une expérience de support réactive, informative et alignée sur vos objectifs',
    },
    {
      number: 8,
      title: 'Paramètres de base de l’application',
      description: 'Explorez la polyvalence des Paramètres de base de l’application, où la personnalisation rencontre la commodité. Ajustez l’esthétique visuelle selon vos préférences en personnalisant la palette de couleurs, assurant une interface visuellement agréable qui résonne avec votre style. Adaptez les paramètres de langue selon vos préférences de communication, offrant une expérience utilisateur fluide et confortable. De plus, exprimez votre individualité en ajoutant un avatar utilisateur. Faites-vous sentir à travers l’application, favorisant un sentiment de connexion personnelle. Les Paramètres de base de l’application vous donnent le contrôle, vous permettant d’affiner chaque détail pour créer un environnement qui reflète vraiment votre identité unique et améliore votre expérience utilisateur globale',
    },
  ],
  de: [
    {
      number: 1,
      title: 'Unbegrenzte anzahl von werkzeugen',
      description: 'Sagt lebewohl zu einschränkungen. Erstellt eine unbegrenzte anzahl von boards, spalten (status) und aufgabenkarten, um eure projekte mit der freiheit und flexibilität zu verwalten, die ihr verdient. Euer projekt sei klein oder umfangreich, unsere plattform passt sich euren bedürfnissen an und garantiert eine nahtlose und grenzenlose erfahrung im aufgabenmanagement',
    },
    {
      number: 2,
      title: 'Fortgeschrittene filter und sortierung',
      description: 'Optimieren sie ihr aufgabenmanagement mit unserer erweiterten filter- und sortierfunktionalität, mit der sie boards einfach in privat, geteilt, favorisiert und archiviert organisieren können, Boards nach erstellungs- und aktualisierungsdaten filtern können. Tauchen Sie tiefer in ihre aufgaben ein mit flexiblen filteroptionen basierend auf prioritäten, zugewiesenen benutzern und tags, um eine beispiellose anpassung an ihre individuellen workflow-präferenzen zu bieten',
    },
    {
      number: 3,
      title: 'Benutzerdefinierte farbmarkierungen',
      description: 'Verbessern sie ihre erfahrung im aufgabenmanagement mit benutzerdefinierten farbmarkierungen. Verleihen sie ihren boards, spalten (status) und aufgabenkarten persönlichkeit, indem sie benutzerdefinierte farbmarkierungen anwenden. Passen sie ihren arbeitsbereich nach ihren vorlieben an und fügen sie eine zusätzliche ebene von lebendigkeit und klarheit hinzu. Mit der möglichkeit, jedes detail zu personalisieren, gestalten sie ihre arbeitsumgebung einzigartig',
    },
    {
      number: 4,
      title: 'Zusammenarbeit ohne Probleme',
      description: 'Entfesseln sie das volle potenzial von teamarbeit. Nutzen sie fortgeschrittene teamfunktionen wie gemeinsame boards und echtzeit-kollaboration. Ob ihr team seite an seite arbeitet oder auf der ganzen Welt verteilt ist, stellt Arella sicher, dass alle synchronisiert sind und fördert so eine effektive zusammenarbeit',
    },
    {
      number: 5,
      title: 'Dynamische Sichtbarkeit des Arbeitsprozesses',
      description: 'Tauchen sie ein in die dynamische sichtbarkeit des arbeitsprozesses. Verschieben sie aufgaben mühelos zwischen spalten mit intuitiven drag-and-drop-gesten und bieten sie einen transparenten und ständig optimierten Workflow. Unsere benutzeroberfläche gewährleistet klare sichtbarkeit auf jeder aufgabenstufe und fördert transparenz sowie ein schnelles erfassen der dynamik des arbeitsablaufs auf einen blick',
    },
    {
      number: 6,
      title: 'Zeitverfolgung',
      description: 'Eintauchen in die feinheiten des projektmanagements in unserer anwendung, in der jede aufgabe wichtige daten hat. Verfolgen sie den fortschritt der aufgaben mit start- und enddaten und stellen sie sicher, dass genaue fristen eingehalten werden. Die schätzung von aufgaben ermöglicht eine sorgfältige planung und eine einschätzung der benötigten zeit für eine aufgabe. Aber die tiefe endet nicht hier. Unsere App geht noch weiter, indem sie benutzern ermöglicht, die tatsächlich auf aufgaben verbrachte zeit zu protokollieren. Dieser detaillierte bericht fördert nicht nur ein genaues zeitmanagement, sondern dient auch als wertvolle ressource für die leistungsanalyse. Identifizieren sie muster, verfeinern sie ihre schätzungen und gewinnen sie handlungsorientierte einblicke in ihre arbeitsgewohnheiten, und legen sie so den weg für kontinuierliche verbesserung frei',
    },
    {
      number: 7,
      title: 'Unterstützung',
      description: 'Sollten sie fragen zur funktionalität unserer plattform haben, unterstützung bei der einrichtung benötigen oder auf herausforderungen stoßen, steht ihnen unser support-team bereit, um ihnen persönliche aufmerksamkeit zu schenken. Unser engagement geht über die bloße behebung von problemen hinaus – wir streben danach, sie mit umfassenden einblicken zu stärken, damit sie nicht nur unmittelbare anliegen lösen, sondern auch die nuancen unserer plattform verstehen. Ihr erfolg ist unsere priorität, und wir sind darauf bedacht, eine support-erfahrung anzubieten, die reaktionsschnell, informativ und auf ihre ziele ausgerichtet ist',
    },
    {
      number: 8,
      title: 'Grundlegende anwendungseinstellungen',
      description: 'Erkunde die vielseitigkeit der grundlegenden anwendungseinstellungen, wo anpassung auf Bequemlichkeit trifft. Passe die visuelle ästhetik an deine vorlieben an, indem du das farbschema personalisierst und eine visuell ansprechende oberfläche schaffst, die zu deinem stil passt. Stelle die spracheinstellungen nach deinen kommunikationspräferenzen ein und sorge für eine nahtlose und komfortable Benutzererfahrung. Außerdem drücke deine individualität aus, indem du einen benutzer-avatar hinzufügst. Mach deine präsenz in der gesamten anwendung bemerkbar und fördere ein gefühl der persönlichen verbindung. Die grundlegenden anwendungseinstellungen geben dir die kontrolle und ermöglichen es dir, jeden detail zu feinabstimmen, um eine umgebung zu schaffen, die wirklich deine einzigartige identität widerspiegelt und dein gesamtes benutzererlebnis verbessert',
    },
  ],
  es: [
    {
      number: 1,
      title: 'Número ilimitado de herramientas',
      description: 'Di adiós a las limitaciones. Crea un número ilimitado de tableros, columnas (estados) y tarjetas de tareas, otorgándote la capacidad de gestionar tus proyectos con la libertad y flexibilidad que mereces. Ya sea que tu proyecto sea pequeño o extenso, nuestra plataforma se adapta a tus necesidades, asegurando una experiencia de gestión de tareas sin problemas y sin límites',
    },
    {
      number: 2,
      title: 'Filtros avanzados y clasificación',
      description: 'Optimiza la gestión de tus tareas con nuestra avanzada funcionalidad de filtros y clasificación que te permite organizar fácilmente tableros en privados, compartidos, favoritos y archivados, filtrar tableros según las fechas de creación y actualización. Adéntrate más en tus tareas con opciones de filtrado flexibles basadas en prioridades, usuarios asignados y etiquetas, brindando una personalización sin precedentes para adaptarse a tus únicas preferencias de flujo de trabajo',
    },
    {
      number: 3,
      title: 'Marcas de color personalizadas',
      description: 'Mejora tu experiencia de gestión de tareas con marcas de color personalizadas. Infunde personalidad en tus tableros, columnas (estados) y tarjetas de tareas aplicando marcas de color personalizadas. Adapta tu espacio de trabajo a tus preferencias, añadiendo una capa adicional de vitalidad y claridad. Con la capacidad de personalizar cada detalle, haz que tu entorno de trabajo sea verdaderamente único',
    },
    {
      number: 4,
      title: 'Colabora de manera fluida',
      description: 'Libera todo el potencial del trabajo en equipo. Aprovecha las funciones avanzadas del equipo, como tableros compartidos y colaboración en tiempo real. Ya sea que tu equipo trabaje codo a codo o esté ubicado en todo el mundo, Arella asegura que todos estén sincronizados, promoviendo una colaboración efectiva',
    },
    {
      number: 5,
      title: 'Visibilidad dinámica del proceso de trabajo',
      description: 'Sumérgete en la visibilidad dinámica del proceso de trabajo. Mueve las tareas sin esfuerzo entre las columnas mediante gestos intuitivos de arrastrar y soltar, ofreciendo un flujo de trabajo transparente y en constante optimización. Nuestra interfaz asegura una visibilidad clara en cada etapa de la tarea, promoviendo la transparencia y una comprensión rápida de la dinámica del flujo de trabajo con solo un vistazo',
    },
    {
      number: 6,
      title: 'Seguimiento del tiempo',
      description: 'Sumérgete en las complejidades de la gestión de proyectos en nuestra aplicación, donde cada tarea tiene datos importantes. Realiza un seguimiento del progreso de las tareas con fechas de inicio y fin, asegurando plazos precisos. La estimación de tareas permite una planificación cuidadosa y una estimación del tiempo necesario para completar una tarea. Pero la profundidad no termina ahí. Nuestra aplicación va aún más lejos al permitir que los usuarios registren el tiempo real dedicado a las tareas. Este informe detallado no solo promueve una gestión precisa del tiempo, sino que también sirve como un recurso valioso para el análisis del rendimiento. Identifica patrones, perfecciona tus estimaciones y obtén ideas prácticas sobre tus hábitos de trabajo, allanando el camino para la mejora continua',
    },
    {
      number: 7,
      title: 'Soporte',
      description: 'En caso de tener preguntas sobre la funcionalidad de nuestra plataforma, necesitar orientación en el proceso de configuración o enfrentar algún desafío, nuestro equipo de soporte está aquí para brindarle atención personalizada. Nuestro compromiso va más allá de simplemente abordar problemas: nuestro objetivo es capacitarlo con conocimientos integrales, asegurando que no solo resuelva preocupaciones inmediatas, sino que también comprenda las complejidades de nuestra plataforma. Su éxito es nuestra prioridad, y estamos dedicados a proporcionar una experiencia de soporte receptiva, informativa y alineada con sus objetivos',
    },
    {
      number: 8,
      title: 'Configuraciones básicas de la aplicación',
      description: 'Explora la versatilidad de Configuración Básica de la Aplicación, donde la personalización se encuentra con la conveniencia. Ajusta la estética visual para que coincida con tus preferencias personalizando la paleta de colores, asegurando una interfaz visualmente agradable que resuene con tu estilo. Adapta la configuración de idioma según tus preferencias de comunicación, proporcionando una experiencia de usuario cómoda. Además, expresa tu individualidad agregando un avatar de usuario. Haz sentir tu presencia en toda la aplicación, fomentando una sensación de conexión personal. La Configuración Básica de la Aplicación te pone en control, permitiéndote afinar cada detalle para crear un entorno que refleje verdaderamente tu identidad única y mejore tu experiencia general como usuario',
    },
  ],
};

export default FeatureListText;
