import React from 'react';
import Icon from '../Icon';

const IconCopy = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-copy"
    >
      <svg
        viewBox="0 0 20 23"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.3724 2.94379L17.0562 0.627634C16.6543 0.22577 16.1093 2.97177e-06 15.541 0L7.85714 0C6.67366 0 5.71429 0.959375 5.71429 2.14286V4.28571H2.14286C0.959375 4.28571 0 5.24509 0 6.42857V20.7143C0 21.8978 0.959375 22.8571 2.14286 22.8571H12.1429C13.3263 22.8571 14.2857 21.8978 14.2857 20.7143V18.5714H17.8571C19.0406 18.5714 20 17.6121 20 16.4286V4.45902C20 3.8907 19.7742 3.34566 19.3724 2.94379ZM11.875 20.7143H2.41071C2.33967 20.7143 2.27154 20.6861 2.22131 20.6358C2.17108 20.5856 2.14286 20.5175 2.14286 20.4464V6.69643C2.14286 6.62539 2.17108 6.55726 2.22131 6.50702C2.27154 6.45679 2.33967 6.42857 2.41071 6.42857H5.71429V16.4286C5.71429 17.6121 6.67366 18.5714 7.85714 18.5714H12.1429V20.4464C12.1429 20.5175 12.1146 20.5856 12.0644 20.6358C12.0142 20.6861 11.946 20.7143 11.875 20.7143ZM17.5893 16.4286H8.125C8.05396 16.4286 7.98583 16.4004 7.9356 16.3501C7.88536 16.2999 7.85714 16.2318 7.85714 16.1607V2.41071C7.85714 2.33967 7.88536 2.27154 7.9356 2.22131C7.98583 2.17108 8.05396 2.14286 8.125 2.14286H12.8571V6.07143C12.8571 6.66317 13.3368 7.14286 13.9286 7.14286H17.8571V16.1607C17.8571 16.2318 17.8289 16.2999 17.7787 16.3501C17.7285 16.4004 17.6603 16.4286 17.5893 16.4286ZM17.8571 5H15V2.14286H15.43C15.501 2.14286 15.5692 2.17107 15.6194 2.22129L17.7787 4.38058C17.8036 4.40546 17.8233 4.43499 17.8368 4.46749C17.8502 4.49999 17.8571 4.53482 17.8571 4.57V5Z"
        />
      </svg>
    </Icon>
  );
};

export default IconCopy;
