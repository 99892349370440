import React from 'react';

// Style
import './Copyright.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const Copyright = () => {
  const today = new Date();
  const year = today.getFullYear();
  const I18N = useI18N();

  return (
    <div className="c-copyright">
      <span>{ `© Lanadev. ${year}.` }</span>
      <span>
        &nbsp;
        {I18N.RIGHTS}
      </span>
    </div>
  );
};

export default Copyright;
