import React, { useState, useRef } from 'react';

import './Tooltip.scss';

const Tooltip = (props) => {
  const {
    children,
    text,
    info,
    position = 'bottom',
    disabled,
  } = props;

  const classTooltipContent = `c-tooltip__content c-tooltip__content_${position.toLowerCase()}`;
  const content = text || info;

  const [showTooltip, setShowTooltip] = useState(false);
  const refSetTimeout = useRef(null);

  const onMouseEnterHandler = () => {
    if (disabled) return;
    if (refSetTimeout.current) {
      clearTimeout(refSetTimeout.current);
    }
    setShowTooltip(true);
  };

  const onMouseLeaveHandler = () => {
    if (disabled) return;
    refSetTimeout.current = setTimeout(() => {
      setShowTooltip(false);
    }, 300);
  };

  return (
    <span
      className="c-tooltip"
      onMouseEnter={onMouseEnterHandler}
      onMouseLeave={onMouseLeaveHandler}
    >
      {children}
      {showTooltip && (
        <div className={classTooltipContent}>
          {content}
        </div>
      )}
    </span>
  );
};

export default Tooltip;
