import React from 'react';
import { useCookies } from 'react-cookie';

// Component
import Button from '../Button';
import { IconEmail } from '../Icons';
import FeatureListText from './FeatureListText';

// Style
import './PromoFeatureList.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const PromoFeatureList = (props) => {
  const I18N = useI18N();

  const {
    navigateToSupportForm,
  } = props;

  const [cookies] = useCookies(['i18next']);
  const lang = cookies.i18next || navigator.language || navigator.userLanguage || 'en';
  const translation = FeatureListText[lang];

  return (
    <div className="c-promo-feature-list">
      <div className="c-promo__container">
        <div className="c-promo-feature-list__title">
          <span>{I18N.WHAT_YOU_GET_WITH}</span>
          <span>
            <span className="c-promo__logo">
              <strong>ΛRE</strong>
              LLΛ
            </span>
            :
          </span>
        </div>
        <div className="c-promo-feature-list__list">
          {translation && translation.map((feature) => (
            <div
              className="c-promo-feature-list__list__item"
              key={feature.number}
            >
              <div className="c-promo-feature-list__list__item__number">{`0${feature.number}`}</div>
              <div className="c-promo-feature-list__list__item__title">{feature.title}</div>
              <div className="c-promo-feature-list__list__item__description">{feature.description}</div>
              <div className="c-promo-feature-list__list__item__button__mobile__invisible">
                {`0${feature.number}` === '07' && (
                <Button
                  text={I18N.GET_IN_TOUCH}
                  iconLeft={<IconEmail size="xl" />}
                  size="xl"
                  onClick={navigateToSupportForm}
                />
                )}
              </div>
              <div className="c-promo-feature-list__list__item__button__invisible">
                {`0${feature.number}` === '08' && (
                <Button
                  text={I18N.GET_IN_TOUCH}
                  iconLeft={<IconEmail size="xl" />}
                  size="xl"
                  onClick={navigateToSupportForm}
                />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PromoFeatureList;
