import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useI18N } from '../../i18n/useI18N';

import Modal from '../Modal';
import CheckboxControl from '../CheckboxControl';

import { filterConfigState } from '../../store/atoms';

import './FilterModal.scss';

const FilterModal = (props) => {
  const { boardData, closeFilterModal } = props;

  const I18N = useI18N();
  const [filterData, setFilterData] = useRecoilState(filterConfigState(boardData.id));
  const [tmpFilterData, setTmpFilterData] = useState(filterData);

  const cancelFilterModal = () => {
    setTmpFilterData(filterData);
    closeFilterModal();
  };

  const submitFilterModal = () => {
    setFilterData(tmpFilterData);
    closeFilterModal();
  };

  const changeFilter = (param) => {
    setTmpFilterData((prevParams) => ({
      ...prevParams,
      [param]: !prevParams[param],
    }));
  };

  return (
    <Modal
      className="c-filter-modal"
      isVisible={() => null}
      onClose={closeFilterModal}
      title={I18N.FILTERS}
      btnAcceptHandler={submitFilterModal}
      btnCancelHandler={cancelFilterModal}
    >
      <div className="c-filter-modal__checkboxes">
        <CheckboxControl
          name="user"
          label={I18N.ASSIGNED_USER}
          value={tmpFilterData.assignedToUser}
          onChange={() => null}
          disabled
        />
        <CheckboxControl
          name="Priority"
          label={I18N.PRIORITY}
          value={tmpFilterData.priority}
          onChange={() => changeFilter('priority')}
        />
        <CheckboxControl
          name="tags"
          label={I18N.TAGS}
          value={tmpFilterData.tags}
          onChange={() => changeFilter('tags')}
        />
      </div>
    </Modal>
  );
};

export default FilterModal;
