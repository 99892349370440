import React from 'react';

// import { useI18N } from '../../../i18n/useI18N';

import Icon from '../Icon';

const IconGoogle = (props) => {
  const { size, inverse, onClick } = props;
  // const I18N = useI18N();

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-google"
    >
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_4558_6125)">
          <path
            d="M12.5406 1.18406C8.94363 2.43187 5.84162 4.80027 3.69019 7.94137C1.53876 11.0825 0.451312 14.8307 0.58758 18.6355C0.723849 22.4403 2.07665 26.1012 4.44727 29.0803C6.8179 32.0595 10.0814 34.1999 13.7584 35.1872C16.7394 35.9564 19.8627 35.9902 22.8596 35.2856C25.5746 34.6758 28.0847 33.3713 30.144 31.5C32.2874 29.4928 33.8431 26.9395 34.644 24.1144C35.5143 21.0421 35.6692 17.8114 35.0968 14.67H18.3568V21.6141H28.0515C27.8578 22.7216 27.4426 23.7786 26.8308 24.722C26.219 25.6653 25.4231 26.4755 24.4909 27.1041C23.3072 27.8874 21.9727 28.4144 20.5731 28.6509C19.1695 28.912 17.7298 28.912 16.3262 28.6509C14.9035 28.3571 13.5577 27.7699 12.3746 26.9269C10.4738 25.5813 9.04655 23.6698 8.29652 21.465C7.53403 19.2189 7.53403 16.7839 8.29652 14.5378C8.83041 12.9634 9.71299 11.5299 10.8784 10.3444C12.2121 8.96272 13.9005 7.97511 15.7585 7.4899C17.6165 7.00468 19.5723 7.04061 21.4112 7.59374C22.8478 8.03453 24.1615 8.80503 25.2475 9.84374C26.3406 8.75624 27.4318 7.66593 28.5212 6.57281C29.0837 5.98499 29.6968 5.42531 30.2509 4.82343C28.593 3.28083 26.6471 2.08041 24.5246 1.29093C20.6594 -0.112534 16.4302 -0.150251 12.5406 1.18406Z"
            fill="white"
          />
          <path
            d="M12.5401 1.18405C16.4294 -0.151164 20.6586 -0.11444 24.5241 1.28811C26.647 2.08296 28.592 3.28915 30.2476 4.83749C29.6851 5.43936 29.0916 6.00186 28.5179 6.58686C27.4266 7.67624 26.3363 8.76186 25.247 9.84374C24.161 8.80502 22.8473 8.03452 21.4107 7.59374C19.5724 7.03867 17.6167 7.00066 15.7582 7.48389C13.8997 7.96712 12.2102 8.95291 10.8751 10.3331C9.70967 11.5187 8.82709 12.9522 8.2932 14.5266L2.46289 10.0125C4.54979 5.87407 8.16312 2.70849 12.5401 1.18405Z"
            fill="#E33629"
          />
          <path
            d="M0.917101 14.4844C1.23024 12.9312 1.75052 11.4272 2.46398 10.0125L8.29429 14.5378C7.53179 16.7839 7.53179 19.2189 8.29429 21.465C6.35179 22.965 4.40835 24.4725 2.46398 25.9875C0.678463 22.4334 0.133912 18.3839 0.917101 14.4844Z"
            fill="#F8BD00"
          />
          <path
            d="M18.3567 14.6672H35.0967C35.669 17.8086 35.5141 21.0393 34.6439 24.1116C33.843 26.9366 32.2873 29.49 30.1439 31.4972C28.2623 30.0291 26.3723 28.5722 24.4908 27.1041C25.4236 26.4748 26.2198 25.6638 26.8317 24.7194C27.4435 23.7751 27.8584 22.717 28.0514 21.6084H18.3567C18.3539 19.2966 18.3567 16.9819 18.3567 14.6672Z"
            fill="#587DBD"
          />
          <path
            d="M2.46094 25.9875C4.40531 24.4875 6.34875 22.98 8.29125 21.465C9.04277 23.6706 10.4721 25.5822 12.375 26.9269C13.5617 27.766 14.9105 28.3484 16.335 28.6369C17.7386 28.8979 19.1783 28.8979 20.5819 28.6369C21.9815 28.4003 23.316 27.8734 24.4997 27.09C26.3813 28.5581 28.2712 30.015 30.1528 31.4831C28.0938 33.3555 25.5837 34.6609 22.8684 35.2716C19.8715 35.9761 16.7482 35.9423 13.7672 35.1731C11.4095 34.5436 9.20721 33.4339 7.29844 31.9134C5.2783 30.3092 3.62822 28.2879 2.46094 25.9875Z"
            fill="#319F43"
          />
        </g>
        <defs>
          <clipPath id="clip0_4558_6125">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

export default IconGoogle;
