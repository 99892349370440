import React from 'react';
import Icon from '../Icon';

import './IconEdit.scss';

const IconEdit = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-edit">
      <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.23685 3.6665H2.1579C1.79032 3.6665 1.43779 3.80698 1.17788 4.05703C0.917957 4.30708 0.771936 4.64622 0.771936 4.99984V10.9998C0.771936 11.3535 0.917957 11.6926 1.17788 11.9426C1.43779 12.1927 1.79032 12.3332 2.1579 12.3332H8.39474C8.76232 12.3332 9.11484 12.1927 9.37476 11.9426C9.63468 11.6926 9.7807 11.3535 9.7807 10.9998V8.99984" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.23684 9.00007H6.31579L12.2061 3.3334C12.4818 3.06819 12.6367 2.70848 12.6367 2.3334C12.6367 1.95833 12.4818 1.59862 12.2061 1.3334C11.9305 1.06819 11.5565 0.919189 11.1667 0.919189C10.7768 0.919189 10.4029 1.06819 10.1272 1.3334L4.23684 7.00007V9.00007Z" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M9.08772 2.3335L11.1667 4.3335" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
};

export default IconEdit;
