import { atomFamily } from 'recoil';

// Configs
import { DEFAULT_BOARD_SORT_TYPE } from '../../config/config';

const sortBoardState = atomFamily({
  key: 'sortBoardAtom',
  default: {
    type: DEFAULT_BOARD_SORT_TYPE,
    orderBy: false,
  },
});

export default sortBoardState;
