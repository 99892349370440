import React from 'react';
import Icon from '../Icon';

const IconVisualization = (props) => {
  const {
    size,
    inverse,
    onClick,
    role,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-visualization"
      role={role}
    >
      <svg
        viewBox="0 0 345.57 345.57"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <g>
            <g>
              <path d="M172.785,0h-15.583v82.164h31.156V32.018c71.392,7.773,126.035,67.902,126.035,140.768
                c0,78.096-63.531,141.626-141.608,141.626c-78.08,0-141.608-63.53-141.608-141.626c0-31.963,10.386-62.123,30.024-87.211
                l5.491-6.995L42.145,59.37l-5.479,6.996C12.679,96.993,0,133.796,0,172.785C0,268.054,77.507,345.57,172.785,345.57
                c95.269,0,172.785-77.504,172.785-172.785C345.57,77.507,268.054,0,172.785,0z"
              />
            </g>
            <g>
              <path d="M147.949,199.257c5.645,6.768,13.577,10.924,22.314,11.704c1.009,0.096,2.017,0.144,3.008,0.144
                c16.858,0,31.264-13.174,32.777-29.97c0.792-8.749-1.874-17.297-7.512-24.073c-5.645-6.776-13.571-10.938-22.332-11.736
                c-2.88-0.252-5.801-0.099-8.905,0.511l-95.167-80.65l69.908,102.736c-0.879,2.558-1.414,4.945-1.625,7.269
                C139.624,183.939,142.299,192.49,147.949,199.257z"
              />
            </g>
          </g>
        </g>
      </svg>
    </Icon>
  );
};

export default IconVisualization;
