/* eslint-disable react/jsx-no-useless-fragment */
import React, {
  useEffect,
  useState,
  Suspense,
  lazy,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

// Configs
import {
  // API_CONTROLLER,
  AUTH_FORM_TYPE,
  AUTH_STATUS,
  BOARD_DEFAULT,
  // DELAY_TIME,
  PAGE,
} from '../../config/config';

// Utils
import orderSort from '../../utils/orderSort';

// Store
import {
  activeBoardState,
  // activeTaskState,
  boardsState,
  sessionState,
  userProfileState,
} from '../../store/atoms';
import useBoardMutations from '../../store/mutations/useBoardMutations';

// Hooks
// import useSyncData from '../../hooks/useSyncData';
import { useI18N } from '../../i18n/useI18N';

// Components
import Board from '../../components/Board';
import Loader from '../../components/Loader';

// Styles
import './BoardView.scss';

const BoardView = () => {
  const Onboarding = lazy(() => import('../../components/Onboarding'));

  const I18N = useI18N();
  const { boardId } = useParams();

  // Global State
  const [activeBoard, setActiveBoard] = useRecoilState(activeBoardState);
  const [session, setSession] = useRecoilState(sessionState);
  // const activeTask = useRecoilValue(activeTaskState);
  const userProfile = useRecoilValue(userProfileState);
  const boards = useRecoilValue(boardsState);

  const [cookies, setCookie] = useCookies(['onboardingApp']);

  const navigate = useNavigate();
  const isMobile = window.innerWidth < 768;
  const [showOnboarding, setShowOnboarding] = useState(!cookies.onboardingApp && !isMobile);

  // Mutations
  const { setActiveBoardById } = useBoardMutations();

  // State
  const [, setIsDraggableItem] = useState(false);
  // const [isDraggableItem, setIsDraggableItem] = useState(false);

  // Component logic

  const highestOrderBoard = boards.length
    ? orderSort(boards).at(-1).order + 1
    : 0;

  const favoriteBoard = (userProfile?.favoriteBoards)
    ? userProfile.favoriteBoards
      .find((favBoard) => favBoard.boardId === +boardId)
    : null;

  // const syncBoard = () => {
  //   if (boardId !== 'new'
  //     && !userProfile.isTemp
  //     && !activeTask
  //     && !isDraggableItem) {
  //     setActiveBoardById(boardId);
  //   }
  // };

  // const { startSync, stopSync } = useSyncData(
  //   API_CONTROLLER.BOARD,
  //   DELAY_TIME,
  //   [activeTask, isDraggableItem, boardId],
  //   syncBoard,
  // );

  const newBoard = {
    ...BOARD_DEFAULT,
    name: I18N.BOARD_TITLE,
    id: 'new',
    ownerId: userProfile.id,
    users: [{ ...userProfile }],
    order: highestOrderBoard,
  };

  const closeOnboarding = () => {
    setCookie('onboardingApp', true, { path: '/', maxAge: 365 * 24 * 60 * 60 });
    setShowOnboarding(false);
  };

  const handleRegister = () => {
    setSession({
      ...session,
      authStatus: AUTH_STATUS.NOT_AUTH,
    });
    if (!cookies.onboardingApp) {
      closeOnboarding();
    }
    navigate({
      pathname: `/${PAGE.AUTH.PATH}`,
      search: `?type=${AUTH_FORM_TYPE.REGISTER}`,
    });
  };

  useEffect(() => {
    // startSync();
    if (activeBoard?.id !== parseInt(boardId, 10) && boardId !== 'new') {
      setActiveBoardById(boardId);
    }

    if (boardId === 'new') {
      setActiveBoard(newBoard);
    }

    return () => {
      // stopSync();
      // TODO problem: when we go to task page we should do unnesassary request to board
      setActiveBoard(null);
    };
  }, []);

  return (
    <div className="v-board">
      {activeBoard ? (
        <Board favoriteBoardData={favoriteBoard} setIsDraggableItem={setIsDraggableItem} />
      ) : (
        <Loader />
      )}
      {showOnboarding && userProfile && activeBoard && (
        <Suspense fallback={<></>}>
          <Onboarding
            closeOnboarding={closeOnboarding}
            handleRegister={handleRegister}
          />
        </Suspense>
      )}
    </div>
  );
};

export default BoardView;
