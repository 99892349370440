import React, { useState } from 'react';

// Component
import Button from '../Button';
import Modal from '../Modal';
import {
  IconSelect,
  IconChange,
} from '../Icons';
import InputControl from '../InputControl';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Style
import './TaskBoardSelect.scss';
import { checkBoardIndexShouldUpdate, generateNewBoardIndex } from '../../utils/boardIndexUtils';
import TaskWarning from '../TaskWarning/TaskWarning';

const TaskBoardSelect = (props) => {
  const {
    data,
    isTaskPage = false,
    changeBoardTaskMutation,
    isMobile,
    boards,
  } = props;

  const I18N = useI18N();

  const taskBoard = data.board;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedBoard, setSelectedBoard] = useState(taskBoard);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBoards, setFilteredBoards] = useState(boards);
  const [boardIndexAlert, setBoardIndexAlert] = useState(null);

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const closeWarning = () => {
    setBoardIndexAlert(null);
  };

  const selectBoard = (board) => {
    setSelectedBoard(board);
  };

  const changeBoardTask = () => {
    const payload = {
      boardId: selectedBoard.id,
      id: data.id,
    };

    const newBoard = boards.find((board) => board.id === selectedBoard.id);

    const isBoardIndexShouldUpdate = checkBoardIndexShouldUpdate(data.boardIndex, newBoard.tasks)
      && selectedBoard.id !== data.board.id;

    if (isBoardIndexShouldUpdate && !boardIndexAlert) {
      const newBoardIndex = generateNewBoardIndex(
        newBoard.taskIndexCounter,
        newBoard.taskIndexPrefix,
        newBoard.tasks,
      );

      const newTaskIndexCounter = Number(newBoardIndex.replace(`${newBoard.taskIndexPrefix}-`, ''));

      setBoardIndexAlert({
        keyTitle: 'CHANGING_TASK_INDEX',
        keyDescription: 'MESSAGE_ABOUT_CHANGED_TASK_INDEX',
        optionsTranslate: { oldBoardIndex: data.boardIndex, newBoardIndex },
        payload: { ...payload, taskIndexCounter: newTaskIndexCounter, boardIndex: newBoardIndex },
      });
    }

    if (isBoardIndexShouldUpdate && boardIndexAlert) {
      const { payload: newPayload } = boardIndexAlert;
      closeWarning();

      changeBoardTaskMutation(newPayload, isTaskPage);
    }

    if (!isBoardIndexShouldUpdate) {
      changeBoardTaskMutation(payload, isTaskPage);
    }
  };

  const resetInput = () => {
    setSearchQuery('');
    setFilteredBoards(boards);
  };

  const acceptHandler = () => {
    changeBoardTask();
    resetInput();
    closeModal();
  };

  const cancelHandler = () => {
    setSelectedBoard(taskBoard);
    resetInput();
    closeModal();
  };

  const handleChangeInput = (event) => {
    const { value } = event.currentTarget;
    setSearchQuery(value);
    const newBoards = boards.filter((memberBoards) => memberBoards.name.indexOf(value) >= 0);
    setFilteredBoards(newBoards);
  };

  return (
    <div className="c-task-board-select">
      <div className="c-task-board-select__header">
        <span className="c-task-board-select__header__title">
          {I18N.BOARD}
        </span>
        <Button
          type="primary-inverse"
          text={I18N.BOARD}
          iconLeft={<IconChange />}
          size="xs"
          onClick={openModal}
        />
      </div>
      <div className="c-task-board-select__board-current">
        {taskBoard ? taskBoard.name : I18N.BACKLOG}
      </div>
      {boardIndexAlert && (
        <TaskWarning
          acceptChange={changeBoardTask}
          closeWarning={closeWarning}
          data={boardIndexAlert}
        />
      )}
      <Modal
        isVisible={isOpenModal}
        onClose={cancelHandler}
        title={I18N.CHANGE_BOARD}
        btnAcceptText={I18N.CHANGE_BOARD}
        btnAcceptHandler={acceptHandler}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={cancelHandler}
        className="modal-board-select"
        isMobile={isMobile}
      >
        <InputControl
          label={I18N.SEARCH_BOARD}
          type="text"
          name="board-query"
          placeholder={`${I18N.ENTER_BOARD_NAME}...`}
          value={searchQuery}
          onChange={handleChangeInput}
          onReset={resetInput}
        />
        {filteredBoards.length > 0
          ? (
            <div className="c-task-board-select__boards">
              <h4 className="c-task-board-select__boards__title">
                {I18N.BOARDS}
              </h4>
              <ul className="c-task-board-select__boards__list">
                {filteredBoards.map((memberBoards) => (
                  <li
                    className="c-task-board-select__boards__list__member"
                    key={memberBoards.id}
                    onClick={() => selectBoard(memberBoards)}
                  >
                    <span className="c-task-board-select__boards__list__member__name">
                      {memberBoards.name}
                    </span>
                    {selectedBoard.id === memberBoards.id
                      && (
                        <IconSelect
                          size="xs"
                        />
                      )}
                  </li>
                ))}
              </ul>
            </div>
          )
          : (
            <div className="c-task-board-select__boards">
              <h4 className="c-task-board-select__boards__title">
                {I18N.BOARDS}
              </h4>
              <div className="c-task-board-select__boards__message">
                {I18N.NO_BOARDS_FOUND}
              </div>
            </div>
          )}
      </Modal>
    </div>
  );
};

export default TaskBoardSelect;
