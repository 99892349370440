import React from 'react';
import Icon from '../Icon';

const IconExpand = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-expand"
    >
      <svg
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 6.60714V1.07143C0 0.477679 0.477679 0 1.07143 0H6.60714C6.90179 0 7.14286 0.241071 7.14286 0.535714V2.32143C7.14286 2.61607 6.90179 2.85714 6.60714 2.85714H2.85714V6.60714C2.85714 6.90179 2.61607 7.14286 2.32143 7.14286H0.535714C0.241071 7.14286 0 6.90179 0 6.60714ZM12.8571 0.535714V2.32143C12.8571 2.61607 13.0982 2.85714 13.3929 2.85714H17.1429V6.60714C17.1429 6.90179 17.3839 7.14286 17.6786 7.14286H19.4643C19.7589 7.14286 20 6.90179 20 6.60714V1.07143C20 0.477679 19.5223 0 18.9286 0H13.3929C13.0982 0 12.8571 0.241071 12.8571 0.535714ZM19.4643 12.8571H17.6786C17.3839 12.8571 17.1429 13.0982 17.1429 13.3929V17.1429H13.3929C13.0982 17.1429 12.8571 17.3839 12.8571 17.6786V19.4643C12.8571 19.7589 13.0982 20 13.3929 20H18.9286C19.5223 20 20 19.5223 20 18.9286V13.3929C20 13.0982 19.7589 12.8571 19.4643 12.8571ZM7.14286 19.4643V17.6786C7.14286 17.3839 6.90179 17.1429 6.60714 17.1429H2.85714V13.3929C2.85714 13.0982 2.61607 12.8571 2.32143 12.8571H0.535714C0.241071 12.8571 0 13.0982 0 13.3929V18.9286C0 19.5223 0.477679 20 1.07143 20H6.60714C6.90179 20 7.14286 19.7589 7.14286 19.4643Z"
        />
      </svg>
    </Icon>
  );
};

export default IconExpand;
