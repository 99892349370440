import { selector } from 'recoil';
import activeBoardState from '../atoms/activeBoardAtom';

export const filterActiveBoardState = selector({
  key: 'filterActiveBoard',
  get: ({ get }) => {
    const activeBoard = get(activeBoardState);

    return activeBoard.tasks || [];
  },
});

export default filterActiveBoardState;
