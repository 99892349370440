import React from 'react';
import Icon from '../Icon';

const IconTransparency = (props) => {
  const {
    size,
    inverse,
    onClick,
    role,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-transparency"
      role={role}
    >
      <svg
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3448_1142)">
          <path d="M11.6663 11.6666V36.6666H36.6663V11.6666H11.6663ZM34.9997 34.9999H13.333V13.3333H34.9997V34.9999ZM9.99967 9.99992V28.3333H3.33301V3.33325H28.333V9.99992H9.99967Z" />
          <path opacity="0.5" d="M13.333 13.3333H28.333V28.3333H13.333V13.3333Z" />
        </g>
      </svg>
    </Icon>
  );
};

export default IconTransparency;
