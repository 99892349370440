import { atomFamily } from 'recoil';

const filterConfigState = atomFamily({
  key: 'filterConfigAtom',
  default: {
    boardId: null,
    assignedToUser: true,
    priority: false,
    tags: false,
  },
});
export default filterConfigState;
