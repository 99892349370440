/* eslint-disable no-plusplus */
const generateTaskIndexCounter = (listTasks, taskIndexCounter, taskIndexPrefix) => {
  let numberIndex = taskIndexCounter + 1;

  const array = listTasks.map((task) => {
    const dataBoardIndex = task.boardIndex.split('-');

    if (dataBoardIndex[0] === taskIndexPrefix) {
      return Number(dataBoardIndex[1]);
    }

    return [];
  }).flat();
  array.sort((a, b) => a - b);

  const index = array.findIndex((value) => value >= numberIndex);

  if (index === -1) {
    array.push(numberIndex);
    return numberIndex;
  }

  if (array[index] === numberIndex) {
    while (array.includes(numberIndex)) {
      numberIndex++;
    }
    array.push(numberIndex);
    return numberIndex;
  }

  array.splice(index, 0, numberIndex);
  return numberIndex;
};

export const generateNewBoardIndex = (taskIndexCounter, taskIndexPrefix, listTasks) => {
  const newTaskIndexCounter = generateTaskIndexCounter(
    listTasks,
    taskIndexCounter,
    taskIndexPrefix,
  );
  const indexCount = `000${newTaskIndexCounter}`.slice(-4);

  return `${taskIndexPrefix}-${indexCount}`;
};

export const createDefaultNewBoardIndex = (taskIndexCounter, taskIndexPrefix) => {
  const indexCount = `000${taskIndexCounter + 1}`.slice(-4);
  return `${taskIndexPrefix}-${indexCount}`;
};

export const checkBoardIndexShouldUpdate = (currentBoardIndex, tasks) => {
  const taskWithSameBoardIndex = tasks.find(
    (newBoardTask) => newBoardTask.boardIndex === currentBoardIndex,
  );

  return !!taskWithSameBoardIndex;
};
