import { atomFamily } from 'recoil';

const activeFiltersState = atomFamily({
  key: 'priorityConfigAtom',
  default: {
    priority: [],
    userIds: [],
    tagsIds: [],
  },
});
export default activeFiltersState;
