import React, { useState, useRef, useEffect } from 'react';

// Component
import HeadingWrapper from './HeadingWrapper';
import {
  IconCrossRound,
  IconEdit,
  IconSave,
} from '../Icons';

// Style
import './Heading.scss';

const Heading = (props) => {
  const {
    type,
    text,
    children,
    iconLeft,
    iconRight,
    editable,
    onChange,
    onReset,
    onSave,
    onFocus,
    maxSymbol,
  } = props;

  // State
  const [isEditMode, setIsEditMode] = useState(false);
  const [headingValue, setHeadingValue] = useState(text);
  const refHeadingInput = useRef();
  const classType = type || 'primary';
  const showSaveIcon = typeof onSave === 'function';
  const showResetIcon = typeof onReset === 'function';

  const limitSymbol = (value) => {
    const limitTitle = value.length > maxSymbol ? `${value.substring(0, maxSymbol)}...` : value;
    return limitTitle;
  };

  const content = children || (maxSymbol ? limitSymbol(text) : text);

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleFocus = () => ((typeof onFocus === 'function') ? onFocus() : null);

  const handleBlur = () => {
    if (!showSaveIcon && !showResetIcon) {
      setIsEditMode(false);
    }
  };

  const handleChange = (event) => {
    if (typeof onChange === 'function') {
      onChange(event);
    }
  };

  const handleClickReset = () => {
    if (typeof onReset === 'function') {
      onReset();
    } else {
      onChange({ currentTarget: { value: headingValue } });
    }
    setIsEditMode(!isEditMode);
  };

  const handleClickSave = () => {
    setHeadingValue(text);
    setIsEditMode(!isEditMode);
    if (typeof onSave === 'function') {
      onSave();
    }
  };

  const handleKeyDown = (event) => {
    if (event.code === 'Escape') {
      handleClickReset();
    }

    if (event.code === 'Enter') {
      handleClickSave();
    }
  };

  useEffect(() => {
    if (refHeadingInput.current) {
      refHeadingInput.current.focus();
    }
  }, [isEditMode]);

  return (
    <HeadingWrapper classType={classType}>
      <div className="c-heading__content">
        {iconLeft && (
          <span className="c-heading__content__iconLeft">
            {iconLeft}
          </span>
        )}
        {isEditMode
          ? (
            <input
              className="c-heading__content__input"
              value={text}
              maxLength="300px"
              onChange={handleChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              ref={refHeadingInput}
              onKeyDown={handleKeyDown}
              type="text"
            />
          )
          : content}
        {iconRight && (
          <span className="c-heading__content__iconRight">
            {iconRight}
          </span>
        )}
      </div>
      {editable && !isEditMode && (
        <div className="c-heading__icon-edit">
          <IconEdit
            onClick={toggleEditMode}
            size="xs"
          />
        </div>
      )}
      {editable && isEditMode && (
        <div className="c-heading__action">
          {showSaveIcon && (
            <IconSave
              onClick={handleClickSave}
              size="xs"
            />
          )}
          {showResetIcon && (
            <IconCrossRound
              onClick={handleClickReset}
              size="xs"
            />
          )}
        </div>
      )}
    </HeadingWrapper>
  );
};

export default Heading;
