import React from 'react';

// Style
import './Tag.scss';

const Tag = (props) => {
  const { tag } = props;

  return (
    <span className="c-tag">
      #
      { tag.name }
    </span>
  );
};

export default Tag;
