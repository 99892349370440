import React from 'react';
import Icon from '../Icon';

const IconSelect = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-select"
    >
      <svg
        viewBox="0 0 20 15"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.79289 14.6213L0.292881 8.12133C-0.097627 7.73082 -0.097627 7.09765 0.292881 6.70711L1.70706 5.29289C2.09757 4.90234 2.73077 4.90234 3.12128 5.29289L7.5 9.67156L16.8787 0.292881C17.2692 -0.097627 17.9024 -0.097627 18.2929 0.292881L19.7071 1.7071C20.0976 2.09761 20.0976 2.73077 19.7071 3.12132L8.20711 14.6214C7.81656 15.0119 7.1834 15.0119 6.79289 14.6213Z" />
      </svg>
    </Icon>
  );
};

export default IconSelect;
