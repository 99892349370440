import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';

// Components
import UserAvatar from '../UserAvatar';
import Loader from '../Loader';
import LinkUrl from '../LinkUrl';
import Paragraph from '../Paragraph';
import { IconEnvelope } from '../Icons';

// Store
import useUserProfileMutations from '../../store/mutations/useUserProfileMutations';
import { sessionState } from '../../store/atoms';

// Style
import './UserProfile.scss';

const UserProfile = (props) => {
  const { userId } = props;
  const {
    getUserProfileById,
  } = useUserProfileMutations();

  const [session, setSession] = useRecoilState(sessionState);

  useEffect(() => {
    getUserProfileById(userId);

    return () => {
      setSession((currentSession) => ({
        ...currentSession,
        foreignUserProfile: null,
      }));
    };
  }, []);

  return (
    <div className="c-user-profile">
      {session.foreignUserProfile ? (
        <div className="c-user-profile__data">
          <div className="c-user-profile__left-side">
            <UserAvatar
              size="xl"
              userFirstName={session.foreignUserProfile.firstName}
              userLastName={session.foreignUserProfile.lastName}
              userImageUrl={session.foreignUserProfile.userImageUrl}
            />
          </div>
          <div className="c-user-profile__right-side">
            {session.foreignUserProfile.userName && (
              <Paragraph type="extra-large">{session.foreignUserProfile.userName}</Paragraph>
            )}
            {session.foreignUserProfile.userInfo && (
              <Paragraph>{session.foreignUserProfile.userInfo}</Paragraph>
            )}
            {session.foreignUserProfile.email && (
            <LinkUrl
              newTab
              typeUrl="mailto"
              url={session.foreignUserProfile.email}
            >
              <IconEnvelope size="sm" />
              <span className="c-user-profile__email">{session.foreignUserProfile.email}</span>
            </LinkUrl>
            )}
          </div>
        </div>
      ) : <Loader />}
    </div>
  );
};

export default UserProfile;
