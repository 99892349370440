import React, { useState, useEffect } from 'react';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Utils
import orderSort from '../../utils/orderSort';

// Components
import Modal from '../Modal';
import Button from '../Button';
import {
  IconChange,
  IconSelect,
} from '../Icons';

// Style
import './TaskChangeStatus.scss';

const TaskChangeStatus = (props) => {
  const {
    boardStatuses,
    data,
    updateActiveTaskMutation,
    isMobile,
  } = props;

  const initialStatus = boardStatuses.find((status) => status.id === data.statusId);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(initialStatus);

  const I18N = useI18N();

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const statusUpdate = () => {
    if (selectedStatus) {
      const updatedStatusId = {
        statusId: selectedStatus.id,
        id: data.id,
      };
      updateActiveTaskMutation(updatedStatusId);
    }
  };

  const cancelHandler = () => {
    setSelectedStatus(initialStatus);
    closeModal();
  };

  const acceptHandler = () => {
    statusUpdate();
    closeModal();
  };

  const statusSelect = (status) => {
    setSelectedStatus(status);
  };

  useEffect(() => {
    if (boardStatuses.length > 0) {
      setSelectedStatus(initialStatus);
    }
  }, [boardStatuses]);

  return (
    <div className="c-task-change-status">
      <div className="c-task-change-status__header">
        <span className="c-task-change-status__header__title">
          {I18N.STATUS}
        </span>
        <Button
          type="primary-inverse"
          text={I18N.STATUS}
          iconLeft={<IconChange />}
          size="xs"
          onClick={openModal}
        />
      </div>
      <div className="c-task-change-status__status-current">
        {selectedStatus?.name}
      </div>
      <Modal
        isVisible={isOpenModal}
        onClose={cancelHandler}
        title={I18N.CHANGE_STATUS}
        btnAcceptText={I18N.CHANGE_STATUS}
        btnAcceptHandler={acceptHandler}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={cancelHandler}
        isMobile={isMobile}
        className="modal-task-change-status"
      >
        {boardStatuses.length > 0
          ? (
            <div className="c-task-change-status__statuses">
              <h4 className="c-task-change-status__statuses__title">
                {I18N.STATUS}
              </h4>
              <ul className="c-task-change-status__statuses__list">
                {orderSort(boardStatuses).map((status) => (
                  <li
                    className="c-task-change-status__statuses__list__member"
                    key={status.id}
                    onClick={() => statusSelect(status)}
                  >
                    <span className="c-task-change-status__statuses__list__member__name">
                      {status.name}
                    </span>
                    {selectedStatus?.id === status.id
                      && (
                        <IconSelect
                          size="xs"
                        />
                      )}
                  </li>
                ))}
              </ul>
            </div>
          )
          : (
            <div className="c-task-change-status__statuses">
              <h4 className="c-task-change-status__statuses__title">
                {I18N.STATUSES}
              </h4>
              <div className="c-task-change-status__statuses__message">
                {I18N.NO_STATUSES_FOUND}
              </div>
            </div>
          )}
      </Modal>
    </div>
  );
};

export default TaskChangeStatus;
