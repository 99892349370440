import React from 'react';

import { useI18N } from '../../i18n/useI18N';

// Styles
import './PrioritySelect.scss';

const PrioritySelect = (props) => {
  const { onChange, priority } = props;
  const I18N = useI18N();

  const priorities = ['low', 'medium', 'high'];

  const handleChange = (event) => {
    const { value } = event.target;
    const selectedPriority = priorities[value];
    onChange(selectedPriority);
  };

  return (
    <div className="c-priority-select">
      <div className="c-priority-select__slider">
        <input
          type="range"
          min={0}
          max={2}
          value={priorities.indexOf(priority)}
          onChange={handleChange}
        />
        <div className="c-priority-select__circles">
          <span className="c-priority-select__circle" data-testid="circle" />
          <span className="c-priority-select__circle" data-testid="circle" />
          <span className="c-priority-select__circle" data-testid="circle" />
        </div>
      </div>
      <div className="c-priority-select__labels">
        <span>{I18N.LOW}</span>
        <span>{I18N.MEDIUM}</span>
        <span>{I18N.HIGH}</span>
      </div>
    </div>
  );
};

export default PrioritySelect;
