import React from 'react';

// Component
import Button from '../Button';
import { IconEntry } from '../Icons';
import ContactForm from '../ContactForm';

// Style
import './PromoContactForm.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Configs
import { CONTACT_FORM_TYPE } from '../../config/config';

const PromoContactForm = (props) => {
  const {
    navigateToHome,
    token,
    userProfile,
  } = props;

  const I18N = useI18N();

  return (
    <div className="c-promo-contact-form">
      <div className="c-promo__container">
        <div className="c-promo-contact-form__promo-block">
          <div className="c-promo-contact-form__promo-block__title">{I18N.STREAMLINE_YOUR_WORKFLOW}</div>
          <div className="c-promo-contact-form__promo-block__description">
            <span>{I18N.SEE_WHAT_YOUR_TEAM_COULD_DO_WITH}</span>
            <span className="c-promo__logo">
              <strong>ΛRE</strong>
              LLΛ
            </span>
          </div>
          {token && userProfile.isTemp && (
            <Button
              text={I18N.TRY_WITHOUT_REGISTRATION}
              type="primary"
              iconLeft={<IconEntry size="lg" />}
              size="xl"
              onClick={navigateToHome}
            />
          )}
          {token && !userProfile.isTemp && (
            <Button
              text={I18N.VIEW_BOARDS}
              type="primary"
              iconLeft={<IconEntry size="lg" />}
              size="xl"
              onClick={navigateToHome}
            />
          )}
        </div>
        <div className="c-promo-contact-form__form">
          <div className="c-promo-contact-form__form__title">
            {I18N.CONTACT_US}
          </div>
          <div className="c-promo-contact-form__form__description">
            {I18N.MESSAGE_PROMO_CONTACT_FORM_B}
          </div>
          <ContactForm
            formType={CONTACT_FORM_TYPE.CONTACT}
            title={I18N.CONTACT_FORM}
          />
        </div>
      </div>
    </div>
  );
};

export default PromoContactForm;
