import { atom } from 'recoil';

// Config
import {
  AUTH_STATUS,
} from '../../config/config';

const sessionState = atom({
  key: 'session',
  default: {
    jwt: '',
    authFormType: null,
    authStatus: AUTH_STATUS.NOT_AUTH,
    errorLogin: null,
    errorRegister: null,
    errorChangePwd: null,
    restorePwd: false,
    hasError: null,
    foreignUserProfile: null,
  },
});

export default sessionState;
