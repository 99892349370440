import React, { useState } from 'react';

import { useI18N } from '../../i18n/useI18N';

import { BOARD_PROPERTY, STATUS_CARD } from '../../config/config';

import ColorPalette from '../ColorPalette';
import {
  IconColorPattern,
} from '../Icons';

import './BoardColorMark.scss';

const BoardColorMark = (props) => {
  const {
    boardData,
    setBoardData,
    updateBoard,
  } = props;

  const [isChangeColor, setIsChangeColor] = useState(false);

  const I18N = useI18N();

  const handleColorPalette = (color) => {
    setBoardData({ ...boardData, colorMark: color });
    updateBoard({ type: BOARD_PROPERTY.COLOR_MARK, value: color });
    setIsChangeColor(false);
  };

  return (
    <>
      <div className="c-board-actions-panel__color-mark">
        <div className="c-board-actions-panel__color-mark-header action-title">
          <span className="c-board-actions-panel__color-mark-title action-subtitle">
            {I18N.COLOR_MARK}
          </span>
          <IconColorPattern
            size="xs"
            onClick={() => setIsChangeColor(!isChangeColor)}
          />
        </div>
        <div
          onClick={() => setIsChangeColor(!isChangeColor)}
          style={{
            backgroundColor:
                    boardData.colorMark || STATUS_CARD.COLOR_MARK_DEFAULT,
          }}
          className="c-board-actions-panel__color-board"
        />
      </div>
      {isChangeColor && (
      <>
        <ColorPalette
          selectedColor={
                    boardData.colorMark || STATUS_CARD.COLOR_MARK_DEFAULT
                  }
          onClick={handleColorPalette}
        />
        <div
          className="__blanked"
          onClick={() => setIsChangeColor(!isChangeColor)}
        />
      </>
      )}
    </>
  );
};

export default BoardColorMark;
