import { useRecoilState } from 'recoil';
import { useApi } from '../../hooks/useApi';
import { toastersState } from '../atoms';

// Utils
import generateRandomStr from '../../utils/generateRandomStr';

const useFormMutations = () => {
  const [fetch] = useApi();
  const [toasterList, setToasterList] = useRecoilState(toastersState);

  const sendFormMutation = async (data) => {
    try {
      await fetch.sendForm(data);
      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'success',
          message: 'Form have send successfully',
        },
      ]);
    } catch (error) {
      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'error',
          message: error.response.data.error,
        },
      ]);
    }
  };

  return {
    sendFormMutation,
  };
};

export default useFormMutations;
