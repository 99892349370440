import React from 'react';
import Icon from '../Icon';

const IconRegister = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-register"
    >
      <svg
        viewBox="0 0 25 25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.7503 9.37496H4.16699V8.33329H18.7503V9.37496ZM13.542 12.5H4.16699V13.5416H13.542V12.5ZM21.8753 4.16663V13.5416H20.8337V5.20829H2.08366V18.75H11.4587V19.7916H1.04199V4.16663H21.8753ZM24.0316 16.2885L23.2951 15.552L17.1878 21.6593L14.2055 18.677L13.4691 19.4135L17.1878 23.1323L24.0316 16.2885Z"
        />
      </svg>
    </Icon>
  );
};

export default IconRegister;
