import React from 'react';

import './SelectControl.scss';

const SelectOption = (props) => {
  const {
    value,
    text,
  } = props;

  return (
    <li
      className="c-option"
      value={value}
    >
      {text}
    </li>
  );
};

export default SelectOption;
