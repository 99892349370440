import React from 'react';

// Component
import ContactForm from '../../components/ContactForm';

// Style
import './SupportView.scss';

// Configs
import { CONTACT_FORM_TYPE } from '../../config/config';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const SupportView = () => {
  const I18N = useI18N();

  return (
    <div className="v-support">
      <ContactForm
        formType={CONTACT_FORM_TYPE.SUPPORT}
        title={I18N.SUPPORT_FORM}
        description={I18N.SUPPORT_FORM_TITLE}
      />
    </div>
  );
};

export default SupportView;
