import React, { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

// Store
import { timeLogListState } from '../../store/atoms/index';
import useTaskMutations from '../../store/mutations/useTaskMutations';

// Translations
import { useI18N } from '../../i18n/useI18N';

// Configs
import { PAGE } from '../../config/config';

// Utils
import { convertMinuteToEstimationString } from '../../utils/timeUtils';

// Components
import LinkRouter from '../LinkRouter';
import TimeReportFilter from '../TimeReportFilter';

// Style
import './UserTimeReport.scss';

const UserTimeReport = (props) => {
  const { userId } = props;

  const I18N = useI18N();

  const { getTimeLogListMutation } = useTaskMutations();

  const [timeLogList, setTimeLogList] = useRecoilState(timeLogListState);

  const totalTime = useMemo(() => [], [timeLogList]);

  const formattedTimeLogList = useMemo(() => {
    if (!timeLogList) {
      return [];
    }

    const taskList = new Map();
    timeLogList.forEach((item) => {
      if (taskList.has(item.taskId)) {
        taskList.get(item.taskId).totalTime += item.timeLogs[0].value;
        totalTime.push(item.timeLogs[0].value);
      } else {
        taskList.set(item.taskId, {
          taskIndex: item.boardIndex,
          title: item.title,
          status: item.status,
          totalTime: item.timeLogs[0].value,
          boardId: item.boardId,
          id: item.taskId,
        });

        totalTime.push(item.timeLogs[0].value);
      }
    });

    return Array.from(taskList.values());
  }, [timeLogList]);

  const getTotalTime = () => {
    const totalMinutes = totalTime.reduce((sum, current) => sum + current, 0);
    return convertMinuteToEstimationString(totalMinutes);
  };
  useEffect(() => () => setTimeLogList(null), []);

  return (
    <div className="c-user-time-report">
      <TimeReportFilter
        getTimeLogList={getTimeLogListMutation}
        userId={userId}
      />
      {timeLogList && (
      <div className="c-user-time-report__log-list">
        <p className="c-user-time-report__header">
          <span>{I18N.TASK_INDEX}</span>
          <span>{I18N.TASK_C}</span>
          <span>{I18N.STATUS}</span>
          <span>{I18N.TOTAL_TIME}</span>
        </p>
          {timeLogList.length > 0 ? (
            <>
              <ul className="c-user-time-report__list">
                {formattedTimeLogList.map((record) => (
                  <li
                    key={record.id ?? 'pending'}
                    className="c-user-time-report__list__item"
                  >
                    <LinkRouter
                      page={PAGE.TASK.PATH}
                      data={{ boardId: record.boardId, index: record.taskIndex }}
                      fromRoot
                    >
                      <span className="c-user-time-report__list__item-data">{record.taskIndex}</span>
                    </LinkRouter>
                    <span className="c-user-time-report__list__item-data">{record.title}</span>
                    <span className="c-user-time-report__list__item-data">{record.status}</span>
                    <span className="c-user-time-report__list__item-data">
                      {convertMinuteToEstimationString(record.totalTime)}
                    </span>
                  </li>
                ))}
              </ul>
              <div className="c-user-time-report__total-time">
                <span>{`${I18N.TOTAL_TIME} ${getTotalTime()}`}</span>
              </div>
            </>
          ) : I18N.NOT_FOUND}
      </div>
      )}
    </div>
  );
};

export default UserTimeReport;
