import React from 'react';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Components
import { IconArrow } from '../Icons';

// Styles
import './SortBoard.scss';

const SortBoard = (props) => {
  const { children, onClick, sortOrderDirections } = props;

  const I18N = useI18N();

  const classNameSortOrder = sortOrderDirections ? 'ascending' : 'descending';
  const classNameBoardSort = `c-sort-board ${classNameSortOrder}`;

  return (
    <div className={classNameBoardSort}>
      <span className="c-sort-board__title">{I18N.SORT_BY}</span>
      <ul className="c-sort-board__list-sort-types">
        {children}
      </ul>
      <IconArrow size="xs" onClick={onClick} />
      <IconArrow size="xs" onClick={onClick} />
    </div>
  );
};

export default SortBoard;
