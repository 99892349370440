import React from 'react';
import Icon from '../Icon';

const IconPlus = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-plus">
      <svg viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.66663 1V10.3333" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1 5.6665H10.3333" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Icon>
  );
};

export default IconPlus;
