export const convertMinuteToEstimationString = (minute) => {
  const hour = Math.floor(minute / 60);
  const minuteRemainder = `0${minute % 60}m`;
  const minuteRemainderFormat = minuteRemainder.substring(minuteRemainder.length - 3);
  let result = '';
  if (minute < 60) {
    result = minuteRemainderFormat;
  }
  if (hour < 8) {
    result = `${hour}h ${minuteRemainderFormat}`;
  }
  if (hour >= 8) {
    result = `${Math.floor(hour / 8)}d ${hour % 8}h ${minuteRemainderFormat}`;
  }
  return result.replace(/0h/g, '').replace(/00m/g, '').replace(/ {2}/g, ' ').trim();
};

export const convertEstimationStringToMinuteBackUp = (record) => {
  const cleanedRecord = record.replace(/\s/g, '');
  const cleanedRecordWithSpace = cleanedRecord.replace(/d/g, 'd ').replace(/h/g, 'h ');
  const arr = cleanedRecordWithSpace.split(' ');
  const dayString = arr.find((elem) => elem.includes('d'));
  const day = dayString ? parseInt(dayString, 10) : 0;
  const hourString = arr.find((elem) => elem.includes('h'));
  const hour = hourString ? parseInt(hourString, 10) : 0;
  const minuteString = arr.find((elem) => elem.includes('m'));
  const minute = minuteString ? parseInt(minuteString, 10) : 0;
  const estimationTime = (day * 8 * 60) + (hour * 60) + minute;
  return estimationTime;
};

export const convertEstimationStringToMinute = (record) => {
  if (typeof record !== 'string' || record === '') {
    throw new Error('Invalid function argument!');
  }
  const regex = /^(\d{1,2}d\s*)?(\d{1,2}h\s*)?(\d{1,2}m\s*)?$/;
  const matches = record.match(regex);

  let totalMinutes = 0;

  if (matches[1]) {
    const days = parseInt(matches[1], 10);
    totalMinutes += days * 8 * 60;
  }
  if (matches[2]) {
    const hours = parseInt(matches[2], 10);
    totalMinutes += hours * 60;
  }
  if (matches[3]) {
    const minutes = parseInt(matches[3], 10);
    totalMinutes += minutes;
  }
  return totalMinutes;
};
