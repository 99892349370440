/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// Configs
import { POSITION } from '../../config/config';

// Component
import ColorMarkBar from '../ColorMarkBar';
import { IconCrossRound } from '../Icons';
import TaskActions from '../TaskActions';
import TaskAssignedUser from '../TaskAssignedUser';
import TaskAuthor from '../TaskAuthor';
import TaskBoardSelect from '../TaskBoardSelect';
import TaskColorMark from '../TaskColorMark';
import TaskDate from '../TaskDate';
import TextEditor from '../TextEditor';
import TaskEstimationTime from '../TaskEstimationTime';
import TaskChangeStatus from '../TaskChangeStatus';
import TaskHeader from '../TaskHeader';
import TaskLoggedTime from '../TaskLoggedTime';
import TaskPriority from '../TaskPriority';
import Modal from '../Modal';
import TagList from '../TagList';
import ToDoList from '../ToDoList/ToDoList';

// Style
import './Task.scss';

// Store
import useTaskMutations from '../../store/mutations/useTaskMutations';
import { boardsState } from '../../store/atoms';

// Hooks
import { useToaster } from '../../hooks/useToast';
import { useI18N } from '../../i18n/useI18N';

const Task = (props) => {
  const {
    data,
    setActiveTask,
    boardTeam,
    boardStatuses,
    isModalMode,
  } = props;

  const {
    colorMark,
    boardIndex,
  } = data;

  const boards = useRecoilValue(boardsState);

  const navigate = useNavigate();
  const { showToastSuccess, showToastError } = useToaster();

  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);

  const I18N = useI18N();

  const classTaskMode = isModalMode ? 'c-task' : 'c-task c-task_full-screen';

  const isNewTask = !data.id;

  const {
    changeBoardTaskMutation,
    changeStatusTaskMutation,
    createTimeLogMutation,
    createToDoMutation,
    deleteTaskMutation,
    deleteTimeLogMutation,
    deleteToDoMutation,
    saveNewTaskMutation,
    updateActiveTaskMutation,
    updateTimeLogMutation,
    updateToDoMutation,
  } = useTaskMutations();

  const openTaskPage = () => {
    navigate(`/board/${data.board.id}/task/${boardIndex}`);
  };

  const copyTaskLink = async () => {
    const taskLinkFull = window.location.href;
    let taskLink = taskLinkFull.split('?')[0];
    if (isModalMode) {
      taskLink += `/task/${boardIndex}`;
    }
    try {
      await navigator.clipboard.writeText(`${taskLink}`);
      showToastSuccess('Link copied!');
    } catch (error) {
      showToastError('Failed to copy URL to clipboard.');
    }
  };

  const handleCloseTask = () => {
    setActiveTask(null);
    if (!isModalMode) {
      navigate(`/board/${data.board?.id}`);
    }
  };

  const closeTaskConfirmation = () => {
    if (isNewTask) {
      setShowCloseConfirmation(true);
    } else {
      handleCloseTask();
    }
  };

  const acceptClose = () => {
    handleCloseTask();
  };

  return (
    <>
      <div className={classTaskMode}>
        <section className="c-task__content">
          <TaskHeader
            data={data}
            updateActiveTaskMutation={updateActiveTaskMutation}
            openTaskPage={openTaskPage}
            copyTaskLink={copyTaskLink}
            isModalMode={isModalMode}
          />
          <TextEditor
            data={data}
            updateActiveTaskMutation={updateActiveTaskMutation}
          />
          {!isNewTask && (
            <TaskLoggedTime
              data={data}
              createTimeLogMutation={createTimeLogMutation}
              updateTimeLogMutation={updateTimeLogMutation}
              deleteTimeLogMutation={deleteTimeLogMutation}
              boardTeam={boardTeam}
              isModalMode={isModalMode}
              openTaskPage={openTaskPage}
            />
          )}
          <TagList
            updateActiveTaskMutation={updateActiveTaskMutation}
            data={data}
          />
          {!isNewTask && (
            <ToDoList
              data={data}
              createToDoMutation={createToDoMutation}
              updateToDoMutation={updateToDoMutation}
              deleteToDoMutation={deleteToDoMutation}
              isModalMode={isModalMode}
            />
          )}
        </section>
        <section className="c-task__aside">
          {isModalMode && (
            <div className="c-task__aside__header-action">
              <IconCrossRound
                onClick={closeTaskConfirmation}
              />
            </div>
          )}
          {boardTeam.length !== 1 && (
            <>
              <TaskAssignedUser
                data={data}
                boardTeam={boardTeam}
                updateActiveTaskMutation={updateActiveTaskMutation}
              />
              <TaskAuthor
                data={data}
                boardTeam={boardTeam}
              />
            </>
          )}

          <TaskEstimationTime
            data={data}
            updateActiveTaskMutation={updateActiveTaskMutation}
          />
          <TaskDate
            data={data}
            updateActiveTaskMutation={updateActiveTaskMutation}
          />
          <TaskPriority
            data={data}
            updateActiveTaskMutation={updateActiveTaskMutation}
          />
          <TaskColorMark
            data={data}
            updateActiveTaskMutation={updateActiveTaskMutation}
          />
          {!isNewTask && (
            <>
              <TaskChangeStatus
                data={data}
                boardStatuses={boardStatuses}
                updateActiveTaskMutation={changeStatusTaskMutation}
              />
              <TaskBoardSelect
                data={data}
                changeBoardTaskMutation={changeBoardTaskMutation}
                isTaskPage={!isModalMode}
                boards={boards}
              />
            </>
          )}
          <TaskActions
            data={data}
            deleteTaskMutation={deleteTaskMutation}
            saveNewTaskMutation={saveNewTaskMutation}
            handleCloseTask={handleCloseTask}
            isNewTask={isNewTask}
            boards={boards}
          />
        </section>
        <ColorMarkBar
          position={POSITION.LEFT}
          color={colorMark}
        />
      </div>

      {isModalMode && (
        <div
          className="overlay"
          onClick={handleCloseTask}
        />
      )}
      {showCloseConfirmation && (
        <Modal
          isVisible
          onClose={() => setShowCloseConfirmation(false)}
          title={I18N.DATA_NOT_SAVED}
          content={I18N.MESSAGE_ABOUT_UNSAVED_DATA}
          btnCancelText={I18N.CANCEL}
          btnAcceptText={I18N.ACCEPT}
          btnAcceptHandler={acceptClose}
          btnCancelHandler={() => setShowCloseConfirmation(false)}
        />
      )}
    </>
  );
};

export default Task;
