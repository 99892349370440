import React from 'react';
import { useNavigate } from 'react-router-dom';

// Translate
import { useI18N } from '../../i18n/useI18N';

// Components
import { IconArrowLeft } from '../Icons';
import Button from '../Button';

// Style
import './ErrorMessage.scss';
import { PAGE } from '../../config/config';

const ErrorMessage = (props) => {
  const {
    name,
    description,
    icon,
    number,
    isNavigateHome,
    buttonText,
  } = props;

  const I18N = useI18N();
  const navigate = useNavigate();

  const buttonContent = buttonText || I18N.GO_HOME;

  const goBack = () => {
    if (navigate.length > 1 && !isNavigateHome) {
      navigate(-2);
    } else {
      navigate(PAGE.HOME.PATH);
    }
  };

  return (
    <div className="c-error-message">
      {icon && (<div className="c-error-message__icon">{icon}</div>)}
      {number && (<span className="c-error-message__number">{number}</span>)}
      <div className="c-error-message__text">
        <span className="c-error-message__name">{name}</span>
        {description && (
        <span className="c-error-message__description">{description}</span>
        )}
      </div>
      <Button iconLeft={<IconArrowLeft />} text={buttonContent} type="primary-inverse" onClick={goBack} />
    </div>
  );
};

export default ErrorMessage;
