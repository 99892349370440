import React from 'react';
import Icon from '../Icon';

const IconTwitter = (props) => {
  const {
    size,
    inverse,
    onClick,
    role,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-twitter"
      role={role}
    >
      <svg
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M21.5578 22.4345L10.1805 7.39386H8.28544L19.7899 22.4345H21.5578Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V28C0 29.1046 0.89543 30 2 30H28C29.1046 30 30 29.1046 30 28V2C30 0.895431 29.1046 0 28 0H2ZM24.089 5.59326L17.1381 13.5319L25.3145 24.3355H18.9127L13.9037 17.7824L8.1649 24.3355H4.9841L12.4171 15.8413L4.57561 5.59326H11.1381L15.6716 11.5841L20.9082 5.59326H24.089Z" />
      </svg>
    </Icon>
  );
};

export default IconTwitter;
