import React from 'react';
import { Link, useMatch } from 'react-router-dom';

// Utils
import generatePath from '../../utils/generatePath';

// Styles
import './LinkRouter.scss';

const LinkRouter = (props) => {
  const {
    page,
    text,
    children,
    data,
    fromRoot,
    onClick,
  } = props;

  // Component logic
  const path = generatePath(page, data);
  const currentPath = fromRoot ? `/${path}` : path;
  const currentPage = (useMatch(path)) ? 'active' : '';
  const linkRouterClassName = `c-link-router ${currentPage}`;

  // Return function
  return (
    <Link
      to={currentPath}
      className={linkRouterClassName}
      onClick={onClick}
    >
      {children || text}
    </Link>
  );
};

export default LinkRouter;
