import React from 'react';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Components
import Panel from '../../components/Panel';
import ErrorMessage from '../../components/ErrorMessage';
import { IconLock } from '../../components/Icons';

// Style
import './NoAccessView.scss';

const NoAccessView = () => {
  const I18N = useI18N();

  return (
    <div className="v-no-access">
      <Panel>
        <ErrorMessage
          isNavigateHome
          name={I18N.MESSAGE_TO_ERROR_403}
          description={I18N.DESCRIPTION_ERROR_403}
          icon={<IconLock />}
        />
      </Panel>
    </div>
  );
};

export default NoAccessView;
