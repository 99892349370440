import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// Store
import {
  activeBoardState,
  boardsState,
  profileSettingsState,
  sessionState,
  toastersState,
  userProfileState,
  usersSearchResultState,
} from '../atoms';

// Hooks
import { useApi } from '../../hooks/useApi';
import { useI18N } from '../../i18n/useI18N';

// Utils
import generateRandomStr from '../../utils/generateRandomStr';

const useBoardMutations = () => {
  const [boards, setBoards] = useRecoilState(boardsState);
  const [activeBoard, setActiveBoard] = useRecoilState(activeBoardState);
  const [toasterList, setToasterList] = useRecoilState(toastersState);
  const setSelectUsers = useSetRecoilState(usersSearchResultState);
  const [userProfile, setUserProfile] = useRecoilState(userProfileState);
  const [settings, setSettings] = useRecoilState(profileSettingsState);
  const session = useRecoilValue(sessionState);

  const I18N = useI18N();

  const navigate = useNavigate();

  const [fetch] = useApi();

  const modifyActiveBoard = (newBoardData) => {
    const updatedBoards = boards.map(
      (board) => (board.id === newBoardData.id ? newBoardData : board),
    );
    setActiveBoard(newBoardData);
    setBoards(updatedBoards);
  };

  const updateAvailableBoards = (boardId) => {
    const newBoards = boards.filter((boardData) => boardData.id !== Number(boardId));
    setActiveBoard(null);
    setBoards(newBoards);
  };

  const setActiveBoardById = async (boardId) => {
    const response = await fetch.getBoardById(boardId);

    const hasAccessUser = response.data
      ? response.data.users.find((boardUser) => boardUser.id === userProfile.id)
      : false;
    if (hasAccessUser) {
      modifyActiveBoard(response.data);
    } else {
      updateAvailableBoards(boardId);
    }
  };

  const getBoardsByUserId = async (userId, accessToken = null) => {
    const response = await fetch.getBoardsByUserId(userId, accessToken);
    setBoards(response.data);
  };

  const createBoardMutation = async (board) => {
    try {
      const response = await fetch.createBoard(board);

      if (response && response.data) {
        setBoards([...boards || [], response.data]);
        setActiveBoard(response.data);
      }

      navigate(`/board/${response.data.id}`);

      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'success',
          message: I18N.BOARD_CREATED,
        },
      ]);
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: error.response.data.error,
      }]);
    }
  };

  const createBoardFromTemplateMutation = async (accessToken) => {
    try {
      const response = await fetch.createBoardFromTemplate(accessToken);
      setBoards([...boards || [], response.data]);
      setActiveBoard(response.data);
      return response.data;
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: error.response.data.error,
      }]);
    }
    return null;
  };

  const copyBoardMutation = async (boardId, userId) => {
    try {
      const data = {
        userId,
      };
      const response = await fetch.copyBoard(boardId, data);
      if (response && response.data) {
        setBoards([...boards || [], response.data]);
        setActiveBoard(response.data);
      }

      navigate(`/board/${response.data.id}`);

      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'success',
          message: I18N.BOARD_CREATED,
        },
      ]);
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: error.response.data.error,
      }]);
    }
  };

  const updateBoardMutation = async (board) => {
    try {
      const result = await fetch.patchBoardById(board.id, board);
      if (result.data.deletedBoard) {
        updateAvailableBoards(board.id);
      } else {
        getBoardsByUserId(userProfile.id, session.jwt);
        setActiveBoard({ ...result.data, tasks: activeBoard.tasks });

        setToasterList([
          ...toasterList,
          {
            id: generateRandomStr(),
            type: 'success',
            message: I18N.SAVED,
          },
        ]);
      }
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: error.response.data.error,
      }]);

      if (error.response.data.status === 404) {
        updateAvailableBoards(board.id);
      }
    }
  };

  const deleteBoardMutation = async (boardId) => {
    if (boardId === 'new') {
      setActiveBoard(null);
    } else {
      const response = await fetch.deleteBoardById(boardId);
      if (response) {
        updateAvailableBoards(boardId);
      }
    }
  };

  const createNewStatusMutation = async (data) => {
    if (data.name) {
      const response = await fetch.createNewStatus(data);
      if (response) {
        const updatedActiveBoard = {
          ...response.data.board,
          statuses: [...response.data.board.statuses, response.data],
        };

        modifyActiveBoard(updatedActiveBoard);
      }
    }
  };

  const reorderStatusesMutation = async (statusesData) => {
    const initialActiveBoardData = { ...activeBoard };
    const initialBoardsData = { ...boards };

    const newStatuses = activeBoard.statuses.map((boardStatus) => {
      const finderStatus = statusesData.find((status) => status.id === boardStatus.id);
      if (finderStatus) {
        return {
          ...boardStatus,
          order: finderStatus.order,
        };
      }

      return boardStatus;
    });

    const updatedActiveBoard = { ...initialActiveBoardData, statuses: newStatuses };

    modifyActiveBoard(updatedActiveBoard);
    try {
      await fetch.patchStatusesOrder(statusesData);

      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'success',
          message: I18N.SAVED,
        },
      ]);
    } catch (error) {
      setActiveBoard(initialActiveBoardData);
      setActiveBoard(initialBoardsData);

      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR_PAGE,
      }]);
    }
  };

  const reorderBoardsMutation = async (newBoardSequence) => {
    const initialSettings = { ...settings };
    const data = { ...settings.data, order: { ...settings.data.order, ...newBoardSequence } };
    setSettings({ ...settings, data });

    try {
      await fetch.patchSettingsById(settings.id, { settings: data });

      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'success',
          message: I18N.SAVED,
        },
      ]);
    } catch (error) {
      setSettings(initialSettings);

      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR_PAGE,
      }]);
    }
  };

  const updateStatusMutation = async (status) => {
    if (!status.id) return;
    const response = await fetch.patchStatusById(status.id, status);
    if (response) {
      if (activeBoard?.id) {
        const updatedActiveBoard = await fetch.getBoardById(activeBoard.id);
        if (updatedActiveBoard?.data) {
          modifyActiveBoard(updatedActiveBoard.data);
        }
      }
    }
  };

  const deleteStatusMutation = async (statusId, boardId) => {
    const response = await fetch.deleteStatusById(statusId);
    if (response && response.data) {
      const boardResponse = await fetch.getBoardById(boardId);
      modifyActiveBoard(boardResponse.data);
    }
  };

  const searchUsersMutation = async (userName) => {
    const response = await fetch.searchUser({ name: userName });
    setSelectUsers({ users: response.data, hasError: null });
    if (!response.data.length) {
      setSelectUsers({ users: response.data, hasError: true });
    }
  };

  const addBoardToFavoriteMutation = async (boardId) => {
    setUserProfile({
      ...userProfile,
      favoriteBoards: [...userProfile.favoriteBoards, { boardId }],
    });
    try {
      const response = await fetch.addFavoriteBoard({ user: userProfile, boardId });
      setUserProfile({
        ...response.data.favoriteBoardByUser,
        favoriteBoards: [...userProfile.favoriteBoards, response.data],
      });
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: error.response.data.error,
      }]);
    }
  };

  const deleteBoardFromFavoriteMutation = async (boardId) => {
    const initialUserProfileData = { ...userProfile };
    const favoriteBoard = userProfile.favoriteBoards.find(
      (favBoard) => favBoard.boardId === boardId,
    );

    setUserProfile({
      ...userProfile,
      favoriteBoards: userProfile.favoriteBoards
        .filter((favBoard) => favBoard.id !== favoriteBoard.id),
    });

    try {
      await fetch.deleteBoardFromFavorite(favoriteBoard.id);

      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'success',
          message: I18N.SAVED,
        },
      ]);
    } catch (error) {
      setUserProfile(initialUserProfileData);
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: error.response.data.error,
      }]);
    }
  };

  return {
    addBoardToFavoriteMutation,
    copyBoardMutation,
    createBoardFromTemplateMutation,
    createBoardMutation,
    createNewStatusMutation,
    deleteBoardFromFavoriteMutation,
    deleteBoardMutation,
    deleteStatusMutation,
    getBoardsByUserId,
    modifyActiveBoard,
    reorderBoardsMutation,
    reorderStatusesMutation,
    searchUsersMutation,
    setActiveBoardById,
    updateBoardMutation,
    updateStatusMutation,
  };
};

export default useBoardMutations;
