import React from 'react';
import Icon from '../Icon';

const IconBlockedRound = (props) => {
  const { size, inverse, onClick } = props;
  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-block-round">
      <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0C4.47718 0 0 4.47714 0 10C0 15.5229 4.47718 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47718 15.5229 0 10 0ZM15.2463 4.75371C17.8853 7.39274 18.0689 11.4263 16.08 14.2552L5.74476 3.92C8.57556 1.92984 12.6087 2.11617 15.2463 4.75371V4.75371ZM4.75371 15.2463C2.11468 12.6073 1.93113 8.57367 3.91996 5.7448L14.2552 16.08C11.4245 18.0702 7.39129 17.8839 4.75371 15.2463V15.2463Z" />
      </svg>

    </Icon>
  );
};

export default IconBlockedRound;
