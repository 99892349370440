/* eslint-disable max-len */
import React from 'react';

// Component
import Button from '../Button';
import {
  IconRegister,
  IconEntry,
} from '../Icons';

// Style
import './PromoCTA.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

const PromoCTA = (props) => {
  const {
    handleRegister,
    handleLogin,
    navigateToHome,
    token,
    userProfile,
    ctaLeft,
    ctaRight,
    sloganPrimary,
    sloganSecondary,
  } = props;

  const I18N = useI18N();

  return (
    <div className="c-promo-cta">
      {ctaLeft && (
        <div className="c-promo__container">
          <div className="c-promo-cta__action">
            <div className="c-promo-cta__action__title">
              <span>{I18N.WHAT_IS}</span>
              <span className="c-promo__logo">
                <strong>ΛRE</strong>
                LLΛ
                <span className="c-promo__white">?</span>
              </span>
            </div>
            <div className="c-promo-cta__buttons">
              {token && userProfile.isTemp && (
              <>
                <Button
                  text={I18N.REGISTER}
                  iconLeft={<IconRegister size="xl" />}
                  size="xl"
                  onClick={handleRegister}
                  type="brand"
                />
                <Button
                  text={I18N.TRY_WITHOUT_REGISTRATION}
                  type="primary"
                  iconLeft={<IconEntry size="xl" />}
                  size="xl"
                  onClick={navigateToHome}
                />
              </>
              )}
            </div>
            {!token && (
              <Button
                text={I18N.SIGN_IN}
                iconLeft={<IconRegister size="xl" />}
                size="xl"
                onClick={handleLogin}
                type="brand"
              />
            )}
            {token && !userProfile.isTemp && (
              <Button
                text={I18N.VIEW_BOARDS}
                type="primary-inverse"
                iconLeft={<IconEntry size="xl" />}
                size="xl"
                onClick={navigateToHome}
              />
            )}
          </div>
          <div className="c-promo-cta__slogan">
            <p className="c-promo-cta__slogan_primary">{sloganPrimary}</p>
            <p>{sloganSecondary}</p>
          </div>
        </div>
      )}
      {ctaRight && (
        <div className="c-promo-cta__reverse__container">
          <div className="c-promo-cta__slogan">
            <p className="c-promo-cta__slogan_primary">{sloganPrimary}</p>
            <p>{sloganSecondary}</p>
          </div>
          <div className="c-promo-cta__reverse__action">
            <div className="c-promo-cta__action__title">
              <span className="c-promo-cta__title__end">{I18N.WHAT_IS}</span>
              <span className="c-promo__logo">
                <strong>ΛRE</strong>
                LLΛ
                <span className="c-promo__white">?</span>
              </span>
            </div>
            <div className="c-promo-cta__buttons">
              {token && userProfile.isTemp && (
              <>
                <Button
                  text={I18N.REGISTER}
                  iconLeft={<IconRegister size="xl" />}
                  size="xl"
                  onClick={handleRegister}
                  type="brand"
                />
                <Button
                  text={I18N.TRY_WITHOUT_REGISTRATION}
                  type="primary"
                  iconLeft={<IconEntry size="xl" />}
                  size="xl"
                  onClick={navigateToHome}
                />
              </>
              )}
              {!token && (
              <Button
                text={I18N.SIGN_IN}
                iconLeft={<IconRegister size="xl" />}
                size="xl"
                onClick={handleLogin}
                type="brand"
              />
              )}
              {token && !userProfile.isTemp && (
              <Button
                text={I18N.VIEW_BOARDS}
                type="primary-inverse"
                iconLeft={<IconEntry size="xl" />}
                size="xl"
                onClick={navigateToHome}
              />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PromoCTA;
