import React from 'react';

// Components
import Button from '../Button';
import Heading from '../Heading';
import Timer from '../Timer';
import InputControl from '../InputControl';

const AuthFormRestore = (props) => {
  const {
    I18N,
    isMobile,
    formData,
    formError,
    handleBlur,
    handleChange,
    handleFocus,
    resetInput,
    session,
    submitRestorePwd,
    restoreButtonType,
    activeBtn,
    activeRestoreBtn,
    handleClickLogin,
    activationRestorePwdBtn,
    expireDateTime,
  } = props;

  return (
    <div className="c-auth-form__view restore">

      <form className="c-auth-form__form">

        {(isMobile && session.restorePwd !== true) && <Heading text={I18N.FORGOT_YOUR_PASSWORD} type="secondary" />}
        <p className={`c-auth-form__restore-label ${session.restorePwd && isMobile ? 'heading' : null}`}>
          {session.restorePwd === true
            ? I18N.CHECK_EMAIL
            : I18N.MESSAGE_TO_RESTORE_PASSWORD}
        </p>

        {session.restorePwd !== true && (
          <InputControl
            label={I18N.EMAIL}
            type="text"
            name="email"
            placeholder="Enter email"
            value={formData.email || ''}
            onChange={handleChange}
            errorMessage={formError.email}
            isValid={formError.email === '' && formData.email !== ''}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onReset={isMobile ? () => resetInput('email') : null}
            required
          />
        )}
        {session.restorePwd === true && (
          <Timer
            targetDate={expireDateTime}
            callback={activationRestorePwdBtn}
            showUnitDateTime
            suggestion="You can repeat password recovery"
            secondsFinish={3}
            showSeconds
          />
        )}
        <div className="c-auth-form__restore-buttons">
          <div className="c-auth-form__restore-btn restore">
            <Button
              text={I18N.RESTORE}
              onClick={submitRestorePwd}
              size="xl"
              disabled={!(activeBtn && activeRestoreBtn)}
            />
          </div>
          <div className="c-auth-form__restore-btn sign-in">
            <Button
              text={I18N.SIGN_IN}
              onClick={handleClickLogin}
              size="xl"
              type={restoreButtonType}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AuthFormRestore;
