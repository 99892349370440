import React, { useEffect } from 'react';

// Component
import Portal from '../Portal';
import Button from '../Button';
import {
  IconCrossRound,
  IconWarning,
} from '../Icons';
import Paragraph from '../Paragraph';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Styles
import './Modal.scss';

const Modal = (props) => {
  const {
    className = '',
    isVisible,
    onClose,
    title,
    content,
    children,
    type,
    btnAcceptText,
    btnAcceptHandler,
    btnCancelText,
    btnCancelHandler,
    isMobile,
    btnAcceptIcon,
  } = props;

  const I18N = useI18N();

  const modalTitle = title || '';
  const acceptBtnText = btnAcceptText || I18N.OK;
  const cancelBtnText = btnCancelText || I18N.CANCEL;
  const showAcceptBtn = typeof btnAcceptHandler === 'function';
  const showCancelBtn = typeof btnCancelHandler === 'function';
  const isWarning = type === 'WARNING';
  const modalClassName = !isMobile ? `c-modal ${className}` : `c-modal mobile ${className}`;
  const modalOverlayClassName = !isMobile ? 'c-modal__overlay' : 'c-modal__overlay mobile';
  const classSelector = modalClassName.split(' ').map((item) => (item ? `.${item}` : item)).join('');
  const footerClassName = title === I18N.FILTERS ? 'c-modal__footer filters' : 'c-modal__footer';

  const handleKeyDown = (event) => {
    if (event.code === 'Escape') {
      onClose();
    }

    if (event.code === 'Enter' && showAcceptBtn) {
      document.querySelector(classSelector).focus();
      btnAcceptHandler();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isVisible, btnAcceptHandler]);

  return (
    <Portal>
      {isVisible && (
        <div
          className={modalOverlayClassName}
          onClick={onClose}
        >
          <div
            className={modalClassName}
            onClick={(e) => e.stopPropagation()}
            tabIndex="-1"
          >
            <div className="c-modal__header">
              <p className="c-modal__header__title">
                {isWarning && (
                  <IconWarning
                    size="xl"
                  />
                )}
                {modalTitle}
              </p>
              <IconCrossRound
                size="lg"
                onClick={onClose}
              />
            </div>
            <div className="c-modal__content">
              {children || (
                <Paragraph
                  text={content}
                />
              )}
            </div>
            <div className={footerClassName}>
              {isWarning
                ? (
                  <>
                    {showCancelBtn && (
                      <Button
                        type="primary"
                        size="md"
                        onClick={btnCancelHandler}
                        text={cancelBtnText}
                      />
                    )}
                    {showAcceptBtn && (
                      <Button
                        type="danger"
                        onClick={btnAcceptHandler}
                        text={acceptBtnText}
                      />
                    )}
                  </>
                )
                : (
                  <>
                    {showAcceptBtn && (
                      <Button
                        type="confirm"
                        onClick={btnAcceptHandler}
                        text={acceptBtnText}
                        iconRight={btnAcceptIcon}
                      />
                    )}
                    {showCancelBtn && (
                      <Button
                        type="primary-inverse"
                        size="md"
                        onClick={btnCancelHandler}
                        text={cancelBtnText}
                      />
                    )}
                  </>
                )}
            </div>
          </div>
        </div>
      )}
    </Portal>
  );
};

export default Modal;
