import React from 'react';
import Icon from '../Icon';

const IconChevronDown = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-chevron-down"
    >
      <svg
        viewBox="0 0 19 10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0406 0.300081C17.363 0.00980733 17.808 -0.0773504 18.2081 0.071439C18.6081 0.220228 18.9023 0.58236 18.9799 1.02142C19.0575 1.46049 18.9067 1.90978 18.5843 2.20005L10.2719 9.69992C9.82748 10.1 9.17252 10.1 8.72813 9.69992L0.415703 2.20005C-0.0827298 1.75133 -0.141212 0.96224 0.285079 0.437578C0.71137 -0.0870833 1.46101 -0.148643 1.95944 0.300081L9.5 7.10247L17.0406 0.302581V0.300081Z"
        />
      </svg>
    </Icon>
  );
};

export default IconChevronDown;
