import React from 'react';
import Icon from '../Icon';

const IconBacklog = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-backlog">
      <svg width="32" height="28" viewBox="0 0 32 28" xmlns="http://www.w3.org/2000/svg">
        <path d="M29 0H3C1.34312 0 0 1.34313 0 3V25C0 26.6569 1.34312 28 3 28H29C30.6569 28 32 26.6569 32 25V3C32 1.34313 30.6569 0 29 0ZM28.625 25H3.375C3.27554 25 3.18016 24.9605 3.10983 24.8902C3.03951 24.8198 3 24.7245 3 24.625V3.375C3 3.27554 3.03951 3.18016 3.10983 3.10984C3.18016 3.03951 3.27554 3 3.375 3H28.625C28.7245 3 28.8198 3.03951 28.8902 3.10984C28.9605 3.18016 29 3.27554 29 3.375V24.625C29 24.7245 28.9605 24.8198 28.8902 24.8902C28.8198 24.9605 28.7245 25 28.625 25ZM26 19.25V20.75C26 21.1642 25.6642 21.5 25.25 21.5H12.75C12.3358 21.5 12 21.1642 12 20.75V19.25C12 18.8358 12.3358 18.5 12.75 18.5H25.25C25.6642 18.5 26 18.8358 26 19.25ZM26 13.25V14.75C26 15.1642 25.6642 15.5 25.25 15.5H12.75C12.3358 15.5 12 15.1642 12 14.75V13.25C12 12.8358 12.3358 12.5 12.75 12.5H25.25C25.6642 12.5 26 12.8358 26 13.25ZM26 7.25V8.75C26 9.16419 25.6642 9.5 25.25 9.5H12.75C12.3358 9.5 12 9.16419 12 8.75V7.25C12 6.83581 12.3358 6.5 12.75 6.5H25.25C25.6642 6.5 26 6.83581 26 7.25ZM10.25 8C10.25 9.24263 9.24263 10.25 8 10.25C6.75737 10.25 5.75 9.24263 5.75 8C5.75 6.75738 6.75737 5.75 8 5.75C9.24263 5.75 10.25 6.75738 10.25 8ZM10.25 14C10.25 15.2426 9.24263 16.25 8 16.25C6.75737 16.25 5.75 15.2426 5.75 14C5.75 12.7574 6.75737 11.75 8 11.75C9.24263 11.75 10.25 12.7574 10.25 14ZM10.25 20C10.25 21.2426 9.24263 22.25 8 22.25C6.75737 22.25 5.75 21.2426 5.75 20C5.75 18.7574 6.75737 17.75 8 17.75C9.24263 17.75 10.25 18.7574 10.25 20Z" />
      </svg>
    </Icon>
  );
};

export default IconBacklog;
