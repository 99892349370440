import React from 'react';
import Icon from '../Icon';

const IconHome = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-home">
      <svg width="32" height="29" viewBox="0 0 32 29" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.705 13.1261L28.4444 10.2467V2.66667C28.4444 2.43092 28.3508 2.20483 28.1841 2.03813C28.0174 1.87143 27.7913 1.77778 27.5556 1.77778H24C23.7643 1.77778 23.5382 1.87143 23.3715 2.03813C23.2048 2.20483 23.1111 2.43092 23.1111 2.66667V5.53722L17.4878 0.572222C17.1389 0.256111 16.4739 0 16 0C15.5261 0 14.8633 0.256111 14.5144 0.572222L0.292222 13.1278C0.11699 13.3048 0.0129823 13.5401 0 13.7889C0.0113538 14.0062 0.090856 14.2144 0.227222 14.3839L1.41667 15.7056C1.60204 15.8719 1.83551 15.975 2.08333 16C2.29819 15.9785 2.50332 15.8997 2.67722 15.7717L3.56056 14.9939V26.6667C3.56056 27.1382 3.74786 27.5903 4.08125 27.9237C4.41465 28.2571 4.86684 28.4444 5.33833 28.4444H26.6667C27.1382 28.4444 27.5903 28.2571 27.9237 27.9237C28.2571 27.5903 28.4444 27.1382 28.4444 26.6667V14.9933L29.3283 15.7711C29.503 15.8989 29.7086 15.9779 29.9239 16C30.1695 15.9743 30.4005 15.871 30.5833 15.705L31.7728 14.3822C31.9004 14.2082 31.9789 14.0031 32 13.7883C31.9745 13.5419 31.8712 13.3099 31.705 13.1261ZM16 9.77778C16.7032 9.77778 17.3907 9.98631 17.9754 10.377C18.5601 10.7677 19.0158 11.323 19.2849 11.9727C19.554 12.6224 19.6244 13.3373 19.4872 14.027C19.35 14.7167 19.0114 15.3502 18.5142 15.8475C18.0169 16.3447 17.3834 16.6834 16.6937 16.8206C16.0039 16.9578 15.289 16.8874 14.6393 16.6182C13.9897 16.3491 13.4344 15.8934 13.0437 15.3087C12.653 14.724 12.4444 14.0366 12.4444 13.3333C12.4444 12.3903 12.819 11.486 13.4858 10.8192C14.1526 10.1524 15.057 9.77778 16 9.77778ZM22.2222 24.8889H9.77778C9.54203 24.8889 9.31594 24.7952 9.14924 24.6285C8.98254 24.4618 8.88889 24.2357 8.88889 24C8.88889 22.5855 9.45079 21.229 10.451 20.2288C11.4512 19.2286 12.8077 18.6667 14.2222 18.6667H17.7778C19.1923 18.6667 20.5488 19.2286 21.549 20.2288C22.5492 21.229 23.1111 22.5855 23.1111 24C23.1111 24.2357 23.0175 24.4618 22.8508 24.6285C22.6841 24.7952 22.458 24.8889 22.2222 24.8889Z" />
      </svg>
    </Icon>
  );
};

export default IconHome;
