import React from 'react';

import { useI18N } from '../../i18n/useI18N';

import './CookieBanner.scss';

const CookieBanner = (props) => {
  const { handleCookies } = props;

  const I18N = useI18N();

  return (
    <div className="c-cookie-banner">
      <p className="c-cookie-banner__info">{I18N.MESSAGE_TO_COOKIE_BANNER}</p>
      <button className="c-cookie-banner__button" type="button" onClick={handleCookies}>{I18N.OK}</button>
    </div>
  );
};

export default CookieBanner;
