import React from 'react';

// Style
import './LinkUrl.scss';

const LinkUrl = (props) => {
  const {
    url,
    text,
    newTab,
    children,
    typeUrl,
    onClick,
  } = props;

  // Component logic
  const path = typeUrl ? `${typeUrl}:${url}` : url;
  const attrTarget = newTab ? '_blank' : '_self';

  // Return function
  return (
    <a
      href={path}
      target={attrTarget}
      className="c-link-url"
      onClick={onClick}
    >
      {children || text}
    </a>
  );
};

export default LinkUrl;
