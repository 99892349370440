import React, { useState } from 'react';

// Component
import ColorPalette from '../ColorPalette';
import { IconColorPattern } from '../Icons';

// Style
import './TaskColorMark.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';
import useClickOutside from '../../hooks/useClickOutside';

const TaskColorMark = (props) => {
  const {
    data,
    updateActiveTaskMutation,
  } = props;

  const {
    colorMark,
  } = data;

  const I18N = useI18N();

  const [isShowColorPalette, setIsShowColorPalette] = useState(false);

  const wrapperRef = useClickOutside(() => setIsShowColorPalette(false));

  const saveColorMark = (value) => {
    const updatedColorMark = {
      colorMark: value,
      id: data.id,
    };
    updateActiveTaskMutation(updatedColorMark);
  };

  const handleClickColor = (value) => {
    saveColorMark(value);
    setIsShowColorPalette(false);
  };

  return (
    <div className="c-task-color-mark">
      <span ref={wrapperRef}>
        <div className="c-task__title">
          {I18N.COLOR_MARK}
          <IconColorPattern
            onClick={() => setIsShowColorPalette(!isShowColorPalette)}
            size="sm"
          />
          {isShowColorPalette && (
            <ColorPalette
              selectedColor={colorMark}
              onClick={handleClickColor}
            />
          )}
        </div>
        <div
          onClick={() => setIsShowColorPalette(!isShowColorPalette)}
          className="c-task-color-mark__color"
          style={{ background: (colorMark) }}
        />
      </span>
    </div>
  );
};

export default TaskColorMark;
