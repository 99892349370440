/* eslint-disable react/no-array-index-key */
import React from 'react';

// Style
import './Table.scss';

const Table = (props) => {
  const { titles, rows } = props;

  return (
    <table className="c-table">
      <tr className="c-table__header c-row">
        {titles.map((title, indexCell) => (
          <th
            key={title}
            className={`c-row__cell c-${indexCell}`}
          >
            {title}
          </th>
        ))}
      </tr>
      {rows.map((row, index) => (
        <tr
          key={index}
          className="c-table__content c-row"
        >
          {row.map((cell, indexCell) => (
            <td
              key={indexCell}
              className={`c-row__cell c-${indexCell}`}
            >
              {cell}
            </td>
          ))}
        </tr>
      ))}
    </table>
  );
};

export default Table;
