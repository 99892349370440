import React from 'react';

import './ColorMarkBar.scss';

const ColorMarkBar = (props) => {
  const { color, position, onPointerDown } = props;

  const classColorMarkBar = `c-color-mark-bar c-color-mark-bar_${position.toLowerCase()}`;

  return (
    <div
      onPointerDown={onPointerDown}
      className={classColorMarkBar}
      style={{ borderColor: color || '#2374AB' }}
    />
  );
};

export default ColorMarkBar;
