import { useCallback, useState } from 'react';

import { COLOR_THEME } from '../config/config';

const useColorTheme = () => {
  const [colorTheme, setColorTheme] = useState(COLOR_THEME.light);

  const changeColorTheme = useCallback((theme = '') => {
    const currentTheme = theme === '' ? COLOR_THEME.light : theme;
    setColorTheme(currentTheme);
    document.documentElement.setAttribute('data-theme', currentTheme);
  }, []);

  const toggleColorTheme = useCallback(() => {
    const newColorTheme = colorTheme === COLOR_THEME.light ? COLOR_THEME.dark : COLOR_THEME.light;
    changeColorTheme(newColorTheme);
  }, [colorTheme, changeColorTheme]);

  return { colorTheme, changeColorTheme, toggleColorTheme };
};

export default useColorTheme;
