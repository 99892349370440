const generateTimerTime = (timerTime) => {
  const today = new Date();
  const date = today.getDate() + timerTime.days;
  const hour = today.getHours() + timerTime.hours;
  const min = today.getMinutes() + timerTime.minutes;
  const sec = today.getSeconds() + timerTime.seconds;

  today.setDate(date);
  today.setHours(hour);
  today.setMinutes(min);
  today.setSeconds(sec);

  return today.getTime();
};

export default generateTimerTime;
