import React from 'react';
import Icon from '../Icon';

const IconClock = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-clock">
      <svg
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 0C8.95161 0 0 8.95161 0 20C0 31.0484 8.95161 40 20 40C31.0484 40 40 31.0484 40 20C40 8.95161 31.0484 0 20 0ZM20 36.129C11.0887 36.129 3.87097 28.9113 3.87097 20C3.87097 11.0887 11.0887 3.87097 20 3.87097C28.9113 3.87097 36.129 11.0887 36.129 20C36.129 28.9113 28.9113 36.129 20 36.129ZM24.9839 27.7097L18.1371 22.7339C17.8871 22.5484 17.7419 22.2581 17.7419 21.9516V8.70968C17.7419 8.17742 18.1774 7.74194 18.7097 7.74194H21.2903C21.8226 7.74194 22.2581 8.17742 22.2581 8.70968V20.1371L27.6452 24.0564C28.0806 24.371 28.1694 24.9758 27.8548 25.4113L26.3387 27.5C26.0242 27.9274 25.4194 28.0242 24.9839 27.7097V27.7097Z"
        />
      </svg>
    </Icon>
  );
};

export default IconClock;
