import React from 'react';

// Utils
import getDate from '../../utils/getDate';

// Components
import {
  IconCrossRound,
  IconBlockedRound,
  IconCalendar,
  IconCloseEye,
  IconOpenEye,
} from '../Icons';

// Style
import './InputControl.scss';

const InputControl = (props) => {
  const {
    label,
    type,
    name,
    value,
    onChange,
    placeholder,
    disabled,
    errorMessage,
    onReset,
    suggestion,
    isValid,
    required,
    onBlur,
    onFocus,
    onKeyDown,
    autoComplete,
    refInput,
    hidden,
    showPwd,
    changePwdVisibility,
    showVisibility = true,
  } = props;
  // State
  const valueAutoComplete = autoComplete || 'on';
  const hiddenClassName = hidden ? 'hidden' : '';
  const labelClassName = label ? 'label' : '';
  const visitedClassName = value ? 'visited' : '';
  const inputType = type || 'text';
  const validClassName = isValid ? 'valid' : '';
  const errorClassName = errorMessage ? 'error' : '';
  const disabledClassName = disabled ? 'disabled' : '';
  const requiredClassName = required ? 'c-input_required' : '';
  const bottomText = errorMessage || suggestion || '';
  const showResetIcon = typeof onReset === 'function' && value.length > 0 && !disabled;
  const resetInputClassName = showResetIcon ? 'reset' : '';
  const blockInputClassName = `c-input ${requiredClassName} ${disabledClassName} ${validClassName} ${errorClassName} ${labelClassName} ${hiddenClassName} ${resetInputClassName}`;
  const inputClassName = inputType === 'date'
    ? `c-input__input c-input__input_date ${visitedClassName}`
    : 'c-input__input';
  const pwdVisibility = inputType === 'password';

  // Component Logic
  const handleChange = (event) => (typeof onChange === 'function' ? onChange(event) : null);

  const handleFocus = (event) => (typeof onFocus === 'function' ? onFocus(event) : null);

  const handleBlur = (event) => (typeof onBlur === 'function' ? onBlur(event) : null);

  const handleKeyDown = (event) => (typeof onKeyDown === 'function' && event.target.nodeName === 'INPUT'
    ? onKeyDown(event)
    : null);

  return (
    <label className={blockInputClassName}>
      {label && <p className="c-input__label-text">{label}</p>}
      <input
        className={inputClassName}
        type={showPwd ? 'text' : inputType}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        autoComplete={valueAutoComplete}
        ref={refInput}
      />
      {type === 'date' && (
        <div className="c-input__date-elements">
          <IconCalendar size="sm" />
          <span className="c-input__date-value">{getDate(value)}</span>
        </div>
      )}
      {disabled && <IconBlockedRound size="sm" />}
      {showResetIcon && <IconCrossRound size="sm" onClick={onReset} />}

      {showVisibility
        && pwdVisibility
        && (showPwd ? (
          <IconCloseEye onClick={changePwdVisibility} size="md" />
        ) : (
          <IconOpenEye onClick={changePwdVisibility} size="md" />
        ))}

      <span className="c-input__bottom-text">{bottomText}</span>
    </label>
  );
};

export default InputControl;
