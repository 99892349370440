import React from 'react';
import Icon from '../Icons/Icon';

import './PriorityIcon.scss';

const PriorityIcon = (props) => {
  const { priority } = props;

  const initialPriority = priority || 'MEDIUM';
  const classPriorityIcon = `c-priority-icon c-priority-icon__${initialPriority.toLowerCase()}`;

  const handlePriorityIcon = () => {
    switch (priority) {
      case 'high':
        return (
          <svg viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0L19.5263 16.5H0.473721L10 0Z" />
          </svg>
        );
      case 'low':
        return (
          <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <rect width="18" height="18" />
          </svg>
        );
      default:
        return (
          <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" />
          </svg>
        );
    }
  };

  return (
    <Icon size="md" classNameIconType={classPriorityIcon}>
      {handlePriorityIcon()}
    </Icon>
  );
};

export default PriorityIcon;
