import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

// Component
import GuardRouter from '../components/GuardRouter';
import MainLayout from '../components/MainLayout';
import ProtectedRoute from '../components/ProtectedRoute';
import ErrorLayout from '../components/ErrorLayout';

// Views
// import SettingsView from '../views/SettingsView';
import AboutView from '../views/AboutView';
import AuthView from '../views/AuthView';
import BoardView from '../views/BoardView';
import ErrorView from '../views/ErrorView';
import FAQView from '../views/FAQView';
import HomeView from '../views/HomeView';
import NotAccessView from '../views/NotAccessView';
import NotFoundView from '../views/NotFoundView';
import PrivacyPolicyView from '../views/PrivacyPolicyView';
import SupportView from '../views/SupportView';
import TaskView from '../views/TaskView';
import UserProfileView from '../views/UserProfileView';

// Config
import { PAGE } from '../config/config';

const router = createBrowserRouter([
  {
    element: <MainLayout />,
    errorElement: (
      <ErrorLayout>
        <ErrorView />
      </ErrorLayout>
    ),
    path: PAGE.HOME.PATH,
    handle: {
      crumb: () => 'Main',
    },
    children: [
      {
        element: (
          <ProtectedRoute>
            <HomeView />
          </ProtectedRoute>
        ),
        path: PAGE.HOME.PATH,
        handle: {
          crumb: () => '', // 0
        },
      },
      {
        element: (
          <ProtectedRoute redirectPage={PAGE.AUTH.PATH}>
            <UserProfileView />
          </ProtectedRoute>
        ),
        path: PAGE.USER_PROFILE.PATH,
        handle: {
          crumb: () => 'User Profile', // 1
        },
      },
      {
        element: (
          <GuardRouter>
            <BoardView />
          </GuardRouter>
        ),
        path: PAGE.BOARD.PATH,
        handle: {
          crumb: (data) => data, // 2
        },
      },
      {
        element: (
          <GuardRouter>
            <TaskView />
          </GuardRouter>
        ),
        path: PAGE.TASK.PATH,
        handle: {
          crumb: (data) => data, // 3
        },
      },
      // {
      //   element: <SettingsView />,
      //   path: PAGE.SETTINGS.PATH,
      //   handle: {
      //     crumb: () => 'Settings', // 4
      //   },
      // },
      {
        element: <NotFoundView />,
        path: PAGE.NOT_FOUND.PATH,
        handle: {
          crumb: () => 'Error', // 5
        },
      },
      {
        element: <PrivacyPolicyView />,
        path: PAGE.PRIVACY_POLICY.PATH,
        handle: {
          crumb: () => 'Privacy Policy', // 6
        },
      },
      {
        element: <UserProfileView />,
        path: PAGE.RESTORE_USER.PATH,
        handle: {
          crumb: () => 'User Recovery', // 7
        },
      },
      {
        element: <NotAccessView />,
        path: PAGE.NOT_ACCESS.PATH,
        handle: {
          crumb: () => 'Not access', // 8
        },
      },
      {
        element: <AuthView />,
        path: PAGE.AUTH.PATH,
        handle: {
          hideCrumbs: true, // 9
        },
      },
      {
        element: <AboutView />,
        path: PAGE.ABOUT.PATH,
        handle: {
          crumb: () => 'About', // 10
        },
      },
      {
        element: <SupportView />,
        path: PAGE.SUPPORT.PATH,
        handle: {
          crumb: () => 'Support', // 11
        },
      },
      {
        element: <FAQView />,
        path: PAGE.FAQ.PATH,
        handle: {
          crumb: () => 'FAQ', // 12
        },
      },
    ],
  },
]);

export default router;
