import { useNavigate } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

// Configs
import { PAGE } from '../../config/config';

// Hooks
import { useApi } from '../../hooks/useApi';
import { useI18N } from '../../i18n/useI18N';

// Store
import {
  activeBoardState,
  activeTaskState,
  boardsState,
  timeLogListState,
  toastersState,
  userProfileState,
  userProfileTasksState,
} from '../atoms';
import useBoardMutations from './useBoardMutations';

// Utils
import generateRandomStr from '../../utils/generateRandomStr';
import orderSort from '../../utils/orderSort';
import generatePath from '../../utils/generatePath';

const useTaskMutations = () => {
  const [fetch] = useApi();
  const navigate = useNavigate();
  const I18N = useI18N();

  const { modifyActiveBoard } = useBoardMutations();

  const [activeTask, setActiveTask] = useRecoilState(activeTaskState);
  const [toasterList, setToasterList] = useRecoilState(toastersState);
  const [activeBoard, setActiveBoard] = useRecoilState(activeBoardState);
  const [boards, setBoards] = useRecoilState(boardsState);
  const userProfile = useRecoilValue(userProfileState);
  const setUserTasks = useSetRecoilState(userProfileTasksState);
  const setTimeLogList = useSetRecoilState(timeLogListState);

  const createNewTaskMutation = async (extraData) => {
    const newTask = {
      assignToUser: null,
      assignedToUserId: null,
      title: 'New task',
      createdByUserId: userProfile.id,
      priority: 'medium',
      description: '',
      createDateTime: new Date(),
      colorMark: '#2374AB',
      tags: [],
      todoList: [],
      ...extraData,
    };
    setActiveTask(newTask);
  };

  const saveNewTaskMutation = async (extraData) => {
    const response = await fetch.createTask({ ...activeTask, ...extraData });
    if (response && response.data) {
      const updatedActiveBoard = {
        ...response.data.board,
        tasks: [...response.data.board.tasks, response.data],
      };
      setActiveTask(null);

      modifyActiveBoard(updatedActiveBoard);
    }
  };

  const reorderTasksMutation = async (tasksData) => {
    const initialActiveBoardData = { ...activeBoard };
    const initialBoardsData = { ...boards };

    const newTasks = activeBoard.tasks.map((boardTask) => {
      const finderTask = tasksData.find((task) => task.id === boardTask.id);
      if (finderTask) {
        return {
          ...boardTask,
          order: finderTask.order,
          statusId: finderTask.statusId,
        };
      }

      return boardTask;
    });

    const updatedActiveBoard = { ...initialActiveBoardData, tasks: newTasks };

    modifyActiveBoard(updatedActiveBoard);
    try {
      await fetch.patchTasksOrders(tasksData);

      setToasterList([
        ...toasterList,
        {
          id: generateRandomStr(),
          type: 'success',
          message: I18N.SAVED,
        },
      ]);
    } catch (error) {
      setActiveBoard(initialActiveBoardData);
      setBoards(initialBoardsData);

      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR_PAGE,
      }]);
    }
  };

  const updateActiveTask = async (taskData, isTaskPage = false) => {
    const initialActiveTaskData = { ...activeTask };

    try {
      const taskResponse = await fetch.patchTaskById(taskData.id, taskData);
      const boardId = (activeBoard && !isTaskPage) ? activeBoard.id : taskData.boardId;
      const boardResponse = await fetch.getBoardById(boardId);
      setActiveBoard(boardResponse.data);

      const updatedBoards = boards.map((board) => {
        if (board.id === boardResponse.data.id) {
          return boardResponse.data;
        }

        if (taskData?.boardId && board.id === taskData.boardId) {
          return {
            ...taskResponse.data.board,
            tasks: [...board.tasks, taskResponse.data],
            statuses: [...taskResponse.data.board.statuses],
            tasksCount: taskResponse.data.board.tasksCount,
            taskIndexCounter: taskResponse.data.board.taskIndexCounter,
          };
        }

        if (taskData?.boardId && board.id === activeBoard.id) {
          return {
            ...board,
            tasks: board.tasks.filter((task) => task.id !== taskData.id),
            tasksCount: board.tasksCount - 1,
          };
        }

        return board;
      });

      setBoards(updatedBoards);

      if (taskData.boardId && !isTaskPage) {
        setActiveTask(null);
      }

      if (isTaskPage) {
        const path = generatePath(PAGE.TASK.PATH, {
          boardId: taskData.boardId,
          index: taskResponse.data.boardIndex,
        });

        navigate(`/${path}`);
      }
    } catch (error) {
      setActiveTask(initialActiveTaskData);

      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR_PAGE,
      }]);
    }
  };

  const updateActiveTaskMutation = (taskData, isTaskPage = false) => {
    setActiveTask({ ...activeTask, ...taskData });

    const shouldTaskUpdate = !!taskData.id;
    if (shouldTaskUpdate) {
      updateActiveTask(taskData, isTaskPage);
    }
  };

  const changeStatusTaskMutation = async (taskData) => {
    try {
      if (taskData.statusId === activeTask.statusId) {
        throw new Error(I18N.MESSAGE_TO_ERROR_CHANGE_STATUS_TASK);
      }

      const selectedStatusTasks = orderSort(activeBoard.tasks)
        .filter((task) => task.statusId === taskData.statusId);
      let order = 0;

      if (selectedStatusTasks.length) {
        const lastOrderTask = selectedStatusTasks.at(-1).order;
        order = lastOrderTask + 1;
      }

      updateActiveTaskMutation({ ...taskData, order });
    } catch (error) {
      if (error.message === I18N.MESSAGE_TO_ERROR_CHANGE_STATUS_TASK) {
        setToasterList([...toasterList, {
          id: generateRandomStr(),
          type: 'error',
          message: error.message,
        }]);
      } else {
        setToasterList([...toasterList, {
          id: generateRandomStr(),
          type: 'error',
          message: I18N.MESSAGE_TO_ERROR,
        }]);
      }
    }
  };

  const changeBoardTaskMutation = async (taskData, isTaskPage = false) => {
    try {
      if (taskData.boardId === activeBoard.id) {
        throw new Error(I18N.MESSAGE_TO_ERROR_CHANGE_BOARD_TASK);
      }

      const newBoardResponse = await fetch.getBoardById(taskData.boardId);

      let order = 0;
      let newStatusData = null;

      if (newBoardResponse.data.statuses.length) {
        const lowestOrderStatus = orderSort(newBoardResponse.data.statuses)[0];
        newStatusData = { statusId: lowestOrderStatus.id };

        const tasksFirstStatus = orderSort(newBoardResponse.data.tasks)
          .filter((task) => task.statusId === newStatusData.statusId);
        const latestTask = tasksFirstStatus.at(-1);
        order = latestTask ? latestTask.order + 1 : 0;
      } else {
        const newStatus = {
          name: 'Todo',
          boardId: newBoardResponse.data.id,
          order: 0,
          colorMark: '#2374AB',
        };
        const newStatusResponse = await fetch.createNewStatus(newStatus);
        if (newStatusResponse && newStatusResponse.data) {
          newStatusData = { statusId: newStatusResponse.data.id, status: newStatusResponse.data };
        }
      }

      updateActiveTaskMutation({
        ...taskData,
        ...newStatusData,
        order,
      }, isTaskPage);
    } catch (error) {
      if (error.message === I18N.MESSAGE_TO_ERROR_CHANGE_BOARD_TASK) {
        setToasterList([...toasterList, {
          id: generateRandomStr(),
          type: 'error',
          message: error.message,
        }]);
      } else {
        setToasterList([...toasterList, {
          id: generateRandomStr(),
          type: 'error',
          message: I18N.MESSAGE_TO_ERROR,
        }]);
      }
    }
  };

  const deleteTaskMutation = async (taskId) => {
    const initialActiveBoardData = { ...activeBoard };
    const initialBoardsData = { ...boards };

    const tasks = activeBoard.tasks.filter((task) => task.id !== taskId);
    const updatedActiveBoard = { ...activeBoard, tasksCount: activeBoard.tasksCount - 1, tasks };

    modifyActiveBoard(updatedActiveBoard);

    try {
      await fetch.deleteTaskById(taskId);
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR,
      }]);

      setActiveBoard(initialActiveBoardData);
      setBoards(initialBoardsData);
    }
  };

  const getTasksByUserIdMutation = async (userId) => {
    const response = await fetch.getTasksByUserId(userId);
    setUserTasks(response.data);
  };

  const fetchActiveTaskMutation = async (taskId) => {
    const response = await fetch.getTaskById(taskId);
    if (response && response.data) {
      setActiveTask(response.data);
    }
  };

  const createTimeLogMutation = async (timeRecord) => {
    const initialActiveTaskData = { ...activeTask };
    const initialActiveBoardData = { ...activeBoard };

    setActiveTask({ ...activeTask, timeLogs: [...activeTask.timeLogs, timeRecord] });

    try {
      const response = await fetch.createTimeLog(timeRecord);

      const updatedTasks = activeBoard.tasks
        .map((taskData) => (taskData.id === activeTask.id
          ? { ...activeTask, timeLogs: [...activeTask.timeLogs, response.data] }
          : taskData));

      setActiveTask({ ...activeTask, timeLogs: [...activeTask.timeLogs, response.data] });
      modifyActiveBoard({ ...activeBoard, tasks: updatedTasks });
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR,
      }]);

      setActiveTask(initialActiveTaskData);
      setActiveBoard(initialActiveBoardData);
    }
  };

  const updateTimeLogMutation = async (timeRecord) => {
    const initialActiveTaskData = { ...activeTask };

    const updatedTimeLog = activeTask.timeLogs
      .map((log) => (log.id === timeRecord.id
        ? timeRecord
        : log));

    setActiveTask({ ...activeTask, timeLogs: updatedTimeLog });

    try {
      await fetch.patchTimeLogById(timeRecord.id, timeRecord);

      const updatedTasks = activeBoard.tasks
        .map((taskData) => (taskData.id === activeTask.id
          ? { ...activeTask, timeLogs: updatedTimeLog }
          : taskData));
      modifyActiveBoard({ ...activeBoard, tasks: updatedTasks });
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR,
      }]);

      setActiveTask(initialActiveTaskData);
    }
  };

  const deleteTimeLogMutation = async (timeRecord) => {
    const initialActiveTaskData = { ...activeTask };

    const updatedTimeLog = activeTask.timeLogs.filter((log) => log.id !== timeRecord.id);

    setActiveTask({ ...activeTask, timeLogs: updatedTimeLog });
    try {
      await fetch.deleteTimeLogById(timeRecord.id);

      const updatedTasks = activeBoard.tasks
        .map((taskData) => (taskData.id === activeTask.id
          ? { ...activeTask, timeLogs: updatedTimeLog }
          : taskData));
      modifyActiveBoard({ ...activeBoard, tasks: updatedTasks });
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR,
      }]);

      setActiveTask(initialActiveTaskData);
    }
  };

  const setActiveTaskById = async (taskId) => {
    const response = await fetch.getTaskById(taskId);
    if (response) {
      setActiveTask(response.data);
    }
  };

  const deleteToDoMutation = async (todoItem) => {
    const initialActiveTaskData = { ...activeTask };

    const updatedToDoList = activeTask.todoList.filter((todo) => todo.id !== todoItem.id);

    setActiveTask({ ...activeTask, todoList: updatedToDoList });
    try {
      await fetch.deleteToDoById(todoItem.id);

      const updatedTasks = activeBoard.tasks
        .map((taskData) => (taskData.id === activeTask.id
          ? { ...activeTask, todoList: updatedToDoList }
          : taskData));
      modifyActiveBoard({ ...activeBoard, tasks: updatedTasks });
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR,
      }]);

      setActiveTask(initialActiveTaskData);
    }
  };

  const createToDoMutation = async (todoItem) => {
    const initialActiveTaskData = { ...activeTask };
    const initialActiveBoardData = { ...activeBoard };

    setActiveTask({ ...activeTask, todoList: [...activeTask.todoList, todoItem] });

    try {
      const response = await fetch.createToDo(todoItem);

      const updatedTasks = activeBoard.tasks
        .map((taskData) => (taskData.id === activeTask.id
          ? { ...activeTask, todoList: [...activeTask.todoList, response.data] }
          : taskData));

      setActiveTask({ ...activeTask, todoList: [...activeTask.todoList, response.data] });
      modifyActiveBoard({ ...activeBoard, tasks: updatedTasks });
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR,
      }]);

      setActiveTask(initialActiveTaskData);
      setActiveBoard(initialActiveBoardData);
    }
  };

  const updateToDoMutation = async (todoItem) => {
    const initialActiveTaskData = { ...activeTask };

    const updatedToDoList = activeTask.todoList
      .map((todo) => (todo.id === todoItem.id
        ? todoItem
        : todo));

    setActiveTask({ ...activeTask, todoList: updatedToDoList });

    try {
      await fetch.patchToDoById(todoItem.id, todoItem);

      const updatedTasks = activeBoard.tasks
        .map((taskData) => (taskData.id === activeTask.id
          ? { ...activeTask, todoList: updatedToDoList }
          : taskData));
      modifyActiveBoard({ ...activeBoard, tasks: updatedTasks });
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR,
      }]);

      setActiveTask(initialActiveTaskData);
    }
  };

  const getTimeLogListMutation = async (userId, params) => {
    try {
      const response = await fetch.getTimeLogsReport(userId, { params });
      setTimeLogList(response.data);
    } catch (error) {
      setToasterList([...toasterList, {
        id: generateRandomStr(),
        type: 'error',
        message: I18N.MESSAGE_TO_ERROR,
      }]);
    }
  };

  return {
    changeBoardTaskMutation,
    changeStatusTaskMutation,
    createNewTaskMutation,
    createTimeLogMutation,
    createToDoMutation,
    deleteTaskMutation,
    deleteTimeLogMutation,
    deleteToDoMutation,
    fetchActiveTaskMutation,
    getTasksByUserIdMutation,
    getTimeLogListMutation,
    reorderTasksMutation,
    saveNewTaskMutation,
    setActiveTaskById,
    updateActiveTask,
    updateActiveTaskMutation,
    updateTimeLogMutation,
    updateToDoMutation,
  };
};

export default useTaskMutations;
