import React, { useState, useContext } from 'react';
import { useCookies } from 'react-cookie';

// Style
import './FAQ.scss';

// Components
import Panel from '../Panel';
import Heading from '../Heading';
import {
  IconPlus,
  IconQuestionCircle,
  IconMinus,
} from '../Icons';
import FAQText from './FAQText';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Context
import LayoutContext from '../../context/LayoutContext';

const FAQ = () => {
  const I18N = useI18N();
  const [showAnswer, setShowAnswer] = useState([]);
  const { isMobile } = useContext(LayoutContext);

  const toggleShowAnswer = (id) => {
    if (showAnswer.includes(id)) {
      const result = showAnswer.filter((el) => (el !== id));
      return setShowAnswer(result);
    }
    return setShowAnswer([...showAnswer, id]);
  };

  const [cookies] = useCookies(['i18next']);
  const lang = cookies.i18next || 'en';
  const translation = FAQText[lang];

  return (
    <Panel>
      <div className="c-faq">
        <div className="c-faq__title">
          {isMobile && (
            <IconQuestionCircle
              size="xl"
            />
          )}
          <Heading
            type="secondary"
            text={I18N.FAQ}
          />
        </div>
        <ul className="c-faq__list">
          {translation.map((faq) => (
            <li
              className="c-faq__list__item"
              key={faq.id}
            >
              <div className="c-faq__list__item__question">
                {!isMobile && (
                  <span className="c-faq__list__item__question__icon">
                    <IconQuestionCircle
                      size="lg"
                    />
                  </span>
                )}
                <span className="c-faq__list__item__question__text">
                  {faq.question}
                </span>
                {!showAnswer.includes(faq.id) ? (
                  <div
                    className="c-faq__list__item__question__button"
                    onClick={() => toggleShowAnswer(faq.id)}
                  >
                    <IconPlus />
                  </div>
                ) : (
                  <div
                    className="c-faq__list__item__question__button open"
                    onClick={() => toggleShowAnswer(faq.id)}
                  >
                    <IconMinus size="lg" />
                  </div>
                )}
              </div>
              {!showAnswer.includes(faq.id) ? (
                <div className="c-faq__list__item__answer">
                  {faq.answer}
                </div>
              ) : (
                <div className="c-faq__list__item__answer visible">
                  {faq.answer}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </Panel>
  );
};

export default FAQ;
