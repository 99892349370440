/* eslint-disable quote-props */
/* eslint-disable guard-for-in */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { Validator } from 'livr';

// Hooks
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import useToast from '../../hooks/useToast';
import useColorTheme from '../../hooks/useColorTheme';

// Translations
import { useI18N } from '../../i18n/useI18N';

// Components
import { SelectControl, SelectOption } from '../SelectControl';
import Button from '../Button';
import Heading from '../Heading';
import { IconBin, IconEdit } from '../Icons';
import InputControl from '../InputControl';
import Paragraph from '../Paragraph';
import TextareaControl from '../TextareaControl';
import UserAvatar from '../UserAvatar';

// Mutations
import useUserProfileMutations from '../../store/mutations/useUserProfileMutations';
import useProfileSettingsMutations from '../../store/mutations/useProfileSettingsMutations';

import { LANGUAGES, COLOR_THEME } from '../../config/config';

// Store
import {
  userProfileState,
  sessionState,
  profileSettingsState,
} from '../../store/atoms';

// Style
import './UserEditProfile.scss';

const UserEditProfile = () => {
  const [, setCookies] = useCookies(['i18next', 'theme']);
  const { changeColorTheme } = useColorTheme();

  const userProfile = useRecoilValue(userProfileState);
  const profileSettings = useRecoilValue(profileSettingsState);
  const [session] = useRecoilState(sessionState);

  const { updateUserProfile } = useUserProfileMutations();
  const { updateProfileSettingsMutation } = useProfileSettingsMutations();
  const { uploadUserAvatar, deleteUserAvatar } = useUserProfileMutations();

  const avatarRef = useRef(null);

  const { i18n } = useTranslation();
  const I18N = useI18N({ userName: userProfile?.userName });

  const [activeBtn, setActiveBtn] = useState(true);

  const { showToastError } = useToast();

  const hiddenClassName = session.restorePwd ? 'hidden' : '';
  const userAvatarClassName = `c-user-edit-profile__left-side ${hiddenClassName}`;
  const generalInfoClassName = `c-user-edit-profile__form-wrapper ${hiddenClassName}`;
  const settingsClassName = `c-user-edit-profile__right-side ${hiddenClassName}`;

  const defaultFormData = {};

  const defaultSettingsFormData = {
    lang: {
      value: profileSettings?.data?.lang,
      error: null,
    },
    theme: {
      value: profileSettings?.data?.theme?.toLowerCase(),
      error: null,
    },
    order: {
      value: profileSettings?.data?.order,
      error: null,
    },
  };

  const languages = {
    en: 'English',
    uk: 'Українська',
    ru: 'Русский',
    fr: 'Français',
    de: 'Deutsch',
    es: 'Español',
  };

  const colors = {
    light: I18N.LIGHT,
    dark: I18N.DARK,
  };

  const [settingsFormData, setSettingsFormData] = useState(
    defaultSettingsFormData,
  );
  const [formData, setFormData] = useState(defaultFormData);

  const keys = Object.keys(userProfile);

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setCookies('i18next', lang, { path: '/' });
  };

  const changeTheme = (value) => {
    changeColorTheme(value);
    setCookies('theme', value, { path: '/' });
  };

  for (let i = 0; i < keys.length; i += 1) {
    defaultFormData[keys[i]] = {
      value: userProfile[keys[i]],
      error: null,
    };
  }

  const userFormValidationRules = {
    firstName: [{ max_length: 100 }, { min_length: 2 }],
    lastName: [{ max_length: 100 }, { min_length: 2 }],
    userName: [{ max_length: 100 }, { min_length: 2 }],
    email: ['required', 'email'],
    userInfo: [{ max_length: 200 }],
  };

  const userFormValidator = new Validator(userFormValidationRules);
  const userFieldsValidator = new Map();
  Object.keys(userFormValidationRules).forEach((key) => {
    userFieldsValidator.set(
      key,
      new Validator({ [key]: userFormValidationRules[key] }),
    );
  });

  const handleChange = (event) => {
    const formField = {
      [event.currentTarget.name]: {
        value: event.currentTarget.value.toLowerCase(),
        error: null,
      },
    };

    if (event.currentTarget.name === 'theme') {
      changeTheme(event.currentTarget.value);
    }

    if (event.currentTarget.name === 'lang') {
      changeLang(event.currentTarget.value);
    }

    const updatedSettingsFormData = {
      ...settingsFormData,
      ...formField,
    };

    setSettingsFormData(updatedSettingsFormData);

    const data = {
      settings: {
        lang: updatedSettingsFormData.lang?.value,
        theme: updatedSettingsFormData.theme?.value,
        order: updatedSettingsFormData.order?.value,
      },
    };

    if (profileSettings) {
      updateProfileSettingsMutation(profileSettings.id, data);
    }
  };

  const updateForm = (key, event) => {
    const isPwdForm = key === 'currentPassword'
      || key === 'newPassword'
      || key === 'confirmPassword';

    const newFormData = {
      ...formData,
      [key]: {
        value: event.currentTarget.value,
        error: null,
      },
    };
    setFormData(newFormData);

    if (!isPwdForm) {
      setActiveBtn(
        userFormValidator.validate({
          firstName: formData.firstName.value,
          lastName: formData.lastName.value,
          userName: formData.userName.value,
          email: formData.email.value,
          userInfo: formData.userInfo.value,
          [key]: event.currentTarget.value,
        }),
      );
    }
  };

  const handleBlur = (event) => {
    const { value, name } = event.currentTarget;
    let isValid = false;
    let error = null;

    switch (name) {
      case 'firstName':
      case 'lastName':
      case 'userName':
      case 'email':
      case 'userInfo':
        isValid = userFieldsValidator.get(name).validate({ [name]: value });
        error = I18N.NO_VALID_DATA;
        break;
      default:
        break;
    }
    setFormData({
      ...formData,
      [name]: {
        value,
        error: isValid ? null : error,
      },
    });
  };

  const resetField = (key) => {
    setFormData({
      ...formData,
      [key]: { value: '' },
    });

    if (key !== 'userInfo') {
      setActiveBtn(false);
    }
  };

  const handleSubmitForm = () => {
    // TODO: Add validation
    const data = {
      firstName: formData.firstName.value,
      lastName: formData.lastName.value,
      userName: formData.userName.value,
      userInfo: formData.userInfo.value,
      userImageUrl: formData.userImageUrl.value,
      email: formData.email.value,
    };
    updateUserProfile(userProfile.id, data);
  };

  const uploadAvatar = () => {
    if (avatarRef.current) {
      avatarRef.current.click();
    }
  };

  const deleteAvatar = async () => {
    try {
      const fullFileUrl = formData.userImageUrl.value;
      const response = await deleteUserAvatar(fullFileUrl);
      if (response) {
        const newFormData = {
          ...formData,
          userImageUrl: {
            value: '',
          },
        };
        setFormData(newFormData);
      }
    } catch (error) {
      showToastError(error.message || 'Error deleting avatar');
    }
  };

  const uploadFile = async (file) => {
    // check if file is an image and its size is less than or equal to 1MB
    const isImage = file.type.startsWith('image/');
    const isLessThan1MB = file.size <= 1000000; // 1MB in bytes

    if (!isImage) {
      showToastError('Selected file is not an image.');
      return;
    }

    if (!isLessThan1MB) {
      showToastError('Selected file is too large. Max size is 1MB.');
      return;
    }

    const fileName = file.name;
    try {
      const formDataServer = new FormData();
      formDataServer.append('file', file);
      const response = await uploadUserAvatar(formDataServer);
      if (response) {
        const newFormData = {
          ...formData,
          userImageUrl: {
            value: `https://ld-kanban-dev.s3.amazonaws.com/kanban/user/${userProfile.id}/images/${fileName}`,
          },
        };
        setFormData(newFormData);
        avatarRef.current.value = '';
      }
    } catch (error) {
      showToastError(error.message || 'Error uploading avatar');
    }
  };

  const handleKeyDownForInput = (key, event) => {
    if (event.code === 'Escape') {
      event.target.blur();
    }

    if (event.code === 'Delete') {
      resetField(key);
    }
  };

  const handleEnterKeyAction = useCallback(
    (event) => {
      if (event.code === 'Enter' && activeBtn && !session.restorePwd) {
        return handleSubmitForm();
      }
      return null;
    },
    [activeBtn],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEnterKeyAction);
    return () => {
      document.removeEventListener('keydown', handleEnterKeyAction);
    };
  }, [handleEnterKeyAction]);

  useEffect(() => {
    if (profileSettings?.data) {
      const updatedSettingsFormData = {
        lang: {
          value: profileSettings?.data.lang,
          error: null,
        },
        theme: {
          value: profileSettings?.data.theme,
          error: null,
        },
        order: {
          value: profileSettings?.data.order,
          error: null,
        },
      };

      setSettingsFormData(updatedSettingsFormData);
    }
  }, [profileSettings?.data]);

  return (
    <div className="c-user-edit-profile">
      <div className={userAvatarClassName}>
        <div className="c-user-edit-profile__data">
          <div className="c-user-edit-profile__avatar">
            <div className="c-user-edit-profile__avatar-wrapper">
              <UserAvatar
                size="lg"
                userImageUrl={formData.userImageUrl.value}
                userFirstName={formData.firstName.value}
                userLastName={formData.lastName.value}
                onClick={uploadAvatar}
              />
            </div>
            <IconEdit size="sm" onClick={uploadAvatar} />
            {formData.userImageUrl.value && (
              <IconBin size="sm" onClick={deleteAvatar} />
            )}
            <div className="max-size-warning c-user-edit-profile__max-size-warning">
              <Paragraph type="secondary" text="Max size 1Mb" />
            </div>
            <input
              className="c-user-edit-profile__input-file"
              type="file"
              visibility="hidden"
              ref={avatarRef}
              onChange={(e) => uploadFile(e.target.files[0])}
            />
          </div>
          <hr
            width="1"
            className="horizontal-line c-user-edit-profile__horizontal-line"
          />
          <div className="c-user-edit-profile__info">
            {formData.firstName.value && (
              <Paragraph type="secondary">
                {[formData.firstName.value, formData.lastName.value].join(' ')}
              </Paragraph>
            )}
            {formData.userName.value && (
              <Paragraph type="secondary">{formData.userName.value}</Paragraph>
            )}
            {formData.email.value && (
              <Paragraph type="secondary">{formData.email.value}</Paragraph>
            )}
            {formData.userInfo.value && (
              <Paragraph type="secondary">{formData.userInfo.value}</Paragraph>
            )}
          </div>
        </div>
        <div className={generalInfoClassName}>
          <Heading type="secondary" text={I18N.GENERAL_INFO} />
          <InputControl
            label={I18N.FIRST_NAME}
            value={formData.firstName.value}
            name="firstName"
            onChange={(e) => updateForm('firstName', e)}
            errorMessage={formData.firstName.error}
            onReset={() => resetField('firstName')}
            onBlur={handleBlur}
            isValid={formData.firstName !== ''}
            onKeyDown={(e) => handleKeyDownForInput('firstName', e)}
          />
          <InputControl
            label={I18N.LAST_NAME}
            value={formData.lastName.value}
            name="lastName"
            onChange={(e) => updateForm('lastName', e)}
            errorMessage={formData.lastName.error}
            onReset={() => resetField('lastName')}
            onBlur={handleBlur}
            isValid={formData.lastName !== ''}
            onKeyDown={(e) => handleKeyDownForInput('lastName', e)}
          />
          <InputControl
            label={I18N.USERNAME}
            value={formData.userName.value}
            name="userName"
            onChange={(e) => updateForm('userName', e)}
            errorMessage={formData.userName.error}
            onReset={() => resetField('userName')}
            onBlur={handleBlur}
            isValid={formData.userName !== ''}
            onKeyDown={(e) => handleKeyDownForInput('userName', e)}
          />
          <InputControl
            label={I18N.EMAIL}
            value={formData.email.value}
            name="email"
            onChange={(e) => updateForm('email', e)}
            errorMessage={formData.email.error}
            onReset={() => resetField('email')}
            onBlur={handleBlur}
            isValid={formData.email !== ''}
            onKeyDown={(e) => handleKeyDownForInput('email', e)}
          />
          <TextareaControl
            label={I18N.INFO}
            value={formData.userInfo.value}
            name="userInfo"
            onChange={(e) => updateForm('userInfo', e)}
            errorMessage={formData.userInfo.error}
            // onReset={() => resetField('userInfo')}
            onBlur={handleBlur}
            onKeyDown={(e) => handleKeyDownForInput('userInfo', e)}
          />
          <div className="c-user-edit-profile__form-actions">
            <Button
              text={I18N.SAVE}
              onClick={handleSubmitForm}
              disabled={!activeBtn}
            />
          </div>
        </div>
      </div>
      <div className={settingsClassName}>
        <div className="c-user-edit-profile__appearance">
          <Heading type="secondary" text={I18N.SETTINGS} />
          <div className="c-user-edit-profile__theme">
            <SelectControl
              label={I18N.COLOR_THEME}
              name="theme"
              onChange={handleChange}
              value={settingsFormData.theme.value}
            >
              {Object.values(COLOR_THEME).map((value) => (
                <SelectOption value={value} text={colors[value]} key={value} />
              ))}
            </SelectControl>
          </div>
          <div className="c-user-edit-profile__lang">
            <SelectControl
              label={I18N.LANGUAGES}
              name="lang"
              onChange={handleChange}
              value={settingsFormData.lang.value}
            >
              {LANGUAGES.map((item) => (
                <SelectOption value={item} text={languages[item]} key={item} />
              ))}
            </SelectControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserEditProfile;
