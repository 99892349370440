import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

// Translations
import { useI18N } from '../../i18n/useI18N';

// Components
import Panel from '../../components/Panel';
import UserProfile from '../../components/UserProfile';
import UserTasks from '../../components/UserTasks';
import UserEditProfile from '../../components/UserEditProfile';
import Settings from '../../components/Settings';
import Notification from '../../components/Notification';
import UserTimeReport from '../../components/UserTimeReport';
import IconArrowLeft from '../../components/Icons/IconArrowLeft';

// Store
import { userProfileState, sessionState } from '../../store/atoms';

// Constant
import { NOTIFICATION_TYPE } from '../../config/config';

// Style
import './UserProfileView.scss';

const UserProfileView = () => {
  // Component logic
  const { userId } = useParams();
  const userProfile = useRecoilValue(userProfileState);
  const session = useRecoilValue(sessionState);
  const navigate = useNavigate();

  const goBack = () => {
    if (
      window.history
      && window.history.length > 1
      && !window.history.state?.usr?.restoredPwd
    ) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  const isUserOwner = parseInt(userId, 10) === userProfile?.id;

  const I18N = useI18N();

  return (
    <div className="v-user-profile">
      {userProfile && (
        <>
          {!session.restorePwd && (
          <button className="button" type="button" onClick={goBack}>
            <IconArrowLeft size="xs" />
            {I18N.GO_TO_THE_PREVIOUS_PAGE}
          </button>
          )}
          <Panel title={session.restorePwd ? I18N.CHANGE_PASSWORD : I18N.USER_PROFILE}>
            {session.restorePwd && (
              <Notification
                text={I18N.PLEASE_CREATE_NEW_PASSWORD_TO_CONTINUE}
                type={NOTIFICATION_TYPE.WARNING}
              />
            )}
            {(isUserOwner || session.restorePwd) && (
            <div className="c-user-profile__warper">
              <UserEditProfile />
              <Settings />
            </div>
            )}
            {(!isUserOwner && !session.restorePwd && userId) && (
              <UserProfile userId={userId} />
            )}
          </Panel>
          {(!session.restorePwd && userId) && (
          <>
            <Panel title={I18N.USER_TASKS}>
              <UserTasks userId={userId} />
            </Panel>
            <Panel title={I18N.USER_TIME_REPORT}>
              <UserTimeReport userId={userId} />
            </Panel>
          </>
          )}
        </>
      )}
    </div>
  );
};

export default UserProfileView;
