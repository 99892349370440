import React, { Suspense } from 'react';
import { useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

// Store
import { boardsState, userProfileState } from '../../store/atoms';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Configs
import { BOARD_TYPE } from '../../config/config';

// Components
import BoardListEmptyState from '../BoardListEmptyState';
import BoardPanel from '../BoardPanel';
import Loader from '../Loader';

// Style
import './BoardPanelList.scss';

const BoardPanelList = () => {
  const I18N = useI18N();

  const availableBoards = useRecoilValue(boardsState);
  const userProfile = useRecoilValue(userProfileState);

  const navigate = useNavigate();

  const createNewBoard = () => {
    navigate('/board/new');
  };

  const getBoardsByType = (type) => {
    switch (type) {
      case BOARD_TYPE.FAVORITE:
        return {
          name: I18N.FAVORITE,
          boards: userProfile.favoriteBoards.map((favBoard) => {
            const foundBoard = availableBoards
              .find((board) => board.id === favBoard.boardId && !board.isArchive);
            return foundBoard || [];
          })
            .flat(),
        };

      case BOARD_TYPE.PRIVATE:
        return {
          name: I18N.PRIVATE,
          boards: availableBoards.filter((board) => board.usersCount === 1 && !board.isArchive),
        };

      case BOARD_TYPE.SHARED:
        return {
          name: I18N.SHARED,
          boards: availableBoards.filter((board) => board.usersCount > 1 && !board.isArchive),
        };

      case BOARD_TYPE.ARCHIVE:
        return {
          name: I18N.ARCHIVE,
          boards: availableBoards.filter((board) => board.isArchive),
        };

      default:
        return {
          name: I18N.MESSAGE_TO_ERROR_PAGE,
          boards: [],
        };
    }
  };

  return (
    <div className="c-board-panel-list">
      <Suspense fallback={<Loader />}>
        {(
          !!(userProfile?.favoriteBoards)
          && Array.isArray(availableBoards)
          && !!availableBoards.length
        ) ? (
          <>
            {Object.values(BOARD_TYPE).map((boardType) => {
              const { name, boards } = getBoardsByType(boardType);
              return boards?.length > 0 && (
              <BoardPanel
                boardType={boardType}
                key={boardType}
                listBoards={boards}
                name={name}
                onClick={createNewBoard}
                userData={userProfile}
              />
              );
            })}
          </>
          ) : (
            <BoardListEmptyState createBoard={createNewBoard} />
          )}
      </Suspense>
    </div>
  );
};

export default BoardPanelList;
