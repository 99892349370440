import React from 'react';
import Heading from '../Heading';
import Modal from '../Modal';
import { useI18N } from '../../i18n/useI18N';
import { IconChevronRight } from '../Icons';
import './BoardNewMobile.scss';

const BoardNewMobile = (props) => {
  const I18N = useI18N();

  const {
    isVisibleModal,
    onClose,
    onCreate,
    boardData,
    changeBoardName,
    setIsEditTitle,
    changeBoardDescription,
  } = props;

  return (
    <Modal
      title={I18N.CREATE_NEW_BOARD}
      isVisible={isVisibleModal}
      className="c-modal-create-board"
      onClose={onClose}
      btnAcceptHandler={onCreate}
      btnAcceptText={I18N.GO_TO_BOARD}
      btnAcceptIcon={<IconChevronRight />}
    >
      <Heading
        type="secondary"
        text={boardData.name}
        onChange={changeBoardName}
        onFocus={setIsEditTitle}
        editable
        maxSymbol="60"
      />
      <Heading
        type="secondary"
        text={
            boardData.description || boardData.description === ''
              ? boardData.description
              : I18N.BOARD_INFO
          }
        editable
        onChange={changeBoardDescription}
        maxSymbol="180"
      />
    </Modal>
  );
};

export default BoardNewMobile;
