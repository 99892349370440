import React from 'react';
import Icon from '../Icon';

const IconLinkedin = (props) => {
  const {
    size,
    inverse,
    onClick,
    role,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-linkedin"
      role={role}
    >
      <svg
        viewBox="0 0 30 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fillRule="evenodd" clipRule="evenodd" d="M2 0C0.895431 0 0 0.89543 0 2V28C0 29.1046 0.89543 30 2 30H28C29.1046 30 30 29.1046 30 28V2C30 0.895431 29.1046 0 28 0H2ZM21.373 25.8207H25.8102L25.8148 25.8198V17.9588C25.8148 14.1057 24.9795 11.1423 20.4786 11.1423C18.3148 11.1423 16.8645 12.3282 16.2683 13.456H16.2111V11.5032H11.9436V25.8198H16.3864V18.7351C16.3864 16.8648 16.7389 15.0638 19.0527 15.0638C21.3383 15.0638 21.373 17.1976 21.373 18.8579V25.8207ZM5.93602 9.35013C6.24929 9.47999 6.5851 9.54677 6.92422 9.54664C7.43464 9.54701 7.93371 9.39603 8.35833 9.11278C8.78295 8.82953 9.11405 8.42674 9.30976 7.95533C9.50548 7.48393 9.55702 6.96507 9.45787 6.46437C9.35872 5.96367 9.11334 5.50361 8.75275 5.14236C8.39215 4.78111 7.93254 4.5349 7.43202 4.43484C6.9315 4.33478 6.41256 4.38538 5.94079 4.58024C5.46903 4.7751 5.06564 5.10547 4.78162 5.52957C4.4976 5.95367 4.34571 6.45247 4.34516 6.96289C4.34442 7.30201 4.41059 7.63794 4.53988 7.95144C4.66917 8.26494 4.85904 8.54986 5.09861 8.78987C5.33819 9.02988 5.62276 9.22027 5.93602 9.35013ZM4.69766 25.8207H9.14984V11.5041H4.69766V25.8207Z" />
      </svg>
    </Icon>
  );
};

export default IconLinkedin;
