import { useRecoilValue } from 'recoil';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

// Store
import { boardsState } from '../../store/atoms';

// Configs
import { PAGE } from '../../config/config';

const GuardRouter = (props) => {
  const { children } = props;

  const { boardId } = useParams();
  const navigate = useNavigate();

  const boards = useRecoilValue(boardsState);
  const hasAccess = boards.some((board) => board.id === Number(boardId));

  useEffect(() => {
    if (!hasAccess && boardId !== 'new') {
      navigate(`/${PAGE.NOT_ACCESS.PATH}`);
    }
  }, [boardId, boards]);

  return hasAccess || boardId === 'new' ? children : null;
};

export default GuardRouter;
