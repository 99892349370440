import React from 'react';
import Icon from '../Icon';

const IconCalendar = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-calendar">
      <svg viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.78125 11.25H4.21875C3.96094 11.25 3.75 11.0391 3.75 10.7812V9.21875C3.75 8.96094 3.96094 8.75 4.21875 8.75H5.78125C6.03906 8.75 6.25 8.96094 6.25 9.21875V10.7812C6.25 11.0391 6.03906 11.25 5.78125 11.25ZM10 10.7812V9.21875C10 8.96094 9.78906 8.75 9.53125 8.75H7.96875C7.71094 8.75 7.5 8.96094 7.5 9.21875V10.7812C7.5 11.0391 7.71094 11.25 7.96875 11.25H9.53125C9.78906 11.25 10 11.0391 10 10.7812ZM13.75 10.7812V9.21875C13.75 8.96094 13.5391 8.75 13.2812 8.75H11.7188C11.4609 8.75 11.25 8.96094 11.25 9.21875V10.7812C11.25 11.0391 11.4609 11.25 11.7188 11.25H13.2812C13.5391 11.25 13.75 11.0391 13.75 10.7812ZM10 14.5312V12.9688C10 12.7109 9.78906 12.5 9.53125 12.5H7.96875C7.71094 12.5 7.5 12.7109 7.5 12.9688V14.5312C7.5 14.7891 7.71094 15 7.96875 15H9.53125C9.78906 15 10 14.7891 10 14.5312ZM6.25 14.5312V12.9688C6.25 12.7109 6.03906 12.5 5.78125 12.5H4.21875C3.96094 12.5 3.75 12.7109 3.75 12.9688V14.5312C3.75 14.7891 3.96094 15 4.21875 15H5.78125C6.03906 15 6.25 14.7891 6.25 14.5312ZM13.75 14.5312V12.9688C13.75 12.7109 13.5391 12.5 13.2812 12.5H11.7188C11.4609 12.5 11.25 12.7109 11.25 12.9688V14.5312C11.25 14.7891 11.4609 15 11.7188 15H13.2812C13.5391 15 13.75 14.7891 13.75 14.5312ZM17.5 4.375V18.125C17.5 19.1602 16.6602 20 15.625 20H1.875C0.839844 20 0 19.1602 0 18.125V4.375C0 3.33984 0.839844 2.5 1.875 2.5H3.75V0.46875C3.75 0.210938 3.96094 0 4.21875 0H5.78125C6.03906 0 6.25 0.210938 6.25 0.46875V2.5H11.25V0.46875C11.25 0.210938 11.4609 0 11.7188 0H13.2812C13.5391 0 13.75 0.210938 13.75 0.46875V2.5H15.625C16.6602 2.5 17.5 3.33984 17.5 4.375ZM15.625 17.8906V6.25H1.875V17.8906C1.875 18.0195 1.98047 18.125 2.10938 18.125H15.3906C15.5195 18.125 15.625 18.0195 15.625 17.8906Z" />
      </svg>

    </Icon>
  );
};

export default IconCalendar;
