import React from 'react';

// Component
import Button from '../Button';
import ColorMarkBar from '../ColorMarkBar';
import { IconPlus } from '../Icons';

// Style
import './StatusCardAddButton.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Configs
import { POSITION } from '../../config/config';

const StatusCardAddButton = (props) => {
  const { handleCreateNewStatus } = props;

  const I18N = useI18N();

  return (
    <div className="c-status-card-add-button">
      <ColorMarkBar
        position={POSITION.TOP}
      />
      <Button
        iconLeft={<IconPlus />}
        id="button_addColumn_statusCardAddButton"
        type="primary-inverse"
        onClick={handleCreateNewStatus}
      />
      <div className="c-status-card-add-button__text" onClick={handleCreateNewStatus}>
        {I18N.ADD_COLUMN}
      </div>
    </div>
  );
};

export default StatusCardAddButton;
