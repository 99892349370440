import React from 'react';
import Icon from '../Icon';

const IconErrorPage = (props) => {
  const {
    size,
    inverse,
    onClick,
  } = props;

  return (
    <Icon size={size} inverse={inverse} onClick={onClick} classNameIconType="c-icon-error-page">
      <svg viewBox="0 0 76 90" xmlns="http://www.w3.org/2000/svg">
        <path d="M49.2583 62.7246H26.7404C25.9614 62.7246 25.332 63.3344 25.332 64.0892C25.332 64.844 25.9614 65.4538 26.7404 65.4538H39.4078V69.5436C39.4078 72.5498 41.9315 74.998 45.0373 74.998C48.1431 74.998 50.6668 72.5498 50.6668 69.5436V64.0892C50.6668 63.3333 50.0374 62.7246 49.2583 62.7246ZM47.8499 69.5416C47.8499 71.0472 46.586 72.2688 45.0352 72.2688C43.4843 72.2688 42.2204 71.0472 42.2204 69.5416V65.4518H47.8499V69.5416Z" />
        <path d="M26.7433 57.2701C27.1035 57.2701 27.4627 57.1362 27.7381 56.8714C28.2878 56.3387 28.2878 55.4753 27.7381 54.9427L25.9181 53.1793L27.7381 51.4159C28.2878 50.8833 28.2878 50.0199 27.7381 49.4872C27.1883 48.9545 26.2972 48.9545 25.7475 49.4872L23.9275 51.2506L22.1075 49.4872C21.5578 48.9545 20.6667 48.9545 20.1169 49.4872C19.5672 50.0199 19.5672 50.8833 20.1169 51.4159L21.9369 53.1793L20.1169 54.9427C19.5672 55.4753 19.5672 56.3387 20.1169 56.8714C20.3933 57.1372 20.7515 57.2701 21.1117 57.2701C21.4719 57.2701 21.8311 57.1362 22.1065 56.8714L23.9264 55.108L25.7464 56.8714C26.0239 57.1362 26.3831 57.2701 26.7433 57.2701Z" />
        <path d="M55.8851 49.4885C55.3353 48.9558 54.4442 48.9558 53.8944 49.4885L52.0745 51.2518L50.2545 49.4885C49.7048 48.9558 48.8136 48.9558 48.2639 49.4885C47.7141 50.0211 47.7141 50.8846 48.2639 51.4172L50.0838 53.1806L48.2639 54.9439C47.7141 55.4766 47.7141 56.34 48.2639 56.8727C48.5403 57.1385 48.8985 57.2714 49.2587 57.2714C49.6189 57.2714 49.9781 57.1375 50.2534 56.8727L52.0734 55.1093L53.8934 56.8727C54.1698 57.1385 54.5279 57.2714 54.8881 57.2714C55.2483 57.2714 55.6075 57.1375 55.8829 56.8727C56.4327 56.34 56.4327 55.4766 55.8829 54.9439L54.063 53.1806L55.8829 51.4172C56.4348 50.8846 56.4348 50.0201 55.8851 49.4885Z" />
        <path d="M75.5852 19.4892L55.8874 0.398735C55.7554 0.27394 55.5994 0.17654 55.4277 0.106534C55.2539 0.0365274 55.0738 0 54.8884 0H7.03791C3.15626 0 0 3.05707 0 6.81906V83.1809C0 86.9411 3.15518 90 7.03791 90H68.9621C72.8437 90 76 86.9429 76 83.1809V20.4525C76 20.277 75.9623 20.0974 75.8932 19.93C75.8199 19.7646 75.7162 19.6134 75.5843 19.4887L75.5852 19.4892ZM60.5188 19.0905C58.19 19.0905 56.2978 17.2551 56.2978 15.0007V4.65599L71.1955 19.0905H60.5188ZM68.963 87.2681H7.03885C4.70999 87.2681 2.81782 85.4327 2.81782 83.1783V6.81646C2.81782 4.56001 4.71211 2.72668 7.03885 2.72668H53.482V15.0002C53.482 18.7603 56.6372 21.8192 60.5199 21.8192H73.1873L73.1862 83.1789C73.1852 85.4353 71.2909 87.2686 68.962 87.2686L68.963 87.2681Z" />
      </svg>
    </Icon>
  );
};

export default IconErrorPage;
