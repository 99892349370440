import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useRecoilValue, useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// Store
import { userProfileState, sessionState } from '../../store/atoms';

// Configs
import { PAGE } from '../../config/config';

const ProtectedRoute = (props) => {
  const { redirectPage, children } = props;

  // Global State
  const [session, setSession] = useRecoilState(sessionState);
  const userProfile = useRecoilValue(userProfileState);

  //  Hooks
  const navigate = useNavigate();
  const [cookies] = useCookies(['jwt-token']);

  // Logics
  const page = redirectPage || PAGE.ABOUT.PATH;

  useEffect(() => {
    setSession({
      ...session,
      authFormType: null,
    });

    if (!userProfile && !cookies['jwt-token']) {
      navigate(`/${page}`);
    }
  }, [userProfile]);

  return children;
};

export default ProtectedRoute;
