import React from 'react';
import Icon from '../Icon';

const IconEmail = (props) => {
  const {
    size,
    inverse,
    onClick,
    role,
  } = props;

  return (
    <Icon
      size={size}
      inverse={inverse}
      onClick={onClick}
      classNameIconType="c-icon-email"
      role={role}
    >
      <svg
        viewBox="0 0 300 202"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M276 0.0986328H24C10.7997 0.0986328 0 11.4171 0 25.2501V176.159C0 189.992 10.7997 201.311 24 201.311H276C289.2 201.311 300 189.992 300 176.159V25.2501C300 11.4171 289.2 0.0986328 276 0.0986328ZM270 12.6751L150 122.084L29.9997 12.6751H270ZM15.5997 184.963C13.1997 182.447 12 179.932 12 176.159V25.2501C12 21.4778 13.2003 18.9626 15.5997 16.4468L108 100.705L15.5997 184.963ZM29.9997 188.736L117.6 109.508L150 138.432L182.4 109.508L270 188.735L29.9997 188.736ZM288 176.159C288 179.932 286.8 182.447 284.4 184.963L192 100.705L284.4 16.4468C286.8 18.9619 288 21.4771 288 25.2501V176.159Z"
        />
      </svg>
    </Icon>
  );
};

export default IconEmail;
