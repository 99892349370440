import React from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Configs
import {
  POSITION,
  AUTH_FORM_TYPE,
  AUTH_STATUS,
  PAGE,
} from '../../config/config';

// State
import {
  sessionState,
} from '../../store/atoms';

// Components
import Button from '../Button';
import {
  IconColumns,
  IconPlus,
  IconUser,
  IconHorizontalSliders,
  IconFilter,
  IconStar,
} from '../Icons';
import Tooltip from '../Tooltip';

// Style
import './BoardButtonBar.scss';

const TooltipCTA = (props) => {
  const {
    onClick,
  } = props;

  const I18N = useI18N();

  return (
    <div className="c-tooltip-massage">
      <p className="c-tooltip-massage__text">
        {I18N.MESSAGE_TO_REGISTER}
      </p>
      <Button
        text={I18N.REGISTER}
        size="xs"
        onClick={onClick}
      />
    </div>
  );
};

const BoardButtonBar = (props) => {
  const {
    btnAddUserForm,
    btnAddFavoriteBoard,
    btnCreateNewStatus,
    btnCreateTask,
    btnShowActions,
    btnAddFilter,
    disabledAddTask,
    disabledAddUser,
    isActionsMode,
    isAccessToMarkFavorites,
  } = props;

  // Component Logic
  const I18N = useI18N();
  const [session, setSession] = useRecoilState(sessionState);
  const navigate = useNavigate();

  const handleRegister = () => {
    setSession({
      ...session,
      authFormType: AUTH_FORM_TYPE.REGISTER,
      authStatus: AUTH_STATUS.NOT_AUTH,
    });
    navigate(`/${PAGE.AUTH.PATH}`);
  };

  return (
    <div className="c-board-button-bar">
      <Button
        text={I18N.ADD_TASK}
        iconLeft={<IconPlus />}
        size="sm"
        onClick={btnCreateTask}
        disabled={disabledAddTask}
        id="button_addTask_boardButtonBar"
      />
      <Button
        text={I18N.ADD_COLUMN}
        iconLeft={<IconColumns />}
        size="sm"
        onClick={btnCreateNewStatus}
        id="button_addColumn_boardButtonBar"
      />
      <Tooltip
        info={<TooltipCTA onClick={handleRegister} />}
        position={POSITION.BOTTOM}
        disabled={!disabledAddUser}
      >
        <Button
          text={I18N.ADD_USER}
          iconLeft={<IconUser />}
          size="sm"
          onClick={btnAddUserForm}
          disabled={disabledAddUser}
          id="button_addUser_boardButtonBar"
        />
      </Tooltip>
      <Button
        text={I18N.ADD_FILTER}
        iconLeft={<IconFilter />}
        size="sm"
        onClick={btnAddFilter}
        id="button_addFilter_boardButtonBar"
      />
      {isAccessToMarkFavorites && (
        <Tooltip
          info={<TooltipCTA onClick={handleRegister} />}
          position={POSITION.BOTTOM}
          disabled={!disabledAddUser}
        >
          <Button
            text={I18N.ADD_FAVORITE}
            iconLeft={<IconStar />}
            size="sm"
            onClick={btnAddFavoriteBoard}
            disabled={disabledAddUser}
          />
        </Tooltip>
      )}
      <Button
        text={isActionsMode ? I18N.LESS_ACTIONS : I18N.MORE_ACTIONS}
        iconLeft={<IconHorizontalSliders />}
        size="sm"
        onClick={btnShowActions}
      />
    </div>
  );
};

export default BoardButtonBar;
