import React from 'react';

import './Paragraph.scss';

const Paragraph = (props) => {
  const { type, children, text } = props;
  const classType = type || 'primary';
  const classContent = text || children;

  return (
    <p className={`c-paragraph ${classType}`}>
      {classContent}
    </p>
  );
};

export default Paragraph;
