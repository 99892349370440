import React, { useState } from 'react';

// Component
import Button from '../Button';
import Modal from '../Modal';
import { IconBin, IconSave } from '../Icons';

// Style
import './TaskActions.scss';

// Hooks
import { useI18N } from '../../i18n/useI18N';
import TaskWarning from '../TaskWarning/TaskWarning';
import { checkBoardIndexShouldUpdate, generateNewBoardIndex } from '../../utils/boardIndexUtils';

const TaskActions = (props) => {
  const {
    data,
    deleteTaskMutation,
    saveNewTaskMutation,
    handleCloseTask,
    isNewTask,
    isMobile,
    boards,
  } = props;

  const I18N = useI18N();

  const [isOpenModalDeleteTask, setIsOpenModalDeleteTask] = useState(false);
  const [boardIndexAlert, setBoardIndexAlert] = useState(null);

  const closeWarning = () => {
    setBoardIndexAlert(null);
  };

  const createTask = () => {
    const newBoard = boards.find((board) => board.id === data.boardId);

    const isBoardIndexShouldUpdate = checkBoardIndexShouldUpdate(data.boardIndex, newBoard.tasks);

    if (isBoardIndexShouldUpdate && !boardIndexAlert) {
      const newBoardIndex = generateNewBoardIndex(
        newBoard.taskIndexCounter,
        newBoard.taskIndexPrefix,
        newBoard.tasks,
      );

      const newTaskIndexCounter = Number(newBoardIndex.replace(`${newBoard.taskIndexPrefix}-`, ''));

      setBoardIndexAlert({
        keyTitle: 'CHANGING_TASK_INDEX',
        keyDescription: 'MESSAGE_ABOUT_CHANGED_TASK_INDEX',
        optionsTranslate: { oldBoardIndex: data.boardIndex, newBoardIndex },
        payload: { taskIndexCounter: newTaskIndexCounter, boardIndex: newBoardIndex },
      });
    }

    if (isBoardIndexShouldUpdate && boardIndexAlert) {
      const { payload } = boardIndexAlert;
      closeWarning();
      handleCloseTask();

      saveNewTaskMutation(payload);
    }

    if (!isBoardIndexShouldUpdate) {
      handleCloseTask();
      saveNewTaskMutation();
    }
  };

  const handleOpenModalDeleteTask = () => {
    setIsOpenModalDeleteTask(true);
  };

  const handleCloseModalDeleteTask = () => {
    setIsOpenModalDeleteTask(false);
  };

  const handleDeleteTask = () => {
    deleteTaskMutation(data.id);
    handleCloseModalDeleteTask();
    handleCloseTask();
  };

  return (
    <div className="c-task-actions">
      {isNewTask ? (
        <>
          <Button
            text={I18N.CREATE_NEW_TASK}
            iconLeft={<IconSave />}
            size="sm"
            type="confirm-inverse"
            onClick={createTask}
          />
          {boardIndexAlert && (
            <TaskWarning
              acceptChange={createTask}
              closeWarning={closeWarning}
              data={boardIndexAlert}
            />
          )}
        </>
      ) : (
        <>
          <Button
            text={I18N.DELETE_TASK}
            iconLeft={<IconBin />}
            size="sm"
            type="danger-inverse"
            onClick={handleOpenModalDeleteTask}
          />
          <Modal
            isVisible={isOpenModalDeleteTask}
            onClose={handleCloseModalDeleteTask}
            title={I18N.DELETE_TASK}
            content={I18N.PLEASE_CONFIRM_DELETE_ACTION}
            type="WARNING"
            btnAcceptText={I18N.DELETE_TASK}
            btnAcceptHandler={handleDeleteTask}
            btnCancelHandler={handleCloseModalDeleteTask}
            isMobile={isMobile}
            className="modal-task-action"
          />
        </>
      )}
    </div>
  );
};

export default TaskActions;
