const getDate = (value) => {
  if (!value) {
    return 'dd.mm.yyyy';
  }

  const currentDate = new Date(value);
  let date = currentDate.getDate();
  if (date < 10) {
    date = `0${date}`;
  }
  let month = currentDate.getMonth() + 1;
  if (month < 10) {
    month = `0${month}`;
  }
  const year = currentDate.getFullYear();
  const formatCurrentDate = `${date}.${month}.${year}`;
  return formatCurrentDate;
};

export default getDate;
