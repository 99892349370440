import React, {
  useState,
  useEffect,
  useContext,
} from 'react';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Components
import Logo from '../Logo';
import UserField from '../UserField';
import Button from '../Button';
import LinkRouter from '../LinkRouter';
import { IconBurger } from '../Icons';
import MobileMenu from '../MobileMenu';

// Context
import LayoutContext from '../../context/LayoutContext';

// Configs
import { PAGE } from '../../config/config';

// Styles
import './HeaderLayout.scss';

const HeaderLayout = (props) => {
  const {
    user,
    handleLogout,
    handleLogin,
    handleRegister,
  } = props;

  const I18N = useI18N();
  const { isMobile } = useContext(LayoutContext);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const showLogoutButton = () => (user && !user.isTemp && window.location.pathname !== '/auth');
  const showRegisterButton = () => (user && user.isTemp && window.location.pathname !== '/auth');
  const showLoginButton = () => (!user && window.location.pathname !== '/auth');

  useEffect(() => {
    if (!isMobile && showMobileMenu) {
      setShowMobileMenu(false);
    }
  }, [isMobile]);

  const openMobileMenu = () => {
    setShowMobileMenu(true);
  };

  const closeMobileMenu = () => {
    setShowMobileMenu(false);
  };

  // Render function
  return (
    <header className="c-header-layout">
      <h1 className="c-header-layout__title">
        <LinkRouter
          page={PAGE.HOME.PATH}
        >
          <Logo imgUrl="/img/logo_circle.png" />
        </LinkRouter>
      </h1>
      <div className="c-header-layout__user">
        {showLogoutButton() && (
          <>
            <LinkRouter
              page={PAGE.USER_PROFILE.PATH}
              data={{ userId: user.id }}
            >
              <UserField user={user} />
            </LinkRouter>
            <Button
              text={I18N.LOGOUT}
              onClick={handleLogout}
            />
          </>
        )}
        {showRegisterButton() && (
          <Button
            text={I18N.REGISTER}
            onClick={handleRegister}
            id="button_register_headerLayout"
          />
        )}
        {showLoginButton() && (
          <Button
            text={I18N.SIGN_IN}
            onClick={handleLogin}
            id="button_register_headerLayout"
          />
        )}
        {isMobile && (
          <IconBurger
            size="xl"
            onClick={openMobileMenu}
          />
        )}
      </div>
      {showMobileMenu && (
        <MobileMenu
          user={user}
          handleLogout={handleLogout}
          handleLogin={handleLogin}
          handleRegister={handleRegister}
          closeMobileMenu={closeMobileMenu}
          showLogoutButton={showLogoutButton}
          showRegisterButton={showRegisterButton}
          showLoginButton={showLoginButton}
        />
      )}
    </header>
  );
};

export default HeaderLayout;
