import React from 'react';
import { FaUserAlt } from 'react-icons/fa';

import './UserAvatar.scss';

const UserAvatar = (props) => {
  const {
    userImageUrl,
    userFirstName,
    userLastName,
    onClick,
    size,
    backgroundColor,
  } = props;

  const userInitials = userFirstName
    && userLastName
    && typeof userFirstName === 'string'
    && typeof userLastName === 'string'
    ? `${userFirstName[0].toLocaleLowerCase()}${userLastName[0].toLocaleLowerCase()}`
    : '';
  const classClickable = onClick ? 'clickable' : '';
  const classUserAvatarSize = size || 'md';
  const classUserAvatar = `c-user-avatar c-user-avatar_${classUserAvatarSize} ${classClickable}`;

  const handleClick = (event) => {
    if (typeof onClick === 'function') {
      return onClick(event);
    }
    return null;
  };

  const getAvatar = () => {
    if (userImageUrl) {
      return (
        <img className="c-user-avatar__photo" src={userImageUrl} alt="avatar" />
      );
    }

    if (userInitials !== '') {
      return <span>{userInitials}</span>;
    }

    return <FaUserAlt className="c-user-avatar__icon" />;
  };

  return (
    <div
      onClick={handleClick}
      className={classUserAvatar}
      style={{ backgroundColor: backgroundColor || '#D9D9D9' }}
    >
      {getAvatar()}
    </div>
  );
};

export default UserAvatar;
