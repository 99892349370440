import React from 'react';

// Components
import Button from '../Button';
import {
  IconGoogle,
  // IconFacebook,
} from '../Icons';

// Constants
import {
  BASE_URL,
} from '../../config/config';

// Styles
import './AuthFormSocialButtons.scss';

const AuthFormSocialButtons = (props) => {
  const { I18N = {} } = props;

  const authWithGoogle = () => {
    window.open(`${BASE_URL}/auth/google`, '_self');
  };

  // const authWithFacebook = () => {
  //   window.open(`${BASE_URL}/auth/facebook`, '_self');
  // };

  return (
    <div className="c-auth-form-social-buttons">
      <div className="c-auth-form-social-buttons_button c-auth-form-social-buttons_button--google">
        <Button
          type="primary-inverse"
          text={`${I18N.CONTINUE_WITH} Google`}
          iconLeft={<IconGoogle size="xl" />}
          onClick={authWithGoogle}
        />
      </div>
      {/*
      <div className="c-auth-form-social-buttons_buttonc-auth-form-social-buttons_button--facebook">
        <Button
          type="primary-inverse"
          text={`${I18N.CONTINUE_WITH} Facebook`}
          iconLeft={<IconFacebook size="xl" />}
          onClick={authWithFacebook}
        />
      </div>
      */}
    </div>
  );
};

export default AuthFormSocialButtons;
