import React from 'react';

// Hooks
import { useI18N } from '../../i18n/useI18N';

// Components
import Panel from '../../components/Panel';
import ErrorMessage from '../../components/ErrorMessage';

import './NotFoundView.scss';

const NotFoundView = () => {
  const I18N = useI18N();

  return (
    <div className="v-not-found">
      <Panel>
        <ErrorMessage
          isNavigateHome
          name={I18N.MESSAGE_TO_ERROR_404}
          number={404}
        />
      </Panel>
    </div>
  );
};

export default NotFoundView;
