import React from 'react';

// Translations
import { useI18N } from '../../i18n/useI18N';

// Configs
import { COLOR_USER_LIST_DEFAULT } from '../../config/config';

// Utils
import generateRandomStr from '../../utils/generateRandomStr';

// Component
import UserAvatar from '../UserAvatar';
import { IconPlus, IconRemoveUser } from '../Icons';

// Style
import './UserList.scss';

const UserList = (props) => {
  const {
    maxAmount = 3,
    users,
    size,
    onClick,
    userListColors,
    isNotAssignedUser,
    setShowUserFormModal,
    isDisableAddUser,
  } = props;

  const I18N = useI18N();

  // Component Logic
  const handleColorUser = (target = COLOR_USER_LIST_DEFAULT) => new Proxy(target, {
    get: (array, prop) => ((array[prop]) ? array[prop] : array[prop.slice(-1)]),
  });

  const currentColors = handleColorUser(userListColors);
  const userListSizeClassName = size || 'md';
  const clickableClassName = onClick ? 'clickable' : '';
  const userListClassName = `c-user-list c-user-list_${userListSizeClassName} ${clickableClassName}`;

  const handleClick = (id) => ((typeof onClick === 'function') ? onClick(id) : null);

  const getUserAvatar = () => {
    const userList = users.map((user, index, array) => {
      const randomUserId = generateRandomStr();

      if (index < maxAmount) {
        return (
          <span
            key={user?.id || randomUserId}
            className="c-user-list__user"
            data-tooltip-id={isNotAssignedUser ? 'panel-tooltip' : undefined}
            data-tooltip-content={isNotAssignedUser ? (`${user?.userName}` || `${user.email}`) : undefined}
          >
            <UserAvatar
              size={userListSizeClassName}
              userFirstName={user?.firstName}
              userLastName={user?.lastName}
              userImageUrl={user?.userImageUrl}
              onClick={() => handleClick(user?.id || randomUserId)}
              backgroundColor={currentColors[user?.id || randomUserId]}
            />
          </span>
        );
      }

      if (index === maxAmount) {
        return (
          <span key={user?.id || randomUserId} className="c-user-list__user">
            <UserAvatar
              size={userListSizeClassName}
              userFirstName="+"
              userLastName={`${array.length - maxAmount}`}
              backgroundColor={currentColors[user?.id || randomUserId]}
            />
          </span>
        );
      }

      return null;
    });

    if (isNotAssignedUser) {
      userList.push(
        <span
          key="noAssignedUser"
          className="c-user-list__user"
          data-tooltip-id="panel-tooltip"
          data-tooltip-content={`${I18N.NO_ASSIGNED_USER}`}
        >
          <IconRemoveUser size={size} onClick={() => onClick(null)} />
        </span>,
      );
    }

    return userList;
  };

  return (
    <div className={userListClassName}>
      {getUserAvatar()}
      {(setShowUserFormModal && !isDisableAddUser) && <IconPlus size="xs" onClick={setShowUserFormModal} />}
    </div>
  );
};

export default UserList;
