import React from 'react';

import { useI18N } from '../../i18n/useI18N';
import { BOARD_PROPERTY } from '../../config/config';

import InputControl from '../InputControl';
import Modal from '../Modal';
import {
  IconEdit,
} from '../Icons';

import './BoardPrefix.scss';

const BoardPrefix = (props) => {
  const {
    activeBoard,
    boardData,
    handleChangeBoardField,
    isChangePrefixMode,
    setBoardData,
    setIsChangePrefixMode,
    updateBoard,
  } = props;
  const I18N = useI18N();

  const openChangePrefixModeModal = () => {
    setIsChangePrefixMode(true);
  };

  const closeChangePrefixModeModal = () => {
    setIsChangePrefixMode(false);
  };

  const acceptChangePrefixMode = () => {
    closeChangePrefixModeModal();
    updateBoard({ type: BOARD_PROPERTY.PREFIX, value: boardData.taskIndexPrefix });
  };

  const cancelChangePrefixMode = () => {
    setBoardData(activeBoard);
    closeChangePrefixModeModal();
  };

  return (
    <>
      <div className="c-board-actions-panel__board-prefix">
        <div className="c-board-actions-panel__board-prefix-header action-title">
          <span className="c-board-actions-panel__board-prefix-title action-subtitle">
            {I18N.BOARD_PREFIX}
          </span>
          <IconEdit
            size="xs"
            onClick={openChangePrefixModeModal}
          />
        </div>
        <span className="c-board-actions-panel__board-prefix-description">
          [
          {boardData.taskIndexPrefix
      || activeBoard.taskIndexPrefix
      || I18N.BOARD_PREFIX}
          -
          <span className="c-board-actions-panel__board-prefix-counter">
            XXXX
          </span>
          ]
        </span>
      </div>
      <Modal
        isVisible={isChangePrefixMode}
        title={I18N.BOARD_PREFIX}
        onClose={cancelChangePrefixMode}
        btnAcceptText={I18N.SAVE}
        btnAcceptHandler={acceptChangePrefixMode}
        btnCancelText={I18N.CANCEL}
        btnCancelHandler={cancelChangePrefixMode}
      >
        <InputControl
          name="taskIndexPrefix"
          type="text"
          onChange={handleChangeBoardField}
          placeholder={`${I18N.BOARD_PREFIX} - XXXX`}
          value={boardData.taskIndexPrefix || ''}
          suggestion={I18N.PREFIX_SUGGESTION}
        />
      </Modal>
    </>
  );
};

export default BoardPrefix;
